import { useDebounce } from "@uidotdev/usehooks";
import { useState, useCallback, useMemo, useEffect } from "react";
import {
    PipelineProductEnum,
    PipelineTypeEnum,
    PipelineMapList,
    InfraTypeEnum,
    InfrastructureMapList,
    AerialImagesList,
} from "../../../apiClient/generated";
import { useMapApiClient } from "../../../hooks";
import { useMapDataLoader } from "./dataLoader";
import { useQuery } from "@tanstack/react-query";
import { useMap } from "./mapState";
import {
    AerialImagesLayer,
    InfrastructureLayer,
    PipelineV2Layers,
} from "../layers/infrastructure";
import { MAP_ZOOM_SHOW_DETAILS } from "../constants";
import Supercluster from "supercluster";
import { createFeatureCollection } from "../../../utils/geopatialUtils";
import type { Feature } from "geojson";

export const usePipelinesOnMap = (
    enabled: boolean,
    pipelineProduct: PipelineProductEnum[],
    pipelineType: PipelineTypeEnum[],
    filterByArea?: any,
) => {
    const {
        debounced: { viewState, areaOnScreen },
    } = useMap("mainMap");
    const currentZoom = useMemo(() => viewState?.zoom, [viewState]);

    const apiClient = useMapApiClient();
    const debouncedProduct = useDebounce(pipelineProduct, 400);
    const debouncedPipelineType = useDebounce(pipelineType, 400);
    const [pipelineData, setPipelineData] = useState<PipelineMapList[]>([]);
    const [pipelineDetailsData, setPipelineDetailsData] = useState<
        PipelineMapList[]
    >([]);

    // Retrieve overview data
    const overviewDataLoader = useQuery({
        queryKey: [
            "pipelineOverview",
            debouncedProduct,
            debouncedPipelineType,
            filterByArea,
        ],
        queryFn: async () => {
            return await apiClient.mapPipelinesOverviewsRetrieve({
                pipelineProduct:
                    debouncedProduct.length > 0 ? debouncedProduct : undefined,
                pipelineType:
                    debouncedPipelineType.length > 0
                        ? debouncedPipelineType
                        : undefined,
                locationWithin: filterByArea
                    ? JSON.stringify(filterByArea)
                    : undefined,
                pipelineSizeMin: 5000,
            });
        },
        enabled,
    });

    const fetchDataFn = useCallback(
        (
            setter: React.Dispatch<React.SetStateAction<PipelineMapList[]>>,
            filter: {
                pipelineSizeMax?: number;
                pipelineSizeMin?: number;
                product?: PipelineProductEnum[];
                pipelineType?: PipelineTypeEnum[];
            },
        ) => {
            return async (areaToFetch?: any) => {
                const apiFilter = {
                    ...filter,
                    product:
                        filter.product && filter.product.length > 0
                            ? filter.product
                            : undefined,
                    pipelineType:
                        filter.pipelineType && filter.pipelineType.length > 0
                            ? filter.pipelineType
                            : undefined,
                };
                // Fetch page
                let response = await apiClient.mapPipelinesList({
                    ...apiFilter,
                    locationWithin: areaToFetch
                        ? JSON.stringify(areaToFetch)
                        : undefined,
                });
                setter((data) => data.concat(response.results));
                // If more pages exist, fetch those too.
                while (response.next) {
                    const url = new URL(response.next);
                    const parameters = new URLSearchParams(url.search);
                    response = await apiClient.mapPipelinesList({
                        ...apiFilter,
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                        cursor: parameters.get("cursor"),
                    });
                    setter((data) => data.concat(response.results));
                }
            };
        },
        [apiClient],
    );

    // Pipeline data
    const veryCoarseDataLoader = useMapDataLoader({
        loadDataCallback: useMemo(
            () =>
                fetchDataFn(setPipelineData, {
                    product: debouncedProduct,
                    pipelineType: debouncedPipelineType,
                    pipelineSizeMin: 5000,
                }),
            [fetchDataFn, debouncedProduct, debouncedPipelineType],
        ),
        zoomToStartFetching: 10,
        enabled,
        areaOnScreen,
        zoom: currentZoom,
        areaFilter: filterByArea,
    });
    const coarseDataLoader = useMapDataLoader({
        loadDataCallback: useMemo(
            () =>
                fetchDataFn(setPipelineData, {
                    product: debouncedProduct,
                    pipelineType: debouncedPipelineType,
                    pipelineSizeMin: 2000,
                    pipelineSizeMax: 5000,
                }),
            [fetchDataFn, debouncedProduct, debouncedPipelineType],
        ),
        zoomToStartFetching: 10,
        enabled: enabled && !veryCoarseDataLoader.loading,
        areaOnScreen,
        zoom: currentZoom,
        areaFilter: filterByArea,
    });
    const detailsDataLoader = useMapDataLoader({
        loadDataCallback: useMemo(
            () =>
                fetchDataFn(setPipelineDetailsData, {
                    product: debouncedProduct,
                    pipelineType: debouncedPipelineType,
                    pipelineSizeMax: 2000,
                }),
            [fetchDataFn, debouncedProduct, debouncedPipelineType],
        ),
        zoomToStartFetching: 13,
        // Always let the coarse data loader run first
        enabled: enabled && !coarseDataLoader.loading,
        areaOnScreen,
        zoom: currentZoom,
        areaFilter: filterByArea,
    });

    // Reset all data loading when filters change and
    // abort current requests.
    useEffect(() => {
        setPipelineData([]);
        veryCoarseDataLoader.resetState();
        coarseDataLoader.resetState();
        detailsDataLoader.resetState();
    }, [debouncedPipelineType, debouncedProduct, filterByArea]);

    const layers = useMemo(() => {
        return PipelineV2Layers(
            currentZoom >= MAP_ZOOM_SHOW_DETAILS
                ? [...pipelineData, ...pipelineDetailsData]
                : pipelineData,
            overviewDataLoader.data,
            enabled,
            currentZoom,
        );
    }, [
        currentZoom,
        enabled,
        pipelineData,
        pipelineDetailsData,
        overviewDataLoader.data,
    ]);

    return {
        loading:
            detailsDataLoader.loading ||
            coarseDataLoader.loading ||
            (overviewDataLoader.isLoading && overviewDataLoader.isFetching),
        pipelines: enabled ? pipelineData : [],
        overviews: enabled ? overviewDataLoader.data : {},
        layers,
    };
};

/**
 * Infrastructure data: custom hook that loads infrastructure-related
 * data (points, geometries and aerial images) based on filter values.
 *
 * Sites and other equipment are returned separately since they are
 * rendered and fetched in different zoom levels.
 */
export const useInfrastructureOnMap = (
    enabled: boolean,
    infraTypes: InfraTypeEnum[],
    enableOverviews: boolean,
    filterByArea?: any,
) => {
    const apiClient = useMapApiClient();
    // Overview (zoomed out data)
    const [sites, setSites] = useState<InfrastructureMapList[]>([]);
    // Detail (zoomed in data)
    const [equipment, setEquipment] = useState<InfrastructureMapList[]>([]);
    const [aerialImageData, setAerialImageData] = useState<AerialImagesList[]>(
        [],
    );

    const {
        debounced: { viewState, areaOnScreen },
    } = useMap("mainMap");
    const currentZoom = useMemo(() => viewState?.zoom, [viewState]);

    // Retrieve overview data
    const overviewDataLoader = useQuery({
        queryKey: ["infraCoarseOverview", infraTypes, filterByArea],
        queryFn: async () => {
            return await apiClient.mapInfrastructureOverviewsRetrieve({
                boxSize: 150,
                locationWithin: filterByArea
                    ? JSON.stringify(filterByArea)
                    : undefined,
            });
        },
        enabled: enabled && infraTypes.length > 0 && enableOverviews,
        refetchOnWindowFocus: false,
        staleTime: 500,
    });
    const fineOverviewDataLoader = useQuery({
        queryKey: ["infraOverview", infraTypes, filterByArea],
        queryFn: async () => {
            return await apiClient.mapInfrastructureOverviewsRetrieve({
                boxSize: 60,
                locationWithin: filterByArea
                    ? JSON.stringify(filterByArea)
                    : undefined,
            });
        },
        enabled:
            enabled &&
            !overviewDataLoader.isLoading &&
            infraTypes.length > 0 &&
            enableOverviews,
        refetchOnWindowFocus: false,
        staleTime: 500,
    });

    const fetchDataFn = useCallback(
        (
            setter: React.Dispatch<
                React.SetStateAction<InfrastructureMapList[]>
            >,
            filter: { infraType: InfraTypeEnum[] },
        ) => {
            return async (areaToFetch?: any) => {
                // Fetch page
                let response = await apiClient.mapInfrastructureList({
                    ...filter,
                    locationWithin: areaToFetch
                        ? JSON.stringify(areaToFetch)
                        : undefined,
                });
                setter((data) => data.concat(response.results));
                // If more pages exist, fetch those too.
                while (response.next) {
                    const url = new URL(response.next);
                    const parameters = new URLSearchParams(url.search);
                    response = await apiClient.mapInfrastructureList({
                        ...filter,
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                        cursor: parameters.get("cursor"),
                    });
                    setter((data) => data.concat(response.results));
                }
            };
        },
        [apiClient],
    );

    const fetchAerialImagesDataFn = useCallback(
        (setter: React.Dispatch<React.SetStateAction<AerialImagesList[]>>) => {
            return async (areaToFetch?: any) => {
                // Fetch page
                let response = await apiClient.mapAerialImagesList({
                    locationWithin: areaToFetch
                        ? JSON.stringify(areaToFetch)
                        : undefined,
                });
                setter((data) => data.concat(response.results));
                // If more pages exist, fetch those too.
                while (response.next) {
                    const url = new URL(response.next);
                    const parameters = new URLSearchParams(url.search);
                    response = await apiClient.mapAerialImagesList({
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                        cursor: parameters.get("cursor"),
                    });
                    setter((data) => data.concat(response.results));
                }
            };
        },
        [apiClient],
    );

    // Load sites first
    const siteDataLoader = useMapDataLoader({
        loadDataCallback: useMemo(
            () =>
                fetchDataFn(setSites, {
                    infraType: [InfraTypeEnum.Site],
                }),
            [fetchDataFn],
        ),
        zoomToStartFetching: enableOverviews ? 7.9 : 1,
        enabled: enabled,
        areaOnScreen,
        zoom: currentZoom,
        areaFilter: filterByArea,
    });

    // Data loader
    const equipmentDataLoader = useMapDataLoader({
        loadDataCallback: useMemo(
            () =>
                fetchDataFn(setEquipment, {
                    infraType: [
                        InfraTypeEnum.EquipmentGroup,
                        InfraTypeEnum.Equipment,
                    ],
                }),
            [fetchDataFn],
        ),
        zoomToStartFetching: 12,
        enabled: enabled && !siteDataLoader.loading && infraTypes.length > 0,
        areaOnScreen,
        zoom: currentZoom,
        areaFilter: filterByArea,
    });

    // Aerial images data first
    const aerialImagesDataLoader = useMapDataLoader({
        loadDataCallback: fetchAerialImagesDataFn(setAerialImageData),
        zoomToStartFetching: 12,
        enabled: enabled && infraTypes.length > 0,
        areaOnScreen,
        zoom: currentZoom,
        areaFilter: filterByArea,
    });

    // Reset all data loading when filters change and
    // abort current requests.
    useEffect(() => {
        setSites([]);
        setEquipment([]);
        setAerialImageData([]);
        siteDataLoader.resetState();
        equipmentDataLoader.resetState();
        aerialImagesDataLoader.resetState();
    }, [infraTypes, filterByArea]);

    const clusterData = useMemo(() => {
        const index = new Supercluster({ radius: 50, maxZoom: 11 });
        index.load(
            sites.map(
                (i) =>
                    ({
                        type: "Feature",
                        geometry: i.location,
                        properties: { id: i.id },
                    }) as any,
            ),
        );
        return index;
    }, [sites]);

    const clusteredPoints = useMemo(() => {
        if (!areaOnScreen) {
            return [];
        }

        if (currentZoom > MAP_ZOOM_SHOW_DETAILS) {
            return [];
        }

        // Annotate zoom level in which cluster is broken down.
        return clusterData
            .getClusters([-180, -90, 180, 90], currentZoom)
            .map((i) => {
                if (i.properties.cluster) {
                    i.properties.zoom = clusterData.getClusterExpansionZoom(
                        i.properties.cluster_id,
                    );
                    i.properties.tooltip = "Click to zoom in";
                }
                return i;
            });
    }, [clusterData, currentZoom]);

    // Memoize items shown on map based on loaded data,
    // zoom level and filter parameters.
    const infrastructureLayers = useMemo(() => {
        let pointsToShow: InfrastructureMapList[] = [];
        if (infraTypes.includes("SITE")) {
            if (currentZoom > MAP_ZOOM_SHOW_DETAILS) {
                pointsToShow = [...sites];
            } else {
                pointsToShow = [
                    ...sites.map((i) => ({ ...i, shape: undefined })),
                ];
            }
        }
        if (infraTypes.includes("EQUIPMENT")) {
            pointsToShow = [...pointsToShow, ...equipment];
        }
        let data: Feature[] = createFeatureCollection(pointsToShow).features;
        if (enableOverviews && currentZoom < MAP_ZOOM_SHOW_DETAILS) {
            data = clusteredPoints;
        }

        return [
            InfrastructureLayer(
                data,
                currentZoom > 3.5 && !fineOverviewDataLoader.isLoading
                    ? fineOverviewDataLoader.data
                    : overviewDataLoader.data,
                enabled && infraTypes.length > 0,
                currentZoom,
                undefined,
                enableOverviews,
            ),
        ];
    }, [
        infraTypes,
        enabled,
        enableOverviews,
        sites,
        clusteredPoints,
        currentZoom,
        equipment,
        overviewDataLoader.data,
        fineOverviewDataLoader.data,
    ]);

    const aerialImageLayers = useMemo(() => {
        return [AerialImagesLayer(aerialImageData, enabled, currentZoom)];
    }, [enabled, aerialImageData, currentZoom]);

    // Return data
    return {
        loading:
            equipmentDataLoader.loading ||
            siteDataLoader.loading ||
            aerialImagesDataLoader.loading ||
            (overviewDataLoader.isLoading && enabled) ||
            (fineOverviewDataLoader.isLoading && enabled),
        infrastructureLayers,
        aerialImageLayers,
    };
};
