import { Popover } from "@headlessui/react";
import { AccountsUsersListRequest, User } from "../../../apiClient/generated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faSpinner } from "@fortawesome/pro-light-svg-icons";
import { ReactNode, useMemo, useState } from "react";
import {
    autoUpdate,
    flip,
    offset,
    shift,
    useFloating,
} from "@floating-ui/react";
import { SearchInput } from "../../Inputs";
import { useAccountsApiClient } from "../../../hooks";
import { useQuery } from "@tanstack/react-query";
import { Modal } from "../../Modals";
import { PrimaryButton, SecondaryButton } from "../../Buttons";

interface EditableCellV2Props {
    label: string | ReactNode;
    children: ReactNode;
}

const EditableCellV2 = (props: EditableCellV2Props) => {
    const { refs, floatingStyles } = useFloating({
        strategy: "fixed",
        placement: "bottom-start",
        middleware: [
            flip(),
            offset({
                mainAxis: 3,
                crossAxis: -5,
            }),
            shift(),
        ],
        whileElementsMounted: autoUpdate,
    });

    return (
        <Popover as="div" ref={refs.setReference}>
            <Popover.Button className="flex gap-2 items-center hover:underline hover:text-ae-blue-550 group">
                {props.label}
                <FontAwesomeIcon
                    icon={faPencil}
                    className="w-3 hidden group-hover:block"
                />
                <div className="w-3 block group-hover:hidden" />
            </Popover.Button>
            <Popover.Panel
                ref={refs.setFloating}
                style={floatingStyles}
                className="min-w-56 overflow-auto rounded-md p-1 bg-white py-1 border-2 border-ae-slate-300"
            >
                {props.children}
            </Popover.Panel>
        </Popover>
    );
};

interface EditableViewProps {
    value?: number;
    onChange: (userId: User) => void;
    filters?: AccountsUsersListRequest;
}

const EditableView = (props: EditableViewProps) => {
    const [search, setSearch] = useState("");
    const apiClient = useAccountsApiClient();
    const usersQuery = useQuery({
        queryKey: ["users", props.filters],
        queryFn: async () => {
            return await apiClient.accountsUsersList(props.filters);
        },
        refetchOnWindowFocus: false,
        // Keep data for 2 minutes
        staleTime: 2 * 60 * 1000,
    });

    const users = useMemo((): User[] => {
        if (!usersQuery.data) {
            return [];
        }

        if (search.trim()) {
            return usersQuery.data.filter(
                (user) =>
                    user.id === props.value ||
                    `${user.firstName} ${user.lastName} ${user.email}`
                        .toLowerCase()
                        .includes(search.trim().toLowerCase()),
            );
        }

        return usersQuery.data;
    }, [usersQuery.data, search]);

    return (
        <div className="p-1 w-60">
            <SearchInput
                containerClassName="mb-2"
                value={search}
                onChange={setSearch}
                placeholder="Search users..."
            />
            <div className="flex flex-col gap-1 max-h-64 overflow-y-scroll">
                {users.map((user) => (
                    <button
                        className="w-full flex justify-start px-2 py-1 hover:bg-ae-slate-100 rounded disabled:text-ae-blue-550 disabled:border disabled:bg-white"
                        onClick={() => props.onChange(user)}
                        disabled={user.id === props.value}
                    >
                        {user.firstName} {user.lastName}
                    </button>
                ))}
            </div>
        </div>
    );
};

interface EditableUserCellProps {
    assignee?: {
        id: number;
        name: string;
    };
    save: (userId: number) => Promise<any>;
    filters?: AccountsUsersListRequest;
}

export const EditableUserCell = (props: EditableUserCellProps) => {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState<User>(null);

    const saveChanges = async (userId?: number) => {
        setLoading(true);
        try {
            await props.save(userId);
        } catch (e) {
            alert("Error updating user.");
        }
        setShowModal(false);
        setUser(null);
        setLoading(false);
    };

    const confirmSave = (newUser: User) => {
        if (!props.assignee.id) {
            setUser(newUser);
            setShowModal(true);
        } else {
            saveChanges(newUser.id);
        }
    };

    const closeModal = () => {
        setShowModal(false);
        setUser(null);
    };

    if (loading) {
        return (
            <FontAwesomeIcon
                icon={faSpinner}
                className="h-4 mr-2 animate-spin"
            />
        );
    }

    return (
        <>
            <EditableCellV2
                label={
                    props.assignee.id ? (
                        props.assignee.name
                    ) : (
                        <span className="underline text-ae-blue-550 ">
                            Assign
                        </span>
                    )
                }
            >
                <EditableView
                    value={props.assignee.id}
                    onChange={confirmSave}
                />
            </EditableCellV2>
            <Modal visible={showModal} size="sm" onClose={closeModal}>
                <h1 className="text-lg font-semibold mb-5 mt-2">
                    Assign EPA Event
                </h1>
                <p className="text-sm mb-1">
                    Assign this event to{" "}
                    <span className="font-semibold">
                        {user?.firstName} {user?.lastName}
                    </span>
                    ?
                </p>
                <p className="text-sm text-gray-500 mb-5">
                    This will change the state of the EPA event to
                    "Investigation started" and store the investigation date as
                    today.
                </p>
                <div className="flex gap-2">
                    <PrimaryButton
                        onClick={() => user && saveChanges(user?.id)}
                    >
                        Assign and start investigation
                    </PrimaryButton>
                    <SecondaryButton onClick={closeModal}>
                        Cancel
                    </SecondaryButton>
                </div>
            </Modal>
        </>
    );
};
