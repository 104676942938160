import { useEffect, useRef, useState } from "react";
import { useAppSelector, useDatauploadApiClient } from "../../hooks";
import { Modal } from "../../ui/Modals";
import { ArrowPathIcon, CloudArrowUpIcon } from "@heroicons/react/24/solid";
import UploadDropdown from "../UploadDropdown";
import { ALLOWED_FILE_EXTENSIONS } from "../../constants/globals";
import { PrimaryButton, SecondaryButton } from "../../ui/Buttons";
import { CompanyUploadedDataPackage } from "../../apiClient/generated";
import { UploadState, useDataPackageUploader } from "./hooks";
import {
    DocumentCheckIcon,
    DocumentIcon,
    TrashIcon,
    ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

function formatFilesize(size: number) {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return (
        (size / Math.pow(1024, i)).toFixed(2) +
        [" B", " kB", " MB", " GB", " TB"][i]
    );
}

interface FileUploadWithStatusProps extends UploadState {
    onRemove?: () => void;
}

const FileUploadWithStatus = (props: FileUploadWithStatusProps) => {
    return (
        <li
            className={`
            flex py-2 px-3 h-16 border items-center
            rounded text-ae-blue-900
            ${props.error ? "border-red-500" : "border-ae-gray-300"}
        `}
        >
            {props.status === "waiting" ? (
                <DocumentIcon className="w-8 h-8 stroke-1" />
            ) : props.status === "error" ? (
                <ExclamationTriangleIcon className="text-red-500 w-8 h-8 stroke-1" />
            ) : (
                <DocumentCheckIcon className="text-ae-blue-500 w-8 h-8 stroke-1" />
            )}

            <div className="ml-2 text-sm">
                <p className="font-semibold">{props.file.name}</p>
                {props.error ? (
                    <span className="text-red-500">{props.error}</span>
                ) : props.file.size !== 0 ? (
                    formatFilesize(props.file.size)
                ) : (
                    ""
                )}
            </div>

            <div className="flex-grow" />

            {(props.status === "waiting" || props.status === "error") && (
                <button
                    className="flex items-center p-2 rounded hover:bg-ae-neutral-200 "
                    onClick={props.onRemove}
                >
                    <TrashIcon className="h-6 w-6 text-ae-blue-900" />
                </button>
            )}
        </li>
    );
};

interface DataPackageCreateProps {
    visible: boolean;
    dataPackageId?: string;
    onClose: (uploadId?: string) => void;
}

// TODO: Break this component down into smaller parts
export const DataPackageCreate = (props: DataPackageCreateProps) => {
    // API Client and Data Package details
    const apiClient = useDatauploadApiClient();

    // Permissions
    const hasEditPermissions = useAppSelector(
        (state) => state.auth.permissions.dataUploadsEdit,
    );

    // Flow control
    const [dataPackage, setDataPackage] =
        useState<CompanyUploadedDataPackage>();
    const [creatingDataPackage, setCreatingDataPackage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [description, setDescription] = useState("");
    const [notes, setNotes] = useState("");
    const inputRef = useRef<HTMLInputElement>();

    // Error states
    const [descriptionError, setDescriptionError] = useState(false);
    const [uploadsError, setUploadsError] = useState(false);

    // Previously uploaded files
    const [dataPackageFilenames, setDataPackageFilenames] = useState<string[]>(
        [],
    );

    // Custom hook to handle uploads
    const dataUploader = useDataPackageUploader(dataPackage?.id);

    // UI helpers
    const hasFiles =
        Object.keys(dataUploader.uploadState).length > 0 ||
        dataPackageFilenames.length > 0;
    const uploading = Object.values(dataUploader.uploadState).some(
        (f) => f.status === "uploading",
    );
    const waiting = Object.values(dataUploader.uploadState).some(
        (f) => f.status === "waiting",
    );
    const allFilesUploaded = Object.values(dataUploader.uploadState).every(
        (f) => f.status === "success",
    );

    // Upload progress
    const currentUploadedSize = Object.values(dataUploader.uploadState)
        .filter((i) => i.status == "uploading")
        .reduce(
            (total, current) =>
                total +
                (current.status == "success"
                    ? current.total
                    : current.loaded || 0),
            0,
        );
    const totalUploadSize = Object.values(dataUploader.uploadState)
        .filter((i) => i.total && i.total !== 0)
        .reduce((total, current) => total + current.total, 0);

    // When files are added to dropzone
    const onDropFiles = (files: FileList) => {
        if (!files) return;
        setUploadsError(false);

        // Only add files if there's no duplicate already uploaded.
        const newFiles = [...files];
        const filesToUpload = newFiles.filter(
            (f) => !dataPackageFilenames.includes(f.name),
        );
        dataUploader.addFiles(filesToUpload);
    };

    // Clear error state
    useEffect(() => {
        if (descriptionError) {
            setDescriptionError(undefined);
        }
    }, [description]);

    // When modal loads intially, fetch data package
    // data if it exists.
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const response = await apiClient.datauploadDataPackageRetrieve({
                id: props.dataPackageId,
            });
            const filesInPackage =
                await apiClient.datauploadDataPackageFilesInPackageRetrieve({
                    id: props.dataPackageId,
                });
            setNotes(response.notes);
            setDescription(response.description);
            setDataPackage(response);
            setDataPackageFilenames(filesInPackage.files);
            setLoading(false);
        };
        if (props.dataPackageId) {
            fetchData();
        }
    }, []);

    // Handle upload start
    useEffect(() => {
        if (creatingDataPackage) {
            dataUploader.startUpload();
        }
    }, [creatingDataPackage, dataUploader]);

    // Handle upload finish
    useEffect(() => {
        const markAsComplete = async () => {
            try {
                await apiClient.datauploadDataPackageMarkCompleteCreate({
                    id: dataPackage.id,
                });
                props.onClose();
            } catch {
                alert(
                    "This action can only be performed by the user that created this data package.",
                );
            }
            setCreatingDataPackage(false);
        };
        if (creatingDataPackage && !waiting && !uploading) {
            if (allFilesUploaded) {
                markAsComplete();
            } else {
                setCreatingDataPackage(false);
            }
        }
    }, [
        creatingDataPackage,
        waiting,
        uploading,
        allFilesUploaded,
        dataPackage,
    ]);

    // Start creating item and close modal
    const createItemAndStartUpload = async () => {
        setLoading(true);

        if (!hasFiles) {
            setUploadsError(true);
        }

        try {
            if (!dataPackage) {
                const response = await apiClient.datauploadDataPackageCreate({
                    companyUploadedDataPackageRequest: {
                        description,
                        notes,
                    },
                });
                setDataPackage(response);
            }

            // Set flag to start uploading files
            if (hasFiles) {
                setCreatingDataPackage(true);
            }
        } catch (e) {
            try {
                const errorResponse = await e.response.json();
                setDescriptionError(errorResponse.description.join(" "));
            } catch {
                setError(
                    "Error while creating Data Package: the package name must be unique and/or you don't have permissions to perform this action.",
                );
            }
        }
        setLoading(false);
    };

    return (
        <Modal
            visible={props.visible}
            onClose={() => {
                if (
                    (!dataPackage && (description !== "" || notes !== "")) ||
                    Object.values(dataUploader.uploadState).length > 0
                ) {
                    if (
                        confirm(
                            "Are you sure you want to close the window? All changes here will be lost.",
                        )
                    ) {
                        props.onClose();
                    }
                } else if (!creatingDataPackage) {
                    props.onClose();
                }
            }}
            size="sm"
        >
            {/* Animated upload thingy */}
            <div className="ml-2 mt-2 mb-1">
                <div className="h-10 w-10 flex items-center justify-center rounded-full text-ae-blue-500 bg-ae-blue-100">
                    <CloudArrowUpIcon className="h-6 w-6" />
                </div>
                <div className="relative -mt-10 h-10 w-10 rounded-full border opacity-20 border-ae-blue-900 animate-ping" />
            </div>

            {creatingDataPackage ? (
                <>
                    <h1 className="text-ae-blue-900 text-lg font-semibold mb-2">
                        Data Package upload in progress
                    </h1>
                    <p className="text-ae-blue-900 text-sm leading-tight ">
                        Your data package is being securely uploaded.
                    </p>
                    <div className="my-4 bg-ae-blue-100 h-24 rounded flex flex-col items-center justify-center">
                        <p className="text-ae-blue-900 text-sm leading-tight mb-2">
                            Upload in progress
                        </p>
                        <p className="text-xl text-ae-blue-900 font-medium">
                            {!currentUploadedSize ? (
                                "Calculating..."
                            ) : (
                                <>
                                    {`${formatFilesize(
                                        currentUploadedSize,
                                    )} / ${formatFilesize(totalUploadSize)} (${(
                                        (100 * currentUploadedSize) /
                                        totalUploadSize
                                    ).toFixed(2)} %)`}
                                </>
                            )}
                        </p>
                    </div>
                    <p className="text-ae-blue-900 text-sm leading-tight ">
                        Feel free to pause the process and resume at your
                        convenience and come back to finish it later.
                    </p>
                    <div className="w-full flex gap-2 mt-4">
                        <div className="flex-grow" />
                        {(!dataPackage || !dataPackage.uploadComplete) && (
                            <PrimaryButton
                                onClick={() => {
                                    dataUploader.stopUploads();
                                    props.onClose();
                                }}
                            >
                                Pause upload
                            </PrimaryButton>
                        )}
                    </div>
                </>
            ) : (
                <>
                    {/* Title and description */}
                    <h1 className="text-ae-blue-900 text-lg font-semibold mb-2">
                        {dataPackage
                            ? "Continue Data Package upload"
                            : "Create a new Data Package"}
                    </h1>
                    {/* Scrollable form content */}
                    <div className="-mx-4 overflow-y-scroll max-h-[600px] ">
                        <div className="px-4">
                            <p className="text-ae-slate-600 text-sm leading-tight ">
                                A data package is a group of files that will be
                                combined into a single folder after upload.
                            </p>

                            {/* Loading state */}
                            {loading && (
                                <div className="my-4 flex items-center">
                                    <ArrowPathIcon className="h-6 w-6 animate-spin mr-2" />
                                    Loading...
                                </div>
                            )}

                            {/* Main content */}
                            {!loading && (
                                <div className="mt-4">
                                    {/* Description input */}
                                    <div>
                                        <div className="text-ae-slate-900 text-sm font-semibold mb-1">
                                            Description
                                            <span className="text-ae-slate-600 font-light">
                                                {" "}
                                                (must be unique)
                                            </span>
                                        </div>

                                        <input
                                            className={`
                                            rounded w-full disabled:bg-ae-neutral-100 h-9
                                            ${
                                                descriptionError
                                                    ? "border-red-500"
                                                    : "border-ae-gray-300"
                                            }
                                        `}
                                            type="text"
                                            value={description}
                                            onChange={(e) =>
                                                setDescription(e.target.value)
                                            }
                                            disabled={!!dataPackage}
                                        />
                                        {descriptionError && (
                                            <span className="text-sm text-red-500">
                                                {descriptionError}
                                            </span>
                                        )}
                                    </div>

                                    <div className="mt-3">
                                        <div className="text-ae-slate-900 text-sm font-semibold mb-1">
                                            Additional details
                                        </div>

                                        <textarea
                                            className="rounded w-full border-ae-gray-300 disabled:bg-ae-neutral-100"
                                            value={notes}
                                            onChange={(e) =>
                                                setNotes(e.target.value)
                                            }
                                            disabled={!!dataPackage}
                                        />
                                    </div>

                                    {/* Dropdown area and file manager */}
                                    <div
                                        className={`
                                    mt-3 rounded
                                    ${uploadsError && "border border-red-500"}
                                `}
                                    >
                                        {hasFiles && (
                                            <div className="flex flex-col gap-3 mb-3">
                                                {Object.values(
                                                    dataUploader.uploadState,
                                                )
                                                    .toSorted((a) =>
                                                        a.error
                                                            ? 0
                                                            : a.status ===
                                                                "waiting"
                                                              ? 1
                                                              : 2,
                                                    )
                                                    .map((state) => (
                                                        <FileUploadWithStatus
                                                            key={
                                                                state.file.name
                                                            }
                                                            onRemove={() =>
                                                                dataUploader.removeFile(
                                                                    state.file
                                                                        .name,
                                                                )
                                                            }
                                                            {...state}
                                                        />
                                                    ))}
                                                {dataPackageFilenames.map(
                                                    (item) => (
                                                        <FileUploadWithStatus
                                                            key={item}
                                                            status="success"
                                                            file={
                                                                new File(
                                                                    [],
                                                                    item,
                                                                )
                                                            }
                                                        />
                                                    ),
                                                )}
                                            </div>
                                        )}

                                        {(!dataPackage ||
                                            !dataPackage.uploadComplete) && (
                                            <UploadDropdown
                                                inputRef={inputRef}
                                                onChange={onDropFiles}
                                                accept={ALLOWED_FILE_EXTENSIONS.join(
                                                    ",",
                                                )}
                                                maxFileSize={5}
                                                multiple={true}
                                            />
                                        )}
                                    </div>
                                    {uploadsError && (
                                        <span className="text-sm text-red-500">
                                            Upload at least one file before
                                            submitting the package.
                                        </span>
                                    )}

                                    {error && (
                                        <p className="my-4 rounded bg-red-600 px-4 py-2 text-white">
                                            {error}
                                        </p>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    {/* Bottom controls */}
                    <div className="w-full flex gap-2 mt-4">
                        <div className="flex-grow" />
                        <SecondaryButton onClick={() => props.onClose()}>
                            Close
                        </SecondaryButton>
                        {(!dataPackage || !dataPackage.uploadComplete) &&
                            hasEditPermissions && (
                                <PrimaryButton
                                    onClick={createItemAndStartUpload}
                                >
                                    Submit data package
                                </PrimaryButton>
                            )}
                    </div>
                </>
            )}
        </Modal>
    );
};
