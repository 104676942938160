import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNotificationsApi } from "../hooks";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

export const NotificationChannelConfirmation = () => {
    const apiClient = useNotificationsApi();

    // Handle case when password reset token is present
    const [searchParams] = useSearchParams();

    // Form control states
    const [validationStatus, setValidationStatus] = useState<
        "LOADING" | "SUCCESS" | "ERROR"
    >("LOADING");

    useEffect(() => {
        const verifyChannel = async () => {
            const token = searchParams.get("token");
            try {
                await apiClient.notificationsChannelsConfirmEmailCreate({
                    channelConfirmRequest: { token },
                });
                setValidationStatus("SUCCESS");
            } catch {
                setValidationStatus("ERROR");
            }
        };
        verifyChannel();
    }, [apiClient, searchParams]);

    return (
        <>
            {validationStatus === "LOADING" ? (
                <div className="mt-10 flex flex-col items-center justify-center">
                    <ArrowPathIcon className="w-12 animate-spin" />
                </div>
            ) : validationStatus === "SUCCESS" ? (
                <div>
                    <div className="mt-10 flex flex-col items-center justify-center">
                        <CheckIcon className="w-12" />
                    </div>
                    <div className="mt-4 flex flex-col items-center justify-center text-lg">
                        Notification channel successfully verified!
                    </div>
                </div>
            ) : (
                <div>
                    <div className="mt-10 flex flex-col items-center justify-center">
                        <XMarkIcon className="w-12" />
                    </div>
                    <div className="mt-4 flex flex-col items-center justify-center text-lg">
                        Error while processing channel verification!
                    </div>
                    <div className="mt-4 flex flex-col items-center justify-center text-neutral-500">
                        <p>
                            This can happen if you already activated this
                            channel or have an issue in your URL.
                        </p>
                        <p>
                            Re-send the verification email and try again, and
                            contact the support if the issue persists.
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};
