/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `STOPPED` - Stopped
 * * `PENDING` - Pending
 * * `RUNNING` - Running
 * * `COMPLETE` - Complete
 * @export
 */
export const MatchingStatusEnum = {
    Stopped: 'STOPPED',
    Pending: 'PENDING',
    Running: 'RUNNING',
    Complete: 'COMPLETE'
} as const;
export type MatchingStatusEnum = typeof MatchingStatusEnum[keyof typeof MatchingStatusEnum];


export function instanceOfMatchingStatusEnum(value: any): boolean {
    return Object.values(MatchingStatusEnum).includes(value);
}

export function MatchingStatusEnumFromJSON(json: any): MatchingStatusEnum {
    return MatchingStatusEnumFromJSONTyped(json, false);
}

export function MatchingStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MatchingStatusEnum {
    return json as MatchingStatusEnum;
}

export function MatchingStatusEnumToJSON(value?: MatchingStatusEnum | null): any {
    return value as any;
}

