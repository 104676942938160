import { useEffect, useState } from "react";
import {
    EmissionRecordView,
    MapAerialImagesListRequest,
    SimpleInfrastructure,
} from "../../apiClient/generated";
import { useInfrastructureApiClient, useMapApiClient } from "../../hooks";
import { AerialImagesLayer } from "../MapV2/layers/infrastructure";
import * as turf from "@turf/turf";
import { GeoJsonLayer } from "@deck.gl/layers";
import { createFeatureCollection } from "../../utils/geopatialUtils";
import {
    EmissionImagesLayer,
    PlumeOutlinesLayer,
} from "../MapV2/layers/emissions";
import { AerialImagesList } from "../../apiClient/generated";
import { useMap } from "../MapV2/hooks/mapState";
import { MapBase } from "../MapV2/Map";
import { InfrastructureLayer } from "../MapV2/layers/infrastructure_legacy";
import { useQuery } from "@tanstack/react-query";
import { MAP_ZOOM_SHOW_DETAILS } from "../MapV2/constants";

interface EmissionRecordPreviewMap {
    infrastructure?: SimpleInfrastructure[];
    emissionRecords: EmissionRecordView[];
    onClick?: (infrastructure?: SimpleInfrastructure) => void;
}

/**
 * Fetches aerial images for the given infrastructure and emission records
 */
const useAerialImages = (
    emissionRecords: EmissionRecordView[],
    infrastructure?: SimpleInfrastructure[],
) => {
    const [aerialImages, setAerialImages] = useState<AerialImagesList[]>([]);
    const mapApiClient = useMapApiClient();
    useEffect(() => {
        const fetchData = async () => {
            const filter: MapAerialImagesListRequest = {};
            setAerialImages([]);
            const detectionDates = emissionRecords
                .map((record) => record.dataPoint.detectionTimestamp)
                .sort();
            if (detectionDates.length > 0) {
                const takenAtAfter = new Date(detectionDates[0]);
                const takenAtBefore = new Date(
                    detectionDates[detectionDates.length - 1],
                );

                takenAtAfter.setDate(takenAtAfter.getDate() - 10);
                takenAtBefore.setDate(takenAtBefore.getDate() + 10);

                filter.takenAtDataRangeBefore = takenAtBefore;
                filter.takenAtDataRangeAfter = takenAtAfter;
            }
            if (infrastructure && infrastructure.length > 0) {
                filter.infrastructure = infrastructure.map((i) => i.id);
                const response = await mapApiClient.mapAerialImagesList(filter);
                setAerialImages(response.results);
            }
        };
        fetchData();
    }, [infrastructure, emissionRecords, mapApiClient]);

    return aerialImages;
};

export const EmissionRecordPreviewMap = (props: EmissionRecordPreviewMap) => {
    const { flyTo } = useMap("EmissionRecordMinimap");

    // Retrieve infrastructure data
    const infrastructureApiClient = useInfrastructureApiClient();
    const infrastructureQuery = useQuery({
        queryKey: ["infrastructure", props.infrastructure],
        queryFn: async () => {
            const response =
                await infrastructureApiClient.infrastructureListList({
                    parent: props.infrastructure.map((i) => i.id),
                    pageSize: 50,
                });
            return response.results;
        },
    });

    // Retrieve plume outlines and plume images
    const mapApiClient = useMapApiClient();
    const plumesQuery = useQuery({
        queryKey: ["plumes", props.infrastructure, props.emissionRecords],
        queryFn: async () => {
            const response = await mapApiClient.mapPlumeImagesList({
                emission: props.emissionRecords.map((e) => e.id),
            });
            return response.results;
        },
    });
    const outlinesQuery = useQuery({
        queryKey: ["outlines", props.infrastructure, props.emissionRecords],
        queryFn: async () => {
            const response = await mapApiClient.mapPlumeOutlinesList({
                emission: props.emissionRecords.map((e) => e.id),
            });
            return response.results;
        },
    });

    useEffect(() => {
        if (props.infrastructure.length === 0) {
            return undefined;
        }

        const center = turf.center(
            turf.points(
                props.infrastructure.map((i) => i.location.coordinates),
            ),
        );

        // FIXME: for some weird state update reason, flyTo doesn't work directly after instancing the component.
        setTimeout(() => {
            flyTo(
                center.geometry.coordinates[1],
                center.geometry.coordinates[0],
                14,
            );
        }, 100);
    }, [props.infrastructure, flyTo]);

    const aerialImages = useAerialImages(
        props.emissionRecords,
        props.infrastructure,
    );

    const emissionKey =
        props.emissionRecords.map((i) => i.id).join("-") || "empty";

    const layers = [
        AerialImagesLayer(aerialImages, true, 13),
        PlumeOutlinesLayer(
            (outlinesQuery.data || []).filter((p) => !p.hasPlume),
            "plume_outlines",
            true,
            MAP_ZOOM_SHOW_DETAILS + 1,
            0.4,
        ),
        EmissionImagesLayer(
            plumesQuery.data || [],
            "plume_images",
            true,
            MAP_ZOOM_SHOW_DETAILS + 1,
            0.4,
        ),
        InfrastructureLayer({
            data: infrastructureQuery.data || [],
            enabledLayers: ["infrastructure"],
        }),
        new GeoJsonLayer({
            id: emissionKey,
            data: createFeatureCollection(
                props.emissionRecords.map((record) => record.dataPoint),
            ),
            pointType: "circle",
            filled: true,
            visible: true,
            getPointRadius: 5,
            pointRadiusUnits: "pixels",
            lineWidthUnits: "pixels",
            pickable: true,
            getFillColor: [255, 0, 0, 255],
            autoHighlight: true,
            getLineWidth: 1,
            getLineColor: [0, 0, 0, 255],
        }),
    ];

    return (
        <div
            key={emissionKey}
            className="relative h-[630px] overflow-hidden rounded-lg"
        >
            <MapBase
                mapId="EmissionRecordMinimap"
                layers={[layers]}
                showScaleControl
                showZoomControl
                getTooltip={(info) => {
                    if (!info.object) {
                        return;
                    }
                    if ("equipmentType" in info.object.properties) {
                        return `${info.object.properties.name} (${info.object.properties.siteName})`;
                    }
                    return "Emission";
                }}
            />
        </div>
    );
};
