import { useQuery } from "@tanstack/react-query";
import { PickingInfo } from "deck.gl";
import {
    useDataPointsApi,
    useEmissionRecordsApiClient,
    useInfrastructureApiClient,
} from "../../hooks";
import { DateTime } from "luxon";

export interface PickedItems {
    pointClicked: {
        x: number;
        y: number;
    };
    infrastructures: PickingInfo[];
    pipelines: PickingInfo[];
    emissions: PickingInfo[];
    dataPoints: PickingInfo[];
}

const InfrastructureItem = ({
    infrastructureId,
    onClick,
}: {
    infrastructureId: string;
    onClick: () => void;
}) => {
    const apiClient = useInfrastructureApiClient();
    const infrastructureQuery = useQuery({
        queryKey: ["infrastructure", infrastructureId],
        queryFn: async () => {
            const response = await apiClient.infrastructureListRetrieve({
                id: infrastructureId,
            });
            return response;
        },
        staleTime: Infinity,
    });

    if (!infrastructureQuery.data) {
        return null;
    }

    return (
        <button
            className="px-3 w-full hover:bg-neutral-100 border-y border-white hover:border-ae-gray-300"
            onClick={onClick}
        >
            <div className="font-semibold text-left">
                {infrastructureQuery.data.siteName}
            </div>
            <div className="text-gray-600 text-left text-balance">
                <span className="capitalize">
                    {(infrastructureQuery.data.infraType || "")
                        .replaceAll("_", " ")
                        .toLocaleLowerCase()}
                </span>
                {": "}
                {infrastructureQuery.data.equipmentType}
                {" - "}
                {infrastructureQuery.data.name}
            </div>
        </button>
    );
};

const PipelineItem = ({
    pipelineId,
    onClick,
}: {
    pipelineId: string;
    onClick: () => void;
}) => {
    const apiClient = useInfrastructureApiClient();
    const pipelineQuery = useQuery({
        queryKey: ["pipeline", pipelineId],
        queryFn: async () => {
            const response = await apiClient.infrastructurePipelineRetrieve({
                id: pipelineId,
            });
            return response;
        },
        staleTime: Infinity,
    });

    if (!pipelineQuery.data) {
        return null;
    }

    return (
        <button
            className="px-3 w-full hover:bg-neutral-100 border-y border-white hover:border-ae-gray-300"
            onClick={onClick}
        >
            <div className="font-semibold text-left">
                {pipelineQuery.data.lineId}
            </div>
            <div className="text-gray-600 text-left text-balance">
                {pipelineQuery.data.pipelineProduct}
                {" - "}
                {pipelineQuery.data.pipelineType}
            </div>
        </button>
    );
};

const EmissionItem = ({
    emissionId,
    dataPointId,
    onClick,
}: {
    emissionId?: string;
    dataPointId?: string;
    onClick: () => void;
}) => {
    const emissionsApiClient = useEmissionRecordsApiClient();
    const datapointsApiClient = useDataPointsApi();

    const emissionQuery = useQuery({
        queryKey: ["emission", emissionId],
        queryFn: async () => {
            const response = await emissionsApiClient.emissionRecordsRetrieve({
                id: emissionId,
            });
            return response;
        },
        staleTime: Infinity,
        enabled: !!emissionId,
    });

    const dataPointQuery = useQuery({
        queryKey: ["datapoint", dataPointId],
        queryFn: async () => {
            const response = await datapointsApiClient.dataPointsRetrieve({
                id: dataPointId,
            });
            return response;
        },
        staleTime: Infinity,
        enabled: !!dataPointId,
    });

    if (!dataPointQuery.data && !emissionQuery.data) {
        return null;
    }

    const provider =
        dataPointQuery.data?.providerName || emissionQuery.data?.providerName;
    const rate =
        dataPointQuery.data?.detectedRate ||
        emissionQuery.data?.dataPoint.detectedRate;
    const concentration =
        dataPointQuery.data?.concentration ||
        emissionQuery.data?.dataPoint.concentration;
    const timestamp =
        dataPointQuery.data?.detectionTimestamp ||
        emissionQuery.data?.dataPoint.detectionTimestamp;

    return (
        <button
            className="px-3 w-full hover:bg-neutral-100 border-y border-white hover:border-ae-gray-300"
            onClick={onClick}
        >
            <div className="font-semibold text-left">
                {DateTime.fromJSDate(timestamp)
                    .setZone("utc")
                    .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}
            </div>
            <div className="text-gray-600 text-left text-balance">
                {provider}
                {" - "}
                {rate && `${(rate / 1000).toFixed(1)} kg/h`}
                {concentration && `${concentration.toFixed(1)} ppm*m`}
            </div>
        </button>
    );
};

interface MapPopupProps {
    picked: PickedItems;
    onClickItem: (item: PickingInfo) => void;
}

/**
 * Map Popup: when overlapping points are clicked.
 *
 * FIXME: Pop up doesn't follow map point.
 */
export const MapPopup = (props: MapPopupProps) => {
    const { picked } = props;
    return (
        <div
            className="absolute ml-4 -mt-5"
            style={{
                top: picked.pointClicked.y,
                left: picked.pointClicked.x,
            }}
        >
            <div className="rounded bg-white w-64 max-h-72 overflow-auto flex flex-col text-sm py-2">
                {(picked.emissions.length > 0 ||
                    picked.dataPoints.length > 0) && (
                    <div>
                        <p className="text-ae-slate-600 font-semibold px-3">
                            Emissions
                        </p>
                        {picked.emissions.map((emission) => (
                            <EmissionItem
                                emissionId={emission.object.properties.id}
                                onClick={() => props.onClickItem(emission)}
                            />
                        ))}
                        {picked.dataPoints.map((dataPoint) => (
                            <EmissionItem
                                dataPointId={dataPoint.object.properties.id}
                                onClick={() => props.onClickItem(dataPoint)}
                            />
                        ))}
                    </div>
                )}
                {picked.infrastructures.length > 0 && (
                    <div>
                        <p className="text-ae-slate-600 font-semibold px-3 pt-2">
                            Infrastructure
                        </p>
                        {picked.infrastructures.map((infrastructure) => (
                            <InfrastructureItem
                                infrastructureId={
                                    infrastructure.object.properties.id
                                }
                                onClick={() =>
                                    props.onClickItem(infrastructure)
                                }
                            />
                        ))}
                    </div>
                )}
                {picked.pipelines.length > 0 && (
                    <div>
                        <p className="text-ae-slate-600 font-semibold px-3 pt-2">
                            Pipelines
                        </p>
                        {picked.pipelines.map((pipeline) => (
                            <PipelineItem
                                pipelineId={pipeline.object.properties.id}
                                onClick={() => props.onClickItem(pipeline)}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};
