/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GeoJSONFeature } from './GeoJSONFeature';
import {
    GeoJSONFeatureFromJSON,
    GeoJSONFeatureFromJSONTyped,
    GeoJSONFeatureToJSON,
} from './GeoJSONFeature';

/**
 * Custom serializer to properly pass GeoJSON types to frontend.
 * @export
 * @interface GeoJSONFeatureCollection
 */
export interface GeoJSONFeatureCollection {
    /**
     * 
     * @type {string}
     * @memberof GeoJSONFeatureCollection
     */
    type?: string;
    /**
     * 
     * @type {Array<GeoJSONFeature>}
     * @memberof GeoJSONFeatureCollection
     */
    features: Array<GeoJSONFeature>;
}

/**
 * Check if a given object implements the GeoJSONFeatureCollection interface.
 */
export function instanceOfGeoJSONFeatureCollection(value: object): boolean {
    if (!('features' in value)) return false;
    return true;
}

export function GeoJSONFeatureCollectionFromJSON(json: any): GeoJSONFeatureCollection {
    return GeoJSONFeatureCollectionFromJSONTyped(json, false);
}

export function GeoJSONFeatureCollectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeoJSONFeatureCollection {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : json['type'],
        'features': ((json['features'] as Array<any>).map(GeoJSONFeatureFromJSON)),
    };
}

export function GeoJSONFeatureCollectionToJSON(value?: GeoJSONFeatureCollection | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'features': ((value['features'] as Array<any>).map(GeoJSONFeatureToJSON)),
    };
}

