/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pipeline } from './Pipeline';
import {
    PipelineFromJSON,
    PipelineFromJSONTyped,
    PipelineToJSON,
} from './Pipeline';

/**
 * 
 * @export
 * @interface PaginatedPipelineList
 */
export interface PaginatedPipelineList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedPipelineList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPipelineList
     */
    next?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPipelineList
     */
    previous?: string;
    /**
     * 
     * @type {Array<Pipeline>}
     * @memberof PaginatedPipelineList
     */
    results: Array<Pipeline>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedPipelineList
     */
    numPages?: number;
}

/**
 * Check if a given object implements the PaginatedPipelineList interface.
 */
export function instanceOfPaginatedPipelineList(value: object): boolean {
    if (!('count' in value)) return false;
    if (!('results' in value)) return false;
    return true;
}

export function PaginatedPipelineListFromJSON(json: any): PaginatedPipelineList {
    return PaginatedPipelineListFromJSONTyped(json, false);
}

export function PaginatedPipelineListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedPipelineList {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(PipelineFromJSON)),
        'numPages': json['num_pages'] == null ? undefined : json['num_pages'],
    };
}

export function PaginatedPipelineListToJSON(value?: PaginatedPipelineList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(PipelineToJSON)),
        'num_pages': value['numPages'],
    };
}

