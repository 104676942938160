/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ConflictMatchingStatusEnum } from './ConflictMatchingStatusEnum';
import {
    ConflictMatchingStatusEnumFromJSON,
    ConflictMatchingStatusEnumFromJSONTyped,
    ConflictMatchingStatusEnumToJSON,
} from './ConflictMatchingStatusEnum';
import type { CsvDataSridEnum } from './CsvDataSridEnum';
import {
    CsvDataSridEnumFromJSON,
    CsvDataSridEnumFromJSONTyped,
    CsvDataSridEnumToJSON,
} from './CsvDataSridEnum';
import type { MatchingStatusEnum } from './MatchingStatusEnum';
import {
    MatchingStatusEnumFromJSON,
    MatchingStatusEnumFromJSONTyped,
    MatchingStatusEnumToJSON,
} from './MatchingStatusEnum';

/**
 * Extends `InfrastructureImportSerializer` to add admin only fields
 * @export
 * @interface AdminInfrastructureImportRequest
 */
export interface AdminInfrastructureImportRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminInfrastructureImportRequest
     */
    owner: number;
    /**
     * 
     * @type {CsvDataSridEnum}
     * @memberof AdminInfrastructureImportRequest
     */
    csvDataSrid?: CsvDataSridEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminInfrastructureImportRequest
     */
    csvInputFilename: string;
    /**
     * 
     * @type {MatchingStatusEnum}
     * @memberof AdminInfrastructureImportRequest
     */
    matchingStatus?: MatchingStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof AdminInfrastructureImportRequest
     */
    exactMatchDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminInfrastructureImportRequest
     */
    nearMatchDistance?: number;
    /**
     * 
     * @type {ConflictMatchingStatusEnum}
     * @memberof AdminInfrastructureImportRequest
     */
    conflictMatchingStatus?: ConflictMatchingStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof AdminInfrastructureImportRequest
     */
    crossOperatorConflictDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminInfrastructureImportRequest
     */
    crossOperatorNearMatchDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminInfrastructureImportRequest
     */
    similarityCheckerCentroidDistance?: number;
    /**
     * 
     * @type {Blob}
     * @memberof AdminInfrastructureImportRequest
     */
    csvConflictsFile: Blob;
}

/**
 * Check if a given object implements the AdminInfrastructureImportRequest interface.
 */
export function instanceOfAdminInfrastructureImportRequest(value: object): boolean {
    if (!('owner' in value)) return false;
    if (!('csvInputFilename' in value)) return false;
    if (!('csvConflictsFile' in value)) return false;
    return true;
}

export function AdminInfrastructureImportRequestFromJSON(json: any): AdminInfrastructureImportRequest {
    return AdminInfrastructureImportRequestFromJSONTyped(json, false);
}

export function AdminInfrastructureImportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminInfrastructureImportRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'owner': json['owner'],
        'csvDataSrid': json['csv_data_srid'] == null ? undefined : CsvDataSridEnumFromJSON(json['csv_data_srid']),
        'csvInputFilename': json['csv_input_filename'],
        'matchingStatus': json['matching_status'] == null ? undefined : MatchingStatusEnumFromJSON(json['matching_status']),
        'exactMatchDistance': json['exact_match_distance'] == null ? undefined : json['exact_match_distance'],
        'nearMatchDistance': json['near_match_distance'] == null ? undefined : json['near_match_distance'],
        'conflictMatchingStatus': json['conflict_matching_status'] == null ? undefined : ConflictMatchingStatusEnumFromJSON(json['conflict_matching_status']),
        'crossOperatorConflictDistance': json['cross_operator_conflict_distance'] == null ? undefined : json['cross_operator_conflict_distance'],
        'crossOperatorNearMatchDistance': json['cross_operator_near_match_distance'] == null ? undefined : json['cross_operator_near_match_distance'],
        'similarityCheckerCentroidDistance': json['similarity_checker_centroid_distance'] == null ? undefined : json['similarity_checker_centroid_distance'],
        'csvConflictsFile': json['csv_conflicts_file'],
    };
}

export function AdminInfrastructureImportRequestToJSON(value?: AdminInfrastructureImportRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'owner': value['owner'],
        'csv_data_srid': CsvDataSridEnumToJSON(value['csvDataSrid']),
        'csv_input_filename': value['csvInputFilename'],
        'matching_status': MatchingStatusEnumToJSON(value['matchingStatus']),
        'exact_match_distance': value['exactMatchDistance'],
        'near_match_distance': value['nearMatchDistance'],
        'conflict_matching_status': ConflictMatchingStatusEnumToJSON(value['conflictMatchingStatus']),
        'cross_operator_conflict_distance': value['crossOperatorConflictDistance'],
        'cross_operator_near_match_distance': value['crossOperatorNearMatchDistance'],
        'similarity_checker_centroid_distance': value['similarityCheckerCentroidDistance'],
        'csv_conflicts_file': value['csvConflictsFile'],
    };
}

