/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FilterItemDetailGeometry } from './FilterItemDetailGeometry';
import {
    FilterItemDetailGeometryFromJSON,
    FilterItemDetailGeometryFromJSONTyped,
    FilterItemDetailGeometryToJSON,
} from './FilterItemDetailGeometry';

/**
 * Serializer for `FilterItem` items with the shape.
 * @export
 * @interface FilterItemDetail
 */
export interface FilterItemDetail {
    /**
     * 
     * @type {number}
     * @memberof FilterItemDetail
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof FilterItemDetail
     */
    name: string;
    /**
     * 
     * @type {FilterItemDetailGeometry}
     * @memberof FilterItemDetail
     */
    geometry?: FilterItemDetailGeometry;
}

/**
 * Check if a given object implements the FilterItemDetail interface.
 */
export function instanceOfFilterItemDetail(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    return true;
}

export function FilterItemDetailFromJSON(json: any): FilterItemDetail {
    return FilterItemDetailFromJSONTyped(json, false);
}

export function FilterItemDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterItemDetail {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'geometry': json['geometry'] == null ? undefined : FilterItemDetailGeometryFromJSON(json['geometry']),
    };
}

export function FilterItemDetailToJSON(value?: Omit<FilterItemDetail, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'geometry': FilterItemDetailGeometryToJSON(value['geometry']),
    };
}

