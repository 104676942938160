import { useState } from "react";
import { DataUploadApiDataTable } from "../components/DataUpload/UploadList";
import { PermissionGate } from "./PermissionGate";
import { DataPackageCreate } from "../components/DataUpload/DataPackageCreate";
import { useAppSelector } from "../hooks";
import { DashboardContainer, DashboardHeader } from "../ui/DashboardComponents";
import { PlusIcon } from "@heroicons/react/24/outline";

export const DataUploadsPage = () => {
    const [renderKey, setRenderKey] = useState(new Date());

    // Permissions
    const hasEditPermissions = useAppSelector(
        (state) => state.auth.permissions.dataUploadsEdit,
    );

    // Flow control for detail view
    const [selectedId, setSelectedId] = useState<string>();
    const [create, setCreate] = useState(false);

    // Actions
    const actions = [];
    if (hasEditPermissions) {
        actions.push({
            label: (
                <span className="flex items-center gap-2">
                    <PlusIcon className="w-5 h-5" />
                    New Data Package
                </span>
            ),
            fn: () => setCreate(true),
        });
    }

    return (
        <PermissionGate permission="dataUploadsView">
            <DashboardContainer>
                <DashboardHeader title="Data Upload" actions={actions} />

                <div
                    key={renderKey.toISOString()}
                    className="flex flex-1 overflow-hidden"
                >
                    <DataUploadApiDataTable
                        viewDetails={(id) => setSelectedId(id)}
                    />
                </div>

                {create && (
                    <DataPackageCreate
                        visible={create}
                        onClose={(uploadId?: string) => {
                            setCreate(false);
                            setRenderKey(new Date());
                            if (uploadId != undefined) {
                                setSelectedId(uploadId);
                            }
                        }}
                    />
                )}

                {selectedId && (
                    <DataPackageCreate
                        key={selectedId}
                        dataPackageId={selectedId}
                        onClose={() => {
                            setSelectedId(undefined);
                            setRenderKey(new Date());
                        }}
                        visible={!!selectedId}
                    />
                )}
            </DashboardContainer>
        </PermissionGate>
    );
};
