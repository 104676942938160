/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FilterGroup,
  FilterItemDetail,
  PaginatedFilterGroupList,
  PaginatedFilterItemList,
} from '../models/index';
import {
    FilterGroupFromJSON,
    FilterGroupToJSON,
    FilterItemDetailFromJSON,
    FilterItemDetailToJSON,
    PaginatedFilterGroupListFromJSON,
    PaginatedFilterGroupListToJSON,
    PaginatedFilterItemListFromJSON,
    PaginatedFilterItemListToJSON,
} from '../models/index';

export interface GeodataGroupsListRequest {
    ordering?: string;
    page?: number;
    pageSize?: number;
}

export interface GeodataGroupsRetrieveRequest {
    id: number;
}

export interface GeodataItemsListRequest {
    group?: number;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface GeodataItemsRetrieveRequest {
    id: number;
}

/**
 * 
 */
export class GeodataApi extends runtime.BaseAPI {

    /**
     * A viewset to retrieve filter groups
     */
    async geodataGroupsListRaw(requestParameters: GeodataGroupsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedFilterGroupList>> {
        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/geodata/groups/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedFilterGroupListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve filter groups
     */
    async geodataGroupsList(requestParameters: GeodataGroupsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedFilterGroupList> {
        const response = await this.geodataGroupsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve filter groups
     */
    async geodataGroupsRetrieveRaw(requestParameters: GeodataGroupsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilterGroup>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling geodataGroupsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/geodata/groups/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilterGroupFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve filter groups
     */
    async geodataGroupsRetrieve(requestParameters: GeodataGroupsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilterGroup> {
        const response = await this.geodataGroupsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve public data point information for the map.
     */
    async geodataItemsListRaw(requestParameters: GeodataItemsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedFilterItemList>> {
        const queryParameters: any = {};

        if (requestParameters['group'] != null) {
            queryParameters['group'] = requestParameters['group'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/geodata/items/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedFilterItemListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve public data point information for the map.
     */
    async geodataItemsList(requestParameters: GeodataItemsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedFilterItemList> {
        const response = await this.geodataItemsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve public data point information for the map.
     */
    async geodataItemsRetrieveRaw(requestParameters: GeodataItemsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilterItemDetail>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling geodataItemsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/geodata/items/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilterItemDetailFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve public data point information for the map.
     */
    async geodataItemsRetrieve(requestParameters: GeodataItemsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilterItemDetail> {
        const response = await this.geodataItemsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
