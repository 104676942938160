import { useAtom } from "jotai";
import { useCallback } from "react";
import {
    MapDataState,
    mapDataStateFamily,
    MapFiltersState,
    mapFiltersStateFamily,
    MapSelectedContext,
} from "../state";
import { produce } from "immer";

export const useMapData = (
    // FIXME: to avoid having to pass mapId everytime, accept either this input or a context (?)
    mapId: string,
    initialState?: Partial<MapDataState>,
) => {
    // Atom to hold map state here.
    const [mapDataState, setMapDataState] = useAtom(
        mapDataStateFamily({ mapId: mapId, initialState }),
    );

    // Setters for map selections
    const setSelectedContext = useCallback(
        (selectedContext: MapSelectedContext) => {
            setMapDataState((ds) => ({
                ...ds,
                selectedContext,
            }));
        },
        [setMapDataState],
    );

    return {
        ...mapDataState,
        setMapDataState,
        setSelectedContext,
    };
};

export const useMapFilters = (
    // FIXME: to avoid having to pass mapId everytime, accept either this input or a context (?)
    mapId: string,
    initialState?: Partial<MapFiltersState>,
) => {
    // Atom to hold map state here.
    const [filterState, setFilterState] = useAtom(
        mapFiltersStateFamily({ mapId: mapId, initialState }),
    );
    // Create updater function with immer
    const updateMapFilterState = useCallback(
        (updater: (draft: MapFiltersState) => void) => {
            setFilterState((currentState: MapFiltersState) =>
                produce(currentState, updater),
            );
        },
        [setFilterState],
    );

    return {
        filterState,
        updateMapFilterState,
    };
};
