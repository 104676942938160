/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PipelineProductEnum } from './PipelineProductEnum';
import {
    PipelineProductEnumFromJSON,
    PipelineProductEnumFromJSONTyped,
    PipelineProductEnumToJSON,
} from './PipelineProductEnum';
import type { PipelineTypeEnum } from './PipelineTypeEnum';
import {
    PipelineTypeEnumFromJSON,
    PipelineTypeEnumFromJSONTyped,
    PipelineTypeEnumToJSON,
} from './PipelineTypeEnum';

/**
 * Full Pipeline serializer, used to display table and
 * detailed infrastructure information.
 * @export
 * @interface Pipeline
 */
export interface Pipeline {
    /**
     * 
     * @type {string}
     * @memberof Pipeline
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof Pipeline
     */
    readonly ownerName: string;
    /**
     * 
     * @type {string}
     * @memberof Pipeline
     */
    lineId: string;
    /**
     * 
     * @type {PipelineProductEnum}
     * @memberof Pipeline
     */
    pipelineProduct: PipelineProductEnum;
    /**
     * 
     * @type {PipelineTypeEnum}
     * @memberof Pipeline
     */
    pipelineType: PipelineTypeEnum;
}

/**
 * Check if a given object implements the Pipeline interface.
 */
export function instanceOfPipeline(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('ownerName' in value)) return false;
    if (!('lineId' in value)) return false;
    if (!('pipelineProduct' in value)) return false;
    if (!('pipelineType' in value)) return false;
    return true;
}

export function PipelineFromJSON(json: any): Pipeline {
    return PipelineFromJSONTyped(json, false);
}

export function PipelineFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pipeline {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'ownerName': json['owner_name'],
        'lineId': json['line_id'],
        'pipelineProduct': PipelineProductEnumFromJSON(json['pipeline_product']),
        'pipelineType': PipelineTypeEnumFromJSON(json['pipeline_type']),
    };
}

export function PipelineToJSON(value?: Omit<Pipeline, 'id'|'owner_name'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'line_id': value['lineId'],
        'pipeline_product': PipelineProductEnumToJSON(value['pipelineProduct']),
        'pipeline_type': PipelineTypeEnumToJSON(value['pipelineType']),
    };
}

