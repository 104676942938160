import { useState } from "react";
import { SecondaryButton } from "../../ui/Buttons";
import { Modal } from "../../ui/Modals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTableColumns } from "@fortawesome/sharp-light-svg-icons";

type ColumnVisibilityDef = {
    id: string;
    name: string;
}[];

interface TableSettingsModalProps {
    columnVisibility: {
        [k: string]: boolean;
    };
    setColumnVisibility: (newStates: { [k: string]: boolean }) => void;
    configurableColumns: ColumnVisibilityDef;
}

export const TableSettingsModal = (props: TableSettingsModalProps) => {
    const [showTableSettings, setShowTableSettings] = useState(false);

    return (
        <>
            <SecondaryButton
                onClick={() => setShowTableSettings(true)}
                variant="sm"
            >
                <FontAwesomeIcon icon={faTableColumns} className="w-4" />
                Configure table
            </SecondaryButton>

            {/* Table settings modal */}
            <Modal
                visible={showTableSettings}
                size="sm"
                onClose={() => setShowTableSettings(false)}
            >
                <h1 className="mt-2">Table settings</h1>

                <div className="text-sm mt-4">
                    <p className="mb-2">Visible columns</p>
                    <ul className="flex flex-col gap-1">
                        {props.configurableColumns.map((c, index) => (
                            <li
                                key={index}
                                className="flex gap-2 items-center"
                                onClick={() => {
                                    // FIXME: tiny state handling bug here: initially trying to deselect items don't work.
                                    const newValues = {
                                        ...props.columnVisibility,
                                    };
                                    newValues[c.id] =
                                        !props.columnVisibility[c.id] || false;
                                    props.setColumnVisibility(newValues);
                                }}
                            >
                                <input
                                    type="checkbox"
                                    className="text-ae-blue-500 rounded"
                                    checked={
                                        !(c.id in props.columnVisibility) ||
                                        props.columnVisibility[c.id]
                                    }
                                />
                                {c.name}
                            </li>
                        ))}
                    </ul>
                </div>
            </Modal>
        </>
    );
};
