/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `OIL` - Oil
 * * `WATER` - Water
 * * `GAS` - Gas
 * * `NGL` - NGL
 * * `HYDROGEN` - Hydrogen
 * @export
 */
export const PipelineCommodityEnum = {
    Oil: 'OIL',
    Water: 'WATER',
    Gas: 'GAS',
    Ngl: 'NGL',
    Hydrogen: 'HYDROGEN'
} as const;
export type PipelineCommodityEnum = typeof PipelineCommodityEnum[keyof typeof PipelineCommodityEnum];


export function instanceOfPipelineCommodityEnum(value: any): boolean {
    return Object.values(PipelineCommodityEnum).includes(value);
}

export function PipelineCommodityEnumFromJSON(json: any): PipelineCommodityEnum {
    return PipelineCommodityEnumFromJSONTyped(json, false);
}

export function PipelineCommodityEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PipelineCommodityEnum {
    return json as PipelineCommodityEnum;
}

export function PipelineCommodityEnumToJSON(value?: PipelineCommodityEnum | null): any {
    return value as any;
}

