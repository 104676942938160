import { Square2StackIcon } from "@heroicons/react/24/outline";
import { SUPPORT_EMAIL } from "../../constants/globals";
import { Card } from "../../ui/Card";
import { useCopyToClipboard } from "@uidotdev/usehooks";

export const TermsOfService = () => {
    const [, copyToClipBoard] = useCopyToClipboard();

    return (
        <div className="prose px-6 max-w-6xl mx-auto">
            <h1 className="flex pt-10 font-bold font-sans antialiased text-inherit text-xl">
                Terms of Service
            </h1>

            <Card>
                <div className="text-base">
                    <p className="italic">Last updated: July 16, 2024</p>
                    <p className="mt-2">
                        Please read these terms and conditions carefully before
                        using Our Service.
                    </p>
                    <h2 className="mt-4 text-xl">
                        <strong>Interpretation and Definitions</strong>
                    </h2>
                    <h2 className="mt-4 text-xl">
                        <strong>Interpretation</strong>
                    </h2>
                    <p className="mt-2">
                        The words of which the initial letter is capitalized
                        have meanings defined under the following conditions.
                        The following definitions shall have the same meaning
                        regardless of whether they appear in singular or in
                        plural.
                    </p>
                    <h2 className="mt-4 text-xl">
                        <strong>Definitions</strong>
                    </h2>
                    <p className="mt-2">
                        For the purposes of these Terms and Conditions:
                    </p>
                    <ul className="list-inside list-disc mt-2">
                        <li>
                            <strong>Application</strong> means the software
                            program provided by the Company accessed by You on
                            any electronic device, named Aershed
                        </li>
                        <li>
                            <strong>Country</strong> refers to: Texas, United
                            States
                        </li>
                        <li>
                            <strong>Company</strong> (referred to as either
                            &quot;the Company&quot;, &quot;We&quot;,
                            &quot;Us&quot; or &quot;Our&quot; in this Agreement)
                            refers to Aerscape LLC, a Texas limited liability
                            company.
                        </li>
                        <li>
                            <strong>Device</strong> means any device that can
                            access the Service such as a computer, a cellphone
                            or a digital tablet.
                        </li>
                        <li>
                            <strong>Service</strong> refers to the Application.
                        </li>
                        <li>
                            <strong>Terms and Conditions</strong> (also referred
                            as &quot;Terms&quot;) mean these Terms and
                            Conditions that form the entire agreement between
                            You and the Company regarding the use of the
                            Service.
                        </li>
                        <li>
                            <strong>You</strong> means the individual accessing
                            or using the Service, or the company, or other legal
                            entity on behalf of which such individual is
                            accessing or using the Service, as applicable.
                        </li>
                    </ul>
                    <h2 className="mt-4 text-xl">
                        <strong>Acknowledgment</strong>
                    </h2>
                    <p className="mt-2">
                        These are the Terms and Conditions governing the use of
                        this Service and the agreement that operates between You
                        and the Company. These Terms and Conditions set out the
                        rights and obligations of all users regarding the use of
                        the Service.
                    </p>
                    <p className="mt-2">
                        Your access to and use of the Service is conditioned on
                        Your acceptance of and compliance with these Terms and
                        Conditions. These Terms and Conditions apply to all
                        visitors, users and others who access or use the
                        Service.
                    </p>
                    <p className="mt-2">
                        You acknowledge that you are accessing the Service on
                        behalf of a company, or other legal entity, that has a
                        contractual agreement in place with Aerscape, and that
                        accessing the Service is within the scope of your work
                        for that company, or other legal entity.
                    </p>
                    <p className="mt-2">
                        By accessing or using the Service You agree to be bound
                        by these Terms and Conditions. If You disagree with any
                        part of these Terms and Conditions then You may not
                        access the Service.
                    </p>
                    <p className="mt-2">
                        You represent that you are over the age of 18. The
                        Company does not permit those under 18 to use the
                        Service.
                    </p>
                    <p className="mt-2">
                        Your access to and use of the Service is also
                        conditioned on Your acceptance of and compliance with
                        the Privacy Policy of the Company. Our Privacy Policy
                        describes Our policies and procedures on the collection,
                        use and disclosure of Your personal information when You
                        use the Application and tells You about Your privacy
                        rights and how the law protects You. Please read Our
                        Privacy Policy carefully before using Our Service.
                    </p>
                    <h2 className="mt-2 text-xl">
                        <strong>Use of Service</strong>
                    </h2>
                    <ul className="list-inside list-disc mt-2">
                        <li>
                            The service is intended for users who are at least
                            18 years old.
                        </li>
                        <li>
                            You must provide accurate and complete registration
                            information when you sign up for the service.
                        </li>
                        <li>
                            You are responsible for the security of your account
                            and for all activity that occurs under your account.
                            You will set a password upon completing the site's
                            registration process. You are responsible for
                            maintaining the confidentiality of your password,
                            and are fully responsible for all activities that
                            occur under your password. You agree to (a)
                            immediately notify the Company of any unauthorized
                            use of your password or account or any other breach
                            of security, and (b) ensure that you exit from your
                            account at the end of each session. The Company
                            cannot and will not be liable for any loss or damage
                            arising from your failure to comply with this
                            provision, or any other provision under these Terms.
                            You are responsible for ensuring that no
                            unauthorized person shall have access to your
                            password(s) or account(s). It is your sole
                            responsibility to (1) control the dissemination and
                            use of your passwords; (2) authorize, monitor, and
                            control (a) access to, and (b) use of your
                            account(s) and password(s); (3) promptly inform the
                            Company if you wish to deactivate a password(s).
                        </li>
                        <li>
                            User accepts that, in certain circumstances, some
                            company site and equipment locations (e.g., site
                            polygons) have been updated, revised, or created on
                            behalf of the client to improve the services of the
                            platform, including emission event matching to
                            company assets. Aerscape encourages all users to
                            verify their site and equipment locations in the
                            platform and is not responsible for any outcome
                            arising from misplaced or misidentified sites or
                            equipment locations.
                        </li>
                    </ul>
                    <h2 className="mt-2 text-xl">
                        <strong>Prohibited Conduct</strong>
                    </h2>
                    <p className="mt-2">
                        You agree not to engage in the following prohibited
                        activities:
                    </p>
                    <ul className="list-inside list-disc mt-2">
                        <li>
                            Copying, distributing, or disclosing any part of the
                            service in any medium.
                        </li>
                        <li>
                            Transmitting spam, chain letters, or other
                            unsolicited email.
                        </li>
                        <li>
                            Attempting to interfere with the service's integrity
                            or security.
                        </li>
                        <li>
                            <strong>Unauthorized Data Harvesting:</strong> Users
                            are expressly prohibited from extracting,
                            downloading, or copying any data, content, or
                            information from Aerscape through automated means or
                            manual processes without explicit authorization.
                            This includes, but is not limited to, the use of web
                            scraping, data mining, robots, or similar data
                            gathering or extraction tools.
                        </li>
                        <li>
                            <strong>Reverse Engineering</strong>: Users must not
                            reverse engineer, decompile, disassemble, or
                            otherwise attempt to discover the source code or
                            underlying algorithms of any part of the Service,
                            except to the limited extent that applicable laws
                            specifically prohibit such restriction.
                        </li>
                        <li>
                            <strong>Explicit Authorization</strong>: In cases
                            where Our Platform does provide the explicit option
                            to extract or export data from the platform (e.g.,
                            through an officially supported API or download
                            feature), such functionality must be used in
                            accordance with the specific guidelines provided and
                            only for the user's personal or internal business
                            purposes.
                        </li>
                    </ul>
                    <h2 className="mt-4 text-xl">
                        <strong>Links to Other Websites</strong>
                    </h2>
                    <p className="mt-2">
                        Our Service may contain links to third-party web sites
                        or services that are not owned or controlled by the
                        Company.
                    </p>
                    <p className="mt-2">
                        The Company has no control over, and assumes no
                        responsibility for, the content, privacy policies, or
                        practices of any third-party web sites or services. You
                        further acknowledge and agree that the Company shall not
                        be responsible or liable, directly or indirectly, for
                        any damage or loss caused or alleged to be caused by or
                        in connection with the use of or reliance on any such
                        content, goods or services available on or through any
                        such web sites or services.
                    </p>
                    <p className="mt-2">
                        We strongly advise You to read the terms and conditions
                        and privacy policies of any third-party web sites or
                        services that You visit.
                    </p>
                    <h2 className="mt-4 text-xl">
                        <strong>Termination</strong>
                    </h2>
                    <p className="mt-2">
                        We may terminate or suspend Your access immediately,
                        without prior notice or liability, for any reason
                        whatsoever, including without limitation if You breach
                        these Terms and Conditions.
                    </p>
                    <p className="mt-2">
                        Upon termination, Your right to use the Service will
                        cease immediately. You also agree that the Company shall
                        not be liable to You or any third party for any
                        termination of your access to the Service.
                    </p>
                    <h2 className="mt-4 text-xl">
                        <strong>Limitation of Liability</strong>
                    </h2>
                    <p className="mt-2">
                        <strong>
                            NOTWITHSTANDING ANY DAMAGES THAT YOU MIGHT INCUR,
                            THE ENTIRE LIABILITY OF THE COMPANY AND ANY OF ITS
                            SUPPLIERS UNDER ANY PROVISION OF THESE TERMS SHALL
                            BE LIMITED TO THE AMOUNT ACTUALLY PAID BY YOU
                            THROUGH THE SERVICE.
                        </strong>
                    </p>
                    <p className="mt-2">
                        To the maximum extent permitted by applicable law, in no
                        event shall the Company or its suppliers be liable for
                        any special, incidental, indirect, or consequential
                        damages whatsoever (including, but not limited to,
                        damages for loss of profits, loss of data or other
                        information, for business interruption, for personal
                        injury, loss of privacy arising out of or in any way
                        related to the use of or inability to use the Service,
                        third-party software and/or third-party hardware used
                        with the Service, or otherwise in connection with any
                        provision of this Terms), even if the Company or any
                        supplier has been advised of the possibility of such
                        damages and even if the remedy fails of its essential
                        purpose.
                    </p>
                    <p className="mt-2">
                        Some states do not allow the exclusion of implied
                        warranties or limitation of liability for incidental or
                        consequential damages, which means that some of the
                        above limitations may not apply. In these states, each
                        party's liability will be limited to the greatest extent
                        permitted by law.
                    </p>
                    <h2 className="mt-4 text-xl">
                        <strong>
                            &quot;AS IS&quot; and &quot;AS AVAILABLE&quot;
                            Disclaimer
                        </strong>
                    </h2>
                    <p className="mt-2">
                        <strong>
                            THE SERVICE IS PROVIDED TO YOU “AS IS” AND “AS
                            AVAILABLE” AND WITH ALL FAULTS AND DEFECTS WITHOUT
                            WARRANTY OF ANY KIND.
                        </strong>{" "}
                        To the maximum extent permitted under applicable law,
                        the Company, on its own behalf and on behalf of its
                        Affiliates and its and their respective licensors and
                        service providers, <strong>EXPRESSLY DISCLAIMS</strong>{" "}
                        all warranties, whether express, implied, statutory or
                        otherwise, with respect to the Service, including all
                        implied warranties of merchantability, fitness for a
                        particular purpose, title and non-infringement, and
                        warranties that may arise out of course of dealing,
                        course of performance, usage or trade practice. Without
                        limitation to the foregoing, the Company provides no
                        warranty or undertaking, and makes no representation of
                        any kind that the Service will meet Your requirements,
                        achieve any intended results, be compatible or work with
                        any other software, applications, systems or services,
                        operate without interruption, meet any performance or
                        reliability standards or be error free or that any
                        errors or defects can or will be corrected.
                    </p>
                    <p className="mt-2">
                        Without limiting the foregoing, neither the Company nor
                        any of the Company’s suppliers makes any representation
                        or warranty of any kind, express or implied: (i) as to
                        the operation or availability of the Service, or the
                        information, content, and materials or products included
                        thereon; (ii) that the Service will be uninterrupted or
                        error-free; (iii) as to the accuracy, reliability, or
                        currency of any information or content provided through
                        the Service; or (iv) that the Service, its servers, the
                        content, or e-mails sent from or on behalf of the
                        Company are free of viruses, scripts, trojan horses,
                        worms, malware, timebombs or other harmful components.
                    </p>
                    <p className="mt-2">
                        Some jurisdictions do not allow the exclusion of certain
                        types of warranties or limitations on applicable
                        statutory rights of a consumer, so some or all of the
                        above exclusions and limitations may not apply to You.
                        But in such a case the exclusions and limitations set
                        forth in this section shall be applied to the greatest
                        extent enforceable under applicable law.
                    </p>
                    <h2 className="mt-4 text-xl">
                        <strong>Governing Law</strong>
                    </h2>
                    <p className="mt-2">
                        The laws of the Country, excluding its conflicts of law
                        rules, shall govern these Terms and Your use of the
                        Service. Your use of the Application may also be subject
                        to other local, state, national, or international laws.
                    </p>
                    <h2 className="mt-4 text-xl">
                        <strong>Disputes Resolution</strong>
                    </h2>
                    <p className="mt-2">
                        If You have any concern or dispute about the Service,
                        You agree to first try to resolve the dispute informally
                        by contacting the Company.
                    </p>
                    <h2 className="mt-4 text-xl">
                        <strong>United States Legal Compliance</strong>
                    </h2>
                    <p className="mt-2">
                        You represent and warrant that (i) You are not located
                        in a country that is subject to the United States
                        government embargo, or that has been designated by the
                        United States government as a &quot;terrorist
                        supporting&quot; country, and (ii) You are not listed on
                        any United States government list of prohibited or
                        restricted parties.
                    </p>
                    <h2 className="mt-4 text-xl">
                        <strong>Severability and Waiver</strong>
                    </h2>
                    <h3 className="mt-4 text-md">
                        <strong>Severability</strong>
                    </h3>
                    <p className="mt-2">
                        If any provision of these Terms is held to be
                        unenforceable or invalid, such provision will be changed
                        and interpreted to accomplish the objectives of such
                        provision to the greatest extent possible under
                        applicable law and the remaining provisions will
                        continue in full force and effect.
                    </p>
                    <h3 className="mt-4 text-md">
                        <strong>Waiver</strong>
                    </h3>
                    <p className="mt-2">
                        Except as provided herein, the Company’s failure to
                        exercise a right or to require performance of an
                        obligation under these Terms shall not affect the
                        Company’sability to exercise such right or require such
                        performance at any time thereafter nor shall the waiver
                        of a breach constitute a waiver of any subsequent
                        breach.
                    </p>
                    <h2 className="mt-4 text-xl">
                        <strong>Translation Interpretation</strong>
                    </h2>
                    <p className="mt-2">
                        These Terms and Conditions may have been translated if
                        We have made them available to You on our Service. You
                        agree that the original English text shall prevail in
                        the case of a dispute.
                    </p>
                    <h2 className="mt-4 text-xl">
                        <strong>Changes to These Terms and Conditions</strong>
                    </h2>
                    <p className="mt-2">
                        We reserve the right, at Our sole discretion, to modify
                        or replace these Terms at any time. If a revision is
                        material, We will make reasonable efforts to provide at
                        least 30 days' notice prior to any new terms taking
                        effect. What constitutes a material change will be
                        determined based on Our sole discretion.
                    </p>
                    <p className="mt-2">
                        By continuing to access or use Our Service after those
                        revisions become effective, You agree to be bound by the
                        revised terms. If You do not agree to the new terms, in
                        whole or in part, please stop using the website and the
                        Service.
                    </p>
                    <h2 className="mt-4 text-xl">
                        <strong>Contact Us</strong>
                    </h2>
                    <p className="mt-2">
                        If you have any questions about these Terms and
                        Conditions, You can contact us:
                    </p>
                    <ul className="list-inside list-disc mt-2">
                        <li className="mt-1 flex items-center">
                            By email:
                            <button
                                className="flex items-center ml-1 hover:underline"
                                onClick={() => copyToClipBoard(SUPPORT_EMAIL)}
                            >
                                aerscape-help@aerscape.com
                                <Square2StackIcon className="ml-1 w-4 h-4" />
                            </button>
                        </li>
                    </ul>
                </div>
            </Card>
        </div>
    );
};
