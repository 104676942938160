import { useQuery } from "@tanstack/react-query";
import { useNotificationsApi } from "../../hooks";
import { ReactNode } from "react";
import { LoadingIndicator } from "../MapV2/ui/LoadingIndicator";
import { CustomSwitch } from "../../ui/CustomSwitch";

const SettingsSection = (props: { title: string; children: ReactNode }) => (
    <>
        <hr className="my-4" />
        <h3 className="font-md">{props.title}</h3>
        <div>{props.children}</div>
    </>
);

interface EmissionSettingsProps {
    company?: number;
}

export const DataExportSettings = (props: EmissionSettingsProps) => {
    const apiClient = useNotificationsApi();

    const notificationGroupsQuery = useQuery({
        queryKey: ["notificationGroup", props.company],
        queryFn: async () => {
            const response = await apiClient.notificationsGroupsList({
                owner: props.company,
                pageSize: 30,
            });
            return response.results;
        },
    });

    // Notification settings
    const notificationSettingsQuery = useQuery({
        queryKey: ["emissionNotificationSettings", props.company],
        queryFn: async () => {
            const response = await apiClient.notificationsEmissionsList({
                owner: props.company,
                pageSize: 1,
            });
            return response.results[0];
        },
    });

    // Save changes fn
    const saveChanges = async (field: string, value: string | boolean) => {
        await apiClient.notificationsEmissionsPartialUpdate({
            id: notificationSettingsQuery.data?.id,
            patchedEmissionNotificationSettingsRequest: {
                [field]: value,
            },
        });
        notificationSettingsQuery.refetch();
    };

    if (
        notificationSettingsQuery.isLoading ||
        notificationGroupsQuery.isLoading
    ) {
        return <LoadingIndicator />;
    }

    return (
        <div className="mt-2 text-sm">
            <div className="mt-4">
                <SettingsSection title="Emissions CSV export">
                    <div className="flex justify-between">
                        <div className="flex items-center gap-2 w-1/2">
                            <div className="mt-1 text-ae-slate-600 w-2/3">
                                Toggle on switch if you'd like to be notified
                                when a user generates an CSV export of emission
                                data.
                            </div>

                            <CustomSwitch
                                checked={
                                    notificationSettingsQuery.data
                                        .notifyGroupOnReport
                                }
                                onChange={(val) => {
                                    saveChanges("notifyGroupOnReport", val);
                                }}
                                size="lg"
                            />
                        </div>

                        <div className="flex items-center gap-2">
                            <div className="my-1 text-ae-slate-600">
                                Who should be notified about new data?
                            </div>
                            <select
                                className="min-w-64 rounded p-2"
                                value={
                                    notificationSettingsQuery.data
                                        .reportNotificationGroup
                                }
                                onChange={(e) => {
                                    saveChanges(
                                        "reportNotificationGroup",
                                        e.target.value,
                                    );
                                }}
                            >
                                <option
                                    disabled
                                    selected={
                                        notificationSettingsQuery.data
                                            .reportNotificationGroup ===
                                        undefined
                                    }
                                ></option>
                                {notificationGroupsQuery.data.map((group) => (
                                    <option key={group.id} value={group.id}>
                                        {group.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </SettingsSection>
            </div>
        </div>
    );
};
