/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `GATHERING` - Gathering
 * * `TRANSMISSION` - Transmission
 * * `DISTRIBUTION` - Distribution
 * * `DISPOSAL` - Disposal
 * @export
 */
export const PipelineSegmentTypeEnum = {
    Gathering: 'GATHERING',
    Transmission: 'TRANSMISSION',
    Distribution: 'DISTRIBUTION',
    Disposal: 'DISPOSAL'
} as const;
export type PipelineSegmentTypeEnum = typeof PipelineSegmentTypeEnum[keyof typeof PipelineSegmentTypeEnum];


export function instanceOfPipelineSegmentTypeEnum(value: any): boolean {
    return Object.values(PipelineSegmentTypeEnum).includes(value);
}

export function PipelineSegmentTypeEnumFromJSON(json: any): PipelineSegmentTypeEnum {
    return PipelineSegmentTypeEnumFromJSONTyped(json, false);
}

export function PipelineSegmentTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PipelineSegmentTypeEnum {
    return json as PipelineSegmentTypeEnum;
}

export function PipelineSegmentTypeEnumToJSON(value?: PipelineSegmentTypeEnum | null): any {
    return value as any;
}

