/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataPackageDownloadUrl
 */
export interface DataPackageDownloadUrl {
    /**
     * 
     * @type {string}
     * @memberof DataPackageDownloadUrl
     */
    downloadUrl: string;
}

/**
 * Check if a given object implements the DataPackageDownloadUrl interface.
 */
export function instanceOfDataPackageDownloadUrl(value: object): boolean {
    if (!('downloadUrl' in value)) return false;
    return true;
}

export function DataPackageDownloadUrlFromJSON(json: any): DataPackageDownloadUrl {
    return DataPackageDownloadUrlFromJSONTyped(json, false);
}

export function DataPackageDownloadUrlFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataPackageDownloadUrl {
    if (json == null) {
        return json;
    }
    return {
        
        'downloadUrl': json['download_url'],
    };
}

export function DataPackageDownloadUrlToJSON(value?: DataPackageDownloadUrl | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'download_url': value['downloadUrl'],
    };
}

