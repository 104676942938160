import { useCallback, useEffect, useState } from "react";
import { EditableCell } from "./EditableCells";
import { RadioGroup } from "@headlessui/react";
import { MinusCircleIcon } from "@heroicons/react/24/outline";
import { useDebounce } from "@uidotdev/usehooks";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

interface EditingViewProps<T> {
    value: T;
    onChange: (newValue?: T) => void;
    getKey: (item: Partial<T>) => string;
    getDisplayName: (item: Partial<T>) => string;
    getExtraData?: (item: Partial<T>) => { name: string; value: string }[];
    queryFn: (search: string) => Promise<T[]>;
}

const EditingView = <T,>(props: EditingViewProps<T>) => {
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 300);
    const [options, setOptions] = useState<T[]>([]);

    const { queryFn } = props;
    const setQuery = useCallback(
        async (search: string) => {
            if (search == undefined || search.trim() === "") {
                return;
            }
            try {
                setLoading(true);
                const response = await queryFn(search);
                setOptions(response);
                setLoading(false);
            } catch {
                console.log("Failed to fetch options.");
            }
        },
        [queryFn],
    );

    useEffect(() => {
        setQuery(debouncedSearchTerm);
    }, [setQuery, debouncedSearchTerm]);

    return (
        <RadioGroup value={props.value} onChange={props.onChange}>
            <div>
                <div className="flex items-center my-2">
                    <input
                        className="w-96 p-2 border-2 rounded-lg"
                        value={searchTerm}
                        onChange={(event) => setSearchTerm(event.target.value)}
                        placeholder="Start typing for suggestions"
                    />
                    <button className="flex items-center -ml-8">
                        <MagnifyingGlassIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </button>
                </div>
            </div>
            {loading ? (
                <span className="mt-4 animate-pulse">
                    Loading suggestions...
                </span>
            ) : (
                options.length > 0 && (
                    <div className="grid grid-cols-3 -ml-1">
                        {options.map((item) => (
                            <RadioGroup.Option
                                key={props.getKey(item)}
                                value={item}
                            >
                                {({ checked }) => (
                                    <span
                                        className={`
                                m-1 py-2 px-2 border-2 border-slate-200 rounded-lg
                                flex items-center
                                ${
                                    !checked &&
                                    "hover:border-slate-400 hover:bg-slate-100 hover:cursor-pointer"
                                }
                                ${
                                    checked
                                        ? "bg-slate-700 text-white"
                                        : "bg-white"
                                }
                            `}
                                    >
                                        {checked ? (
                                            <CheckCircleIcon className="w-8 h-8 mr-3" />
                                        ) : (
                                            <MinusCircleIcon className="w-8 h-8 mr-3" />
                                        )}
                                        <div>
                                            <span className="font-bold mr-1">
                                                {props.getDisplayName(item)}
                                            </span>
                                            {props.getExtraData && (
                                                <>
                                                    <hr className="my-1" />
                                                    {props
                                                        .getExtraData(item)
                                                        .map((data) => (
                                                            <div className="flex items-center text-sm">
                                                                <span className="mr-2">
                                                                    {data.name}:
                                                                </span>
                                                                <span className="font-bold capitalize">
                                                                    {data.value ||
                                                                        "-"}
                                                                </span>
                                                            </div>
                                                        ))}
                                                </>
                                            )}
                                        </div>
                                    </span>
                                )}
                            </RadioGroup.Option>
                        ))}
                    </div>
                )
            )}
        </RadioGroup>
    );
};

interface EditableAutocompleteCellProps<T> {
    initialValue?: Partial<T>;
    onChange: (newValue?: T) => Promise<void>;
    getKey: (item: Partial<T>) => string;
    getDisplayName: (item: Partial<T>) => string;
    getExtraData?: (item: Partial<T>) => { name: string; value: string }[];
    queryFn: (search: string) => Promise<T[]>;
}

export const EditableAutocompleteCell = <T,>(
    props: EditableAutocompleteCellProps<T>,
) => {
    const [value, setValue] = useState<Partial<T>>(props.initialValue || null);

    return (
        <EditableCell
            title="Select New Parent"
            editable={
                <EditingView
                    value={value}
                    onChange={(newValue) => setValue(newValue)}
                    getKey={props.getKey}
                    getDisplayName={props.getDisplayName}
                    queryFn={props.queryFn}
                    getExtraData={props.getExtraData}
                />
            }
            useDetailView={true}
            valueDisplay={props.getDisplayName(props.initialValue)}
            onCancel={() => setValue(props.initialValue)}
            onSave={() => props.onChange(value as T)}
        />
    );
};
