import { parse } from "csv-parse/browser/esm/sync";

interface LoadCSVFromFileProps {
    file?: File;
    callback: (data: any[], error: boolean) => Promise<void>;
}

export const loadCSVfromFile = (props: LoadCSVFromFileProps) => {
    if (props.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const data = parse(e.target.result as string, {
                    bom: true,
                    trim: true,
                    skipEmptyLines: true,
                    columns: true,
                });
                props.callback(data, false);
            } catch (err) {
                props.callback(undefined, true);
            }
        };
        reader.readAsText(props.file);
    }
};
