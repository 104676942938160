import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon, MinusCircleIcon } from "@heroicons/react/24/solid";
import { ReactNode } from "react";

interface RadioGroupOptionProps {
    checked: boolean;
    key: string | number;
    label: string;
    extraContent: ReactNode;
}

const RadioGroupOption = (props: RadioGroupOptionProps) => (
    <div
        className={`
        py-2 px-2 border-2 border-slate-200 rounded-lg
        flex items-center
        ${
            !props.checked &&
            "hover:border-slate-400 hover:bg-slate-100 hover:cursor-pointer"
        }
        ${props.checked ? "bg-slate-700 text-white" : "bg-white"}
    `}
    >
        <div className="mr-3 w-1/6 flex items-center justify-center">
            {props.checked ? (
                <CheckCircleIcon className="w-8 h-8" />
            ) : (
                <MinusCircleIcon className="w-8 h-8 text-gray-300" />
            )}
        </div>
        <div className="text-sm flex-wrap">
            <RadioGroup.Label as="p" className="font-bold">
                {props.label}
            </RadioGroup.Label>
            <RadioGroup.Description
                className={props.checked ? "text-sky-100" : "text-gray-500"}
            >
                {props.extraContent}
            </RadioGroup.Description>
        </div>
    </div>
);

interface RadioSelectorProps {
    value: string | number;
    onChange: (newValue: string | number) => void;
    options: {
        key: string | number;
        label: string;
        extraContent?: ReactNode;
    }[];
}

export const RadioSelector = (props: RadioSelectorProps) => {
    return (
        <div>
            <RadioGroup value={props.value} onChange={props.onChange}>
                <div className="grid grid-cols-2 gap-2">
                    {props.options.map((option) => (
                        <RadioGroup.Option key={option.key} value={option.key}>
                            {({ checked }) => (
                                <RadioGroupOption
                                    checked={checked}
                                    key={option.key}
                                    label={option.label}
                                    extraContent={option.extraContent}
                                />
                            )}
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
        </div>
    );
};
