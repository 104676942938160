import { CheckIcon } from "@heroicons/react/20/solid";
import { RadioGroup } from "@headlessui/react";
import { useEffect, useState } from "react";
import {
    InfrastructureMatch,
    MatchStatusEnum,
} from "../../../apiClient/generated";
import { useInfrastructureApiClient } from "../../../hooks";
import { DetailViewEditableCell } from "./EditableCells";
import {
    ArrowPathIcon,
    CheckCircleIcon,
    MinusCircleIcon,
} from "@heroicons/react/24/solid";
import { CoordinatesField } from "../../GenericFields";
import { ConflictPreviewMiniMap } from "./CrossOperatorConflictsCell";
import { useQuery } from "@tanstack/react-query";

const matchStatusItems = [
    {
        key: MatchStatusEnum.KeepExisting,
        displayName: "Keep existing site",
        text: "Keep the existing site and discard this one.",
    },
    {
        key: MatchStatusEnum.UpdateExisting,
        displayName: "Update existing site",
        text: "Update the selected existing site with this (including any changes made here).",
    },
    {
        key: MatchStatusEnum.NewSite,
        displayName: "Create new site",
        text: "This is a new site.",
    },
];

interface MatchedInfrastructureListProps {
    matchedInfrastructure: InfrastructureMatch[];
    matchId: number;
    updateMatchId: (newValue?: number) => void;
    highlightId?: string;
}

const MatchedInfrastructureList = (props: MatchedInfrastructureListProps) => {
    return (
        <RadioGroup
            value={props.matchId}
            onChange={props.updateMatchId}
            className="grid grid-cols-2 w-1/2 rounded-2xl bg-white"
        >
            {props.matchedInfrastructure.map((match) => (
                <RadioGroup.Option value={match.id} key={match.id}>
                    {({ checked }) => (
                        <div
                            className={`
                                        m-1 py-2 px-2 border-2 border-slate-200 rounded-lg
                                        flex items-center
                                        ${
                                            props.highlightId ===
                                                match.infrastructure.id &&
                                            "border-slate-400 bg-slate-100 scale-105"
                                        }
                                        ${
                                            !checked &&
                                            "hover:border-slate-400 hover:bg-slate-100 hover:cursor-pointer hover:scale-105"
                                        }
                                        ${
                                            checked
                                                ? "bg-slate-700 text-white"
                                                : ""
                                        }
                                    `}
                        >
                            {checked ? (
                                <CheckCircleIcon className="w-8 h-8 mr-3" />
                            ) : (
                                <MinusCircleIcon className="w-8 h-8 mr-3 text-gray-300" />
                            )}
                            <div className="text-sm">
                                <RadioGroup.Label
                                    className="font-bold"
                                    as="div"
                                >
                                    {match.infrastructure.siteName}
                                </RadioGroup.Label>
                                <RadioGroup.Description
                                    as="div"
                                    className={
                                        checked
                                            ? "text-sky-100"
                                            : "text-gray-500"
                                    }
                                >
                                    <p>
                                        Owner: {match.infrastructure.ownerName}
                                    </p>
                                    <p>
                                        Type: {match.infrastructure.infraType}
                                    </p>
                                    <div className="flex">
                                        Coordinates:
                                        <div className="ml-1">
                                            <CoordinatesField
                                                coordinates={
                                                    match.infrastructure
                                                        .location.coordinates
                                                }
                                            />
                                        </div>
                                    </div>
                                    <p>
                                        Distance: {match.distance.toFixed(1)} m
                                    </p>
                                    {match.infrastructure.shape && (
                                        <>
                                            <p>
                                                Area:{" "}
                                                {match.infrastructureArea.toFixed(
                                                    3,
                                                )}{" "}
                                                mi<sup>2</sup>
                                            </p>
                                            {match.overlapWithMatch > 0 && (
                                                <p>
                                                    Polygon overlap:{" "}
                                                    {(
                                                        (match.overlapWithMatch *
                                                            100) /
                                                        match.infrastructureArea
                                                    ).toFixed(1)}{" "}
                                                    %
                                                </p>
                                            )}
                                        </>
                                    )}
                                </RadioGroup.Description>
                            </div>
                        </div>
                    )}
                </RadioGroup.Option>
            ))}
        </RadioGroup>
    );
};

interface MatchStatusRadioGroupProps {
    matchStatus: string;
    onChange: (newValue?: string) => void;
}

const MatchStatusRadioGroup = (props: MatchStatusRadioGroupProps) => {
    return (
        <div>
            <RadioGroup value={props.matchStatus} onChange={props.onChange}>
                <div className="flex space-x-2">
                    {matchStatusItems.map((option) => (
                        <RadioGroup.Option key={option.key} value={option.key}>
                            {({ checked }) => (
                                <div
                                    className={`
                                        m-1 py-2 px-2 border-2 border-slate-200 rounded-lg
                                        flex items-center
                                        ${
                                            !checked &&
                                            "hover:border-slate-400 hover:bg-slate-100 hover:cursor-pointer"
                                        }
                                        ${
                                            checked
                                                ? "bg-slate-700 text-white"
                                                : "bg-white"
                                        }
                                    `}
                                >
                                    {checked ? (
                                        <CheckCircleIcon className="w-8 h-8 mr-3" />
                                    ) : (
                                        <MinusCircleIcon className="w-8 h-8 mr-3 text-gray-300" />
                                    )}
                                    <div className="text-sm">
                                        <RadioGroup.Label as="p">
                                            {option.displayName}
                                        </RadioGroup.Label>
                                        <RadioGroup.Description
                                            className={
                                                checked
                                                    ? "text-sky-100"
                                                    : "text-gray-500"
                                            }
                                        >
                                            {option.text}
                                        </RadioGroup.Description>
                                    </div>
                                </div>
                            )}
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
        </div>
    );
};

interface EditingViewProps {
    itemId: number;
    matchStatus: string;
    matchId: number;
    onChange: (newValue?: string) => void;
    updateMatchId: (newValue?: number) => void;
    relatedInfrastructure?: string;
}

const EditingView = (props: EditingViewProps) => {
    const apiClient = useInfrastructureApiClient();
    const { updateMatchId, relatedInfrastructure } = props;
    const [hoverSite, setHoverSite] = useState(undefined);

    const itemQuery = useQuery({
        queryKey: ["infrastructureItem", props.itemId],
        queryFn: async () => {
            return await apiClient.infrastructureImportItemsRetrieve({
                id: props.itemId,
            });
        },
        staleTime: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    });

    const matchesQuery = useQuery({
        queryKey: ["infrastructureItemMatches", props.itemId],
        queryFn: async () => {
            return await apiClient.infrastructureImportItemsGetMatchedInfrastructureList(
                { id: props.itemId },
            );
        },
        staleTime: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (matchesQuery.data) {
            if (relatedInfrastructure) {
                const selected = matchesQuery.data.find(
                    (i) => i.infrastructure.id === relatedInfrastructure,
                );
                if (selected) {
                    updateMatchId(selected.id);
                }
            } else if (matchesQuery.data.length === 1) {
                updateMatchId(matchesQuery.data[0].id);
            }
        }
    }, [matchesQuery.data, updateMatchId, relatedInfrastructure]);
    const loading = itemQuery.isLoading || matchesQuery.isLoading;

    return (
        <div>
            <p className="w-full flex justify-left text-md mb-3">
                This site has been identified as a possible duplicate of the
                following existing site(s) in your inventory.
            </p>
            {loading ? (
                <div className="flex items-center mb-4 animate-pulse">
                    <ArrowPathIcon className="mr-2 w-4 h-4 animate-spin" />
                    Loading matching sites...
                </div>
            ) : (
                <div className="flex gap-4 w-full justify-between">
                    <MatchedInfrastructureList
                        matchedInfrastructure={matchesQuery.data}
                        matchId={props.matchId}
                        updateMatchId={props.updateMatchId}
                        highlightId={hoverSite}
                    />
                    <div className="relative h-[330px] w-1/2 bg-black overflow-hidden">
                        <ConflictPreviewMiniMap
                            conflict={itemQuery.data}
                            nearbySites={matchesQuery.data.map(
                                (i) => i.infrastructure,
                            )}
                            onHoverSite={(id) => setHoverSite(id)}
                            onClick={(id) => {
                                const match = matchesQuery.data.find(
                                    (m) => m.infrastructure.id === id,
                                );
                                if (match) {
                                    updateMatchId(match.id);
                                }
                            }}
                        />
                    </div>
                </div>
            )}
            <p className="w-full flex justify-left text-md mt-4 mb-2">
                Select how would you like the import to process data for this
                site.
            </p>
            <MatchStatusRadioGroup
                matchStatus={props.matchStatus}
                onChange={props.onChange}
            />
        </div>
    );
};

interface LocationMatchingCellProps {
    itemId: number;
    matchStatus: string;
    relatedInfrastructure: string;
    onChange: (matchStatus?: string, matchId?: number) => Promise<void>;
}

export const LocationMatchingCell = (props: LocationMatchingCellProps) => {
    const [status, setMatchStatus] = useState(props.matchStatus);
    const [matchId, setMatchId] = useState<number>();

    useEffect(() => {
        setMatchStatus(props.matchStatus);
    }, [props.matchStatus]);

    const reviewRequired =
        props.matchStatus === MatchStatusEnum.NeedsReview ||
        props.matchStatus === MatchStatusEnum.Match;
    const disableSave =
        status === MatchStatusEnum.NeedsReview ||
        status === MatchStatusEnum.Match ||
        (status === MatchStatusEnum.UpdateExisting && !matchId) ||
        (status === MatchStatusEnum.KeepExisting && !matchId);

    return (
        <div>
            {status === MatchStatusEnum.NoMatch ? (
                <div className="flex items-center justify-center text-gray-400">
                    No match
                    <CheckIcon className="ml-2 w-4 h-4 text-green-600" />
                </div>
            ) : (
                <DetailViewEditableCell
                    title="Review infrastructure match with existing data"
                    onCancel={() => {}}
                    onSave={() => props.onChange(status, matchId)}
                    disableSave={disableSave}
                >
                    {(editing) =>
                        editing ? (
                            <EditingView
                                itemId={props.itemId}
                                matchStatus={status}
                                onChange={(v) => setMatchStatus(v)}
                                matchId={matchId}
                                relatedInfrastructure={
                                    props.relatedInfrastructure
                                }
                                updateMatchId={setMatchId}
                            />
                        ) : (
                            <>
                                {reviewRequired
                                    ? "Review required"
                                    : "Review complete"}
                            </>
                        )
                    }
                </DetailViewEditableCell>
            )}
        </div>
    );
};
