/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Serializer for `FilterGroup` items.
 * @export
 * @interface FilterGroup
 */
export interface FilterGroup {
    /**
     * 
     * @type {number}
     * @memberof FilterGroup
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof FilterGroup
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof FilterGroup
     */
    order?: number;
}

/**
 * Check if a given object implements the FilterGroup interface.
 */
export function instanceOfFilterGroup(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    return true;
}

export function FilterGroupFromJSON(json: any): FilterGroup {
    return FilterGroupFromJSONTyped(json, false);
}

export function FilterGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterGroup {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'order': json['order'] == null ? undefined : json['order'],
    };
}

export function FilterGroupToJSON(value?: Omit<FilterGroup, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'order': value['order'],
    };
}

