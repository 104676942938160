/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BlankEnum } from './BlankEnum';
import {
    instanceOfBlankEnum,
    BlankEnumFromJSON,
    BlankEnumFromJSONTyped,
    BlankEnumToJSON,
} from './BlankEnum';
import type { PipelineSegmentTypeEnum } from './PipelineSegmentTypeEnum';
import {
    instanceOfPipelineSegmentTypeEnum,
    PipelineSegmentTypeEnumFromJSON,
    PipelineSegmentTypeEnumFromJSONTyped,
    PipelineSegmentTypeEnumToJSON,
} from './PipelineSegmentTypeEnum';

/**
 * @type AdminInfrastructureImportItemPipelineSegmentType
 * 
 * @export
 */
export type AdminInfrastructureImportItemPipelineSegmentType = BlankEnum | PipelineSegmentTypeEnum;

export function AdminInfrastructureImportItemPipelineSegmentTypeFromJSON(json: any): AdminInfrastructureImportItemPipelineSegmentType {
    return AdminInfrastructureImportItemPipelineSegmentTypeFromJSONTyped(json, false);
}

export function AdminInfrastructureImportItemPipelineSegmentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminInfrastructureImportItemPipelineSegmentType {
    if (json == null) {
        return json;
    }
    return BlankEnumFromJSONTyped(json, true) || PipelineSegmentTypeEnumFromJSONTyped(json, true);
}

export function AdminInfrastructureImportItemPipelineSegmentTypeToJSON(value?: AdminInfrastructureImportItemPipelineSegmentType | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfBlankEnum(value)) {
        return BlankEnumToJSON(value as BlankEnum);
    }
    if (instanceOfPipelineSegmentTypeEnum(value)) {
        return PipelineSegmentTypeEnumToJSON(value as PipelineSegmentTypeEnum);
    }

    return {};
}

