import {
    PaginationState,
    RowData,
    SortingState,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import {
    ArrowDownIcon,
    ArrowUpIcon,
    ArrowsUpDownIcon,
} from "@heroicons/react/20/solid";
import { PlusIcon } from "@heroicons/react/24/solid";

/**
 * Custom definition for extra metadata with types.
 */
interface CustomFilterWidgetProps {
    isFiltered: boolean;
    // TODO: Improve typing for custom filters here? (Maybe not?)
    // Need to ensure the filterValue is valid for the apiClient
    // otherwise it will be ignored.
    filterValue?: any;
    setFilterValue: (newFilterValue?: any) => void;
}

declare module "@tanstack/react-table" {
    // eslint-disable-next-line
    interface TableMeta<TData extends RowData> {
        updateData: (
            rowIndex: number,
            itemId: any,
            value: any,
        ) => Promise<void>;
        deleteRow: (rowIndex: number, itemId: any) => Promise<void>;
        refresh: () => Promise<void>;
    }
    // eslint-disable-next-line
    interface ColumnMeta<TData extends RowData, TValue> {
        customFilterWidget?: React.FC<CustomFilterWidgetProps>;
        sortingKey?: string;
        filteringKey?: string;
    }
}

interface Props {
    // TODO: improve typing
    dataName: string;
    columns: any;
    fetchFunction: any;
    sortable: boolean;
    extraFilters: {
        [key: string]: any;
    };
    onClickRow?: (string) => void;
    defaultPageSize?: number;
    partialUpdate?: (id: string, value: any) => Promise<any>;
    deleteRow?: (id: string) => Promise<any>;
    createRow?: () => void;
    initialSortingState?: SortingState;
}

/**
 * TODO: Refactor table.
 *
 * Reason: some code cruft and weak typing might become issues.
 */
export const DataTable = (props: Props) => {
    const defaultData = useMemo(() => [], []);

    /**
     * Sorting: state & key translation
     */
    const [sorting, setSorting] = useState<SortingState>(
        props.initialSortingState || [],
    );
    let sortingKey = "";
    if (sorting.length > 0) {
        const sortedColumn = sorting[0];
        sortingKey = `${sortedColumn.desc ? "" : "-"}${sortedColumn.id.replace(
            /[A-Z]/g,
            (letter) => `_${letter.toLowerCase()}`,
        )}`;
    }

    /**
     * Table custom pagination
     */
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: props.defaultPageSize || 10,
    });

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );

    /**
     * Query builder & fetch/update functions
     */

    // Work around declarative issues with useQuery:
    // When parameters change, we need to reset pagination to zero.
    // To do it, we need to store the old pagination state and disable
    // react query if the filters are different than the previous state,
    // update the pagination to zero and re-enable querying again.
    const [previousFilters, setPreviousFilters] = useState(props.extraFilters);
    useEffect(() => {
        if (previousFilters !== props.extraFilters) {
            setPreviousFilters(props.extraFilters);
            setPagination((pagination) => {
                return {
                    ...pagination,
                    pageIndex: 0,
                };
            });
        }
    }, [props.extraFilters, previousFilters]);

    const fetchDataOptions = {
        ...props.extraFilters,
        ordering: sortingKey,
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
    };

    const queryClient = useQueryClient();
    const dataQuery = useQuery(
        [props.dataName, fetchDataOptions],
        () =>
            props.fetchFunction({
                ...fetchDataOptions,
                // Backend pagination starts at page 1
                page: pageIndex + 1,
            }),
        {
            enabled: previousFilters === props.extraFilters,
            keepPreviousData: true,
        },
    );

    const partialUpdate = useMutation<any, any, any, any>(
        ["partialUpdate"],
        async (params) => {
            return props.partialUpdate(params.itemId, params.value);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: [props.dataName, fetchDataOptions],
                });
            },
        },
    );

    const deleteRow = useMutation<any, any, any, any>(
        ["deleteRow"],
        async (params) => {
            return props.deleteRow(params.itemId);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: [props.dataName, fetchDataOptions],
                });
            },
        },
    );

    /**
     * Table configuration
     */
    const table = useReactTable({
        data: dataQuery.data?.results ?? defaultData,
        columns: props.columns,
        getCoreRowModel: getCoreRowModel(),
        getRowId: (originalRow: any) => `row_${originalRow.id}`,
        // Pagination
        pageCount: dataQuery.data?.numPages,
        onPaginationChange: setPagination,
        manualPagination: true,
        state: {
            pagination,
            sorting,
        },
        // Update row support
        meta: {
            updateData: (rowIndex, itemId, value) => {
                return partialUpdate.mutateAsync({ rowIndex, itemId, value });
            },
            deleteRow: (rowIndex, itemId) => {
                return deleteRow.mutateAsync({ rowIndex, itemId });
            },
            refresh: async () => {
                dataQuery.refetch();
            },
        },
        // Sorting
        enableSorting: props.sortable,
        manualSorting: true,
        enableSortingRemoval: true,
        onSortingChange: setSorting,
    });

    return (
        <div className="text-base overflow-y-scroll">
            <table className="table-auto w-full">
                <thead className="text-left">
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <th
                                    key={header.id}
                                    className={`
                                        px-6 py-4 whitespace-nowrap font-bold 
                                        text-gray-800
                                        ${
                                            header.column.getCanSort()
                                                ? "cursor-pointer select-none"
                                                : ""
                                        }
                                    `}
                                    onClick={header.column.getToggleSortingHandler()}
                                >
                                    {header.isPlaceholder ? null : (
                                        <span className="flex items-center">
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext(),
                                            )}
                                            {header.column.getCanSort() &&
                                                (header.column.getIsSorted() ===
                                                "asc" ? (
                                                    <ArrowUpIcon className="ml-2 h-4 w-4" />
                                                ) : header.column.getIsSorted() ===
                                                  "desc" ? (
                                                    <ArrowDownIcon className="ml-2 h-4 w-4" />
                                                ) : (
                                                    <ArrowsUpDownIcon className="ml-2 h-4 w-4" />
                                                ))}
                                        </span>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row, index) => (
                        <tr
                            key={row.id}
                            onClick={() => {
                                if (props.onClickRow) {
                                    props.onClickRow((row.original as any).id);
                                }
                            }}
                            className={`
                                w-full hover:bg-slate-200 
                                ${index % 2 == 0 ? "bg-slate-100" : ""}
                                ${
                                    props.onClickRow
                                        ? "hover:cursor-pointer"
                                        : ""
                                }
                            `}
                        >
                            {row.getVisibleCells().map((cell) => (
                                <td
                                    key={cell.id}
                                    className="px-6 py-4 w-min whitespace-nowrap text-sm font-medium text-gray-800"
                                >
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext(),
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            {props.createRow && (
                <div className="w-full m-2 py-2">
                    <button
                        onClick={props.createRow}
                        className="flex items-center px-4 py-2 hover:bg-slate-300 rounded-lg"
                    >
                        <PlusIcon className="w-4 h-4 mr-2" />
                        Add new row
                    </button>
                </div>
            )}
            <div className="w-full flex justify-center items-center space-x-4 mt-2">
                <button
                    className="p-4 rounded-lg hover:bg-slate-200 hover:cursor-pointer"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                >
                    <ChevronLeftIcon className="h-6" />
                </button>
                <span className="flex items-center gap-1">
                    <div>Page</div>
                    <strong>
                        {table.getState().pagination.pageIndex + 1} of{" "}
                        {table.getPageCount()}
                    </strong>
                </span>
                <button
                    className="p-4 rounded-lg hover:bg-slate-200 hover:cursor-pointer"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                >
                    <ChevronRightIcon className="h-6" />
                </button>
                <select
                    className="border p-1 rounded w-48"
                    value={table.getState().pagination.pageSize}
                    onChange={(e) => {
                        table.setPageSize(Number(e.target.value));
                        table.setPageIndex(0);
                    }}
                >
                    {[5, 10, 20, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize} per page
                        </option>
                    ))}
                    {props.defaultPageSize &&
                        ![5, 10, 20, 50].includes(props.defaultPageSize) && (
                            <option
                                key={props.defaultPageSize}
                                value={props.defaultPageSize}
                            >
                                Show {props.defaultPageSize} per page
                            </option>
                        )}
                </select>
                {dataQuery.isFetching ? "Loading..." : null}
                <div></div>
            </div>
        </div>
    );
};
