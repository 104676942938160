/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SimpleDataPoint } from './SimpleDataPoint';
import {
    SimpleDataPointFromJSON,
    SimpleDataPointFromJSONTyped,
    SimpleDataPointToJSON,
} from './SimpleDataPoint';
import type { SimpleInfrastructure } from './SimpleInfrastructure';
import {
    SimpleInfrastructureFromJSON,
    SimpleInfrastructureFromJSONTyped,
    SimpleInfrastructureToJSON,
} from './SimpleInfrastructure';

/**
 * Simplified representation of the `Emission` model.
 * @export
 * @interface SimpleEmission
 */
export interface SimpleEmission {
    /**
     * 
     * @type {string}
     * @memberof SimpleEmission
     */
    readonly id: string;
    /**
     * 
     * @type {SimpleDataPoint}
     * @memberof SimpleEmission
     */
    dataPoint: SimpleDataPoint;
    /**
     * 
     * @type {SimpleInfrastructure}
     * @memberof SimpleEmission
     */
    infrastructure: SimpleInfrastructure;
}

/**
 * Check if a given object implements the SimpleEmission interface.
 */
export function instanceOfSimpleEmission(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('dataPoint' in value)) return false;
    if (!('infrastructure' in value)) return false;
    return true;
}

export function SimpleEmissionFromJSON(json: any): SimpleEmission {
    return SimpleEmissionFromJSONTyped(json, false);
}

export function SimpleEmissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimpleEmission {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'dataPoint': SimpleDataPointFromJSON(json['data_point']),
        'infrastructure': SimpleInfrastructureFromJSON(json['infrastructure']),
    };
}

export function SimpleEmissionToJSON(value?: Omit<SimpleEmission, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data_point': SimpleDataPointToJSON(value['dataPoint']),
        'infrastructure': SimpleInfrastructureToJSON(value['infrastructure']),
    };
}

