import { ReactNode } from "react";

export const InputQuestionUi = (props: {
    label: string;
    optional?: boolean;
    children: ReactNode;
}) => (
    <div className="mt-3 mb-4">
        <p className="text-stone-900 font-normal mb-2">
            {props.label}
            {props.optional && (
                <span className="ml-2 text-gray-600">(optional)</span>
            )}
        </p>
        {props.children}
    </div>
);
