import { PermissionGate } from "./PermissionGate";
import { useParams } from "react-router";
import { InfrastructureBuilder } from "../components/InfrastructureBuilder/InfrastructureBuilder";

export const InfrastructureBuilderPage = () => {
    const { id } = useParams();

    return (
        <PermissionGate permission="infrastructureBuilderEdit">
            <div className="px-6">
                <h1 className="font-bold font-sans antialiased text-inherit pt-10 text-2xl mb-6">
                    Infrastructure Builder
                </h1>

                <InfrastructureBuilder infrastructureImportId={id} />
            </div>
        </PermissionGate>
    );
};
