/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListFilesInDataPackage
 */
export interface ListFilesInDataPackage {
    /**
     * 
     * @type {Array<string>}
     * @memberof ListFilesInDataPackage
     */
    files: Array<string>;
}

/**
 * Check if a given object implements the ListFilesInDataPackage interface.
 */
export function instanceOfListFilesInDataPackage(value: object): boolean {
    if (!('files' in value)) return false;
    return true;
}

export function ListFilesInDataPackageFromJSON(json: any): ListFilesInDataPackage {
    return ListFilesInDataPackageFromJSONTyped(json, false);
}

export function ListFilesInDataPackageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListFilesInDataPackage {
    if (json == null) {
        return json;
    }
    return {
        
        'files': json['files'],
    };
}

export function ListFilesInDataPackageToJSON(value?: ListFilesInDataPackage | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'files': value['files'],
    };
}

