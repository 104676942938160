import { useQuery } from "@tanstack/react-query";
import { useInfrastructureApiClient } from "../../../hooks";
import {
    faArrowsRotate,
    faChevronUp,
    faChevronDown,
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import {
    AlignedDataDisplay,
    CoordinatesField,
} from "../../../ui/GenericFields";

export const InfrastructureDisplay = ({
    infrastructureId,
}: {
    infrastructureId: string;
}) => {
    const apiClient = useInfrastructureApiClient();
    const [open, setOpen] = useState(false);
    const infrastructureQuery = useQuery({
        queryKey: ["insfrastructure", infrastructureId],
        queryFn: async () => {
            return await apiClient.infrastructureListRetrieve({
                id: infrastructureId,
            });
        },
        enabled: open,
    });

    if (!open) {
        return (
            <div className="-mt-2">
                <button
                    className="ml-1 text-xs underline"
                    onClick={() => setOpen(true)}
                >
                    Show details
                    <FontAwesomeIcon
                        icon={faChevronDown}
                        className="h-3 w-3 text-black pl-1"
                    />
                </button>
            </div>
        );
    }

    const data = [
        {
            label: "Type:",
            value: infrastructureQuery.data?.infraType,
        },
        {
            label: "Name:",
            value: infrastructureQuery.data?.name,
        },
        {
            label: "Site Name:",
            value: infrastructureQuery.data?.siteName,
        },
        {
            label: "Location:",
            value: (
                <CoordinatesField
                    coordinates={infrastructureQuery.data?.location.coordinates}
                    allowCopy={true}
                />
            ),
        },
    ];

    if (infrastructureQuery.data) {
        Object.entries(infrastructureQuery.data.extraData || {})
            .toSorted((a, b) => a[0].localeCompare(b[0]))
            .map(([key, value]) => {
                data.push({
                    label: key,
                    value: value as string,
                });
            });
    }

    return (
        <div className="-mt-2 text-sm rounded p-2 border border-ae-gray-250">
            {infrastructureQuery.isLoading ? (
                <div className="flex items-center justify-center">
                    <FontAwesomeIcon
                        icon={faArrowsRotate}
                        className="w-4 animate-spin"
                    />
                </div>
            ) : (
                <>
                    <AlignedDataDisplay data={data} />
                    <button
                        className="ml-1 text-xs underline"
                        onClick={() => setOpen(false)}
                    >
                        Hide details
                        <FontAwesomeIcon
                            icon={faChevronUp}
                            className="h-3 w-3 text-black pl-1"
                        />
                    </button>
                </>
            )}
        </div>
    );
};
