/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DataPointMap } from './DataPointMap';
import {
    DataPointMapFromJSON,
    DataPointMapFromJSONTyped,
    DataPointMapToJSON,
} from './DataPointMap';

/**
 * 
 * @export
 * @interface PaginatedDataPointMapList
 */
export interface PaginatedDataPointMapList {
    /**
     * 
     * @type {string}
     * @memberof PaginatedDataPointMapList
     */
    next?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedDataPointMapList
     */
    previous?: string;
    /**
     * 
     * @type {Array<DataPointMap>}
     * @memberof PaginatedDataPointMapList
     */
    results: Array<DataPointMap>;
}

/**
 * Check if a given object implements the PaginatedDataPointMapList interface.
 */
export function instanceOfPaginatedDataPointMapList(value: object): boolean {
    if (!('results' in value)) return false;
    return true;
}

export function PaginatedDataPointMapListFromJSON(json: any): PaginatedDataPointMapList {
    return PaginatedDataPointMapListFromJSONTyped(json, false);
}

export function PaginatedDataPointMapListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedDataPointMapList {
    if (json == null) {
        return json;
    }
    return {
        
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(DataPointMapFromJSON)),
    };
}

export function PaginatedDataPointMapListToJSON(value?: PaginatedDataPointMapList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(DataPointMapToJSON)),
    };
}

