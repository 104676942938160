import {
    InfraStatusEnum,
    InfraTypeEnum,
    InfrastructureImport,
    InfrastructureImportItem,
    PipelineCommodityEnum,
    PipelineSegmentTypeEnum,
} from "../../apiClient/generated";
import { createColumnHelper } from "@tanstack/react-table";
import { useInfrastructureApiClient } from "../../hooks";
import { DataTable } from "../InfrastructureDataTable/DataTable";
import {
    EditableAttributesCell,
    EditableBooleanCell,
    EditableDateCell,
    EditableEnumCell,
    EditableInfraTypeCell,
    EditableNumberCell,
    EditableTextCell,
} from "../../ui/Table/CustomCells/EditableCells";
import { EditableLocationCell } from "../../ui/Table/CustomCells/EditableLocationCell";
import { useMemo, useState } from "react";
import { MagnifyingGlassIcon, TrashIcon } from "@heroicons/react/20/solid";
import { CustomSwitch } from "../../ui/CustomSwitch";
import { useDebounce } from "@uidotdev/usehooks";
import { EditableAutocompleteCell } from "../../ui/Table/CustomCells/EditableAutocomplete";
import { DetailView, DetailViewActions } from "../../ui/DetailView";
import { LocationMatchingCell } from "../../ui/Table/CustomCells/LocationMatchingCell";
import { LineSimilarityCell } from "../../ui/Table/CustomCells/LineSimilarityCell";

const columnHelper = createColumnHelper<InfrastructureImportItem>();

interface AddItemModalProps {
    visible: boolean;
    relatedImport: InfrastructureImport;
    onClose: () => void;
    onSave: () => void;
}

export const AddItemModal = (props: AddItemModalProps) => {
    const apiClient = useInfrastructureApiClient();
    const [loading, setLoading] = useState(false);
    const [placeholderId, setPlaceholderId] = useState("");
    const [siteName, setsiteName] = useState("");
    const [infraType, setInfraType] = useState("SITE");
    const [equipmentType, setEquipmentType] = useState("");
    const [parent, setParent] = useState<InfrastructureImportItem>();

    const createItem = async () => {
        setLoading(true);
        await apiClient.infrastructureImportItemsCreate({
            infrastructureImportItemCreateRequest: {
                relatedImport: props.relatedImport.id,
                equipmentType,
                infraType: infraType as InfraTypeEnum,
                siteName,
                placeholderId,
                parent: parent.id,
            },
        });
        setLoading(false);
        setPlaceholderId("");
        setsiteName("");
        setInfraType("SITE");
        setEquipmentType("");
        setParent(undefined);
        props.onSave();
    };

    const autocompleteParent = async (search: string) => {
        const response = await apiClient.infrastructureImportItemsList({
            pageSize: 10,
            relatedImport: props.relatedImport.id,
            search: search,
        });
        return response.results;
    };

    return (
        <DetailView
            title={`Add infrastructure`}
            visible={props.visible}
            onClose={props.onClose}
        >
            <p>
                Please fill the required values below, the remaining attributes
                can be directly edited in the table.
            </p>
            <table>
                <tr>
                    <td>
                        <label>Placeholder ID*:</label>
                    </td>
                    <td>
                        <input
                            type="text"
                            className="ml-2 rounded my-2 px-2 py-1 w-48"
                            value={placeholderId}
                            onChange={(e) => setPlaceholderId(e.target.value)}
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        <label>Infrastructure type*:</label>
                    </td>
                    <td>
                        <select
                            value={infraType}
                            className="ml-2 rounded my-2 px-2 py-1 w-48"
                            onChange={(e) => setInfraType(e.target.value)}
                        >
                            {Object.values(InfraTypeEnum).map((e) => (
                                <option value={e} key={e}>
                                    {e
                                        .replace("_", " ")
                                        .toLowerCase()
                                        .replace(/\b\w/g, (l) =>
                                            l.toUpperCase(),
                                        )}
                                </option>
                            ))}
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>
                        <label>Site Name:</label>
                    </td>
                    <td>
                        <input
                            type="text"
                            className="ml-2 rounded my-2 px-2 py-1 w-48"
                            value={siteName}
                            onChange={(e) => setsiteName(e.target.value)}
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        <label>Equipment type:</label>
                    </td>
                    <td>
                        <input
                            type="text"
                            className="ml-2 rounded my-2 px-2 py-1 w-48"
                            value={equipmentType}
                            onChange={(e) => setEquipmentType(e.target.value)}
                        />
                    </td>
                </tr>

                <tr>
                    <td>
                        <label>Parent:</label>
                    </td>
                    <td className="ml-2 rounded my-2 px-2 py-1 w-48 flex">
                        <EditableAutocompleteCell<InfrastructureImportItem>
                            initialValue={parent}
                            getDisplayName={(item) =>
                                item && item.placeholderId
                            }
                            getExtraData={(item) => {
                                return [
                                    {
                                        name: "Site Name",
                                        value: item.siteName,
                                    },
                                    {
                                        name: "Category",
                                        value: item.infraType
                                            .replace("_", " ")
                                            .toLowerCase(),
                                    },
                                    {
                                        name: "Equipment type",
                                        value: item.equipmentType,
                                    },
                                ];
                            }}
                            getKey={(item) => item && `item_${item.id}`}
                            queryFn={(search) => autocompleteParent(search)}
                            onChange={async (newParent) => {
                                setParent(newParent);
                            }}
                        />
                        {parent && (
                            <button
                                className="p-2 text-blue-800 hover:underline"
                                onClick={() => setParent(undefined)}
                            >
                                clear
                            </button>
                        )}
                    </td>
                </tr>
            </table>

            <small>*: Required</small>

            <DetailViewActions
                actions={[
                    {
                        action: props.onClose,
                        label: "Cancel",
                        disabled: loading,
                    },
                    {
                        action: createItem,
                        label: "Create item",
                        disabled:
                            loading || placeholderId === "" || infraType === "",
                    },
                ]}
            />
        </DetailView>
    );
};

interface TableViewProps {
    importData: InfrastructureImport;
    refresh?: () => void;
}

export const TableView = (props: TableViewProps) => {
    const apiClient = useInfrastructureApiClient();
    const [filterSearch, setFilterSearch] = useState("");
    const [showAddModal, setShowAddModal] = useState(false);
    const [filterNoLocation, setFilterNoLocation] = useState(false);
    const [filterLocationNeedsReview, setFilterLocationNeedsReview] =
        useState(false);
    const [filterMissingParent, setFilterMissingParent] = useState(false);
    const [filterEmptyId, setFilterEmptyId] = useState(false);
    const [filterHasDuplicates, setFilterHasDuplicates] = useState(false);
    const [filterNoInfraType, setFilterNoInfraType] = useState(false);
    const debouncedSearch = useDebounce(filterSearch, 400);

    const fetchFunction = async (fetchProps: any) => {
        return await apiClient.infrastructureImportItemsList({
            ...fetchProps,
        });
    };

    const partialUpdate = async (
        id: string,
        value: Partial<InfrastructureImportItem>,
    ) => {
        return apiClient.infrastructureImportItemsPartialUpdate({
            id: parseInt(id),
            patchedInfrastructureImportItemRequest: value,
        });
    };

    const deleteFunction = async (itemId: string) => {
        return await apiClient.infrastructureImportItemsDestroy({
            id: parseInt(itemId),
        });
    };

    const columns = useMemo(
        () => [
            columnHelper.accessor("placeholderId", {
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <EditableTextCell
                            initialValue={value}
                            onChange={(newValue) =>
                                info.table.options.meta?.updateData(
                                    info.row.index,
                                    info.row.original.id,
                                    { placeholderId: newValue },
                                )
                            }
                        />
                    );
                },
                header: () => <span>Temp ID</span>,
            }),
            columnHelper.accessor("siteName", {
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <EditableTextCell
                            initialValue={value}
                            onChange={(newValue) =>
                                info.table.options.meta?.updateData(
                                    info.row.index,
                                    info.row.original.id,
                                    { siteName: newValue },
                                )
                            }
                        />
                    );
                },
                header: () => <span>Site Name</span>,
            }),
            columnHelper.accessor(
                (row) => {
                    return {
                        location: row.location,
                        shape: row.shape,
                        pipelineShape: row.pipelineShape,
                        infraType: row.infraType,
                    };
                },
                {
                    id: "locationandshape",
                    header: () => <span>Location/Shape</span>,
                    cell: (info) => {
                        const geoData = info.getValue();
                        return (
                            <EditableLocationCell
                                initialLocation={geoData.location}
                                initialShape={geoData.shape}
                                initialPipelineShape={geoData.pipelineShape}
                                onChange={(
                                    newLocation,
                                    newShape,
                                    newPipelineShape,
                                ) =>
                                    info.table.options.meta?.updateData(
                                        info.row.index,
                                        info.row.original.id,
                                        {
                                            location: newLocation,
                                            shape: newShape,
                                            pipelineShape: newPipelineShape,
                                        },
                                    )
                                }
                            />
                        );
                    },
                },
            ),
            columnHelper.accessor("infraType", {
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <EditableInfraTypeCell
                            initialValue={value}
                            onChange={(newValue) =>
                                info.table.options.meta?.updateData(
                                    info.row.index,
                                    info.row.original.id,
                                    { infraType: newValue },
                                )
                            }
                        />
                    );
                },
                header: () => <span>Category</span>,
            }),
            columnHelper.accessor(
                (row) => {
                    return {
                        equipmentType: row.equipmentType,
                        infraType: row.infraType,
                    };
                },
                {
                    id: "equipmentType",
                    cell: (info) => {
                        const value = info.getValue();
                        if (["SITE", "PIPELINE"].includes(value.infraType)) {
                            return <span className="text-slate-500">-</span>;
                        }
                        return (
                            <EditableTextCell
                                initialValue={value.equipmentType}
                                onChange={(newValue) =>
                                    info.table.options.meta?.updateData(
                                        info.row.index,
                                        info.row.original.id,
                                        { equipmentType: newValue },
                                    )
                                }
                            />
                        );
                    },
                    header: () => <span>Type</span>,
                },
            ),
            columnHelper.accessor("name", {
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <EditableTextCell
                            initialValue={value}
                            onChange={(newValue) =>
                                info.table.options.meta?.updateData(
                                    info.row.index,
                                    info.row.original.id,
                                    { name: newValue },
                                )
                            }
                        />
                    );
                },
                header: () => <span>Name</span>,
            }),
            columnHelper.accessor(
                (row) => {
                    return {
                        infraType: row.infraType,
                        relatedImport: row.relatedImport,
                        id: row.id,
                        parent: row.parent,
                        parentPlaceholderId: row.parentPlaceholderId,
                    };
                },
                {
                    id: "parent",
                    cell: (info) => {
                        const value = info.getValue();
                        if (["PIPELINE"].includes(value.infraType)) {
                            return <span className="text-slate-500">-</span>;
                        }
                        const autocompleteParent = async (search: string) => {
                            const response =
                                await apiClient.infrastructureImportItemsList({
                                    parentFor: value.id,
                                    pageSize: 10,
                                    relatedImport: props.importData.id,
                                    search: search,
                                });
                            return response.results;
                        };
                        return (
                            <EditableAutocompleteCell<InfrastructureImportItem>
                                initialValue={{
                                    id: value.parent,
                                    placeholderId: value.parentPlaceholderId,
                                }}
                                getDisplayName={(item) =>
                                    item && item.placeholderId
                                }
                                getExtraData={(item) => {
                                    return [
                                        {
                                            name: "Site Name",
                                            value: item.siteName,
                                        },
                                        {
                                            name: "Category",
                                            value: item.infraType
                                                .replace("_", " ")
                                                .toLowerCase(),
                                        },
                                        {
                                            name: "Equipment type",
                                            value: item.equipmentType,
                                        },
                                    ];
                                }}
                                getKey={(item) => item && `item_${item.id}`}
                                queryFn={(search) => autocompleteParent(search)}
                                onChange={(newParent) => {
                                    return info.table.options.meta?.updateData(
                                        info.row.index,
                                        info.row.original.id,
                                        {
                                            parent: newParent
                                                ? newParent.id
                                                : null,
                                        },
                                    );
                                }}
                            />
                        );
                    },
                    header: () => <span>Parent</span>,
                },
            ),
            columnHelper.accessor(
                (row) => {
                    return {
                        id: row.id,
                        lineDuplicates: row.lineDuplicates,
                    };
                },
                {
                    id: "similarLines",
                    cell: (info) => {
                        const value = info.getValue();
                        return (
                            <LineSimilarityCell
                                itemId={value.id}
                                lineDuplicatesId={value.lineDuplicates}
                                save={async (
                                    mergeItems: boolean,
                                    mainItem: number,
                                    itemsToMerge?: number[],
                                ) => {
                                    await apiClient.infrastructureImportItemsMergeSimilarRowsCreate(
                                        {
                                            id: info.row.original.id,
                                            mergeSimilarRowRequest: {
                                                merge: mergeItems,
                                                mainItem,
                                                itemsToMerge,
                                            },
                                        },
                                    );
                                    // Refresh table data
                                    info.table.options.meta?.refresh();
                                }}
                            />
                        );
                    },
                    header: () => <span>Similar lines</span>,
                },
            ),
            columnHelper.accessor("infraStatus", {
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <EditableEnumCell<InfraStatusEnum>
                            initialValue={value}
                            onChange={(newValue) =>
                                info.table.options.meta?.updateData(
                                    info.row.index,
                                    info.row.original.id,
                                    { infraStatus: newValue },
                                )
                            }
                            possibleValues={InfraStatusEnum}
                        />
                    );
                },
                header: () => <span>Status</span>,
                size: 200,
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor("dateOfInstallation", {
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <EditableDateCell
                            initialValue={value}
                            onChange={(newValue) =>
                                info.table.options.meta?.updateData(
                                    info.row.index,
                                    info.row.original.id,
                                    { dateOfInstallation: newValue },
                                )
                            }
                        />
                    );
                },
                header: () => <span>Installation Date</span>,
                size: 200,
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor("pipelineDiameter", {
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <EditableNumberCell
                            initialValue={value}
                            onChange={(newValue) =>
                                info.table.options.meta?.updateData(
                                    info.row.index,
                                    info.row.original.id,
                                    { pipelineDiameter: newValue },
                                )
                            }
                        />
                    );
                },
                header: () => <span>Pipeline Diameter (inches)</span>,
                size: 200,
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor("pipelineCommodity", {
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <EditableEnumCell<PipelineCommodityEnum>
                            initialValue={value}
                            onChange={(newValue) =>
                                info.table.options.meta?.updateData(
                                    info.row.index,
                                    info.row.original.id,
                                    { pipelineCommodity: newValue },
                                )
                            }
                            possibleValues={PipelineCommodityEnum}
                        />
                    );
                },
                header: () => <span>Commodity</span>,
                size: 200,
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor("pipelineHasPhmsa", {
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <EditableBooleanCell
                            initialValue={value}
                            onChange={(newValue) =>
                                info.table.options.meta?.updateData(
                                    info.row.index,
                                    info.row.original.id,
                                    { pipelineHasPhmsa: newValue },
                                )
                            }
                        />
                    );
                },
                header: () => <span>Pipeline PHMSA</span>,
                size: 200,
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor("pipelineSegmentType", {
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <EditableEnumCell<PipelineSegmentTypeEnum>
                            initialValue={value}
                            onChange={(newValue) =>
                                info.table.options.meta?.updateData(
                                    info.row.index,
                                    info.row.original.id,
                                    { pipelineSegmentType: newValue },
                                )
                            }
                            possibleValues={PipelineSegmentTypeEnum}
                        />
                    );
                },
                header: () => <span>Segment Type</span>,
                size: 200,
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor("extraData", {
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <EditableAttributesCell
                            initialValue={value}
                            onChange={(newValue) =>
                                info.table.options.meta?.updateData(
                                    info.row.index,
                                    info.row.original.id,
                                    { extraData: newValue },
                                )
                            }
                        />
                    );
                },
                header: () => <span>Extra attributes</span>,
            }),
            columnHelper.accessor(
                (row) => {
                    return {
                        id: row.id,
                        relatedImport: row.relatedImport,
                        matchStatus: row.matchStatus,
                        relatedInfrastructure: row.relatedInfrastructure,
                    };
                },
                {
                    id: "matchStatus",
                    cell: (info) => {
                        const value = info.getValue();
                        return (
                            <LocationMatchingCell
                                itemId={value.id}
                                matchStatus={value.matchStatus}
                                relatedInfrastructure={
                                    value.relatedInfrastructure
                                }
                                onChange={(matchStatus, selectedMatch) => {
                                    return info.table.options.meta?.updateData(
                                        info.row.index,
                                        value.id,
                                        {
                                            matchStatus,
                                            selectedMatch,
                                        },
                                    );
                                }}
                            />
                        );
                    },
                    header: () => <span>Location Match</span>,
                },
            ),
            columnHelper.display({
                id: "actions",
                header: () => "",
                cell: (props) => {
                    return (
                        <div className="flex items-center space-x-2">
                            <button
                                onClick={() => {
                                    if (
                                        confirm(
                                            "Are you sure you want to delete this item?",
                                        )
                                    ) {
                                        props.table.options.meta?.deleteRow(
                                            props.row.index,
                                            props.row.original.id,
                                        );
                                    }
                                }}
                                className="flex w-fit items-center p-2 rounded-md text-black hover:text-white hover:bg-slate-500"
                            >
                                <TrashIcon className="w-4 h-4" />
                            </button>
                        </div>
                    );
                },
            }),
        ],
        [props.importData, apiClient],
    );

    return (
        <>
            <div className="mx-4 mb-3 flex items-center text-sm justify-between">
                <div className="flex items-center mr-7">
                    <input
                        type="text"
                        className="rounded-lg py-1 px-2"
                        placeholder="Search by ID/Name"
                        value={filterSearch}
                        onChange={(e) => setFilterSearch(e.target.value)}
                    />
                    <MagnifyingGlassIcon className="w-5 h-5 -ml-7" />
                </div>
                <div className="flex flex-wrap ml-10">
                    <p className="mr-4">Only show items:</p>
                    <div className="mb-1 mr-4">
                        <CustomSwitch
                            checked={filterNoLocation}
                            onChange={() =>
                                setFilterNoLocation(!filterNoLocation)
                            }
                            size="sm"
                        />
                        <span className="ml-2">Missing location</span>
                    </div>
                    <div className="mb-1 mr-4">
                        <CustomSwitch
                            checked={filterNoInfraType}
                            onChange={() =>
                                setFilterNoInfraType(!filterNoInfraType)
                            }
                            size="sm"
                        />
                        <span className="ml-2">
                            Missing infrastructure type
                        </span>
                    </div>
                    <div className="mb-1 mr-4">
                        <CustomSwitch
                            checked={filterEmptyId}
                            onChange={() => setFilterEmptyId(!filterEmptyId)}
                            size="sm"
                        />
                        <span className="ml-2">With empty ID</span>
                    </div>
                    <div className="mb-1 mr-4">
                        <CustomSwitch
                            checked={filterMissingParent}
                            onChange={() =>
                                setFilterMissingParent(!filterMissingParent)
                            }
                            size="sm"
                        />
                        <span className="ml-2">
                            Missing parent relationships
                        </span>
                    </div>
                    <div className="mb-1 mr-4">
                        <CustomSwitch
                            checked={filterLocationNeedsReview}
                            onChange={() =>
                                setFilterLocationNeedsReview(
                                    !filterLocationNeedsReview,
                                )
                            }
                            size="sm"
                        />
                        <span className="ml-2">Location needs review</span>
                    </div>
                    <div className="mb-1 mr-4">
                        <CustomSwitch
                            checked={filterHasDuplicates}
                            onChange={() =>
                                setFilterHasDuplicates(!filterHasDuplicates)
                            }
                            size="sm"
                        />
                        <span className="ml-2">With possible duplicates</span>
                    </div>
                </div>
            </div>
            <hr />
            <DataTable
                dataName="infrastructure_import_items"
                columns={columns}
                extraFilters={{
                    relatedImport: props.importData.id,
                    emptyPlaceholderId: filterEmptyId ? true : undefined,
                    missingParent: filterMissingParent ? true : undefined,
                    noLocation: filterNoLocation ? true : undefined,
                    missingInfraType: filterNoInfraType ? true : undefined,
                    locationNeedsReview: filterLocationNeedsReview
                        ? true
                        : undefined,
                    hasLineDuplicates: filterHasDuplicates ? true : undefined,
                    search: debouncedSearch,
                }}
                fetchFunction={fetchFunction}
                defaultPageSize={20}
                partialUpdate={partialUpdate}
                deleteRow={deleteFunction}
                sortable={false}
                createRow={() => setShowAddModal(true)}
            />
            <AddItemModal
                onClose={() => setShowAddModal(false)}
                visible={showAddModal}
                onSave={() => setShowAddModal(false)}
                relatedImport={props.importData}
            />
        </>
    );
};
