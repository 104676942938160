/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `PENDING_IMPORT` - Pending Import
 * * `AV_CHECK` - Av Check
 * * `DATA_IMPORT` - Data Import
 * * `VERIFY_AND_COMPLETE` - Verify And Complete
 * * `WAITING_REVIEW` - Waiting Review
 * * `WAITING_IMPORT` - Waiting Import
 * * `IMPORTING_DATA` - Importing Data
 * * `COMPLETE` - Complete
 * * `VIRUS_DETECTED` - Virus Detected
 * @export
 */
export const ImportStatusEnum = {
    PendingImport: 'PENDING_IMPORT',
    AvCheck: 'AV_CHECK',
    DataImport: 'DATA_IMPORT',
    VerifyAndComplete: 'VERIFY_AND_COMPLETE',
    WaitingReview: 'WAITING_REVIEW',
    WaitingImport: 'WAITING_IMPORT',
    ImportingData: 'IMPORTING_DATA',
    Complete: 'COMPLETE',
    VirusDetected: 'VIRUS_DETECTED'
} as const;
export type ImportStatusEnum = typeof ImportStatusEnum[keyof typeof ImportStatusEnum];


export function instanceOfImportStatusEnum(value: any): boolean {
    return Object.values(ImportStatusEnum).includes(value);
}

export function ImportStatusEnumFromJSON(json: any): ImportStatusEnum {
    return ImportStatusEnumFromJSONTyped(json, false);
}

export function ImportStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportStatusEnum {
    return json as ImportStatusEnum;
}

export function ImportStatusEnumToJSON(value?: ImportStatusEnum | null): any {
    return value as any;
}

