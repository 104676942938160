import { Popover } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { ReactNode, useMemo, useState } from "react";
import {
    autoUpdate,
    flip,
    offset,
    shift,
    useFloating,
} from "@floating-ui/react";
import { faChevronRight } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomCheckbox } from "../../../ui/CustomCheckbox";
import { SearchInput } from "../../../ui/Inputs";
import {
    AdminEmissionsRecordsStatsListProviderWithSourceParameterInner,
    DataProvider,
    PipelineProductEnum,
    PipelineTypeEnum,
} from "../../../apiClient/generated";
import { faArrowsRotate } from "@fortawesome/pro-light-svg-icons";
import { MapProviderFilter } from "../../filters/ProviderFilter";

interface MenuCategoryProps {
    children: string | ReactNode;
}

export const MenuCategory = (props: MenuCategoryProps) => (
    <div className="mb-2 font-medium tracking-wide uppercase text-xs text-zinc-600 flex">
        {props.children}
    </div>
);

interface CheckboxProps {
    label?: string;
    checked: boolean;
    onChange: (newValue: boolean) => void;
    disabled?: boolean;
    icon?: ReactNode;
}

export const Checkbox = (props: CheckboxProps) => (
    <div className="flex items-center gap-3 text-sm">
        <input
            type="checkbox"
            className="rounded mr text-ae-blue-500 disabled:text-neutral-600"
            id={props.label}
            checked={props.checked}
            onChange={(e) =>
                !props.disabled && props.onChange(e.target.checked)
            }
            disabled={props.disabled}
        />
        <label
            htmlFor={props.label}
            className={`flex gap-2 items-center capitalize ${
                props.disabled && "text-neutral-600"
            }`}
        >
            {props.label}
            {props.icon}
        </label>
    </div>
);

interface CheckboxWithMenuProps {
    label: string;
    checked: boolean;
    onChange?: (newValue: boolean) => void;
    children?: ReactNode;
    disabled?: boolean;
    icon?: ReactNode;
}

export const CheckboxWithMenu = (props: CheckboxWithMenuProps) => {
    return (
        <div className="flex">
            <Checkbox
                label={props.label}
                checked={props.checked}
                onChange={props.onChange}
                disabled={props.disabled}
                icon={props.icon}
            />

            <div className="flex-grow" />

            {!props.disabled && props.children && (
                <Popover className="relative">
                    <Popover.Button className="p-1 rounded flex items-center justify-center border border-white hover:border-gray-200">
                        <ChevronRightIcon className="w-3" />
                    </Popover.Button>

                    <Popover.Panel className="absolute z-10 top-0 left-10 rounded p-2 bg-white w-52 max-h-96 overflow-scroll">
                        {props.children}
                    </Popover.Panel>
                </Popover>
            )}
        </div>
    );
};

interface MenuCheckboxItemProps {
    label: string;
    selected: string[] | "all";
    setSelected: (value: string[] | "all") => void;
    options?: {
        id: string;
        label: string;
    }[];
    search?: boolean;
}

export const MenuCheckboxItem = (props: MenuCheckboxItemProps) => {
    const { refs, floatingStyles } = useFloating({
        strategy: "fixed",
        placement: "right-start",
        middleware: [
            offset({
                mainAxis: 10,
                crossAxis: props.search ? -45 : -4,
            }),
            shift(),
            flip(),
        ],
        whileElementsMounted: autoUpdate,
    });

    // Filter items using search value
    const [search, setSearch] = useState("");
    const items = useMemo(() => {
        if (!props.options) {
            return [];
        }
        return props.options.filter((i) =>
            i.label.toLowerCase().includes(search.toLowerCase()),
        );
    }, [search, props.options]);

    return (
        <Popover
            ref={refs.setReference}
            className="w-full h-8 hover:bg-ae-gray-100 flex items-center justify-start rounded"
        >
            <div className="flex items-center w-full gap-2 pl-2">
                <CustomCheckbox
                    checked={
                        props.selected === "all" ||
                        (props.options &&
                            props.selected.length === props.options.length)
                            ? "yes"
                            : props.selected.length === 0
                              ? "no"
                              : "partial"
                    }
                    onClick={(v) =>
                        v === "yes"
                            ? props.setSelected("all")
                            : props.setSelected([])
                    }
                    disabled={props.options && props.options.length === 0}
                />
                <span
                    className={`flex-grow ${
                        props.options &&
                        props.options.length == 0 &&
                        "text-gray-400"
                    }`}
                >
                    {props.label}
                </span>
                {!props.options ? (
                    <div className="p-2 animate-spin">
                        <FontAwesomeIcon
                            icon={faArrowsRotate}
                            className="w-5"
                        />
                    </div>
                ) : (
                    props.options.length > 0 && (
                        <Popover.Button className="flex items-center p-1">
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                className="w-5"
                            />
                        </Popover.Button>
                    )
                )}
            </div>
            <Popover.Panel
                ref={refs.setFloating}
                style={floatingStyles}
                className="min-w-56 overflow-auto rounded-md p-1 bg-white py-1 border-2 border-ae-slate-300"
            >
                {props.search && (
                    <SearchInput
                        value={search}
                        onChange={(v) => setSearch(v)}
                        placeholder="Search providers"
                        containerClassName="mb-1"
                    />
                )}
                {items.map((i) => (
                    <label
                        key={i.id}
                        className="px-2 h-8 flex items-center gap-2 rounded hover:bg-ae-gray-100"
                    >
                        <input
                            type="checkbox"
                            className="rounded text-ae-blue-550"
                            checked={
                                props.selected === "all" ||
                                !!props.selected.find((s) => s === i.id)
                            }
                            onChange={() => {
                                if (props.selected === "all") {
                                    props.setSelected(
                                        props.options
                                            .filter((o) => o.id !== i.id)
                                            .map((o) => o.id),
                                    );
                                } else {
                                    const idx = props.selected.findIndex(
                                        (s) => s === i.id,
                                    );
                                    if (idx === -1) {
                                        props.setSelected([
                                            ...props.selected,
                                            i.id,
                                        ]);
                                    } else {
                                        props.setSelected(
                                            props.selected.filter(
                                                (s) => s !== i.id,
                                            ),
                                        );
                                    }
                                }
                            }}
                        />
                        <span className="line-clamp-1">{i.label}</span>
                    </label>
                ))}
            </Popover.Panel>
        </Popover>
    );
};

interface PipelinePickerMenuProps {
    products: PipelineProductEnum[];
    setProducts: (value: PipelineProductEnum[]) => void;
    types: PipelineTypeEnum[];
    setTypes: (value: PipelineTypeEnum[]) => void;
}

export const PipelinePickerMenu = (props: PipelinePickerMenuProps) => {
    const { refs, floatingStyles } = useFloating({
        strategy: "fixed",
        placement: "right-start",
        middleware: [
            offset({
                mainAxis: 10,
                crossAxis: -32,
            }),
            shift(),
            flip(),
        ],
        whileElementsMounted: autoUpdate,
    });

    return (
        <Popover
            ref={refs.setReference}
            className="w-full h-8 hover:bg-ae-gray-100 flex items-center justify-start rounded"
        >
            <div className="flex items-center w-full gap-2 pl-2">
                <CustomCheckbox
                    checked={
                        props.products.length === 0 || props.types.length === 0
                            ? "no"
                            : props.products.length ===
                                    Object.values(PipelineProductEnum).length &&
                                props.types.length ===
                                    Object.values(PipelineTypeEnum).length
                              ? "yes"
                              : "partial"
                    }
                    onClick={(v) => {
                        if (v === "no") {
                            props.setProducts([]);
                            props.setTypes([]);
                        } else {
                            props.setProducts([
                                PipelineProductEnum.Ngl,
                                PipelineProductEnum.NaturalGas,
                            ]);
                            props.setTypes([
                                PipelineTypeEnum.Distribution,
                                PipelineTypeEnum.Gathering,
                                PipelineTypeEnum.Transmission,
                            ]);
                        }
                    }}
                />
                <span className="flex-grow">Pipelines</span>
                <Popover.Button className="flex items-center p-1">
                    <FontAwesomeIcon icon={faChevronRight} className="w-5" />
                </Popover.Button>
            </div>
            <Popover.Panel
                ref={refs.setFloating}
                style={floatingStyles}
                className="min-w-56 overflow-auto rounded-md p-1 bg-white py-1 border-2 border-ae-slate-300"
            >
                <div className="px-2 mt-2 mb-1 text-neutral-600 text-xs font-semibold leading-tight tracking-wide uppercase">
                    Pipeline Product
                </div>
                {Object.values(PipelineProductEnum).map((product) => (
                    <label
                        key={product}
                        className="px-2 h-8 flex items-center gap-2 rounded hover:bg-ae-gray-100"
                    >
                        <input
                            type="checkbox"
                            className="rounded text-ae-blue-550"
                            checked={
                                !!props.products.find((p) => p === product)
                            }
                            onChange={() => {
                                const idx = props.products.findIndex(
                                    (p) => p === product,
                                );
                                if (idx === -1) {
                                    props.setProducts([
                                        ...props.products,
                                        product,
                                    ]);
                                } else {
                                    props.setProducts(
                                        props.products.filter(
                                            (p) => p !== product,
                                        ),
                                    );
                                }
                            }}
                        />
                        <span className="line-clamp-1 capitalize">
                            {product.toLowerCase().replaceAll("_", " ")}
                        </span>
                    </label>
                ))}
                <hr className="my-3 h-[1px] border-0 mx-2 bg-black bg-opacity-30" />
                <div className="px-2 my-1 text-neutral-600 text-xs font-semibold leading-tight tracking-wide uppercase">
                    Pipeline Types
                </div>
                {Object.values(PipelineTypeEnum).map((pipelineType) => (
                    <label
                        key={pipelineType}
                        className="px-2 h-8 flex items-center gap-2 rounded hover:bg-ae-gray-100"
                    >
                        <input
                            type="checkbox"
                            className="rounded text-ae-blue-550"
                            checked={
                                !!props.types.find((p) => p === pipelineType)
                            }
                            onChange={() => {
                                const idx = props.types.findIndex(
                                    (p) => p === pipelineType,
                                );
                                if (idx === -1) {
                                    props.setTypes([
                                        ...props.types,
                                        pipelineType,
                                    ]);
                                } else {
                                    props.setTypes(
                                        props.types.filter(
                                            (p) => p !== pipelineType,
                                        ),
                                    );
                                }
                            }}
                        />
                        <span className="line-clamp-1 capitalize">
                            {pipelineType.toLowerCase().replaceAll("_", " ")}
                        </span>
                    </label>
                ))}
            </Popover.Panel>
        </Popover>
    );
};

interface DataProviderPickerCheckBoxMenuProps {
    label: string;
    selected:
        | AdminEmissionsRecordsStatsListProviderWithSourceParameterInner[]
        | "all";
    setSelected: (
        value:
            | AdminEmissionsRecordsStatsListProviderWithSourceParameterInner[]
            | "all",
    ) => void;
    dataProviders: DataProvider[];
}

export const DataProviderPickerCheckBoxMenu = (
    props: DataProviderPickerCheckBoxMenuProps,
) => {
    const { refs, floatingStyles } = useFloating({
        strategy: "fixed",
        placement: "right-start",
        middleware: [
            offset({
                mainAxis: 10,
                crossAxis: -45,
            }),
            shift(),
            flip(),
        ],
        whileElementsMounted: autoUpdate,
    });

    return (
        <Popover
            ref={refs.setReference}
            className="w-full h-8 hover:bg-ae-gray-100 flex items-center justify-start rounded"
        >
            <div className="flex items-center w-full gap-2 pl-2">
                <CustomCheckbox
                    checked={
                        props.selected === "all" ||
                        (props.dataProviders &&
                            props.selected.length ===
                                props.dataProviders.length &&
                            !props.selected.some((p) => p.se))
                            ? "yes"
                            : props.selected.length === 0
                              ? "no"
                              : "partial"
                    }
                    onClick={(v) =>
                        v === "yes"
                            ? props.setSelected("all")
                            : props.setSelected([])
                    }
                    disabled={
                        props.dataProviders && props.dataProviders.length === 0
                    }
                />
                <span
                    className={`flex-grow ${
                        props.dataProviders &&
                        props.dataProviders.length == 0 &&
                        "text-gray-400"
                    }`}
                >
                    {props.label}
                </span>
                {!props.dataProviders ? (
                    <div className="p-2 animate-spin">
                        <FontAwesomeIcon
                            icon={faArrowsRotate}
                            className="w-5"
                        />
                    </div>
                ) : (
                    props.dataProviders.length > 0 && (
                        <Popover.Button className="flex items-center p-1">
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                className="w-5"
                            />
                        </Popover.Button>
                    )
                )}
            </div>
            <Popover.Panel
                ref={refs.setFloating}
                style={floatingStyles}
                className="w-[380px] overflow-auto rounded-md p-1 bg-white py-1 border-2 border-ae-slate-300"
            >
                <MapProviderFilter
                    filterValue={props.selected}
                    setFilterValue={props.setSelected}
                    dataProviders={props.dataProviders}
                />
            </Popover.Panel>
        </Popover>
    );
};
