import {
    autoUpdate,
    FloatingArrow,
    offset,
    useFloating,
} from "@floating-ui/react";
import {
    faChartBar,
    faCog,
    faMap,
    faSquareKanban,
    faTable,
    faTableColumns,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, Switch } from "@headlessui/react";
import { useRef } from "react";
import { InputButton } from "../../../ui/Buttons";

interface TablePlotCustomSwitchProps {
    showPlot: boolean;
    onChange: (v: boolean) => void;
}

export const TablePlotCustomSwitch = (props: TablePlotCustomSwitchProps) => {
    const arrowRef = useRef(null);
    const { refs, floatingStyles, context } = useFloating({
        strategy: "fixed",
        placement: "left",
        middleware: [
            offset({
                mainAxis: 10,
            }),
        ],
        whileElementsMounted: autoUpdate,
    });

    return (
        <>
            <Switch
                ref={refs.setReference}
                checked={props.showPlot}
                onChange={props.onChange}
                className={`
                    group bg-white border border-neutral-400
                    relative inline-flex items-center rounded-full
                    w-16 h-8
                `}
            >
                <span
                    className={`${
                        props.showPlot
                            ? "translate-x-[36px]"
                            : "translate-x-[4px]"
                    } 
                        inline-block transform rounded-full bg-zinc-600 transition drop-shadow
                        h-5 w-5
                    `}
                />
                {props.showPlot ? (
                    <FontAwesomeIcon
                        icon={faTable}
                        className="w-5 absolute top-[7px] left-2"
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faSquareKanban}
                        className="w-5 absolute top-[7px] right-2"
                    />
                )}
                <div
                    className="hidden group-hover:block text-xs px-2 py-2 bg-neutral-600 text-white rounded"
                    ref={refs.setFloating}
                    style={floatingStyles}
                >
                    <FloatingArrow
                        className="fill-neutral-600 mt-[2px]"
                        ref={arrowRef}
                        context={context}
                    />
                    {props.showPlot
                        ? "Switch to table view"
                        : "Switch to plot view"}
                </div>
            </Switch>
        </>
    );
};

interface MapPlotToggleProps {
    expanded?: "MAP" | "PLOT";
    setExpanded: (value?: "PLOT" | "MAP") => void;
}

export const MapPlotToggle = (props: MapPlotToggleProps) => (
    <div className="flex border border-r h-8">
        <InputButton
            className="w-8 rounded-l border-r"
            onClick={() => props.setExpanded("PLOT")}
            active={props.expanded === "PLOT"}
        >
            <FontAwesomeIcon icon={faChartBar} />
        </InputButton>
        <InputButton
            className="w-8 border-r"
            onClick={() => props.setExpanded(undefined)}
            active={!props.expanded}
        >
            <FontAwesomeIcon icon={faTableColumns} />
        </InputButton>
        <InputButton
            className="w-8 rounded-r"
            onClick={() => props.setExpanded("MAP")}
            active={props.expanded === "MAP"}
        >
            <FontAwesomeIcon icon={faMap} />
        </InputButton>
    </div>
);

interface SettingsDropdownProps {
    value: string[];
    setValue: (v: string[]) => void;
    options: {
        id: string;
        label: string;
    }[];
}

export const SettingsDropdown = (props: SettingsDropdownProps) => {
    const { value, setValue, options } = props;

    const handleCheckboxChange = (optionId: string) => {
        if (value.includes(optionId)) {
            setValue(value.filter((id) => id !== optionId));
        } else {
            setValue([...value, optionId]);
        }
    };

    return (
        <Popover className="relative">
            {(
                { open }, // Using render props pattern
            ) => (
                <>
                    <Popover.Button>
                        <InputButton className="w-8 h-8 rounded" active={open}>
                            <FontAwesomeIcon icon={faCog} />
                        </InputButton>
                    </Popover.Button>

                    <Popover.Panel className="absolute right-0 z-50 mt-1 w-48 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 p-1">
                        <div>
                            {options.map((option) => (
                                <label
                                    key={option.id}
                                    className="flex items-center space-x-2 px-2 py-1 hover:bg-gray-50 rounded cursor-pointer"
                                >
                                    <input
                                        type="checkbox"
                                        checked={value.includes(option.id)}
                                        onChange={() =>
                                            handleCheckboxChange(option.id)
                                        }
                                        className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                    />
                                    <span className="text-sm text-gray-700">
                                        {option.label}
                                    </span>
                                </label>
                            ))}
                        </div>
                    </Popover.Panel>
                </>
            )}
        </Popover>
    );
};
