/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `4326` - WGS 84 / EPSG4326
 * * `26717` - NAD 27 / EPSG26717
 * * `4269` - NAD 83 / EPSG4269
 * * `3857` - WGS 84 / Pseudo-Mercator / EPSG3857
 * * `32661` - WGS 84 / UPS North / EPSG32661
 * * `900913` - Google Maps Global Mercator / EPSG900913
 * @export
 */
export const CsvDataSridEnum = {
    NUMBER_4326: 4326,
    NUMBER_26717: 26717,
    NUMBER_4269: 4269,
    NUMBER_3857: 3857,
    NUMBER_32661: 32661,
    NUMBER_900913: 900913
} as const;
export type CsvDataSridEnum = typeof CsvDataSridEnum[keyof typeof CsvDataSridEnum];


export function instanceOfCsvDataSridEnum(value: any): boolean {
    return Object.values(CsvDataSridEnum).includes(value);
}

export function CsvDataSridEnumFromJSON(json: any): CsvDataSridEnum {
    return CsvDataSridEnumFromJSONTyped(json, false);
}

export function CsvDataSridEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): CsvDataSridEnum {
    return json as CsvDataSridEnum;
}

export function CsvDataSridEnumToJSON(value?: CsvDataSridEnum | null): any {
    return value as any;
}

