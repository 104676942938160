/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DownloadableDataPackage } from './DownloadableDataPackage';
import {
    DownloadableDataPackageFromJSON,
    DownloadableDataPackageFromJSONTyped,
    DownloadableDataPackageToJSON,
} from './DownloadableDataPackage';

/**
 * 
 * @export
 * @interface PaginatedDownloadableDataPackageList
 */
export interface PaginatedDownloadableDataPackageList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedDownloadableDataPackageList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedDownloadableDataPackageList
     */
    next?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedDownloadableDataPackageList
     */
    previous?: string;
    /**
     * 
     * @type {Array<DownloadableDataPackage>}
     * @memberof PaginatedDownloadableDataPackageList
     */
    results: Array<DownloadableDataPackage>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedDownloadableDataPackageList
     */
    numPages?: number;
}

/**
 * Check if a given object implements the PaginatedDownloadableDataPackageList interface.
 */
export function instanceOfPaginatedDownloadableDataPackageList(value: object): boolean {
    if (!('count' in value)) return false;
    if (!('results' in value)) return false;
    return true;
}

export function PaginatedDownloadableDataPackageListFromJSON(json: any): PaginatedDownloadableDataPackageList {
    return PaginatedDownloadableDataPackageListFromJSONTyped(json, false);
}

export function PaginatedDownloadableDataPackageListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedDownloadableDataPackageList {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(DownloadableDataPackageFromJSON)),
        'numPages': json['num_pages'] == null ? undefined : json['num_pages'],
    };
}

export function PaginatedDownloadableDataPackageListToJSON(value?: PaginatedDownloadableDataPackageList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(DownloadableDataPackageToJSON)),
        'num_pages': value['numPages'],
    };
}

