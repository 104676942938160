import { fromArrayBuffer } from "geotiff";

// This was already upstreamed to loaders.gl, we can remove this code
// when upgrading to 4.x versions of the library
const loadGeoTiff = async (data: ArrayBuffer) => {
    // Load using Geotiff.js
    const tiff = await fromArrayBuffer(data);

    // Assumes we only have one image inside TIFF
    const image = await tiff.getImage();

    // Read image and size
    const rgbData = await image.readRGB({ enableAlpha: true });
    const width = image.getWidth();
    const height = image.getHeight();

    // Create a new ImageData object
    const imageData = new ImageData(width, height);
    imageData.data.set(new Uint8ClampedArray(rgbData as unknown as Uint8Array));
    return imageData;
};

export const GeoTiffLoader = {
    id: "GeoTIFF",
    name: "GeoTIFF",
    module: "",
    version: "1",
    options: {},
    mimeTypes: ["image/tiff", "image/geotiff"],
    extensions: ["geotiff", "tiff", "geotif", "tif"],
    parse: loadGeoTiff,
};
