/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DataProvider,
  PaginatedDataProviderList,
  SecondaryDataSources,
} from '../models/index';
import {
    DataProviderFromJSON,
    DataProviderToJSON,
    PaginatedDataProviderListFromJSON,
    PaginatedDataProviderListToJSON,
    SecondaryDataSourcesFromJSON,
    SecondaryDataSourcesToJSON,
} from '../models/index';

export interface DataProvidersGetSecondaryDataSourcesRetrieveRequest {
    company?: string;
    dataSource?: Array<DataProvidersGetSecondaryDataSourcesRetrieveDataSourceEnum>;
    hasData?: boolean;
    isEnabled?: boolean;
    ordering?: string;
}

export interface DataProvidersListRequest {
    company?: string;
    dataSource?: Array<DataProvidersListDataSourceEnum>;
    hasData?: boolean;
    isEnabled?: boolean;
    ordering?: string;
    page?: number;
    pageSize?: number;
}

export interface DataProvidersRetrieveRequest {
    id: string;
}

/**
 * 
 */
export class DataProvidersApi extends runtime.BaseAPI {

    /**
     * Returns a list of unique secondary data sources.
     */
    async dataProvidersGetSecondaryDataSourcesRetrieveRaw(requestParameters: DataProvidersGetSecondaryDataSourcesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecondaryDataSources>> {
        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['dataSource'] != null) {
            queryParameters['data_source'] = requestParameters['dataSource'];
        }

        if (requestParameters['hasData'] != null) {
            queryParameters['has_data'] = requestParameters['hasData'];
        }

        if (requestParameters['isEnabled'] != null) {
            queryParameters['is_enabled'] = requestParameters['isEnabled'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/data-providers/get_secondary_data_sources/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecondaryDataSourcesFromJSON(jsonValue));
    }

    /**
     * Returns a list of unique secondary data sources.
     */
    async dataProvidersGetSecondaryDataSourcesRetrieve(requestParameters: DataProvidersGetSecondaryDataSourcesRetrieveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecondaryDataSources> {
        const response = await this.dataProvidersGetSecondaryDataSourcesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve data provider information.
     */
    async dataProvidersListRaw(requestParameters: DataProvidersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedDataProviderList>> {
        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['dataSource'] != null) {
            queryParameters['data_source'] = requestParameters['dataSource'];
        }

        if (requestParameters['hasData'] != null) {
            queryParameters['has_data'] = requestParameters['hasData'];
        }

        if (requestParameters['isEnabled'] != null) {
            queryParameters['is_enabled'] = requestParameters['isEnabled'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/data-providers/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDataProviderListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve data provider information.
     */
    async dataProvidersList(requestParameters: DataProvidersListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedDataProviderList> {
        const response = await this.dataProvidersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve data provider information.
     */
    async dataProvidersRetrieveRaw(requestParameters: DataProvidersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataProvider>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling dataProvidersRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/data-providers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataProviderFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve data provider information.
     */
    async dataProvidersRetrieve(requestParameters: DataProvidersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataProvider> {
        const response = await this.dataProvidersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const DataProvidersGetSecondaryDataSourcesRetrieveDataSourceEnum = {
    Epa: 'EPA',
    SelfReported: 'SELF_REPORTED',
    ThirdParty: 'THIRD_PARTY'
} as const;
export type DataProvidersGetSecondaryDataSourcesRetrieveDataSourceEnum = typeof DataProvidersGetSecondaryDataSourcesRetrieveDataSourceEnum[keyof typeof DataProvidersGetSecondaryDataSourcesRetrieveDataSourceEnum];
/**
 * @export
 */
export const DataProvidersListDataSourceEnum = {
    Epa: 'EPA',
    SelfReported: 'SELF_REPORTED',
    ThirdParty: 'THIRD_PARTY'
} as const;
export type DataProvidersListDataSourceEnum = typeof DataProvidersListDataSourceEnum[keyof typeof DataProvidersListDataSourceEnum];
