import { MouseEventHandler, ReactNode } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { Popover } from "@headlessui/react";
import { autoUpdate, offset, shift, useFloating } from "@floating-ui/react";

interface ControlGroupProps {
    children: ReactNode;
}

export const ControlGroup = (props: ControlGroupProps) => (
    <div className="flex divide-x">{props.children}</div>
);

interface ControlButtonProps {
    children: ReactNode | string;
    onClick?: MouseEventHandler<HTMLDivElement>;
    active?: boolean;
    title?: string;
    className?: string;
    selected?: boolean;
}

export const ControlButton = (props: ControlButtonProps) => (
    <div
        className={`
            h-8 p-1 flex items-center justify-center cursor-pointer
            ${
                props.selected
                    ? "text-white bg-ae-blue-600 hover:text-black"
                    : "bg-white text-ae-slate-900 hover:text-ae-blue-550"
            }
            ${props.className}
        `}
        onClick={props.onClick}
        title={props.title}
    >
        {props.children}
    </div>
);

interface MapSearchInputProps {
    value: string;
    onChange: (newValue: string) => void;
    onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const MapSearchInput = (props: MapSearchInputProps) => {
    return (
        <div className="relative overflow-hidden">
            <div className="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">
                <MagnifyingGlassIcon className="w-4 text-ae-blue-900" />
            </div>
            <input
                type="search"
                id="default-search"
                className="block w-44 h-8 ps-8 text-sm border-0 rounded-l"
                placeholder="Search"
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
            />
        </div>
    );
};

interface MapDropdownMenuProps {
    button: ReactNode;
    children: ReactNode;
}

export const MapDropdownMenu = (props: MapDropdownMenuProps) => (
    <Popover className="relative">
        <Popover.Button>{props.button}</Popover.Button>

        <Popover.Panel className="absolute z-50 mt-1 bg-white rounded p-2 left-1/2 transform -translate-x-1/2">
            {props.children}
        </Popover.Panel>
    </Popover>
);

export const MapDropdownMenuV2 = (props: MapDropdownMenuProps) => {
    const { refs, floatingStyles } = useFloating({
        strategy: "fixed",
        placement: "bottom-end",
        middleware: [
            offset({
                mainAxis: 3,
            }),
            shift(),
        ],
        whileElementsMounted: autoUpdate,
    });

    return (
        <Popover className="relative">
            <Popover.Button ref={refs.setReference}>
                {props.button}
            </Popover.Button>
            <Popover.Panel
                ref={refs.setFloating}
                style={floatingStyles}
                className="bg-white rounded p-2 border border-ae-gray-250 z-50"
            >
                {props.children}
            </Popover.Panel>
        </Popover>
    );
};
