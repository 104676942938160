/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BlankEnum } from './BlankEnum';
import {
    instanceOfBlankEnum,
    BlankEnumFromJSON,
    BlankEnumFromJSONTyped,
    BlankEnumToJSON,
} from './BlankEnum';
import type { PipelineCommodityEnum } from './PipelineCommodityEnum';
import {
    instanceOfPipelineCommodityEnum,
    PipelineCommodityEnumFromJSON,
    PipelineCommodityEnumFromJSONTyped,
    PipelineCommodityEnumToJSON,
} from './PipelineCommodityEnum';

/**
 * @type AdminInfrastructureImportItemPipelineCommodity
 * 
 * @export
 */
export type AdminInfrastructureImportItemPipelineCommodity = BlankEnum | PipelineCommodityEnum;

export function AdminInfrastructureImportItemPipelineCommodityFromJSON(json: any): AdminInfrastructureImportItemPipelineCommodity {
    return AdminInfrastructureImportItemPipelineCommodityFromJSONTyped(json, false);
}

export function AdminInfrastructureImportItemPipelineCommodityFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminInfrastructureImportItemPipelineCommodity {
    if (json == null) {
        return json;
    }
    return BlankEnumFromJSONTyped(json, true) || PipelineCommodityEnumFromJSONTyped(json, true);
}

export function AdminInfrastructureImportItemPipelineCommodityToJSON(value?: AdminInfrastructureImportItemPipelineCommodity | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfBlankEnum(value)) {
        return BlankEnumToJSON(value as BlankEnum);
    }
    if (instanceOfPipelineCommodityEnum(value)) {
        return PipelineCommodityEnumToJSON(value as PipelineCommodityEnum);
    }

    return {};
}

