import { Square2StackIcon } from "@heroicons/react/24/outline";
import { SUPPORT_EMAIL } from "../../constants/globals";
import { Card } from "../../ui/Card";
import { useCopyToClipboard } from "@uidotdev/usehooks";

export const PrivacyPolicy = () => {
    const [, copyToClipBoard] = useCopyToClipboard();

    return (
        <div className="prose px-6 max-w-6xl mx-auto">
            <h1 className="flex pt-10 font-bold font-sans antialiased text-inherit text-xl">
                Privacy Policy
            </h1>

            <Card>
                <div className="text-base">
                    <p className="italic">Last updated: May 23, 2024</p>
                    <p className="mt-2">
                        This Privacy Policy describes Our policies and
                        procedures on the collection, use and disclosure of Your
                        information when You use the Service and tells You about
                        Your privacy rights and how the law protects You.
                    </p>
                    <p className="mt-2">
                        We use Your Personal Data to provide and improve the
                        Service. By using the Service, You agree to the
                        collection and use of information in accordance with
                        this Privacy Policy.
                    </p>
                    <h2 className="mt-4 text-xl">
                        <strong>Interpretation and Definitions</strong>
                    </h2>
                    <h3 className="mt-2 text-md">
                        <strong>Interpretation</strong>
                    </h3>
                    <p className="mt-2">
                        The words of which the initial letter is capitalized
                        have meanings defined under the following conditions.
                        The following definitions shall have the same meaning
                        regardless of whether they appear in singular or in
                        plural.
                    </p>
                    <h3 className="mt-2 text-md">
                        <strong>Definitions</strong>
                    </h3>
                    <p className="mt-2">
                        For the purposes of this Privacy Policy:
                    </p>
                    <ul className="list-inside list-disc mt-2">
                        <li>
                            <strong>Account</strong> means a unique account
                            created for You to access our Service or parts of
                            our Service.
                        </li>
                        <li>
                            <strong>Affiliate</strong> means an entity that
                            controls, is controlled by or is under common
                            control with a party, where &quot;control&quot;
                            means ownership of 50% or more of the shares, equity
                            interest or other securities entitled to vote for
                            election of directors or other managing authority.
                        </li>
                        <li>
                            <strong>Application</strong> refers to Aershed, the
                            software program provided by the Company.
                        </li>
                        <li>
                            <strong>Company</strong> (referred to as either
                            &quot;the Company&quot;, &quot;We&quot;,
                            &quot;Us&quot; or &quot;Our&quot; in this Agreement)
                            refers to Aerscape LLC, a Texas limited liability
                            company.
                        </li>
                        <li>
                            <strong>Country</strong> refers to: Texas, United
                            States
                        </li>
                        <li>
                            <strong>Device</strong> means any device that can
                            access the Service such as a computer, a cellphone
                            or a digital tablet.
                        </li>
                        <li>
                            <strong>Personal Data</strong> is any information
                            that relates to an identified or identifiable
                            individual.
                        </li>
                        <li>
                            <strong>Service</strong> refers to the Application.
                        </li>
                        <li>
                            <strong>Service Provider</strong> means any natural
                            or legal person who processes the data on behalf of
                            the Company. It refers to third-party companies or
                            individuals employed by the Company to facilitate
                            the Service, to provide the Service on behalf of the
                            Company, to perform services related to the Service
                            or to assist the Company in analyzing how the
                            Service is used.
                        </li>
                        <li>
                            <strong>Usage Data</strong> refers to data collected
                            automatically, either generated by the use of the
                            Service or from the Service infrastructure itself
                            (for example, the duration of a page visit).
                        </li>
                        <li>
                            <strong>You</strong> (referred to as either “You” or
                            “Your”) means the individual accessing or using the
                            Service, or the organization, or other legal entity
                            on behalf of which such individual is accessing or
                            using the Service, as applicable.
                        </li>
                    </ul>
                    <h2 className="mt-4 text-xl">
                        <strong>Collecting and Using Your Personal Data</strong>
                    </h2>
                    <h3 className="mt-2 text-xl">
                        <strong>Types of Data Collected</strong>
                    </h3>
                    <h4 className="mt-2 text-md">
                        <strong>Personal Data</strong>
                    </h4>
                    <p className="mt-2">
                        While using Our Service, We may ask You to provide Us
                        with certain personally identifiable information that
                        can be used to contact or identify You. Personally
                        identifiable information may include, but is not limited
                        to:
                    </p>
                    <ul className="list-inside list-disc mt-2">
                        <li>Email address</li>
                        <li>First name and last name</li>
                        <li>Usage Data</li>
                    </ul>
                    <h4 className="mt-2 text-md">
                        <strong>Usage Data</strong>
                    </h4>
                    <p className="mt-2">
                        Usage Data is collected automatically when using the
                        Service.
                    </p>
                    <p className="mt-2">
                        Usage Data may include information such as Your Device's
                        Internet Protocol address (e.g. IP address), browser
                        type, browser version, the pages of our Service that You
                        visit, the time and date of Your visit, the time spent
                        on those pages, unique device identifiers and other
                        diagnostic data.
                    </p>
                    <p className="mt-2">
                        When You access the Service by or through a mobile
                        device, We may collect certain information
                        automatically, including, but not limited to, the type
                        of mobile device You use, Your mobile device unique ID,
                        the IP address of Your mobile device, Your mobile
                        operating system, the type of mobile Internet browser
                        You use, unique device identifiers and other diagnostic
                        data.
                    </p>
                    <p className="mt-2">
                        We may also collect information that Your browser sends
                        whenever You visit our Service or when You access the
                        Service by or through a mobile device.
                    </p>
                    <h2 className="mt-4 text-xl">
                        <strong>Use of Your Personal Data</strong>
                    </h2>
                    <p className="mt-2">
                        The Company may use Personal Data for the following
                        purposes:
                    </p>
                    <ul className="list-inside list-disc mt-2">
                        <li>
                            <strong>To provide and maintain our Service</strong>
                            , including to monitor the usage of our Service.
                        </li>
                        <li>
                            <strong>To manage Your Account:</strong> to manage
                            Your registration as a user of the Service. The
                            Personal Data You provide can give You access to
                            different functionalities of the Service that are
                            available to You as a registered user.
                        </li>
                        <li>
                            <strong>For the performance of a contract:</strong>{" "}
                            the development, compliance and undertaking of the
                            purchase contract for the products, items or
                            services You have purchased or of any other contract
                            with Us through the Service.
                        </li>
                        <li>
                            <strong>To contact You:</strong> To contact You by
                            email, telephone calls, SMS, or other equivalent
                            forms of electronic communication, such as a mobile
                            application's push notifications regarding updates
                            or informative communications related to the
                            functionalities, products or contracted services,
                            including the security updates, when necessary or
                            reasonable for their implementation.
                        </li>
                        <li>
                            <strong>To provide You</strong> with information
                            about other features, services and events which we
                            offer that are similar to those that you have
                            already purchased or enquired about unless You have
                            opted not to receive such information.
                        </li>
                        <li>
                            <strong>To manage Your requests:</strong> To attend
                            and manage Your requests to Us.
                        </li>
                        <li>
                            <strong>For other purposes</strong>: We may use Your
                            information for other purposes, such as data
                            analysis, identifying usage trends, determining the
                            effectiveness of our outreach and to evaluate and
                            improve our Service, products, services, marketing
                            and your experience.
                        </li>
                    </ul>
                    <p className="mt-2">
                        We may share Your personal information in the following
                        situations:
                    </p>
                    <ul className="list-inside list-disc mt-2">
                        <li>
                            <strong>With Your consent</strong>: We may disclose
                            Your personal information for any other purpose with
                            Your consent.
                        </li>
                    </ul>
                    <h2 className="mt-4 text-xl">
                        <strong>Retention of Your Personal Data</strong>
                    </h2>
                    <p className="mt-2">
                        The Company will retain Your Personal Data only for as
                        long as is necessary for the purposes set out in this
                        Privacy Policy. We will retain and use Your Personal
                        Data to the extent necessary to comply with our legal
                        obligations (for example, if we are required to retain
                        Your data to comply with applicable laws), resolve
                        disputes, and enforce our legal agreements and policies.
                    </p>
                    <p className="mt-2">
                        The Company will also retain Usage Data for internal
                        analysis purposes. Usage Data is generally retained for
                        a shorter period of time, except when this data is used
                        to strengthen the security or to improve the
                        functionality of Our Service, or We are legally
                        obligated to retain this data for longer time periods.
                    </p>
                    <h2 className="mt-4 text-xl">
                        <strong>Disclosure of Your Personal Data</strong>
                    </h2>
                    <h3 className="mt-2 text-md">
                        <strong>Business Transactions</strong>
                    </h3>
                    <p className="mt-2">
                        If the Company is involved in a merger, acquisition or
                        asset sale, Your Personal Data may be transferred. We
                        will provide notice before Your Personal Data is
                        transferred and becomes subject to a different Privacy
                        Policy.
                    </p>
                    <h3 className="mt-2 text-md">
                        <strong>Law enforcement</strong>
                    </h3>
                    <p className="mt-2">
                        Under certain circumstances, the Company may be required
                        to disclose Your Personal Data if required to do so by
                        law or in response to valid requests by public
                        authorities (e.g. a court or a government agency).
                    </p>
                    <h3 className="mt-2 text-md">
                        <strong>Other legal requirements</strong>
                    </h3>
                    <p className="mt-2">
                        The Company may disclose Your Personal Data in the good
                        faith belief that such action is necessary to:
                    </p>
                    <ul className="list-inside list-disc mt-2">
                        <li>Comply with a legal obligation</li>
                        <li>
                            Protect and defend the rights or property of the
                            Company
                        </li>
                        <li>
                            Prevent or investigate possible wrongdoing in
                            connection with the Service
                        </li>
                        <li>
                            Protect the personal safety of Users of the Service
                            or the public
                        </li>
                        <li>Protect against legal liability</li>
                    </ul>
                    <h2 className="mt-4 text-xl">
                        <strong>Security of Your Personal Data</strong>
                    </h2>
                    <p className="mt-2">
                        The security of Your Personal Data is important to Us,
                        but remember that no method of transmission over the
                        Internet, or method of electronic storage is 100%
                        secure. While We strive to use commercially acceptable
                        means to protect Your Personal Data, We cannot guarantee
                        its absolute security.
                    </p>
                    <h2 className="mt-4 text-xl">
                        <strong>Delete Your Personal Data</strong>
                    </h2>
                    <p className="mt-2">
                        You have the right to delete or request that We assist
                        in deleting the Personal Data that We have collected
                        about You.
                    </p>
                    <p className="mt-2">
                        You may contact Us to request access to, correct, or
                        delete any personal information that You have provided
                        to Us.
                    </p>
                    <p className="mt-2">
                        Please note, however, that We may need to retain certain
                        information when we have a legal obligation or lawful
                        basis to do so.
                    </p>
                    <h2 className="mt-4 text-xl">
                        <strong>Changes to this Privacy Policy</strong>
                    </h2>
                    <p className="mt-2">
                        We may update Our Privacy Policy from time to time. We
                        will notify You of any changes by posting the new
                        Privacy Policy on this page.
                    </p>
                    <p className="mt-2">
                        We will let You know via email and/or a prominent notice
                        on Our Service, prior to the change becoming effective
                        and update the &quot;Last updated&quot; date at the top
                        of this Privacy Policy.
                    </p>
                    <p className="mt-2">
                        You are advised to review this Privacy Policy
                        periodically for any changes. Changes to this Privacy
                        Policy are effective when they are posted on this page.
                    </p>
                    <h2 className="mt-4 text-xl">
                        <strong>Contact Us</strong>
                    </h2>
                    <p className="mt-2">
                        If you have any questions about this Privacy Policy, You
                        can contact Us:
                    </p>
                    <ul className="list-inside list-disc mt-2">
                        <li className="mt-1 flex items-center">
                            By email:
                            <button
                                className="flex items-center ml-1 hover:underline"
                                onClick={() => copyToClipBoard(SUPPORT_EMAIL)}
                            >
                                aerscape-help@aerscape.com
                                <Square2StackIcon className="ml-1 w-4 h-4" />
                            </button>
                        </li>
                    </ul>
                </div>
            </Card>
        </div>
    );
};
