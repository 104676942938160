/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ReportResposeStatusEnum } from './ReportResposeStatusEnum';
import {
    ReportResposeStatusEnumFromJSON,
    ReportResposeStatusEnumFromJSONTyped,
    ReportResposeStatusEnumToJSON,
} from './ReportResposeStatusEnum';

/**
 * 
 * @export
 * @interface ReportRespose
 */
export interface ReportRespose {
    /**
     * 
     * @type {ReportResposeStatusEnum}
     * @memberof ReportRespose
     */
    status: ReportResposeStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportRespose
     */
    dataDownloadId: string;
}

/**
 * Check if a given object implements the ReportRespose interface.
 */
export function instanceOfReportRespose(value: object): boolean {
    if (!('status' in value)) return false;
    if (!('dataDownloadId' in value)) return false;
    return true;
}

export function ReportResposeFromJSON(json: any): ReportRespose {
    return ReportResposeFromJSONTyped(json, false);
}

export function ReportResposeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportRespose {
    if (json == null) {
        return json;
    }
    return {
        
        'status': ReportResposeStatusEnumFromJSON(json['status']),
        'dataDownloadId': json['data_download_id'],
    };
}

export function ReportResposeToJSON(value?: ReportRespose | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': ReportResposeStatusEnumToJSON(value['status']),
        'data_download_id': value['dataDownloadId'],
    };
}

