/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FilterItemDetailGeometry
 */
export interface FilterItemDetailGeometry {
    /**
     * 
     * @type {string}
     * @memberof FilterItemDetailGeometry
     */
    type?: FilterItemDetailGeometryTypeEnum;
    /**
     * 
     * @type {Array<Array<Array<Array<number>>>>}
     * @memberof FilterItemDetailGeometry
     */
    coordinates?: Array<Array<Array<Array<number>>>>;
}


/**
 * @export
 */
export const FilterItemDetailGeometryTypeEnum = {
    MultiPolygon: 'MultiPolygon'
} as const;
export type FilterItemDetailGeometryTypeEnum = typeof FilterItemDetailGeometryTypeEnum[keyof typeof FilterItemDetailGeometryTypeEnum];


/**
 * Check if a given object implements the FilterItemDetailGeometry interface.
 */
export function instanceOfFilterItemDetailGeometry(value: object): boolean {
    return true;
}

export function FilterItemDetailGeometryFromJSON(json: any): FilterItemDetailGeometry {
    return FilterItemDetailGeometryFromJSONTyped(json, false);
}

export function FilterItemDetailGeometryFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterItemDetailGeometry {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : json['type'],
        'coordinates': json['coordinates'] == null ? undefined : json['coordinates'],
    };
}

export function FilterItemDetailGeometryToJSON(value?: FilterItemDetailGeometry | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'coordinates': value['coordinates'],
    };
}

