import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { EmissionRecordView } from "../../apiClient/generated";
import { DateTime } from "luxon";
import { useAppSelector, useEmissionRecordsApiClient } from "../../hooks";
import { AlignedDataDisplay, CoordinatesField } from "../../ui/GenericFields";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes";
import { UnifiedIcon } from "../UnifiedIcon";
import { faChevronDown, faChevronUp } from "@fortawesome/sharp-light-svg-icons";
import lodash from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-light-svg-icons";

interface EmissionRecordDisplayProps {
    emissionId?: string;
    emissionRecord?: EmissionRecordView;
    showMatches?: boolean;
}

export const EmissionRecordDisplay = (props: EmissionRecordDisplayProps) => {
    const apiClient = useEmissionRecordsApiClient();
    const userFlags = useAppSelector((s) => s.auth.flags);
    const [showExtraData, setShowExtraData] = useState(false);

    // Emission information
    const emissionQuery = useQuery({
        queryKey: ["emissionRecord", props.emissionId],
        queryFn: async () => {
            return await apiClient.emissionRecordsRetrieve({
                id: props.emissionId,
            });
        },
        enabled: !!props.emissionId,
        // Cache for 15 seconds
        staleTime: 15000,
    });

    if (props.emissionId && (emissionQuery.isLoading || !emissionQuery.data)) {
        return (
            <div className="flex w-full justify-center">
                <ArrowPathIcon className="p-1 h-8 animate-spin text-slate-500"></ArrowPathIcon>
            </div>
        );
    }

    const data = emissionQuery.data || props.emissionRecord;
    if (!data) {
        return "Error loading emission data.";
    }

    const viewData = [
        {
            label: "Provider name:",
            value: (
                <>
                    {data.providerName}
                    <Link
                        className="ml-1 text-xs underline"
                        to={`${ROUTES.LICENSING}?section_id=${data.providerName}`}
                        target="_blank"
                    >
                        (License)
                    </Link>
                </>
            ),
        },
        data.dataPoint?.secondaryDataSource
            ? {
                  label: "Secondary data source:",
                  value: data.dataPoint?.secondaryDataSource || "-",
              }
            : undefined,
        data.dataPoint?.data?.quantification_institution
            ? {
                  label: "Quantifying institution:",
                  value: data.dataPoint?.data?.quantification_institution,
              }
            : undefined,
        {
            label: "Coordinates:",
            value: (
                <CoordinatesField
                    coordinates={data.dataPoint.location.coordinates}
                    allowCopy={true}
                />
            ),
        },
        {
            label: "Time (UTC):",
            value: DateTime.fromJSDate(data.dataPoint.detectionTimestamp)
                .setZone("utc")
                .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS),
        },
    ].filter((x) => x) as { label: string; value: any }[];

    const extraData = Object.keys(data.dataPoint.data || []).map(
        (key: string) => {
            return {
                label: `${lodash.capitalize(key).replaceAll("_", " ")}:`,
                value: data.dataPoint.data[key],
            };
        },
    );

    if (showExtraData) {
        viewData.push(...[{ label: "---", value: "---" }, ...extraData]);
    }

    if (props.showMatches) {
        viewData.unshift({
            label: "Infrastructure match(es):",
            value: userFlags.includes("enable_site_page") ? (
                <div>
                    {data.matches.map((m) => {
                        // Compute time ranges within 3 months of the emission
                        let beforeDate = "";
                        let afterDate = "";
                        if (data) {
                            const timestamp =
                                data.dataPoint.detectionTimestamp.getTime();
                            beforeDate = new Date(
                                timestamp + 45 * 24 * 60 * 60 * 1000,
                            ).toISOString();
                            afterDate = new Date(
                                timestamp - 45 * 24 * 60 * 60 * 1000,
                            ).toISOString();
                        }
                        const url = `${ROUTES.INFRASTRUCURE_DETAIL.replace(
                            ":id",
                            m.id,
                        )}?selectedContext=${data?.id}&detectionDateRangeAfter=${afterDate}&detectionDateRangeBefore=${beforeDate}`;

                        return (
                            <a
                                className="flex items-center gap-1"
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {m.name || m.siteName}
                                <FontAwesomeIcon
                                    icon={faArrowUpRightFromSquare}
                                    className="w-3"
                                />
                            </a>
                        );
                    })}
                </div>
            ) : (
                data.matches.map((m) => m.name || m.siteName).join(", ")
            ),
        });
    }

    if (
        data.dataPoint.detectedRateUncertainty &&
        data.dataPoint.detectedRateUncertainty > 0
    ) {
        viewData.unshift({
            label: "Detected rate uncertainty:",
            value: (
                <>
                    ±{" "}
                    {(data.dataPoint.detectedRateUncertainty / 1000).toFixed(2)}{" "}
                    kg/h
                </>
            ),
        });
    }

    if (data.dataPoint.detectedRate && data.dataPoint.detectedRate > 0) {
        viewData.unshift({
            label: "Detected rate:",
            value: <>{(data.dataPoint.detectedRate / 1000).toFixed(2)} kg/h</>,
        });
    }

    if (data.dataPoint.concentrationUncertainty) {
        viewData.unshift({
            label: "Concentration uncertainty:",
            value: (
                <>
                    ± {data.dataPoint.concentrationUncertainty.toFixed(2)} ppm*m
                </>
            ),
        });
    }

    if (data.dataPoint.concentration) {
        viewData.unshift({
            label: "Concentration:",
            value: <>{data.dataPoint.concentration.toFixed(2)} ppm*m</>,
        });
    }

    return (
        <>
            <AlignedDataDisplay data={viewData} />
            {extraData.length > 0 && (
                <button
                    className="mt-2 ml-1 text-xs underline"
                    onClick={() => setShowExtraData(!showExtraData)}
                    type="button"
                >
                    Show {showExtraData ? "less" : "more"}
                    <UnifiedIcon
                        icon={showExtraData ? faChevronUp : faChevronDown}
                        className="h-3 w-3 text-black pl-1"
                    />
                </button>
            )}
        </>
    );
};

interface MultipleEmissionRecordDisplayProps {
    emissionRecords?: EmissionRecordView[];
    showMatches?: boolean;
}

export const MultipleEmissionRecordDisplay = (
    props: MultipleEmissionRecordDisplayProps,
) => {
    const [currentEmission, setCurrentEmission] = useState(0);

    // Blank component if no data is passed.
    if (!props.emissionRecords || props.emissionRecords.length == 0) {
        return null;
    }

    return (
        <div>
            {props.emissionRecords.length > 1 && (
                <div className="p-1 mb-1 flex justify-between items-center text-sm">
                    Multiple emissions
                    <div className="flex gap-1 items-center">
                        <button
                            className="p-2"
                            onClick={(e) => {
                                e.preventDefault();
                                setCurrentEmission(
                                    (currentEmission -
                                        1 +
                                        props.emissionRecords.length) %
                                        props.emissionRecords.length,
                                );
                            }}
                        >
                            <ChevronLeftIcon className="w-5" />
                        </button>
                        {currentEmission + 1}/{props.emissionRecords.length}
                        <button
                            className="p-2"
                            onClick={(e) => {
                                e.preventDefault();
                                setCurrentEmission(
                                    (currentEmission + 1) %
                                        props.emissionRecords.length,
                                );
                            }}
                        >
                            <ChevronRightIcon className="w-5" />
                        </button>
                    </div>
                </div>
            )}
            <EmissionRecordDisplay
                emissionRecord={props.emissionRecords[currentEmission]}
                showMatches={true}
            />
        </div>
    );
};
