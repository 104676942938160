import { useState } from "react";
import { Infrastructure } from "../../../apiClient/generated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/pro-light-svg-icons";
import { InfrastructureTree } from "../../MapV2/ui/InfrastructureTree";
import { SidebarSection, SidebarSkeleton } from "../../MapV2/ui/SidebarItems";
import { InfrastructureSidebarDisplay } from "../../MapV2/sidebar/common";

interface InfrastructureTabProps {
    infrastructure?: Infrastructure;
    treeData?: Infrastructure[];
}

const InfrastructureTab = (props: InfrastructureTabProps) => {
    return (
        <div>
            <SidebarSection
                title="General Information"
                loading={!props.infrastructure}
            >
                <InfrastructureSidebarDisplay
                    infrastructure={props.infrastructure}
                />
            </SidebarSection>
            <SidebarSection
                title="Related Infrastructure"
                loading={!props.treeData}
            >
                <InfrastructureTree infrastructure={props.treeData || []} />
            </SidebarSection>
        </div>
    );
};

interface SidepanelProps {
    infrastructure?: Infrastructure;
    treeData?: Infrastructure[];
}

export const SidePanel = (props: SidepanelProps) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <>
            <button
                onClick={() => setExpanded(true)}
                className="flex items-center p-2 gap-2 absolute top-8 right-0 bg-ae-neutral-50 border rounded-l border-slate-200"
            >
                <FontAwesomeIcon icon={faBuilding} className="w-4" />
                <span className="mr-4">Attributes</span>
            </button>
            {expanded && (
                <div className="fixed right-0 top-0 h-screen w-64 overflow-auto bg-ae-neutral-50 z-50 border-l border-slate-200">
                    <SidebarSkeleton
                        title="Infrastructure"
                        tabs={[
                            {
                                title: "Details",
                                content: (
                                    <InfrastructureTab
                                        infrastructure={props.infrastructure}
                                        treeData={props.treeData}
                                    />
                                ),
                            },
                        ]}
                        onClose={() => setExpanded(false)}
                    />
                </div>
            )}
        </>
    );
};
