import { ReactNode } from "react";

export const FormSection = ({
    children,
    title,
    description,
}: {
    children: ReactNode;
    title?: string;
    description?: string;
}) => (
    <div className="w-full p-4 bg-white rounded text-sm mb-4">
        {title && (
            <p className="font-semibold text-base mb-1 text-black">{title}</p>
        )}
        {description && <p className="text-neutral-600">{description}</p>}
        {(title || description) && <div className="mb-6" />}
        {children}
    </div>
);

export const SubmitToCdxNotice = () => (
    <div className="w-full rounded bg-ae-blue-900 text-white px-4 py-2 mb-4">
        Please note that the form included here must be submitted to the EPA.
        You can complete the submission by visiting
        <span className="ml-1 font-bold underline">
            EPA's Central Data Exchange (CDX)
        </span>
        .
    </div>
);
