import { ReactElement } from "react";
import { useAppSelector } from "../hooks";
import { Permissions } from "../apiClient/generated";

interface Props {
    permission: keyof Permissions;
    children: ReactElement;
    requiredFlag?: string;
}

export const PermissionGate = ({
    permission,
    children,
    requiredFlag,
}: Props) => {
    const userPermissions = useAppSelector((state) => state.auth.permissions);
    const userflags = useAppSelector((state) => state.auth.flags);

    // TODO: Implement no permissions message page.
    if (requiredFlag && !userflags.includes(requiredFlag)) {
        return null;
    }

    if (!userPermissions[permission]) {
        return null;
    }

    return children;
};
