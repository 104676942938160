import { useFormContext, Controller } from "react-hook-form";
import { CustomCheckbox } from "../../../ui/CustomCheckbox";

interface FormFieldProps {
    fieldName: string;
    disabled?: boolean;
}

// Higher-order component to handle common Controller logic
const withFormControl = (WrappedComponent: React.ComponentType<any>) => {
    return ({ fieldName, ...props }: FormFieldProps & Record<string, any>) => {
        const { control } = useFormContext();
        return (
            <Controller
                name={fieldName}
                control={control}
                render={({ field }) => (
                    <WrappedComponent {...field} {...props} />
                )}
            />
        );
    };
};

// Base components
const YesNoBase = ({ value, onChange, disabled }) => (
    <div className="flex gap-4 text-sm">
        {["yes", "no"].map((option) => (
            <label
                key={option}
                className={`flex items-center gap-2 mb-2 ${
                    disabled && "text-gray-700 italic"
                }`}
            >
                <input
                    type="radio"
                    disabled={disabled}
                    value={option}
                    checked={value === (option === "yes")}
                    onChange={() => onChange(option === "yes")}
                    className="disabled:text-gray-600"
                />
                {option.charAt(0).toUpperCase() + option.slice(1)}
            </label>
        ))}
    </div>
);

const InputBase = ({ type, value, onChange, disabled, className }) => (
    <input
        type={type}
        className={`rounded border border-gray-300 h-8 text-sm ${className}`}
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
    />
);

const TextAreaBase = ({ value, onChange, disabled }) => (
    <textarea
        className="rounded border border-gray-300 min-h-24 text-sm min-w-72"
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
    />
);

const CheckboxBase = ({ value, onChange, disabled, label, nestedIndex }) => (
    <div className="flex gap-4 items-center py-1 mr-4 text-pretty">
        <CustomCheckbox
            checked={
                nestedIndex !== undefined && Array.isArray(value)
                    ? value[nestedIndex]
                        ? "yes"
                        : "no"
                    : value
                      ? "yes"
                      : "no"
            }
            disabled={disabled}
            onClick={() => {
                if (nestedIndex !== undefined) {
                    const newValue = Array.isArray(value) ? [...value] : [];
                    newValue[nestedIndex] = !newValue[nestedIndex];
                    onChange(newValue);
                } else {
                    onChange(!value);
                }
            }}
        />
        {label}
    </div>
);

const DateBase = ({ value, onChange, disabled }) => {
    // Handle the date formatting when receiving the value
    const formattedValue = value
        ? new Date(value).toISOString().split("T")[0]
        : "";

    return (
        <input
            type="date"
            className="rounded border border-gray-300 h-8 text-sm"
            disabled={disabled}
            value={formattedValue}
            onChange={(e) => {
                // Ensure we pass a proper date value back to the form
                const date = e.target.value ? new Date(e.target.value) : null;
                onChange(date);
            }}
        />
    );
};

// Exported components using HOC
export const YesNoQuestion = withFormControl(YesNoBase);
export const TextField = withFormControl((props) => (
    <InputBase {...props} type="text" className="min-w-72" />
));
export const DateField = withFormControl(DateBase);
export const TimeField = withFormControl((props) => (
    <InputBase {...props} type="time" />
));
export const TextArea = withFormControl(TextAreaBase);
export const CheckboxField = withFormControl(CheckboxBase);
