import { InitOverrideFunction } from "../../../apiClient/generated";

/**
 * Generates a function for the map data loader.
 *
 * It handles fetching data from all pages in the map apis,
 * that use cursor pagination.
 *
 */
// FIXME: Replace repeated code on other instances with this function.
export const generateFetchAllPagesFn = <Data, Filters>(
    fetchFn: (
        requestParameters: Filters,
        initOverrides: RequestInit | InitOverrideFunction,
    ) => Promise<{
        next?: string;
        previous?: string;
        results: Data[];
    }>,
    setter: (data: Data[]) => void,
    filters: Record<string, any>,
) => {
    return async (areaToFetch?: any, signal?: AbortSignal) => {
        // If a list filter is empty, short circuit and return.
        Object.values(filters).forEach((f) => {
            if (Array.isArray(f) && f.length === 0) {
                return [];
            }
        });

        // Remove any "all" filters -> same as selecting all items
        // FIXME: remove `any`
        const cleanedFilters: any = Object.entries(filters).reduce(
            (acc, [key, value]) => {
                if (value === "all") {
                    return acc;
                }
                return { ...acc, [key]: value };
            },
            {} as Filters,
        );

        let response = undefined;
        let nextPageCursor = undefined;
        do {
            response = await fetchFn(
                {
                    ...cleanedFilters,
                    locationWithin: areaToFetch
                        ? JSON.stringify(areaToFetch)
                        : undefined,
                    providerWithSource: cleanedFilters.providerWithSource
                        ? JSON.stringify(cleanedFilters.providerWithSource)
                        : undefined,
                    cursor: nextPageCursor,
                },
                {
                    signal,
                },
            );

            // Push data to setter function
            setter(response.results);

            // If there's another page, set the cursor and let the loop run.
            if (response.next) {
                const url = new URL(response.next);
                const parameters = new URLSearchParams(url.search);
                nextPageCursor = parameters.get("cursor");
            } else {
                nextPageCursor = undefined;
            }
        } while (nextPageCursor);
    };
};
