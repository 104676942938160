/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Serializer for emission statistics.
 * @export
 * @interface DataPointStats
 */
export interface DataPointStats {
    /**
     * 
     * @type {string}
     * @memberof DataPointStats
     */
    dataProvider: string;
    /**
     * 
     * @type {number}
     * @memberof DataPointStats
     */
    dataPointCnt: number;
    /**
     * 
     * @type {number}
     * @memberof DataPointStats
     */
    plumeImageCnt: number;
}

/**
 * Check if a given object implements the DataPointStats interface.
 */
export function instanceOfDataPointStats(value: object): boolean {
    if (!('dataProvider' in value)) return false;
    if (!('dataPointCnt' in value)) return false;
    if (!('plumeImageCnt' in value)) return false;
    return true;
}

export function DataPointStatsFromJSON(json: any): DataPointStats {
    return DataPointStatsFromJSONTyped(json, false);
}

export function DataPointStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataPointStats {
    if (json == null) {
        return json;
    }
    return {
        
        'dataProvider': json['data_provider'],
        'dataPointCnt': json['data_point_cnt'],
        'plumeImageCnt': json['plume_image_cnt'],
    };
}

export function DataPointStatsToJSON(value?: DataPointStats | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data_provider': value['dataProvider'],
        'data_point_cnt': value['dataPointCnt'],
        'plume_image_cnt': value['plumeImageCnt'],
    };
}

