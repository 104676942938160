import { Tab } from "@headlessui/react";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { useAccountsApiClient, useDataImportApiClient } from "../../hooks";
import { useCallback, useEffect, useState } from "react";
import { Card } from "../../ui/Card";
import { useDebounce } from "@uidotdev/usehooks";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { createColumnHelper } from "@tanstack/react-table";
import { DataImport } from "../../apiClient/generated";
import { DataTableV3 } from "../../components/DataTable/DataTableV3";

const columnHelper = createColumnHelper<DataImport>();
const columns = [
    columnHelper.accessor("createdAt", {
        id: "createdAt",
        cell: (info) => info.getValue().toLocaleString(),
        header: () => "Created At",
        enableSorting: true,
    }),
    columnHelper.accessor("importType", {
        id: "importType",
        header: () => "Import Type",
        cell: (info) => info.renderValue(),
        enableSorting: true,
    }),
    columnHelper.accessor("status", {
        id: "status",
        cell: (info) => info.getValue(),
        header: () => "Status",
        enableSorting: true,
    }),
    columnHelper.accessor("importParams", {
        id: "importParams",
        header: () => "Import Params",
        cell: (info) => JSON.stringify(info.renderValue()),
    }),
];

const ManageAPIKeyCard: React.FC = () => {
    const accountsApiClient = useAccountsApiClient();
    const [token, setToken] = useState<string | null>(null);

    useEffect(() => {
        accountsApiClient
            .accountsTokenRetrieve()
            .then((response) => {
                setToken(response.token);
            })
            .catch(() => {
                setToken(null);
            });
    }, [accountsApiClient]);

    const regenerateToken = useCallback(() => {
        accountsApiClient
            .accountsTokenCreate()
            .then((response) => {
                setToken(response.token);
            })
            .catch(() => {
                setToken(null);
            });
    }, [accountsApiClient]);

    return (
        <Card>
            <Tab.Group>
                <div className="flex justify-between py-4 px-10 items-center">
                    <h1 className="text-xl">API Keys</h1>

                    <Tab.List className="flex space-x-4 ">
                        <Tab
                            className={({ selected }) =>
                                `flex items-center py-1 px-2 rounded-lg ${
                                    selected
                                        ? "font-bold"
                                        : "hover:text-slate-100 hover:bg-slate-800"
                                }`
                            }
                        >
                            <LockClosedIcon className="w-5 h-5 mr-1" />
                            API Keys
                        </Tab>
                    </Tab.List>
                </div>
                <hr />

                <Tab.Panels>
                    <Tab.Panel>
                        <div className="px-10 py-6">
                            <h2 className="font-bold text-lg">API Keys</h2>
                            <p className="mt-2">
                                You can use this page to retrieve API keys to
                                use in data pipelines.
                            </p>
                            <p className="mt-2">
                                Regenerating the API key revokes previous keys.
                            </p>
                            <div className="flex mt-2">
                                <input
                                    type="text"
                                    readOnly={true}
                                    placeholder="No API key"
                                    className="rounded-lg w-96"
                                    value={token || ""}
                                />
                                <button
                                    className="ml-3 px-4 rounded-lg italic hover:bg-slate-700 hover:text-white"
                                    onClick={regenerateToken}
                                >
                                    Regenerate
                                </button>
                            </div>
                        </div>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </Card>
    );
};

const DataImportTableCard: React.FC = () => {
    const apiClient = useDataImportApiClient();
    const [filterSearch, setFilterSearch] = useState("");
    const debouncedSearch = useDebounce(filterSearch, 400);

    const fetchFunction = async (props: any) => {
        return await apiClient.dataImportList(props);
    };

    return (
        <Card>
            <div className="mx-4 mb-3 flex items-center text-sm justify-between">
                <div className="flex items-center">
                    <input
                        type="text"
                        className="rounded-lg py-1 px-2"
                        placeholder="Search by CSV filename"
                        value={filterSearch}
                        onChange={(e) => setFilterSearch(e.target.value)}
                    />
                    <MagnifyingGlassIcon className="w-5 h-5 -ml-7" />
                </div>
            </div>
            <hr />
            <DataTableV3
                dataName="dataImport"
                columns={columns}
                extraFilters={{
                    search: debouncedSearch,
                }}
                fetchFunction={fetchFunction}
                sortable={true}
                filterable={false}
            />
        </Card>
    );
};

export const AdminDataImportPage = () => {
    return (
        <div className="container mx-auto px-6">
            <h1 className="mt-6 text-2xl">Manage Data Imports</h1>
            <ManageAPIKeyCard />
            <DataImportTableCard />
        </div>
    );
};
