import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { NotificationGroup } from "../../apiClient/generated";
import { useAccountsApiClient, useNotificationsApi } from "../../hooks";
import { createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import { SecondaryButton, PrimaryButton } from "../../ui/Buttons";
import { Modal } from "../../ui/Modals";
import { ListBox } from "../../ui/Inputs";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, number, array, string } from "yup";
import { DataTableV3, RowActionButtons } from "../DataTable/DataTableV3";

const groupsColumnHelper = createColumnHelper<NotificationGroup>();
const createEditFormSchema = object({
    groupName: string().required(),
    people: array().of(number()),
    channels: array().of(number()),
}).test(
    "at-least-one-selected",
    "At least one person or channel must be selected.",
    function (value) {
        return (
            (value.people && value.people.length > 0) ||
            (value.channels && value.channels.length > 0)
        );
    },
);

interface CreateEditFormProps {
    company?: number;
    group?: NotificationGroup;
    peopleOptions: {
        id: number;
        label: string;
    }[];
    channelOptions: {
        id: number;
        label: string;
    }[];
    onFinish: () => void;
}

const CreateEditForm = (props: CreateEditFormProps) => {
    const queryClient = useQueryClient();
    const apiClient = useNotificationsApi();

    // Form and submit methods
    const form = useForm({
        defaultValues: {
            groupName: props.group?.name || "",
            people: props.group?.people.map((p) => p.id) || [],
            channels: props.group?.channels.map((c) => c.id) || [],
        },
        resolver: yupResolver(createEditFormSchema),
    });

    const saveForm = async (data) => {
        if (props.group) {
            await apiClient.notificationsGroupsUpdate({
                id: props.group.id,
                notificationGroupCreateRequest: {
                    owner: props.company,
                    name: data.groupName,
                    channels: data.channels,
                    people: data.people,
                },
            });
        } else {
            await apiClient.notificationsGroupsCreate({
                notificationGroupCreateRequest: {
                    owner: props.company,
                    name: data.groupName,
                    channels: data.channels,
                    people: data.people,
                },
            });
        }
        queryClient.refetchQueries({ queryKey: ["groupQuery"] });
        queryClient.refetchQueries({ queryKey: ["notificationGroup"] });
        queryClient.refetchQueries({
            queryKey: ["emissionNotificationSettings"],
        });
        form.reset();
        props.onFinish();
    };

    return (
        <form>
            <div className="my-4 text-sm">
                <div className="mb-2">
                    <p className="mb-1">Name:</p>
                    <input
                        type="text"
                        className="rounded p-1 w-full"
                        {...form.register("groupName")}
                    />
                </div>
                <div className="mb-2">
                    <p className="mb-1">People:</p>
                    <Controller
                        name="people"
                        control={form.control}
                        render={({ field }) => (
                            <ListBox
                                options={props.peopleOptions}
                                selected={props.peopleOptions.filter((p) =>
                                    field.value.includes(p.id),
                                )}
                                setSelected={(selected) =>
                                    field.onChange(selected.map((i) => i.id))
                                }
                            />
                        )}
                    />
                </div>
                <div className="mb-2">
                    <p className="mb-1">Channels:</p>
                    <Controller
                        name="channels"
                        control={form.control}
                        render={({ field }) => (
                            <ListBox
                                options={props.channelOptions}
                                selected={props.channelOptions.filter((p) =>
                                    field.value.includes(p.id),
                                )}
                                setSelected={(selected) =>
                                    field.onChange(selected.map((i) => i.id))
                                }
                            />
                        )}
                    />
                </div>
            </div>
            {form.formState.errors && (
                <div className="mb-2 text-bold text-red-600 text-sm">
                    {form.formState.errors[""]?.message}
                </div>
            )}
            {!form.formState.isSubmitting && (
                <div className="flex justify-end gap-2">
                    <SecondaryButton
                        onClick={(e) => {
                            props.onFinish();
                            e.preventDefault();
                        }}
                    >
                        Cancel
                    </SecondaryButton>
                    <PrimaryButton onClick={form.handleSubmit(saveForm)}>
                        Save
                    </PrimaryButton>
                </div>
            )}
        </form>
    );
};

interface CreateEditModalProps {
    groupId?: number;
    company?: number;
    visible: boolean;
    onClose: () => void;
}

const CreateEditModal = (props: CreateEditModalProps) => {
    const apiClient = useNotificationsApi();
    const accountsApiClient = useAccountsApiClient();

    const groupQuery = useQuery({
        queryKey: ["groupQuery", props.groupId, props.company],
        queryFn: async () => {
            return await apiClient.notificationsGroupsRetrieve({
                id: props.groupId,
            });
        },
        enabled: !!props.groupId,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
    });

    const peopleQuery = useQuery({
        queryKey: ["peopleQuery", props.company],
        queryFn: async () => {
            const _people = await accountsApiClient.accountsUsersList({
                companymembershipCompany: props.company,
            });
            return _people.map((i) => {
                return {
                    id: i.id,
                    label: `${i.firstName} ${i.lastName} (${i.email})`,
                };
            });
        },
        refetchOnWindowFocus: false,
        refetchOnMount: true,
    });

    const channelQuery = useQuery({
        queryKey: ["channelQuery", props.company],
        queryFn: async () => {
            const _channels = await apiClient.notificationsChannelsList({
                owner: props.company,
            });
            return _channels.results.map((i) => {
                return {
                    id: i.id,
                    label: i.email || i.webhookUrl,
                };
            });
        },
        refetchOnWindowFocus: false,
        refetchOnMount: true,
    });

    const loading =
        (props.groupId && groupQuery.isLoading) ||
        channelQuery.isLoading ||
        peopleQuery.isLoading;

    return (
        <Modal size="sm" visible={props.visible} onClose={props.onClose}>
            {props.groupId ? (
                <p className="mt-4 font-semibold text-lg">Edit group</p>
            ) : (
                <p className="mt-4 font-semibold text-lg">Add new group</p>
            )}
            {!loading && (
                <CreateEditForm
                    company={props.company}
                    group={groupQuery.data}
                    peopleOptions={peopleQuery.data}
                    channelOptions={channelQuery.data}
                    onFinish={props.onClose}
                />
            )}
        </Modal>
    );
};

interface NotificationGroupProps {
    company?: number;
}

export const NotificationGroupSettings = (props: NotificationGroupProps) => {
    const queryClient = useQueryClient();
    const apiClient = useNotificationsApi();
    const [showAddModal, setShowAddModal] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState<number>();

    const fetchFunction = async (props: any) => {
        return await apiClient.notificationsGroupsList(props);
    };

    const deleteRowFunction = async (id) => {
        const response = await apiClient.notificationsGroupsDestroy({ id });
        queryClient.refetchQueries({ queryKey: ["notificationGroup"] });
        queryClient.refetchQueries({
            queryKey: ["emissionNotificationSettings"],
        });
        return response;
    };

    const columns = [
        groupsColumnHelper.accessor("name", {
            id: "name",
            header: () => "Name",
            cell: (info) => (
                <span className="capitalize">
                    {info.getValue().toLowerCase()}
                </span>
            ),
            enableSorting: true,
        }),
        groupsColumnHelper.accessor("people", {
            id: "people",
            header: () => "People",
            cell: (info) => {
                const value = info.getValue();
                return (
                    <span className="line-clamp-1">
                        {value.map((i) => i.email).join(", ")}
                    </span>
                );
            },
            enableSorting: true,
        }),
        groupsColumnHelper.accessor("channels", {
            id: "channels",
            header: () => "Mailing lists & Webhooks",
            cell: (info) => {
                const value = info.getValue();
                return (
                    <span className="line-clamp-1">
                        {value.map((i) => i.email || i.webhookUrl).join(", ")}
                    </span>
                );
            },
            enableSorting: true,
        }),
        groupsColumnHelper.display({
            id: "actions",
            cell: (info) => (
                <RowActionButtons
                    actions={[
                        {
                            fn: () => {
                                setSelectedGroup(info.row.original.id);
                                setShowAddModal(true);
                            },
                            icon: <PencilIcon className="h-4 w-4" />,
                            tooltip: "Edit",
                        },
                        {
                            fn: () =>
                                info.table.options.meta?.deleteRow(
                                    info.row.index,
                                    info.row.original.id,
                                ),
                            icon: <TrashIcon className="h-4 w-4" />,
                            tooltip: "Delete",
                        },
                    ]}
                />
            ),
        }),
    ];

    return (
        <>
            <hr className="mt-2" />
            <DataTableV3<NotificationGroup>
                dataName="notificationGroup"
                columns={columns}
                extraFilters={{
                    owner: props.company,
                }}
                fetchFunction={fetchFunction}
                deleteRow={deleteRowFunction}
                createRow={() => setShowAddModal(true)}
                sortable={false}
                filterable={false}
            />

            {/* Add modal */}
            <CreateEditModal
                company={props.company}
                groupId={selectedGroup}
                visible={showAddModal}
                onClose={() => {
                    setSelectedGroup(undefined);
                    setShowAddModal(false);
                }}
            />
        </>
    );
};
