/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Serializer for notification groups.
 * @export
 * @interface PatchedNotificationGroupCreateRequest
 */
export interface PatchedNotificationGroupCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof PatchedNotificationGroupCreateRequest
     */
    owner?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedNotificationGroupCreateRequest
     */
    name?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedNotificationGroupCreateRequest
     */
    channels?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedNotificationGroupCreateRequest
     */
    people?: Array<number>;
}

/**
 * Check if a given object implements the PatchedNotificationGroupCreateRequest interface.
 */
export function instanceOfPatchedNotificationGroupCreateRequest(value: object): boolean {
    return true;
}

export function PatchedNotificationGroupCreateRequestFromJSON(json: any): PatchedNotificationGroupCreateRequest {
    return PatchedNotificationGroupCreateRequestFromJSONTyped(json, false);
}

export function PatchedNotificationGroupCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedNotificationGroupCreateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'owner': json['owner'] == null ? undefined : json['owner'],
        'name': json['name'] == null ? undefined : json['name'],
        'channels': json['channels'] == null ? undefined : json['channels'],
        'people': json['people'] == null ? undefined : json['people'],
    };
}

export function PatchedNotificationGroupCreateRequestToJSON(value?: PatchedNotificationGroupCreateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'owner': value['owner'],
        'name': value['name'],
        'channels': value['channels'],
        'people': value['people'],
    };
}

