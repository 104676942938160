/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `PENDING` - Pending
 * * `RUNNING` - Running
 * * `COMPLETE` - Complete
 * @export
 */
export const DeduplicationStatusEnum = {
    Pending: 'PENDING',
    Running: 'RUNNING',
    Complete: 'COMPLETE'
} as const;
export type DeduplicationStatusEnum = typeof DeduplicationStatusEnum[keyof typeof DeduplicationStatusEnum];


export function instanceOfDeduplicationStatusEnum(value: any): boolean {
    return Object.values(DeduplicationStatusEnum).includes(value);
}

export function DeduplicationStatusEnumFromJSON(json: any): DeduplicationStatusEnum {
    return DeduplicationStatusEnumFromJSONTyped(json, false);
}

export function DeduplicationStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeduplicationStatusEnum {
    return json as DeduplicationStatusEnum;
}

export function DeduplicationStatusEnumToJSON(value?: DeduplicationStatusEnum | null): any {
    return value as any;
}

