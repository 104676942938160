/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `EPA_NOTIFICATION` - Epa Notification
 * * `USER_CREATED` - User Created
 * * `MATCHED_INFRASTRUCTURE` - Matched Infrastructure
 * @export
 */
export const NotificationReasonEnum = {
    EpaNotification: 'EPA_NOTIFICATION',
    UserCreated: 'USER_CREATED',
    MatchedInfrastructure: 'MATCHED_INFRASTRUCTURE'
} as const;
export type NotificationReasonEnum = typeof NotificationReasonEnum[keyof typeof NotificationReasonEnum];


export function instanceOfNotificationReasonEnum(value: any): boolean {
    return Object.values(NotificationReasonEnum).includes(value);
}

export function NotificationReasonEnumFromJSON(json: any): NotificationReasonEnum {
    return NotificationReasonEnumFromJSONTyped(json, false);
}

export function NotificationReasonEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationReasonEnum {
    return json as NotificationReasonEnum;
}

export function NotificationReasonEnumToJSON(value?: NotificationReasonEnum | null): any {
    return value as any;
}

