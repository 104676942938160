/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `WAITING_ACTION` - Waiting Action
 * * `WORK_IN_PROGRESS` - Work In Progress
 * * `COMPLETED` - Completed
 * @export
 */
export const EventStatusEnum = {
    WaitingAction: 'WAITING_ACTION',
    WorkInProgress: 'WORK_IN_PROGRESS',
    Completed: 'COMPLETED'
} as const;
export type EventStatusEnum = typeof EventStatusEnum[keyof typeof EventStatusEnum];


export function instanceOfEventStatusEnum(value: any): boolean {
    return Object.values(EventStatusEnum).includes(value);
}

export function EventStatusEnumFromJSON(json: any): EventStatusEnum {
    return EventStatusEnumFromJSONTyped(json, false);
}

export function EventStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventStatusEnum {
    return json as EventStatusEnum;
}

export function EventStatusEnumToJSON(value?: EventStatusEnum | null): any {
    return value as any;
}

