/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `DEMONSTRABLE_ERROR` - Demonstrable Error
 * * `STATUS_AND_RESPONSE_PLAN` - Status And Response Plan
 * @export
 */
export const FileTypeEnum = {
    DemonstrableError: 'DEMONSTRABLE_ERROR',
    StatusAndResponsePlan: 'STATUS_AND_RESPONSE_PLAN'
} as const;
export type FileTypeEnum = typeof FileTypeEnum[keyof typeof FileTypeEnum];


export function instanceOfFileTypeEnum(value: any): boolean {
    return Object.values(FileTypeEnum).includes(value);
}

export function FileTypeEnumFromJSON(json: any): FileTypeEnum {
    return FileTypeEnumFromJSONTyped(json, false);
}

export function FileTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileTypeEnum {
    return json as FileTypeEnum;
}

export function FileTypeEnumToJSON(value?: FileTypeEnum | null): any {
    return value as any;
}

