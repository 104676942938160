/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `GATHERING` - Gathering
 * * `TRANSMISSION` - Transmission
 * * `DISTRIBUTION` - Distribution
 * * `OTHER` - Other
 * @export
 */
export const PipelineTypeEnum = {
    Gathering: 'GATHERING',
    Transmission: 'TRANSMISSION',
    Distribution: 'DISTRIBUTION',
    Other: 'OTHER'
} as const;
export type PipelineTypeEnum = typeof PipelineTypeEnum[keyof typeof PipelineTypeEnum];


export function instanceOfPipelineTypeEnum(value: any): boolean {
    return Object.values(PipelineTypeEnum).includes(value);
}

export function PipelineTypeEnumFromJSON(json: any): PipelineTypeEnum {
    return PipelineTypeEnumFromJSONTyped(json, false);
}

export function PipelineTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PipelineTypeEnum {
    return json as PipelineTypeEnum;
}

export function PipelineTypeEnumToJSON(value?: PipelineTypeEnum | null): any {
    return value as any;
}

