import { ROUTES } from "..";
import { useAppSelector } from "../../hooks";
import { NavLink, Navigate, useLocation, useOutlet } from "react-router-dom";
import aerscapeLogo from "./../../assets/img/aerscape-logo-small-inverted.png";
import { AdminOverviewPage } from "./AdminOverviewPage";

export const AdminDashboardPage = () => {
    const isLoggedIn = useAppSelector((state) => state.auth.loggedIn);
    const isStaff = useAppSelector((state) => state.auth.isStaff);
    const location = useLocation();
    let outlet = useOutlet();

    if (!isLoggedIn) {
        const params = {};
        if (location.pathname !== "/") {
            params["next"] = `${location.pathname}${location.search}`;
        }
        const urlParams = new URLSearchParams(params);
        return <Navigate to={`${ROUTES.LOGIN}?${urlParams.toString()}`} />;
    }

    if (!isStaff) {
        return <Navigate to={ROUTES.ROOT} />;
    }

    if (outlet == null) {
        outlet = <AdminOverviewPage />;
    }

    return (
        <div className="bg-slate-100 h-screen w-screen">
            <div className="flex justify-between items-center p-4 text-white w-full h-16 bg-slate-800 drop-shadow-xl">
                <span className="font-bold mr-6 flex items-center">
                    <img
                        className="h-8 animate-spin-once mr-4"
                        src={aerscapeLogo}
                    />
                    AerShed Administration
                </span>

                <div className="flex space-x-4">
                    <NavLink
                        to={ROUTES.ADMIN_INFRASTRUCURE_IMPORT}
                        className={({ isActive }) => {
                            return `p-2 rounded-md ${
                                isActive
                                    ? "bg-slate-600 cursor-default"
                                    : "hover:bg-slate-600"
                            }`;
                        }}
                    >
                        Infrastructure imports
                    </NavLink>
                    <NavLink
                        to={ROUTES.ADMIN_DATA_IMPORT}
                        className={({ isActive }) => {
                            return `p-2 rounded-md ${
                                isActive
                                    ? "bg-slate-600 cursor-default"
                                    : "hover:bg-slate-600"
                            }`;
                        }}
                    >
                        Data imports
                    </NavLink>
                    <NavLink
                        to={ROUTES.ADMIN_STATS}
                        className={({ isActive }) => {
                            return `p-2 rounded-md ${
                                isActive
                                    ? "bg-slate-600 cursor-default"
                                    : "hover:bg-slate-600"
                            }`;
                        }}
                    >
                        Stats
                    </NavLink>
                    <NavLink
                        to={ROUTES.ADMIN_DJANGO_ADMIN}
                        className={({ isActive }) => {
                            return `p-2 rounded-md ${
                                isActive
                                    ? "bg-slate-600 cursor-default"
                                    : "hover:bg-slate-600"
                            }`;
                        }}
                    >
                        Django Admin
                    </NavLink>
                    <NavLink
                        to={ROUTES.ROOT}
                        className={({ isActive }) => {
                            return `p-2 rounded-md ${
                                isActive
                                    ? "bg-slate-600 cursor-default"
                                    : "hover:bg-slate-600"
                            }`;
                        }}
                    >
                        Back to platform
                    </NavLink>
                </div>
            </div>

            {outlet}
        </div>
    );
};
