import { CheckIcon } from "@heroicons/react/20/solid";
import { RadioGroup } from "@headlessui/react";
import { useEffect, useState } from "react";
import {
    AdminInfrastructureImportItem,
    CrossOperatorConflictStatusEnum,
    Infrastructure,
    InfrastructureImportItem,
} from "../../../apiClient/generated";
import { useInfrastructureApiClient } from "../../../hooks";
import { DetailViewEditableCell } from "./EditableCells";
import {
    ArrowPathIcon,
    CheckCircleIcon,
    MinusCircleIcon,
} from "@heroicons/react/24/solid";
import { CoordinatesField } from "../../GenericFields";
import { GeoJsonLayer } from "@deck.gl/layers";
import * as turf from "@turf/turf";
import { useQuery } from "@tanstack/react-query";
import type { GeoJSON } from "geojson";
import { MapBase } from "../../../components/MapV2/Map";
import { useMap } from "../../../components/MapV2/hooks/mapState";

const getFormattedDistance = (pointA, pointB) => {
    const distance = turf.distance(pointA, pointB, { units: "meters" });
    if (!distance) {
        return "";
    }
    return `${distance.toFixed(1)} m`;
};

const conflictStatusItems = [
    {
        key: CrossOperatorConflictStatusEnum.AllowAndCreate,
        displayName: "Allow and create",
        text: "Allows creating this site near the conflicting infrastructure.",
    },
    {
        key: CrossOperatorConflictStatusEnum.BlockAndIgnore,
        displayName: "Block and ignore from import",
        text: "Blocks the creation of this site and its children, and exports a CSV file to follow up with the operator.",
    },
];

interface ConflictPreviewMiniMapProps {
    conflict: AdminInfrastructureImportItem | InfrastructureImportItem;
    nearbySites: Infrastructure[];
    onHoverSite?: (infrastructureId?: string) => void;
    onClick?: (infrastructureId?: string) => void;
}

export const ConflictPreviewMiniMap = (props: ConflictPreviewMiniMapProps) => {
    const { flyTo } = useMap("ConflictPreviewMinimap");
    const { conflict } = props;

    // FIXME: for some weird state update reason, flyTo doesn't work directly after instancing the component.
    useEffect(() => {
        setTimeout(() => {
            flyTo(
                props.conflict.location.coordinates[1],
                props.conflict.location.coordinates[0],
                14,
            );
        }, 100);
    }, [conflict, flyTo]);

    const layers = [
        new GeoJsonLayer({
            id: "infrastructure",
            data: {
                type: "FeatureCollection",
                features: props.nearbySites.map((item) => {
                    return {
                        type: "Feature",
                        geometry: item.shape || item.location,
                        properties: item,
                    };
                }),
            } as GeoJSON,
            pickable: true,
            pointRadiusUnits: "pixels",
            lineWidthUnits: "pixels",
            getPointRadius: 9,
            getFillColor: (item) => {
                if (item.geometry.type === "Polygon") {
                    return [0, 0, 0, 0];
                }
                return [0, 0, 0];
            },
            autoHighlight: true,
            getLineWidth: 3,
            getLineColor: (item) => {
                if (item.geometry.type === "Polygon") {
                    return [0, 0, 0];
                }
                return [255, 255, 255];
            },
        }),
        new GeoJsonLayer({
            id: "geometries",
            data: {
                type: "FeatureCollection",
                features: [
                    {
                        type: "Feature",
                        geometry:
                            props.conflict.shape || props.conflict.location,
                        properties: props.conflict,
                    },
                ],
            } as GeoJSON,
            pickable: true,
            pointRadiusUnits: "pixels",
            lineWidthUnits: "pixels",
            getPointRadius: 5,
            getFillColor: (item) => {
                if (item.geometry.type === "Polygon") {
                    return [0, 0, 0, 0];
                }
                return [255, 0, 0];
            },
            autoHighlight: true,
            getLineWidth: 2,
            getLineColor: (item) => {
                if (item.geometry.type === "Polygon") {
                    return [255, 0, 0];
                }
                return [255, 255, 255];
            },
        }),
    ];

    return (
        <div className="w-full h-full relative rounded overflow-hidden">
            <MapBase
                mapId="ConflictPreviewMinimap"
                layers={[layers]}
                showScaleControl
                showZoomControl
                getTooltip={({ object }) => {
                    if (!object || !object.properties) {
                        return;
                    }
                    return `${object.properties.siteName}`;
                }}
                onHover={(info) => {
                    if (info.object) {
                        props.onHoverSite(info.object.properties.id);
                    } else {
                        props.onHoverSite(undefined);
                    }
                }}
                onLeftClick={({ info }) => {
                    if (info.length > 0 && info[0].object && props.onClick) {
                        props.onClick(info[0].object.properties.id);
                    }
                }}
            />
            <div className="absolute top-1 right-1 z-0 text-sm bg-white p-2 rounded">
                <ul className="list-inside text-xs">
                    <li className="flex items-center">
                        <div className="rounded-full h-2 w-2 mr-1 bg-black" />
                        Current infrastructure
                    </li>
                    <li className="flex items-center">
                        <div className="rounded-full h-2 w-2 mr-1 bg-red-500" />
                        Conflicting item
                    </li>
                </ul>
            </div>
        </div>
    );
};

interface EditingViewProps {
    itemId: number;
    conflictStatus: CrossOperatorConflictStatusEnum;
    onChange: (newConflictStatus?: CrossOperatorConflictStatusEnum) => void;
}

const EditingView = (props: EditingViewProps) => {
    const apiClient = useInfrastructureApiClient();
    const [hoverSite, setHoverSite] = useState(undefined);

    const itemQuery = useQuery({
        queryKey: ["adminInfrasturctureImportItem", props.itemId],
        queryFn: async () => {
            return await apiClient.infrastructureAdminImportItemsRetrieve({
                id: props.itemId,
            });
        },
        staleTime: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    });

    const conflictsQuery = useQuery({
        queryKey: ["adminInfrastructureItemConflicts", props.itemId],
        queryFn: async () => {
            return await apiClient.infrastructureAdminImportItemsGetConflictsRetrieve(
                {
                    id: props.itemId,
                },
            );
        },
        staleTime: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    });

    const loading = itemQuery.isLoading || conflictsQuery.isLoading;

    return (
        <div>
            <p className="w-full flex justify-left text-md mb-3">
                This site has been identified to be in the close proximity of
                the following existing site(s) in another company's inventory.
            </p>
            {loading ? (
                <div className="flex items-center mb-4 animate-pulse">
                    <ArrowPathIcon className="mr-2 w-4 h-4 animate-spin" />
                    Loading conflicting sites...
                </div>
            ) : (
                <div className="flex w-full gap-4 justify-between">
                    <div className="w-2/6">
                        <p className="font-bold">Item</p>
                        <p>
                            <span className="font-bold">Site Name:</span>{" "}
                            {itemQuery.data.siteName}
                        </p>
                        <p>
                            <span className="font-bold">Owner:</span>{" "}
                            {itemQuery.data.ownerName}
                        </p>
                        <p className="flex space-x-1">
                            <span className="font-bold">Location:</span>
                            <CoordinatesField
                                coordinates={
                                    itemQuery.data.location.coordinates
                                }
                                allowCopy={true}
                            />
                        </p>
                    </div>
                    <div className="w-2/6 overflow-hidden">
                        {conflictsQuery.data && (
                            <>
                                <p className="font-bold">
                                    Conflicting infrastructure
                                    {conflictsQuery.data.isExactMatch
                                        ? " (exact match)"
                                        : " (near matches)"}
                                </p>
                                <div className="mt-1 flex flex-col gap-2 h-[300px] overflow-scroll">
                                    {conflictsQuery.data.infrastructure.map(
                                        (confictingInfrastructure) => {
                                            return (
                                                <div
                                                    className={`
                                                    bg-slate-100 rounded-lg p-2 border-2 
                                                    ${
                                                        hoverSite ===
                                                        confictingInfrastructure.id
                                                            ? "border-ae-slate-600 bg-slate-200"
                                                            : "border-slate-100"
                                                    }
                                                `}
                                                >
                                                    <p>
                                                        <span className="font-bold">
                                                            Site Name:
                                                        </span>{" "}
                                                        {
                                                            confictingInfrastructure.siteName
                                                        }
                                                    </p>
                                                    <p>
                                                        <span className="font-bold">
                                                            Owner:
                                                        </span>{" "}
                                                        {
                                                            confictingInfrastructure.ownerName
                                                        }
                                                    </p>
                                                    <p className="flex space-x-1">
                                                        <span className="font-bold">
                                                            Location:
                                                        </span>
                                                        <CoordinatesField
                                                            coordinates={
                                                                confictingInfrastructure
                                                                    .location
                                                                    .coordinates
                                                            }
                                                            allowCopy={true}
                                                        />
                                                    </p>
                                                    <p>
                                                        <span className="font-bold mr-1">
                                                            Distance:
                                                        </span>
                                                        {getFormattedDistance(
                                                            itemQuery.data
                                                                .location
                                                                .coordinates,
                                                            confictingInfrastructure
                                                                .location
                                                                .coordinates,
                                                        )}
                                                    </p>
                                                </div>
                                            );
                                        },
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    <div className="relative h-[330px] w-3/6 overflow-hidden">
                        <ConflictPreviewMiniMap
                            conflict={itemQuery.data}
                            nearbySites={conflictsQuery.data.infrastructure}
                            onHoverSite={(id) => setHoverSite(id)}
                        />
                    </div>
                </div>
            )}
            <p className="w-full flex justify-left text-md mt-4 mb-2">
                Select how would you like the platform to process data for this
                site.
            </p>
            <div>
                <RadioGroup
                    value={props.conflictStatus}
                    onChange={props.onChange}
                >
                    <div className="flex space-x-2">
                        {conflictStatusItems.map((option) => (
                            <RadioGroup.Option
                                key={option.key}
                                value={option.key}
                            >
                                {({ checked }) => (
                                    <div
                                        className={`
                                        m-1 py-2 px-2 border-2 border-slate-200 rounded-lg
                                        flex items-center
                                        ${
                                            !checked &&
                                            "hover:border-slate-400 hover:bg-slate-100 hover:cursor-pointer"
                                        }
                                        ${
                                            checked
                                                ? "bg-slate-700 text-white"
                                                : "bg-white"
                                        }
                                    `}
                                    >
                                        {checked ? (
                                            <CheckCircleIcon className="w-8 h-8 mr-3" />
                                        ) : (
                                            <MinusCircleIcon className="w-8 h-8 mr-3 text-gray-300" />
                                        )}
                                        <div className="text-sm">
                                            <RadioGroup.Label as="p">
                                                {option.displayName}
                                            </RadioGroup.Label>
                                            <RadioGroup.Description
                                                className={
                                                    checked
                                                        ? "text-sky-100"
                                                        : "text-gray-500"
                                                }
                                            >
                                                {option.text}
                                            </RadioGroup.Description>
                                        </div>
                                    </div>
                                )}
                            </RadioGroup.Option>
                        ))}
                    </div>
                </RadioGroup>
            </div>
        </div>
    );
};

interface CrossOperatorConflictsCellProps {
    itemId: number;
    conflictStatus: CrossOperatorConflictStatusEnum;
    onChange: (
        newConflictStatus: CrossOperatorConflictStatusEnum,
    ) => Promise<void>;
}

export const CrossOperatorConflictsCell = (
    props: CrossOperatorConflictsCellProps,
) => {
    const [conflictStatus, setConflictStatus] = useState(props.conflictStatus);

    useEffect(() => {
        setConflictStatus(props.conflictStatus);
    }, [props.conflictStatus]);

    return (
        <div>
            {props.conflictStatus ===
            CrossOperatorConflictStatusEnum.NoConflict ? (
                <div className="flex italic text-gray-300 items-center justify-center">
                    No conflicts
                    <CheckIcon className="ml-2 w-4 h-4 text-green-600" />
                </div>
            ) : (
                <DetailViewEditableCell
                    title="Infrastructure conflict with another operator"
                    onCancel={() => {
                        setConflictStatus(props.conflictStatus);
                    }}
                    onSave={() => props.onChange(conflictStatus)}
                    disableSave={
                        conflictStatus !==
                            CrossOperatorConflictStatusEnum.AllowAndCreate &&
                        conflictStatus !==
                            CrossOperatorConflictStatusEnum.BlockAndIgnore
                    }
                >
                    {(editing) =>
                        editing ? (
                            <EditingView
                                itemId={props.itemId}
                                conflictStatus={conflictStatus}
                                onChange={(v) => setConflictStatus(v)}
                            />
                        ) : (
                            <>{conflictStatus}</>
                        )
                    }
                </DetailViewEditableCell>
            )}
        </div>
    );
};
