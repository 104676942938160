import { PrimaryButton } from "../ui/Buttons";

export const ErrorPage = () => (
    <div className="bg-white dark:bg-gray-900">
        <div className="py-12 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div className="mx-auto max-w-screen-sm text-center">
                <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">
                    Ooops!
                </h1>
                <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">
                    Something went wrong.
                </p>

                <p className="mb-4 text-lg font-light text-gray-600">
                    We're sorry for the inconvenience. An unexpected error has
                    occurred, but don't worry - our team has been automatically
                    alerted and is already working to resolve the issue as
                    quickly as possible.
                </p>

                <PrimaryButton onClick={() => window.location.reload()}>
                    Refresh page
                </PrimaryButton>
            </div>
        </div>
    </div>
);
