export const configurableColumns = [
    { id: "name", name: "Name" },
    { id: "siteName", name: "Site Name" },
    { id: "infraStatus", name: "Status" },
    { id: "dateOfInstallation", name: "Installation Date" },
    { id: "pipelineDiameter", name: "Diameter (inches)" },
    { id: "pipelineCommodity", name: "Commodity" },
    { id: "pipelineHasPhmsa", name: "Pipeline PHMSA" },
    { id: "pipelineSegmentType", name: "Segment Type" },
    { id: "owner", name: "Owner" },
    { id: "infraType", name: "Infrastructure" },
    { id: "equipmentType", name: "Equipment Type" },
    { id: "location", name: "Location" },
    { id: "parent", name: "Parent" },
];
