/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DataPointMapLocation } from './DataPointMapLocation';
import {
    DataPointMapLocationFromJSON,
    DataPointMapLocationFromJSONTyped,
    DataPointMapLocationToJSON,
} from './DataPointMapLocation';
import type { DataSourceEnum } from './DataSourceEnum';
import {
    DataSourceEnumFromJSON,
    DataSourceEnumFromJSONTyped,
    DataSourceEnumToJSON,
} from './DataSourceEnum';
import type { PlumeImage } from './PlumeImage';
import {
    PlumeImageFromJSON,
    PlumeImageFromJSONTyped,
    PlumeImageToJSON,
} from './PlumeImage';

/**
 * Serializer to represent `DataPoint` information.
 * @export
 * @interface SimpleDataPoint
 */
export interface SimpleDataPoint {
    /**
     * 
     * @type {string}
     * @memberof SimpleDataPoint
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleDataPoint
     */
    dataBatch: string;
    /**
     * 
     * @type {DataSourceEnum}
     * @memberof SimpleDataPoint
     */
    readonly dataSource: DataSourceEnum;
    /**
     * 
     * @type {PlumeImage}
     * @memberof SimpleDataPoint
     */
    plumeImage: PlumeImage;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleDataPoint
     */
    emissionDetected?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SimpleDataPoint
     */
    detectedRate?: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleDataPoint
     */
    detectedRateUncertainty?: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleDataPoint
     */
    detectionLimit: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleDataPoint
     */
    concentration?: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleDataPoint
     */
    concentrationUncertainty?: number;
    /**
     * 
     * @type {DataPointMapLocation}
     * @memberof SimpleDataPoint
     */
    location: DataPointMapLocation;
    /**
     * 
     * @type {any}
     * @memberof SimpleDataPoint
     */
    data?: any;
    /**
     * 
     * @type {Date}
     * @memberof SimpleDataPoint
     */
    detectionTimestamp: Date;
    /**
     * 
     * @type {string}
     * @memberof SimpleDataPoint
     */
    readonly providerName: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleDataPoint
     */
    secondaryDataSource?: string;
    /**
     * 
     * @type {Date}
     * @memberof SimpleDataPoint
     */
    readonly createdAt: Date | null;
}

/**
 * Check if a given object implements the SimpleDataPoint interface.
 */
export function instanceOfSimpleDataPoint(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('dataBatch' in value)) return false;
    if (!('dataSource' in value)) return false;
    if (!('plumeImage' in value)) return false;
    if (!('detectionLimit' in value)) return false;
    if (!('location' in value)) return false;
    if (!('detectionTimestamp' in value)) return false;
    if (!('providerName' in value)) return false;
    if (!('createdAt' in value)) return false;
    return true;
}

export function SimpleDataPointFromJSON(json: any): SimpleDataPoint {
    return SimpleDataPointFromJSONTyped(json, false);
}

export function SimpleDataPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimpleDataPoint {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'dataBatch': json['data_batch'],
        'dataSource': DataSourceEnumFromJSON(json['data_source']),
        'plumeImage': PlumeImageFromJSON(json['plume_image']),
        'emissionDetected': json['emission_detected'] == null ? undefined : json['emission_detected'],
        'detectedRate': json['detected_rate'] == null ? undefined : json['detected_rate'],
        'detectedRateUncertainty': json['detected_rate_uncertainty'] == null ? undefined : json['detected_rate_uncertainty'],
        'detectionLimit': json['detection_limit'],
        'concentration': json['concentration'] == null ? undefined : json['concentration'],
        'concentrationUncertainty': json['concentration_uncertainty'] == null ? undefined : json['concentration_uncertainty'],
        'location': DataPointMapLocationFromJSON(json['location']),
        'data': json['data'] == null ? undefined : json['data'],
        'detectionTimestamp': (new Date(json['detection_timestamp'])),
        'providerName': json['provider_name'],
        'secondaryDataSource': json['secondary_data_source'] == null ? undefined : json['secondary_data_source'],
        'createdAt': (json['created_at'] == null ? null : new Date(json['created_at'])),
    };
}

export function SimpleDataPointToJSON(value?: Omit<SimpleDataPoint, 'id'|'data_source'|'provider_name'|'created_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data_batch': value['dataBatch'],
        'plume_image': PlumeImageToJSON(value['plumeImage']),
        'emission_detected': value['emissionDetected'],
        'detected_rate': value['detectedRate'],
        'detected_rate_uncertainty': value['detectedRateUncertainty'],
        'detection_limit': value['detectionLimit'],
        'concentration': value['concentration'],
        'concentration_uncertainty': value['concentrationUncertainty'],
        'location': DataPointMapLocationToJSON(value['location']),
        'data': value['data'],
        'detection_timestamp': ((value['detectionTimestamp']).toISOString()),
        'secondary_data_source': value['secondaryDataSource'],
    };
}

