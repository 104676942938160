/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `CREATED` - Created
 * * `IN_PROGRESS` - In Progress
 * * `WAITING_AV_CHECK` - Waiting Av Check
 * * `READY` - Ready
 * * `VIRUS_DETECTED` - Virus Detected
 * @export
 */
export const DataDownloadStatusEnum = {
    Created: 'CREATED',
    InProgress: 'IN_PROGRESS',
    WaitingAvCheck: 'WAITING_AV_CHECK',
    Ready: 'READY',
    VirusDetected: 'VIRUS_DETECTED'
} as const;
export type DataDownloadStatusEnum = typeof DataDownloadStatusEnum[keyof typeof DataDownloadStatusEnum];


export function instanceOfDataDownloadStatusEnum(value: any): boolean {
    return Object.values(DataDownloadStatusEnum).includes(value);
}

export function DataDownloadStatusEnumFromJSON(json: any): DataDownloadStatusEnum {
    return DataDownloadStatusEnumFromJSONTyped(json, false);
}

export function DataDownloadStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataDownloadStatusEnum {
    return json as DataDownloadStatusEnum;
}

export function DataDownloadStatusEnumToJSON(value?: DataDownloadStatusEnum | null): any {
    return value as any;
}

