/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `NO_MATCH` - No Match
 * * `NEEDS_REVIEW` - Needs Review
 * * `MATCH` - Match
 * * `KEEP_EXISTING` - Keep Existing
 * * `UPDATE_EXISTING` - Update Existing
 * * `NEW_SITE` - New Site
 * @export
 */
export const MatchStatusEnum = {
    NoMatch: 'NO_MATCH',
    NeedsReview: 'NEEDS_REVIEW',
    Match: 'MATCH',
    KeepExisting: 'KEEP_EXISTING',
    UpdateExisting: 'UPDATE_EXISTING',
    NewSite: 'NEW_SITE'
} as const;
export type MatchStatusEnum = typeof MatchStatusEnum[keyof typeof MatchStatusEnum];


export function instanceOfMatchStatusEnum(value: any): boolean {
    return Object.values(MatchStatusEnum).includes(value);
}

export function MatchStatusEnumFromJSON(json: any): MatchStatusEnum {
    return MatchStatusEnumFromJSONTyped(json, false);
}

export function MatchStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MatchStatusEnum {
    return json as MatchStatusEnum;
}

export function MatchStatusEnumToJSON(value?: MatchStatusEnum | null): any {
    return value as any;
}

