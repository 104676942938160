import { useState, useEffect } from "react";
import { Navigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useAuthApiClient, useAppSelector, useAppDispatch } from "../../hooks";
import { ROUTES } from "../../routes";
import { logInWithToken } from "../../store/auth";
import { LoginForm } from "./LoginForm";
import { SecondFactorForm } from "./SecondFactorForm";
import { ArrowPathIcon } from "@heroicons/react/24/solid";

export const TwoStepLogin = () => {
    const authApiClient = useAuthApiClient();

    // Username and password states
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [intermediateToken, setIntermediateToken] = useState("");
    const [deviceType, setDeviceType] = useState("");
    const [challengeResponse, setChallengeResponse] = useState("");

    // Form control states
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [loginState, setLoginState] = useState<"ssocheck" | "login" | "2fa">(
        "ssocheck",
    );

    // Selectors
    const globalLoading = useAppSelector((state) => state.auth.loading);
    const isLoggedIn = useAppSelector((state) => state.auth.loggedIn);

    // Handle next parameter
    const [searchParams] = useSearchParams();
    const nextParam = searchParams.get("next");

    // Actions
    const dispatch = useAppDispatch();

    const performLogin = async () => {
        try {
            setError("");
            setLoading(true);
            const response = await authApiClient.authLoginCreate({
                loginViewRequest: {
                    email: email,
                    password: password,
                },
            });
            setIntermediateToken(response.intermediateToken);
            setDeviceType(response.deviceType);
            setLoginState("2fa");
            setLoading(false);
        } catch (e) {
            setError("Incorrect username or password.");
            setLoading(false);
        }
    };

    const performSSOCheck = async () => {
        try {
            setError("");
            setLoading(true);
            const response = await authApiClient.authSsoRequiredCreate({
                sSORequiredCheckRequest: {
                    email: email,
                },
            });
            if (response.ssoRequired) {
                const url = response.authUrl;
                window.location.href = url;
            } else {
                setLoginState("login");
                setLoading(false);
            }
        } catch (e) {
            setError("Incorrect username.");
            setLoading(false);
        }
    };

    const performSecondFactorCheck = async () => {
        try {
            setLoading(true);
            const response = await authApiClient.auth2faCreate({
                secondFactorViewRequest: {
                    challengeResponse,
                    intermediateToken,
                },
            });
            dispatch(logInWithToken(response.token));
        } catch (e) {
            setError("Incorrect second factor token.");
            setLoading(false);
        }
    };

    // Check if there's a token stored in the localStorage,
    // if yes, trigger token validation before displaying login.
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token && !isLoggedIn) {
            dispatch(logInWithToken(token));
        }
    }, [isLoggedIn, dispatch]);

    // Check if SSO is enabled on the platform
    useEffect(() => {
        const checkSSO = async () => {
            const response = await authApiClient.authSsoRequiredRetrieve();
            if (response.enabled) {
                setLoginState("ssocheck");
            } else {
                setLoginState("login");
            }
        };
        checkSSO();
    }, [authApiClient]);

    // Redirect to dashboard if logged in
    if (isLoggedIn) {
        let nextPage = ROUTES.ROOT;
        if (nextParam) {
            nextPage = nextParam;
        }
        return <Navigate to={nextPage} />;
    }

    // Display a loading page instead of flashing login page.
    if (globalLoading) {
        return (
            <>
                <h2 className="mt-12 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Checking your credentials...
                </h2>
                <p className="flex mt-5 justify-center">
                    <ArrowPathIcon className="w-14 h-14 animate-spin" />
                </p>
            </>
        );
    }

    return (
        <>
            {(loginState === "login" || loginState == "ssocheck") && (
                <LoginForm
                    email={email}
                    onChangeEmail={setEmail}
                    password={password}
                    onChangePassword={setPassword}
                    submit={
                        loginState === "login" ? performLogin : performSSOCheck
                    }
                    loading={loading}
                    error={error}
                    ssoCheck={loginState === "ssocheck"}
                />
            )}
            {loginState === "2fa" && (
                <SecondFactorForm
                    challengeResponse={challengeResponse}
                    onChangeChallengeResponse={setChallengeResponse}
                    device={deviceType}
                    performLogin={performSecondFactorCheck}
                    loading={loading}
                    error={error}
                />
            )}
        </>
    );
};
