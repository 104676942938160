/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `SELF_REPORTED` - SELF_REPORTED
 * * `THIRD_PARTY` - THIRD_PARTY
 * * `EPA` - EPA
 * @export
 */
export const DataSourceEnum = {
    SelfReported: 'SELF_REPORTED',
    ThirdParty: 'THIRD_PARTY',
    Epa: 'EPA'
} as const;
export type DataSourceEnum = typeof DataSourceEnum[keyof typeof DataSourceEnum];


export function instanceOfDataSourceEnum(value: any): boolean {
    return Object.values(DataSourceEnum).includes(value);
}

export function DataSourceEnumFromJSON(json: any): DataSourceEnum {
    return DataSourceEnumFromJSONTyped(json, false);
}

export function DataSourceEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSourceEnum {
    return json as DataSourceEnum;
}

export function DataSourceEnumToJSON(value?: DataSourceEnum | null): any {
    return value as any;
}

