/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AdminInfrastructureImportItemLocation } from './AdminInfrastructureImportItemLocation';
import {
    AdminInfrastructureImportItemLocationFromJSON,
    AdminInfrastructureImportItemLocationFromJSONTyped,
    AdminInfrastructureImportItemLocationToJSON,
} from './AdminInfrastructureImportItemLocation';
import type { AdminInfrastructureImportItemPipelineCommodity } from './AdminInfrastructureImportItemPipelineCommodity';
import {
    AdminInfrastructureImportItemPipelineCommodityFromJSON,
    AdminInfrastructureImportItemPipelineCommodityFromJSONTyped,
    AdminInfrastructureImportItemPipelineCommodityToJSON,
} from './AdminInfrastructureImportItemPipelineCommodity';
import type { AdminInfrastructureImportItemPipelineSegmentType } from './AdminInfrastructureImportItemPipelineSegmentType';
import {
    AdminInfrastructureImportItemPipelineSegmentTypeFromJSON,
    AdminInfrastructureImportItemPipelineSegmentTypeFromJSONTyped,
    AdminInfrastructureImportItemPipelineSegmentTypeToJSON,
} from './AdminInfrastructureImportItemPipelineSegmentType';
import type { AdminInfrastructureImportItemPipelineShape } from './AdminInfrastructureImportItemPipelineShape';
import {
    AdminInfrastructureImportItemPipelineShapeFromJSON,
    AdminInfrastructureImportItemPipelineShapeFromJSONTyped,
    AdminInfrastructureImportItemPipelineShapeToJSON,
} from './AdminInfrastructureImportItemPipelineShape';
import type { AdminInfrastructureImportItemShape } from './AdminInfrastructureImportItemShape';
import {
    AdminInfrastructureImportItemShapeFromJSON,
    AdminInfrastructureImportItemShapeFromJSONTyped,
    AdminInfrastructureImportItemShapeToJSON,
} from './AdminInfrastructureImportItemShape';
import type { InfraStatusEnum } from './InfraStatusEnum';
import {
    InfraStatusEnumFromJSON,
    InfraStatusEnumFromJSONTyped,
    InfraStatusEnumToJSON,
} from './InfraStatusEnum';
import type { InfraTypeEnum } from './InfraTypeEnum';
import {
    InfraTypeEnumFromJSON,
    InfraTypeEnumFromJSONTyped,
    InfraTypeEnumToJSON,
} from './InfraTypeEnum';

/**
 * Infrastructure Import Item create serializer.
 * @export
 * @interface InfrastructureImportItemCreate
 */
export interface InfrastructureImportItemCreate {
    /**
     * 
     * @type {number}
     * @memberof InfrastructureImportItemCreate
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureImportItemCreate
     */
    placeholderId?: string;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureImportItemCreate
     */
    relatedImport: string;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureImportItemCreate
     */
    dataFilename?: string;
    /**
     * 
     * @type {any}
     * @memberof InfrastructureImportItemCreate
     */
    extraData?: any;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureImportItemCreate
     */
    siteName?: string;
    /**
     * 
     * @type {number}
     * @memberof InfrastructureImportItemCreate
     */
    owner?: number;
    /**
     * 
     * @type {number}
     * @memberof InfrastructureImportItemCreate
     */
    parent?: number;
    /**
     * 
     * @type {AdminInfrastructureImportItemLocation}
     * @memberof InfrastructureImportItemCreate
     */
    location?: AdminInfrastructureImportItemLocation;
    /**
     * 
     * @type {AdminInfrastructureImportItemShape}
     * @memberof InfrastructureImportItemCreate
     */
    shape?: AdminInfrastructureImportItemShape;
    /**
     * 
     * @type {AdminInfrastructureImportItemPipelineShape}
     * @memberof InfrastructureImportItemCreate
     */
    pipelineShape?: AdminInfrastructureImportItemPipelineShape;
    /**
     * 
     * @type {InfraTypeEnum}
     * @memberof InfrastructureImportItemCreate
     */
    infraType: InfraTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureImportItemCreate
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureImportItemCreate
     */
    equipmentType?: string;
    /**
     * 
     * @type {any}
     * @memberof InfrastructureImportItemCreate
     */
    operatorUniqueIds?: any;
    /**
     * 
     * @type {InfraStatusEnum}
     * @memberof InfrastructureImportItemCreate
     */
    infraStatus?: InfraStatusEnum;
    /**
     * Pipeline diameter in inches.
     * @type {number}
     * @memberof InfrastructureImportItemCreate
     */
    pipelineDiameter?: number;
    /**
     * 
     * @type {AdminInfrastructureImportItemPipelineSegmentType}
     * @memberof InfrastructureImportItemCreate
     */
    pipelineSegmentType?: AdminInfrastructureImportItemPipelineSegmentType;
    /**
     * 
     * @type {AdminInfrastructureImportItemPipelineCommodity}
     * @memberof InfrastructureImportItemCreate
     */
    pipelineCommodity?: AdminInfrastructureImportItemPipelineCommodity;
    /**
     * 
     * @type {boolean}
     * @memberof InfrastructureImportItemCreate
     */
    pipelineHasPhmsa?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof InfrastructureImportItemCreate
     */
    dateOfInstallation?: Date;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureImportItemCreate
     */
    pipelineMaterial?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InfrastructureImportItemCreate
     */
    pipelineAboveGround?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InfrastructureImportItemCreate
     */
    pipelineDotRegulated?: boolean;
}

/**
 * Check if a given object implements the InfrastructureImportItemCreate interface.
 */
export function instanceOfInfrastructureImportItemCreate(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('relatedImport' in value)) return false;
    if (!('infraType' in value)) return false;
    return true;
}

export function InfrastructureImportItemCreateFromJSON(json: any): InfrastructureImportItemCreate {
    return InfrastructureImportItemCreateFromJSONTyped(json, false);
}

export function InfrastructureImportItemCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfrastructureImportItemCreate {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'placeholderId': json['placeholder_id'] == null ? undefined : json['placeholder_id'],
        'relatedImport': json['related_import'],
        'dataFilename': json['data_filename'] == null ? undefined : json['data_filename'],
        'extraData': json['extra_data'] == null ? undefined : json['extra_data'],
        'siteName': json['site_name'] == null ? undefined : json['site_name'],
        'owner': json['owner'] == null ? undefined : json['owner'],
        'parent': json['parent'] == null ? undefined : json['parent'],
        'location': json['location'] == null ? undefined : AdminInfrastructureImportItemLocationFromJSON(json['location']),
        'shape': json['shape'] == null ? undefined : AdminInfrastructureImportItemShapeFromJSON(json['shape']),
        'pipelineShape': json['pipeline_shape'] == null ? undefined : AdminInfrastructureImportItemPipelineShapeFromJSON(json['pipeline_shape']),
        'infraType': InfraTypeEnumFromJSON(json['infra_type']),
        'name': json['name'] == null ? undefined : json['name'],
        'equipmentType': json['equipment_type'] == null ? undefined : json['equipment_type'],
        'operatorUniqueIds': json['operator_unique_ids'] == null ? undefined : json['operator_unique_ids'],
        'infraStatus': json['infra_status'] == null ? undefined : InfraStatusEnumFromJSON(json['infra_status']),
        'pipelineDiameter': json['pipeline_diameter'] == null ? undefined : json['pipeline_diameter'],
        'pipelineSegmentType': json['pipeline_segment_type'] == null ? undefined : AdminInfrastructureImportItemPipelineSegmentTypeFromJSON(json['pipeline_segment_type']),
        'pipelineCommodity': json['pipeline_commodity'] == null ? undefined : AdminInfrastructureImportItemPipelineCommodityFromJSON(json['pipeline_commodity']),
        'pipelineHasPhmsa': json['pipeline_has_phmsa'] == null ? undefined : json['pipeline_has_phmsa'],
        'dateOfInstallation': json['date_of_installation'] == null ? undefined : (new Date(json['date_of_installation'])),
        'pipelineMaterial': json['pipeline_material'] == null ? undefined : json['pipeline_material'],
        'pipelineAboveGround': json['pipeline_above_ground'] == null ? undefined : json['pipeline_above_ground'],
        'pipelineDotRegulated': json['pipeline_dot_regulated'] == null ? undefined : json['pipeline_dot_regulated'],
    };
}

export function InfrastructureImportItemCreateToJSON(value?: Omit<InfrastructureImportItemCreate, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'placeholder_id': value['placeholderId'],
        'related_import': value['relatedImport'],
        'data_filename': value['dataFilename'],
        'extra_data': value['extraData'],
        'site_name': value['siteName'],
        'owner': value['owner'],
        'parent': value['parent'],
        'location': AdminInfrastructureImportItemLocationToJSON(value['location']),
        'shape': AdminInfrastructureImportItemShapeToJSON(value['shape']),
        'pipeline_shape': AdminInfrastructureImportItemPipelineShapeToJSON(value['pipelineShape']),
        'infra_type': InfraTypeEnumToJSON(value['infraType']),
        'name': value['name'],
        'equipment_type': value['equipmentType'],
        'operator_unique_ids': value['operatorUniqueIds'],
        'infra_status': InfraStatusEnumToJSON(value['infraStatus']),
        'pipeline_diameter': value['pipelineDiameter'],
        'pipeline_segment_type': AdminInfrastructureImportItemPipelineSegmentTypeToJSON(value['pipelineSegmentType']),
        'pipeline_commodity': AdminInfrastructureImportItemPipelineCommodityToJSON(value['pipelineCommodity']),
        'pipeline_has_phmsa': value['pipelineHasPhmsa'],
        'date_of_installation': value['dateOfInstallation'] == null ? undefined : ((value['dateOfInstallation'] as any).toISOString().substring(0,10)),
        'pipeline_material': value['pipelineMaterial'],
        'pipeline_above_ground': value['pipelineAboveGround'],
        'pipeline_dot_regulated': value['pipelineDotRegulated'],
    };
}

