import * as togeojson from "@mapbox/togeojson";
import type { FeatureCollection } from "geojson";

export const emptyFeatureCollection: FeatureCollection = {
    type: "FeatureCollection",
    features: [],
};

interface readGeoFileAsJson {
    file?: File;
    callback: (geojson: any, error: boolean) => void;
}

export const readGeoFileAsGeoJson = (props: readGeoFileAsJson) => {
    if (props.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                // Try parsing as GeoJSON
                const jsonData = normalizeGEOJSON(
                    JSON.parse(e.target.result as string),
                );
                props.callback(jsonData, false);
            } catch (err) {
                // Try parsing as KML
                try {
                    const kml = new DOMParser().parseFromString(
                        e.target.result as string,
                        "application/xml",
                    );
                    const errorNode = kml.querySelector("parsererror");
                    if (errorNode) {
                        throw new Error(
                            "Error while trying to parse file as KML.",
                        );
                    } else {
                        const jsonData = togeojson.kml(kml);
                        props.callback(jsonData, false);
                    }
                } catch (err) {
                    props.callback(undefined, true);
                }
            }
        };

        reader.readAsText(props.file);
    }
};

export const normalizeGEOJSON = (json: any) => {
    if (json.type === "FeatureCollection") {
        return json;
    } else if (json.type === "Feature") {
        return {
            type: "FeatureCollection",
            features: [json],
        };
    }
    return {
        type: "FeatureCollection",
        features: [
            {
                type: "Feature",
                geometry: json,
            },
        ],
    };
};

type FeatureInput = {
    [key: string]: any;
    shape?: any;
    pipelineShape?: any;
    location?: any;
};

export const createFeatureCollection = (
    data: FeatureInput[],
): FeatureCollection => {
    const getShape = (feature: FeatureInput) => {
        if (feature.pipelineShape) {
            return feature.pipelineShape;
        }
        if (feature.shape) {
            return feature.shape;
        }
        if (feature.location) {
            return feature.location;
        }
        // FIXME: feature.location check **before** feature.geometry to avoid plume outlines from rendering.
        if (feature.geometry) {
            return feature.geometry;
        }
    };
    return {
        type: "FeatureCollection",
        features: data.map((feature: FeatureInput) => {
            return {
                type: "Feature",
                properties: feature,
                geometry: getShape(feature),
            };
        }),
    };
};

export const isValidCoordinates = (lat: number, lon: number) => {
    if (Number.isNaN(lon) || Number.isNaN(lat)) {
        return false;
    }
    return -180 <= lon && lon <= 180 && -90 <= lat && lat <= 90;
};
