/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmissionRecordStatsResponse
 */
export interface EmissionRecordStatsResponse {
    /**
     * 
     * @type {number}
     * @memberof EmissionRecordStatsResponse
     */
    _new: number;
    /**
     * 
     * @type {number}
     * @memberof EmissionRecordStatsResponse
     */
    lastNDays: number;
    /**
     * 
     * @type {number}
     * @memberof EmissionRecordStatsResponse
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof EmissionRecordStatsResponse
     */
    highestRate: number;
    /**
     * 
     * @type {number}
     * @memberof EmissionRecordStatsResponse
     */
    avgRate: number;
}

/**
 * Check if a given object implements the EmissionRecordStatsResponse interface.
 */
export function instanceOfEmissionRecordStatsResponse(value: object): boolean {
    if (!('_new' in value)) return false;
    if (!('lastNDays' in value)) return false;
    if (!('total' in value)) return false;
    if (!('highestRate' in value)) return false;
    if (!('avgRate' in value)) return false;
    return true;
}

export function EmissionRecordStatsResponseFromJSON(json: any): EmissionRecordStatsResponse {
    return EmissionRecordStatsResponseFromJSONTyped(json, false);
}

export function EmissionRecordStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmissionRecordStatsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        '_new': json['new'],
        'lastNDays': json['last_n_days'],
        'total': json['total'],
        'highestRate': json['highest_rate'],
        'avgRate': json['avg_rate'],
    };
}

export function EmissionRecordStatsResponseToJSON(value?: EmissionRecordStatsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'new': value['_new'],
        'last_n_days': value['lastNDays'],
        'total': value['total'],
        'highest_rate': value['highestRate'],
        'avg_rate': value['avgRate'],
    };
}

