import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuthApiClient } from "../../hooks";
import { EmailForm } from "./EmailForm";
import { NewPasswordForm } from "./NewPasswordForm";
import { PasswordResetComplete } from "./PasswordResetComplete";

export const ResetPassword = () => {
    const authApiClient = useAuthApiClient();

    // Handle case when password reset token is present
    const [searchParams] = useSearchParams();
    const passwordResetToken = searchParams.get("token");
    const email = searchParams.get("email");
    const onboarding = searchParams.get("onboarding");

    // Form control states
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [requestSent, setRequestSent] = useState(false);
    const [passwordResetComplete, setPasswordResetComplete] = useState(false);

    const requestChangePassword = async (email: string) => {
        setLoading(true);
        try {
            await authApiClient.authPasswordResetCreate({
                passwordResetRequest: { email: email },
            });
        } catch {
            // Do nothing if request fails.
        }
        setRequestSent(true);
        setLoading(false);
    };

    const changePassword = async (newPassword: string) => {
        setLoading(true);
        try {
            await authApiClient.authPasswordResetUpdate({
                passwordResetRequest: {
                    resetToken: passwordResetToken,
                    newPassword,
                },
            });
            setPasswordResetComplete(true);
        } catch (e) {
            // Try parsing error message
            try {
                const errorResponse = await e.response.json();
                setError(
                    `Failed to reset the user password: ${errorResponse.error}`,
                );
            } catch {
                setError(
                    "Failed to reset the user password. Double-check the password and request a new password reset if the error persists.",
                );
            }
        }
        setLoading(false);
    };

    if (passwordResetComplete) {
        return <PasswordResetComplete />;
    }

    return (
        <>
            {passwordResetToken && (
                <NewPasswordForm
                    loading={loading}
                    setPassword={changePassword}
                    error={error}
                />
            )}
            {!passwordResetToken && (
                <EmailForm
                    onboarding={!!onboarding}
                    email={email}
                    loading={loading}
                    sendRequest={requestChangePassword}
                    successMessage={requestSent}
                />
            )}
        </>
    );
};
