/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InfrastructureImportPresignedUploadUrl
 */
export interface InfrastructureImportPresignedUploadUrl {
    /**
     * 
     * @type {string}
     * @memberof InfrastructureImportPresignedUploadUrl
     */
    uploadUrl: string;
}

/**
 * Check if a given object implements the InfrastructureImportPresignedUploadUrl interface.
 */
export function instanceOfInfrastructureImportPresignedUploadUrl(value: object): boolean {
    if (!('uploadUrl' in value)) return false;
    return true;
}

export function InfrastructureImportPresignedUploadUrlFromJSON(json: any): InfrastructureImportPresignedUploadUrl {
    return InfrastructureImportPresignedUploadUrlFromJSONTyped(json, false);
}

export function InfrastructureImportPresignedUploadUrlFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfrastructureImportPresignedUploadUrl {
    if (json == null) {
        return json;
    }
    return {
        
        'uploadUrl': json['upload_url'],
    };
}

export function InfrastructureImportPresignedUploadUrlToJSON(value?: InfrastructureImportPresignedUploadUrl | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'upload_url': value['uploadUrl'],
    };
}

