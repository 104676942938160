import React, { memo } from "react";
import {
    InfraTypeEnum,
    InfrastructureImportItem,
} from "../../../apiClient/generated";
import { Handle, Position } from "reactflow";

interface CustomNodeProps {
    id: string;
    data: InfrastructureImportItem;
}

export const CustomNode = memo((props: CustomNodeProps) => (
    <div className="bg-white border-slate-200 border-2 w-[200px] px-4 py-4 text-xs rounded-xl shadow-lg">
        {props.data.placeholderId}
        <hr className="my-2" />

        {props.data.siteName !== "" && (
            <p>
                <span className="font-bold mr-1">Name:</span>
                {props.data.siteName}
            </p>
        )}

        <p>
            <span className="font-bold mr-1">Type:</span>
            <span className="capitalize">
                {props.data.infraType.split("_").join(" ").toLowerCase()}
            </span>
        </p>

        {props.data.equipmentType !== "" && (
            <p>
                <span className="font-bold mr-1">Equipment Type:</span>
                {props.data.equipmentType}
            </p>
        )}

        {props.data.infraType !== InfraTypeEnum.Pipeline && (
            <Handle
                className="w-16 h-4 -top-2 bg-slate-400 rounded-none"
                type="target"
                position={Position.Top}
            />
        )}

        {props.data.infraType !== "EQUIPMENT_COMPONENT" && (
            <Handle
                className="w-16 h-4 -bottom-2 bg-slate-400 rounded-none"
                type="source"
                position={Position.Bottom}
            />
        )}
    </div>
));
