/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginViewRequest
 */
export interface LoginViewRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginViewRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoginViewRequest
     */
    password: string;
}

/**
 * Check if a given object implements the LoginViewRequest interface.
 */
export function instanceOfLoginViewRequest(value: object): boolean {
    if (!('email' in value)) return false;
    if (!('password' in value)) return false;
    return true;
}

export function LoginViewRequestFromJSON(json: any): LoginViewRequest {
    return LoginViewRequestFromJSONTyped(json, false);
}

export function LoginViewRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginViewRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
    };
}

export function LoginViewRequestToJSON(value?: LoginViewRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'password': value['password'],
    };
}

