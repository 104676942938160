/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MergeSimilarRowRequest
 */
export interface MergeSimilarRowRequest {
    /**
     * 
     * @type {boolean}
     * @memberof MergeSimilarRowRequest
     */
    merge: boolean;
    /**
     * 
     * @type {number}
     * @memberof MergeSimilarRowRequest
     */
    mainItem: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof MergeSimilarRowRequest
     */
    itemsToMerge: Array<number>;
}

/**
 * Check if a given object implements the MergeSimilarRowRequest interface.
 */
export function instanceOfMergeSimilarRowRequest(value: object): boolean {
    if (!('merge' in value)) return false;
    if (!('mainItem' in value)) return false;
    if (!('itemsToMerge' in value)) return false;
    return true;
}

export function MergeSimilarRowRequestFromJSON(json: any): MergeSimilarRowRequest {
    return MergeSimilarRowRequestFromJSONTyped(json, false);
}

export function MergeSimilarRowRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MergeSimilarRowRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'merge': json['merge'],
        'mainItem': json['main_item'],
        'itemsToMerge': json['items_to_merge'],
    };
}

export function MergeSimilarRowRequestToJSON(value?: MergeSimilarRowRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'merge': value['merge'],
        'main_item': value['mainItem'],
        'items_to_merge': value['itemsToMerge'],
    };
}

