import { useEffect } from "react";
import { InfrastructureImport } from "../../apiClient/generated";
import { useInfrastructureApiClient } from "../../hooks";
import { Card } from "../../ui/Card";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

interface WaitingAntivirusCheckProps {
    importData: InfrastructureImport;
    refresh?: () => void;
}

export const WaitingAntivirusCheck = (props: WaitingAntivirusCheckProps) => {
    const { importData, refresh } = props;
    const apiClient = useInfrastructureApiClient();

    useEffect(() => {
        if (importData && importData.importStatus === "AV_CHECK") {
            const interval = setInterval(async () => {
                const response =
                    await apiClient.infrastructureAdminImportRetrieve({
                        id: importData.id,
                    });
                if (response.importStatus !== "AV_CHECK") {
                    refresh();
                }
            }, 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [importData, refresh, apiClient]);

    return (
        <Card>
            <p className="w-full flex justify-center">
                <MagnifyingGlassIcon className="w-20 h-20" />
                <MagnifyingGlassIcon className="absolute w-20 h-20 animate-ping opacity-10" />
            </p>

            <p className="w-full flex justify-center">
                Waiting antivirus check...
            </p>

            <p className="w-full flex justify-center text-sm">
                Check back in a few minutes, or
                <button className="ml-1 text-blue-800" onClick={props.refresh}>
                    refresh status now
                </button>
                .
            </p>
        </Card>
    );
};
