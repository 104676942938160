import { useCallback, useMemo } from "react";
import { useMapFilters } from "../../hooks/mapDataAndFilters";
import { DateRangeField } from "../../../../ui/Inputs";

export const DateRangeControl = () => {
    const { filterState, updateMapFilterState } = useMapFilters("mainMap");

    /**
     * Memoize dates from state to avoid unnecessary rerenders.
     */
    const startDateFilter = useMemo(
        () => filterState.emissions.startDateFilter,
        [filterState.emissions.startDateFilter],
    );
    const endDateFilter = useMemo(
        () => filterState.emissions.endDateFilter,
        [filterState.emissions.endDateFilter],
    );

    /**
     * Handlers for changing start and end dates
     */
    const handleChangeStartDate = useCallback(
        (v) => {
            updateMapFilterState((d) => {
                d.emissions.startDateFilter = v;
            });
        },
        [updateMapFilterState],
    );
    const handleChangeEndDate = useCallback(
        (v) => {
            updateMapFilterState((d) => {
                d.emissions.endDateFilter = v;
            });
        },
        [updateMapFilterState],
    );

    return (
        <DateRangeField
            valueAfter={startDateFilter}
            valueBefore={endDateFilter}
            onChangeAfter={handleChangeStartDate}
            onChangeBefore={handleChangeEndDate}
            showPresets={true}
            showRangeNavigation={true}
            className="flex"
        />
    );
};
