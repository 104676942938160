/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataPackageArchiveUrl
 */
export interface DataPackageArchiveUrl {
    /**
     * 
     * @type {string}
     * @memberof DataPackageArchiveUrl
     */
    archiveUrl: string;
}

/**
 * Check if a given object implements the DataPackageArchiveUrl interface.
 */
export function instanceOfDataPackageArchiveUrl(value: object): boolean {
    if (!('archiveUrl' in value)) return false;
    return true;
}

export function DataPackageArchiveUrlFromJSON(json: any): DataPackageArchiveUrl {
    return DataPackageArchiveUrlFromJSONTyped(json, false);
}

export function DataPackageArchiveUrlFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataPackageArchiveUrl {
    if (json == null) {
        return json;
    }
    return {
        
        'archiveUrl': json['archive_url'],
    };
}

export function DataPackageArchiveUrlToJSON(value?: DataPackageArchiveUrl | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'archive_url': value['archiveUrl'],
    };
}

