import { EditableGeoJsonLayer } from "@deck.gl-community/editable-layers";
import { useCallback, useMemo, useState } from "react";
import { DrawPolygonMode, ViewMode } from "@deck.gl-community/editable-layers";
import { emptyFeatureCollection } from "../../utils/geopatialUtils";
import { PathStyleExtension } from "@deck.gl/extensions";

const selectedFeatureIndexes: number[] = [];

/**
 * Custom hook to handle drawing and area filtering on map.
 */
export const useDrawingAndFilteringOnMap = () => {
    const [isFiltering, setIsFiltering] = useState(false);
    const [isDrawing, setIsDrawing] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [drawnShape, setDrawnShape] = useState(emptyFeatureCollection);

    // Drawing on map
    const drawingLayer = useMemo(
        () =>
            new (EditableGeoJsonLayer as any)({
                id: "drawing-layer",
                data: drawnShape,
                mode: isDrawing ? DrawPolygonMode : ViewMode,
                selectedFeatureIndexes,
                pickable: isDrawing,
                getFillColor: [0, 0, 0, 40],
                getLineWidth: 1,
                getLineColor: [0, 0, 0, 150],
                modeConfig: {
                    enableSnapping: true,
                },
                getDashArray: [20, 20],
                dashJustified: true,
                dashGapPickable: true,
                extensions: [new PathStyleExtension({ dash: true })],
                onEdit: ({ updatedData, editType }) => {
                    if (editType === "addFeature") {
                        setIsDrawing(false);
                        setDrawnShape(updatedData);
                    }
                },
            }),
        [drawnShape, isDrawing],
    );

    const hasShape = useMemo(() => {
        return (
            drawnShape && drawnShape.features && drawnShape.features.length > 0
        );
    }, [drawnShape]);

    const filterByArea = useMemo(() => {
        if (hasShape && isFiltering) {
            return drawnShape.features.find(
                (i) =>
                    i.geometry.type == "Polygon" ||
                    i.geometry.type == "MultiPolygon",
            ).geometry;
        }
    }, [drawnShape, isFiltering, hasShape]);

    return {
        drawnShape,
        drawingLayer,
        isDrawing,
        isFiltering,
        setIsFiltering,
        startDrawing: useCallback(() => setIsDrawing(true), []),
        stopDrawing: useCallback((newShape) => {
            setIsDrawing(false);
            setDrawnShape(newShape || emptyFeatureCollection);
            setIsFiltering(false);
        }, []),
        hasShape,
        filterByArea,
        selectedFilters,
        setSelectedFilters,
    };
};
