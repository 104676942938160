/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EventStatusEnum } from './EventStatusEnum';
import {
    EventStatusEnumFromJSON,
    EventStatusEnumFromJSONTyped,
    EventStatusEnumToJSON,
} from './EventStatusEnum';

/**
 * Emission Event serializer used for update operations.
 * @export
 * @interface EmissionEventUpdate
 */
export interface EmissionEventUpdate {
    /**
     * 
     * @type {string}
     * @memberof EmissionEventUpdate
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof EmissionEventUpdate
     */
    responsible?: number;
    /**
     * 
     * @type {EventStatusEnum}
     * @memberof EmissionEventUpdate
     */
    eventStatus: EventStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof EmissionEventUpdate
     */
    rootCause?: number;
    /**
     * 
     * @type {Date}
     * @memberof EmissionEventUpdate
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EmissionEventUpdate
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof EmissionEventUpdate
     */
    notes?: string;
}

/**
 * Check if a given object implements the EmissionEventUpdate interface.
 */
export function instanceOfEmissionEventUpdate(value: object): boolean {
    if (!('eventStatus' in value)) return false;
    return true;
}

export function EmissionEventUpdateFromJSON(json: any): EmissionEventUpdate {
    return EmissionEventUpdateFromJSONTyped(json, false);
}

export function EmissionEventUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmissionEventUpdate {
    if (json == null) {
        return json;
    }
    return {
        
        'description': json['description'] == null ? undefined : json['description'],
        'responsible': json['responsible'] == null ? undefined : json['responsible'],
        'eventStatus': EventStatusEnumFromJSON(json['event_status']),
        'rootCause': json['root_cause'] == null ? undefined : json['root_cause'],
        'startDate': json['start_date'] == null ? undefined : (new Date(json['start_date'])),
        'endDate': json['end_date'] == null ? undefined : (new Date(json['end_date'])),
        'notes': json['notes'] == null ? undefined : json['notes'],
    };
}

export function EmissionEventUpdateToJSON(value?: EmissionEventUpdate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'description': value['description'],
        'responsible': value['responsible'],
        'event_status': EventStatusEnumToJSON(value['eventStatus']),
        'root_cause': value['rootCause'],
        'start_date': value['startDate'] == null ? undefined : ((value['startDate'] as any).toISOString()),
        'end_date': value['endDate'] == null ? undefined : ((value['endDate'] as any).toISOString()),
        'notes': value['notes'],
    };
}

