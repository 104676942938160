/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `CREATED` - Created
 * * `OPERATOR_NOTIFIED` - Operator Notified
 * * `INVESTIGATION_STARTED` - Investigation Started
 * * `COMPLETE` - Complete
 * * `SUBMITTED_TO_EPA` - Submitted To Epa
 * @export
 */
export const EpaOperatorNotificationStatusEnum = {
    Created: 'CREATED',
    OperatorNotified: 'OPERATOR_NOTIFIED',
    InvestigationStarted: 'INVESTIGATION_STARTED',
    Complete: 'COMPLETE',
    SubmittedToEpa: 'SUBMITTED_TO_EPA'
} as const;
export type EpaOperatorNotificationStatusEnum = typeof EpaOperatorNotificationStatusEnum[keyof typeof EpaOperatorNotificationStatusEnum];


export function instanceOfEpaOperatorNotificationStatusEnum(value: any): boolean {
    return Object.values(EpaOperatorNotificationStatusEnum).includes(value);
}

export function EpaOperatorNotificationStatusEnumFromJSON(json: any): EpaOperatorNotificationStatusEnum {
    return EpaOperatorNotificationStatusEnumFromJSONTyped(json, false);
}

export function EpaOperatorNotificationStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): EpaOperatorNotificationStatusEnum {
    return json as EpaOperatorNotificationStatusEnum;
}

export function EpaOperatorNotificationStatusEnumToJSON(value?: EpaOperatorNotificationStatusEnum | null): any {
    return value as any;
}

