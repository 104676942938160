import {
    compressToEncodedURIComponent,
    decompressFromEncodedURIComponent,
} from "lz-string";
import { MapFiltersState, MapSelectedContext, MapState } from "../state";

interface AllMapData extends Partial<MapFiltersState> {
    lat?: number;
    lon?: number;
    z?: number;
    basemap: string | number;
    mapData: MapSelectedContext;
}

/**
 * Serialize useful bits of the map state to string.
 *
 * We are using shorter names and a simpler structure to
 * limit the output size of the string.
 */
export const serializeMapStates = (
    mapState: MapState,
    filters: MapFiltersState,
    selectedOnMap: MapSelectedContext,
) => {
    const combinedStates: AllMapData = {
        lat: mapState.debounced.viewState?.latitude,
        lon: mapState.debounced.viewState?.longitude,
        z: mapState.debounced.viewState?.zoom,
        basemap: mapState.basemap,
        infrastructure: filters.infrastructure,
        emissions: filters.emissions,
        mapData: selectedOnMap,
    };
    return compressToEncodedURIComponent(JSON.stringify(combinedStates));
};

/**
 * Deserializer for the map state.
 */
export const deserializeMapStates = (mapContextString: string): AllMapData => {
    return JSON.parse(decompressFromEncodedURIComponent(mapContextString));
};
