/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Root causes serializer.
 * @export
 * @interface RootCause
 */
export interface RootCause {
    /**
     * 
     * @type {number}
     * @memberof RootCause
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof RootCause
     */
    name: string;
}

/**
 * Check if a given object implements the RootCause interface.
 */
export function instanceOfRootCause(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    return true;
}

export function RootCauseFromJSON(json: any): RootCause {
    return RootCauseFromJSONTyped(json, false);
}

export function RootCauseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RootCause {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
    };
}

export function RootCauseToJSON(value?: Omit<RootCause, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
    };
}

