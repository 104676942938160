import { Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks";
import { logOut } from "../store/auth";
import { ROUTES } from ".";
import { useEffect } from "react";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { useQueryClient } from "@tanstack/react-query";

export const LogoutPage = () => {
    const dispatch = useAppDispatch();
    const loggedIn = useAppSelector((state) => state.auth.loggedIn);
    const queryClient = useQueryClient();

    useEffect(() => {
        queryClient.clear();
        dispatch(logOut());
    }, [queryClient, dispatch]);

    if (!loggedIn) {
        return <Navigate to={ROUTES.LOGIN} />;
    }

    return (
        <>
            <div className="flex justify-center text-lg mt-32">
                <ArrowPathIcon className="w-20 h-20 animate-spin" />
            </div>
            <p className="flex justify-center text-lg mt-4">
                Logging you out...
            </p>
            <small className="flex justify-center mt-4">
                <button
                    className="text-blue-600 mr-1"
                    onClick={() => dispatch(logOut())}
                >
                    Click here
                </button>
                if nothing happens.
            </small>
        </>
    );
};
