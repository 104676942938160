export const IPNotWhitelisted = () => (
    <div className="bg-white dark:bg-gray-50">
        <div className="py-12 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div className="mx-auto max-w-screen-sm text-center">
                <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">
                    Ooops!
                </h1>
                <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">
                    Your IP is not whitelisted.
                </p>

                <p className="mb-4 text-lg font-light text-gray-600">
                    We're sorry for the inconvenience. Your company has provided
                    a range of IP addresses that can be allowed into the system.
                    Your current IP address is not in that range. Please contact
                    your system administrator for more information.
                </p>
            </div>
        </div>
    </div>
);
