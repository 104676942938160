export const usBoundaries = {
    type: "Feature",
    properties: {},
    geometry: {
        coordinates: [
            [
                [-71.88507396274274, 21.012465431352382],
                [-79.10062890926574, 30.555126981505225],
                [-73.80753407293398, 34.73406922993162],
                [-73.70311236065068, 36.962989387179846],
                [-70.66030203599144, 39.02107008159737],
                [-63.355883798030845, 44.11734444790477],
                [-68.87172115456491, 48.20533241645833],
                [-73.99848085321955, 46.52906761121406],
                [-80.70337980009116, 45.49887856437118],
                [-91.38391907454644, 49.63616296865084],
                [-123.45591565889976, 49.60968396044467],
                [-123.41174603710567, 48.383114302789096],
                [-127.24794060804177, 48.586608613442905],
                [-143.3008306858022, 59.750500598587905],
                [-143.41376533377013, 71.7742694465619],
                [-156.50022591934874, 73.71805596203384],
                [-169.35040611837633, 67.03088181529526],
                [-167.9133507457954, 61.98272974760707],
                [-173.43591638599807, 56.66875913784969],
                [-187.03314747839107, 54.53708448672134],
                [-190.2605631730317, 49.143649656029396],
                [-173.9557907285651, 49.504407246521595],
                [-143.74909955672933, 59.66384426466507],
                [-127.49219609215808, 48.617933550243066],
                [-130.3747768277157, 37.112298295215126],
                [-119.76730080149323, 31.769566915422843],
                [-104.47495099048528, 28.73119210851712],
                [-97.15331591332146, 24.519543908965176],
                [-92.45562231250649, 26.087777515960255],
                [-78.96833215157501, 23.566635081392377],
                [-71.88507396274274, 21.012465431352382],
            ],
        ],
        type: "Polygon",
    },
};
