import { useEffect } from "react";
import { Card } from "../../ui/Card";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { InfrastructureImport } from "../../apiClient/generated";
import { useInfrastructureApiClient } from "../../hooks";

interface WaitingDataImportProps {
    importData: InfrastructureImport;
    refresh?: () => void;
}

export const WaitingLocationMatcher = (props: WaitingDataImportProps) => {
    const { importData, refresh } = props;
    const apiClient = useInfrastructureApiClient();
    useEffect(() => {
        if (importData && importData.matchingStatus === "RUNNING") {
            const interval = setInterval(async () => {
                const response =
                    await apiClient.infrastructureAdminImportRetrieve({
                        id: importData.id,
                    });
                if (response.conflictMatchingStatus !== "RUNNING") {
                    refresh();
                }
            }, 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [importData, refresh, apiClient]);

    return (
        <Card>
            <p className="w-full flex justify-center">
                <ArrowPathIcon className="w-20 h-20 animate-spin" />
            </p>

            <p className="w-full flex justify-center">
                Re-running location matcher on import items
            </p>

            <p className="w-full flex justify-center text-sm">
                Check back in a few moments, or
                <button className="ml-1 text-blue-800" onClick={props.refresh}>
                    refresh status now
                </button>
                .
            </p>
        </Card>
    );
};
