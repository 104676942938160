/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Serializer for creating new data uploads and listing them.
 * @export
 * @interface CompanyUploadedDataPackage
 */
export interface CompanyUploadedDataPackage {
    /**
     * 
     * @type {string}
     * @memberof CompanyUploadedDataPackage
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyUploadedDataPackage
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyUploadedDataPackage
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyUploadedDataPackage
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyUploadedDataPackage
     */
    readonly uploadedByName: string;
    /**
     * 
     * @type {Date}
     * @memberof CompanyUploadedDataPackage
     */
    readonly uploadedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyUploadedDataPackage
     */
    readonly uploadComplete: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyUploadedDataPackage
     */
    readonly archiving: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyUploadedDataPackage
     */
    readonly archiveAvailable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyUploadedDataPackage
     */
    readonly virusDetected: boolean;
}

/**
 * Check if a given object implements the CompanyUploadedDataPackage interface.
 */
export function instanceOfCompanyUploadedDataPackage(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('description' in value)) return false;
    if (!('uploadedByName' in value)) return false;
    if (!('uploadedAt' in value)) return false;
    if (!('uploadComplete' in value)) return false;
    if (!('archiving' in value)) return false;
    if (!('archiveAvailable' in value)) return false;
    if (!('virusDetected' in value)) return false;
    return true;
}

export function CompanyUploadedDataPackageFromJSON(json: any): CompanyUploadedDataPackage {
    return CompanyUploadedDataPackageFromJSONTyped(json, false);
}

export function CompanyUploadedDataPackageFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyUploadedDataPackage {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': json['description'],
        'slug': json['slug'] == null ? undefined : json['slug'],
        'notes': json['notes'] == null ? undefined : json['notes'],
        'uploadedByName': json['uploaded_by_name'],
        'uploadedAt': (new Date(json['uploaded_at'])),
        'uploadComplete': json['upload_complete'],
        'archiving': json['archiving'],
        'archiveAvailable': json['archive_available'],
        'virusDetected': json['virus_detected'],
    };
}

export function CompanyUploadedDataPackageToJSON(value?: Omit<CompanyUploadedDataPackage, 'id'|'uploaded_by_name'|'uploaded_at'|'upload_complete'|'archiving'|'archive_available'|'virus_detected'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'description': value['description'],
        'slug': value['slug'],
        'notes': value['notes'],
    };
}

