/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminInfrastructureImportItemPipelineShape
 */
export interface AdminInfrastructureImportItemPipelineShape {
    /**
     * 
     * @type {string}
     * @memberof AdminInfrastructureImportItemPipelineShape
     */
    type?: AdminInfrastructureImportItemPipelineShapeTypeEnum;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof AdminInfrastructureImportItemPipelineShape
     */
    coordinates?: Array<Array<number>>;
}


/**
 * @export
 */
export const AdminInfrastructureImportItemPipelineShapeTypeEnum = {
    LineString: 'LineString'
} as const;
export type AdminInfrastructureImportItemPipelineShapeTypeEnum = typeof AdminInfrastructureImportItemPipelineShapeTypeEnum[keyof typeof AdminInfrastructureImportItemPipelineShapeTypeEnum];


/**
 * Check if a given object implements the AdminInfrastructureImportItemPipelineShape interface.
 */
export function instanceOfAdminInfrastructureImportItemPipelineShape(value: object): boolean {
    return true;
}

export function AdminInfrastructureImportItemPipelineShapeFromJSON(json: any): AdminInfrastructureImportItemPipelineShape {
    return AdminInfrastructureImportItemPipelineShapeFromJSONTyped(json, false);
}

export function AdminInfrastructureImportItemPipelineShapeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminInfrastructureImportItemPipelineShape {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : json['type'],
        'coordinates': json['coordinates'] == null ? undefined : json['coordinates'],
    };
}

export function AdminInfrastructureImportItemPipelineShapeToJSON(value?: AdminInfrastructureImportItemPipelineShape | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'coordinates': value['coordinates'],
    };
}

