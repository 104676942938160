import { ChangeEvent, useContext, useState } from "react";
import * as turf from "@turf/turf";
import {
    normalizeGEOJSON,
    readGeoFileAsGeoJson,
} from "../../../../utils/geopatialUtils";
import { ControlButton } from "../../ui/MapControls";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Popover } from "@headlessui/react";
import { useMap } from "../../hooks/mapState";
import { Modal } from "../../../../ui/Modals";
import { GeoFilterSelect } from "../../../filters/GeoFilterSelect";
import { PrimaryButton } from "../../../../ui/Buttons";
import { MapContext } from "../../MapView";
import { faArrowsRotate } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGeoDataApi } from "../../../../hooks";

const PopOverContent = (props: { close: () => void }) => {
    const context = useContext(MapContext);
    const apiClient = useGeoDataApi();
    const { flyTo } = useMap("mainMap");
    const {
        drawnShape,
        isDrawing,
        startDrawing,
        stopDrawing,
        hasShape,
        selectedFilters,
        setSelectedFilters,
        setIsFiltering,
    } = context.drawAndFilterControls;
    const [modalOpen, setModalOpen] = useState(false);
    const [loadingPresets, setLoadingPresets] = useState(false);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        readGeoFileAsGeoJson({
            file: file,
            callback: (geojson) => {
                if (geojson) {
                    stopDrawing(geojson);
                    if (geojson.features?.length > 0) {
                        const feature = geojson.features[0];
                        const center = turf.centroid(feature);
                        const lat = center.geometry.coordinates[1];
                        const lon = center.geometry.coordinates[0];
                        flyTo(lat, lon, 9);
                        props.close();
                    }
                }
            },
        });
    };

    const handlePresetSelection = async () => {
        setModalOpen(false);
        setLoadingPresets(true);
        const shapes = [];
        for (const itemId of selectedFilters) {
            const response = await apiClient.geodataItemsRetrieve({
                id: itemId,
            });
            shapes.push(turf.feature(response.geometry));
        }
        let union = shapes[0];
        for (let i = 1; i < shapes.length; i++) {
            union = turf.union(union, shapes[i]);
        }
        const center = turf.centroid(union);
        const lat = center.geometry.coordinates[1];
        const lon = center.geometry.coordinates[0];
        flyTo(lat, lon, 7);
        stopDrawing(normalizeGEOJSON(union));
        setLoadingPresets(false);
        setIsFiltering(true);
    };

    return (
        <div className="w-64">
            <h2 className="mb-2 font-semibold text-sm text-ae-slate-700">
                Filter by area
            </h2>
            <p className="text-ae-blue-900 text-sm">
                Utilize the tools to create a closed area, acting as a filter to
                limit the displayed data on the map.
            </p>
            <p className="text-ae-blue-900 text-sm">
                You can also import a GeoJSON file with polygons or pick a
                predefined area.
            </p>
            <div className="mt-5 flex flex-col">
                {loadingPresets ? (
                    <div className="flex items-center justify-center mb-3">
                        <FontAwesomeIcon
                            icon={faArrowsRotate}
                            className="w-4 animate-spin"
                        />
                    </div>
                ) : !isDrawing ? (
                    <>
                        {!hasShape ? (
                            <>
                                <button
                                    onClick={startDrawing}
                                    className="text-sm flex justify-center py-2 text-ae-blue-900 bg-white border border-ae-slate-200 rounded-md font-medium mb-2"
                                >
                                    Draw area on map
                                </button>
                                <label
                                    htmlFor="file-input"
                                    className="mb-2 text-sm flex cursor-pointer justify-center py-2 text-ae-blue-900 bg-white border border-ae-slate-200 rounded-md font-medium"
                                >
                                    Upload KML/GeoJSON
                                </label>
                                <input
                                    id="file-input"
                                    type="file"
                                    className="hidden"
                                    accept=".json,.geojson,.kml"
                                    onChange={handleFileChange}
                                />
                                <button
                                    className="text-sm flex justify-center py-2 text-ae-blue-900 bg-white border border-ae-slate-200 rounded-md font-medium mb-2"
                                    onClick={() => setModalOpen(true)}
                                >
                                    Pick from a predefined area
                                </button>
                                <Modal
                                    visible={modalOpen}
                                    size="md"
                                    onClose={() => setModalOpen(false)}
                                >
                                    <h1 className="text-lg font-semibold mb-5 mt-2">
                                        Pick a predefined area
                                    </h1>
                                    <GeoFilterSelect
                                        selected={selectedFilters}
                                        onSelect={(s) => setSelectedFilters(s)}
                                    />
                                    <div className="mt-2 flex justify-end w-full">
                                        <PrimaryButton
                                            variant="lg"
                                            onClick={handlePresetSelection}
                                        >
                                            Apply filters
                                        </PrimaryButton>
                                    </div>
                                </Modal>
                            </>
                        ) : (
                            <button
                                onClick={() => {
                                    stopDrawing(undefined);
                                    setSelectedFilters([]);
                                }}
                                className="text-sm flex justify-center py-2 text-ae-blue-900 bg-white border border-ae-slate-200 rounded-md font-medium"
                            >
                                Clear selection
                            </button>
                        )}
                    </>
                ) : (
                    <div className="flex flex-col justify-between text-sm text-ae-blue-900">
                        <p className="mb-2">
                            Drawing mode active: Click on the map to draw a
                            closed polygon.
                        </p>
                        <button
                            onClick={() => {
                                stopDrawing(drawnShape);
                            }}
                            className="text-sm flex justify-center py-2 text-ae-blue-900 bg-white border border-ae-slate-200 rounded-md font-medium"
                        >
                            Cancel
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export const DrawOnMapControl = () => {
    return (
        <Popover className="relative">
            <Popover.Button>
                <ControlButton className="w-8">
                    <PencilSquareIcon className="w-4" />
                </ControlButton>
            </Popover.Button>

            <Popover.Panel className="absolute z-50 mt-1 bg-white rounded p-2 left-1/2 transform -translate-x-1/2">
                {({ close }) => <PopOverContent close={close} />}
            </Popover.Panel>
        </Popover>
    );
};
