/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `ALL` - All
 * * `NONE` - None
 * * `BATCH_DIGEST` - Batch Digest
 * @export
 */
export const ThirdPartyEnabledEnum = {
    All: 'ALL',
    None: 'NONE',
    BatchDigest: 'BATCH_DIGEST'
} as const;
export type ThirdPartyEnabledEnum = typeof ThirdPartyEnabledEnum[keyof typeof ThirdPartyEnabledEnum];


export function instanceOfThirdPartyEnabledEnum(value: any): boolean {
    return Object.values(ThirdPartyEnabledEnum).includes(value);
}

export function ThirdPartyEnabledEnumFromJSON(json: any): ThirdPartyEnabledEnum {
    return ThirdPartyEnabledEnumFromJSONTyped(json, false);
}

export function ThirdPartyEnabledEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThirdPartyEnabledEnum {
    return json as ThirdPartyEnabledEnum;
}

export function ThirdPartyEnabledEnumToJSON(value?: ThirdPartyEnabledEnum | null): any {
    return value as any;
}

