import { Switch } from "@headlessui/react";

interface Props {
    checked: boolean;
    onChange: (val) => void;
    size: "lg" | "sm" | "xs";
    disabled?: boolean;
}

export const CustomSwitch = (props: Props) => {
    const switchSize =
        props.size === "lg"
            ? "h-6 w-11"
            : props.size == "xs"
              ? "h-4 w-7"
              : "h-5 w-10";
    const dotSize = props.size === "lg" ? "h-4 w-4" : "h-3 w-3";
    const translateOn =
        props.size === "xs" ? "translate-x-[14px]" : "translate-x-6";
    const translateOff =
        props.size === "xs" ? "translate-x-[2px]" : "translate-x-1";

    return (
        <Switch
            checked={props.checked}
            onChange={(checked) => {
                if (!props.disabled) {
                    props.onChange(checked);
                }
            }}
            className={`${
                props.checked
                    ? props.disabled
                        ? "bg-gray-400 cursor-default"
                        : "bg-ae-blue-550"
                    : "bg-slate-900"
            } 
                        relative inline-flex items-center rounded-full
                        ${switchSize}
                    `}
        >
            <span
                className={`${props.checked ? translateOn : translateOff} 
                            inline-block transform rounded-full bg-white transition drop-shadow-lg
                            ${dotSize}
                        `}
            />
        </Switch>
    );
};
