import { EventStatusEnum } from "../../../apiClient/generated";

interface EventStatusDisplayProps {
    status: EventStatusEnum;
}

export const EventStatusDisplay = (props: EventStatusDisplayProps) => {
    let color = "black";
    switch (props.status) {
        case "WAITING_ACTION":
            color = "red-500";
            break;
        case "WORK_IN_PROGRESS":
            color = "yellow-500";
            break;
        case "COMPLETED":
            color = "green-500";
            break;
    }
    return (
        <div className="flex items-center capitalize">
            <div
                className={`h-3 w-3 bg-${color} rounded-full mr-2 shadow shadow-${color}`}
            />
            {props.status.replaceAll("_", " ").toLowerCase()}
        </div>
    );
};
