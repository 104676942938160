/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Infrastructure } from './Infrastructure';
import {
    InfrastructureFromJSON,
    InfrastructureFromJSONTyped,
    InfrastructureToJSON,
} from './Infrastructure';

/**
 * Infrastructure Conflicts serializer
 * @export
 * @interface InfrastructureConflict
 */
export interface InfrastructureConflict {
    /**
     * 
     * @type {boolean}
     * @memberof InfrastructureConflict
     */
    isExactMatch: boolean;
    /**
     * 
     * @type {Array<Infrastructure>}
     * @memberof InfrastructureConflict
     */
    infrastructure: Array<Infrastructure>;
}

/**
 * Check if a given object implements the InfrastructureConflict interface.
 */
export function instanceOfInfrastructureConflict(value: object): boolean {
    if (!('isExactMatch' in value)) return false;
    if (!('infrastructure' in value)) return false;
    return true;
}

export function InfrastructureConflictFromJSON(json: any): InfrastructureConflict {
    return InfrastructureConflictFromJSONTyped(json, false);
}

export function InfrastructureConflictFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfrastructureConflict {
    if (json == null) {
        return json;
    }
    return {
        
        'isExactMatch': json['is_exact_match'],
        'infrastructure': ((json['infrastructure'] as Array<any>).map(InfrastructureFromJSON)),
    };
}

export function InfrastructureConflictToJSON(value?: InfrastructureConflict | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'is_exact_match': value['isExactMatch'],
        'infrastructure': ((value['infrastructure'] as Array<any>).map(InfrastructureToJSON)),
    };
}

