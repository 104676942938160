import { faSpinner } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ControlContainer } from "../elements/common";

export const LoadingIndicator = () => (
    <ControlContainer className="h-8 flex justify-center items-center gap-1 px-4">
        <FontAwesomeIcon icon={faSpinner} className="h-4 mr-2 animate-spin" />
        Loading...
    </ControlContainer>
);
