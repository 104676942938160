import { SelfReportedEmissionDashboard } from "../../components/dashboards/SelfReportedDashboard";
import { PermissionGate } from "../PermissionGate";

export const SelfReportedEmissionDashboardsPage = () => {
    return (
        <PermissionGate
            permission="emissionView"
            requiredFlag="enable_unified_dashboards"
        >
            <SelfReportedEmissionDashboard />
        </PermissionGate>
    );
};
