import { useState, useMemo, useEffect, useCallback } from "react";
import * as turf from "@turf/turf";

/**
 * Use map data loader: custom hook that loads data for
 * map areas after a certain zoom level and caches the results.
 */
interface MapDataLoaderProps {
    areaOnScreen: any;
    zoom: number;
    zoomToStartFetching: number;
    enabled: boolean;
    loadDataCallback: (area: any, signal?: AbortSignal) => Promise<void>;
    areaFilter?: any;
}

export const useMapDataLoader = (props: MapDataLoaderProps) => {
    const [abortController, setAbortController] = useState(
        new AbortController(),
    );
    // Data state
    const [loading, setLoading] = useState(0);
    // Stores a polygon with all areas that are currently loading.
    const [areaLoading, setAreaLoading] = useState();
    // Stores a polygon with all areas that are already loaded.
    const [areaLoaded, setAreaLoaded] = useState();

    const areaInUse = useMemo(() => {
        let area;
        if (areaLoaded && areaLoading) {
            area = turf.union(areaLoaded, areaLoading);
        } else if (areaLoading) {
            area = areaLoading;
        } else if (areaLoaded) {
            area = areaLoaded;
        }
        return area;
    }, [areaLoading, areaLoaded]);

    // Load data when screen moves.
    useEffect(() => {
        const loadData = async () => {
            try {
                if (!props.areaOnScreen) {
                    return;
                }
                // Calculate screen bounds as GeoJSON polygon
                // and compute different with data already loaded.
                let areaToFetch = Object.assign({}, props.areaOnScreen);
                if (props.areaFilter) {
                    areaToFetch = turf.difference(
                        props.areaFilter,
                        areaToFetch,
                    );
                }
                if (areaInUse) {
                    areaToFetch = turf.difference(areaToFetch, areaInUse);
                }
                if (!areaToFetch) {
                    return;
                }

                // Set loading status
                setLoading((loading) => loading + 1);
                setAreaLoading((area) => {
                    return area ? turf.union(area, areaToFetch) : areaToFetch;
                });

                // Load data here
                await props.loadDataCallback(
                    areaToFetch.geometry,
                    abortController.signal,
                );

                // setArea only if success
                setAreaLoading((area) =>
                    area ? turf.difference(area, areaToFetch) : undefined,
                );
                setAreaLoaded((area) =>
                    area ? turf.union(area, areaToFetch) : areaToFetch,
                );
                setLoading((loading) => loading - 1);
            } catch (e) {
                setAreaLoaded(undefined);
                setAreaLoading(undefined);
                setLoading(0);
                // Throw error if this wasn't an aborted or cancelled request.
                if (!["AbortError", "FetchError"].includes(e.name)) {
                    throw new Error(e);
                }
            }
        };

        // Load all data in area if zoom > zoomToStartFetching
        if (props.zoom > props.zoomToStartFetching && props.enabled) {
            loadData();
        }
    }, [
        props.enabled,
        props.zoom,
        props.zoomToStartFetching,
        props.areaOnScreen,
        props.areaFilter,
        // This causes the infinite retry loop
        // props.loadDataCallback,
        areaInUse,
        abortController,
    ]);

    // Allow resetting state and re-fetch data
    const resetState = useCallback(() => {
        abortController.abort();
        setAbortController(new AbortController());
        setLoading(0);
        setAreaLoaded(undefined);
        setAreaLoading(undefined);
    }, [abortController]);

    return {
        loading: loading > 0,
        areaLoaded,
        areaLoading,
        resetState,
    };
};
