import { ShareIcon } from "@heroicons/react/24/outline";
import { useState, useMemo } from "react";
import { ControlButton, MapDropdownMenu } from "../../ui/MapControls";
import { PrimaryButton } from "../../../../ui/Buttons";
import { serializeMapStates } from "../../utils/state";
import { useCopyToClipboard } from "@uidotdev/usehooks";
import { useMap } from "../../hooks/mapState";
import { useMapData, useMapFilters } from "../../hooks/mapDataAndFilters";

export const ShareMapControl = () => {
    const mapState = useMap("mainMap");
    const { selectedContext } = useMapData("mainMap");
    const { filterState } = useMapFilters("mainMap");
    const [, copyToClipboard] = useCopyToClipboard();
    const [textCopied, setTextCopied] = useState(false);

    const url = useMemo(() => {
        return `${window.location.href}?mapState=${serializeMapStates(
            {
                _viewState: mapState._viewState,
                basemap: mapState.basemap,
                debounced: mapState.debounced,
            },
            filterState,
            selectedContext,
        )}`;
    }, [mapState, selectedContext, filterState]);

    const copyUrl = () => {
        copyToClipboard(url);
        setTextCopied(true);
        setTimeout(() => {
            setTextCopied(false);
        }, 600);
    };

    return (
        <MapDropdownMenu
            button={
                <ControlButton className="w-8 rounded-r">
                    <ShareIcon className="w-4" />
                </ControlButton>
            }
        >
            <div className="w-64">
                <p className="text-slate-700 text-sm font-semibold mb-2">
                    Share your current map view:
                </p>
                <div className="flex flex-row space-x-2 items-center">
                    <input
                        type="text"
                        value={url}
                        className="w-full rounded h-9 text-sm"
                        onClick={(e: any) => e.target.select()}
                        readOnly
                    />
                    <PrimaryButton onClick={copyUrl}>
                        {textCopied ? "Copied" : "Copy"}
                    </PrimaryButton>
                </div>
            </div>
        </MapDropdownMenu>
    );
};
