/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SSOEnabledResponse
 */
export interface SSOEnabledResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SSOEnabledResponse
     */
    enabled: boolean;
}

/**
 * Check if a given object implements the SSOEnabledResponse interface.
 */
export function instanceOfSSOEnabledResponse(value: object): boolean {
    if (!('enabled' in value)) return false;
    return true;
}

export function SSOEnabledResponseFromJSON(json: any): SSOEnabledResponse {
    return SSOEnabledResponseFromJSONTyped(json, false);
}

export function SSOEnabledResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SSOEnabledResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
    };
}

export function SSOEnabledResponseToJSON(value?: SSOEnabledResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'enabled': value['enabled'],
    };
}

