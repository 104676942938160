import * as React from "react";

type Props = {
    rate?: number;
    uncertainty?: number;
    unit: string;
};

export const RateWithUncertaintyCell: React.FC<Props> = ({
    rate,
    uncertainty,
    unit,
}) => {
    if (rate === undefined) {
        return "-";
    }

    return (
        <div>
            {rate.toFixed(2)}{" "}
            {uncertainty !== undefined && (
                <span>± {uncertainty.toFixed(2)} </span>
            )}
            {unit}
        </div>
    );
};
