import { object, boolean, number, string, date } from "yup";
import {
    EmissionRecordView,
    EmissionStatusEnum,
    EventStatusEnum,
} from "../../apiClient/generated";
import {
    useEmissionRecordsApiClient,
    useEmissionEventsApiClient,
    useAppSelector,
} from "../../hooks";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";

export const formSchema = object({
    similarEmission: string().nullable(),
    accept: boolean()
        .nullable()
        .when("similarEmission", {
            is: null,
            then: (schema) =>
                schema.test(
                    "accept",
                    "Field required",
                    (value: boolean) => value !== null,
                ),
            otherwise: (schema) => schema.notRequired(),
        }),
    markAsComplete: boolean(),
    // Responsible
    responsible: number().when("accept", {
        is: true,
        then: (schema) => schema.required("Field required."),
        otherwise: (schema) => schema.notRequired(),
    }),
    notifyResponsible: boolean(),
    // Infrastructure
    infrastructure: string().nullable(),
    infrastructureDetail: string().nullable(),
    // Root cause fields
    rootCause: number().when(["accept", "infrastructure", "markAsComplete"], {
        is: (
            accept: boolean,
            infrastructure: boolean,
            markAsComplete: boolean,
        ) => accept && markAsComplete && infrastructure,
        then: (schema) => schema.required("Field required."),
        otherwise: (schema) => schema.notRequired(),
    }),
    startDate: date()
        .optional()
        .transform((value, input) => (input === "" ? undefined : value)),
    startTime: string(),
    endDate: date()
        .optional()
        .transform((value, input) => (input === "" ? undefined : value)),
    endTime: string(),
    notes: string(),
    rejectReason: string().when("accept", {
        is: (accept) => accept || accept === null,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required("Reject reason is required."),
    }),
});

const formatTime = (date: Date) => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
};

export const useEventForm = (
    emissionRecord: EmissionRecordView,
    onSuccess: () => void,
) => {
    const queryClient = useQueryClient();
    const currentUserId = useAppSelector((state) => state.auth.userId);
    const apiClient = useEmissionRecordsApiClient();
    const eventsApiClient = useEmissionEventsApiClient();

    // Find compute event that is not completed.
    // If there are others, ignore, this form will not be rendered
    // when all events are marked as complete.
    const currentEvent = useMemo(() => {
        return emissionRecord.events.find((e) => e.eventStatus !== "COMPLETED");
    }, [emissionRecord]);

    // Form setup
    const form = useForm({
        defaultValues: {
            similarEmission: null,
            accept:
                emissionRecord.emissionStatus === "ACCEPTED"
                    ? true
                    : emissionRecord.emissionStatus === "REJECTED"
                      ? false
                      : null,
            responsible:
                currentEvent && currentEvent.responsible
                    ? currentEvent.responsible
                    : undefined,
            notifyResponsible: true,
            infrastructure: emissionRecord.infrastructure,
            infrastructureDetail: emissionRecord.infrastructureDetail,
            rootCause: currentEvent ? currentEvent.rootCause?.id : undefined,
            startDate:
                currentEvent && currentEvent.startDate
                    ? (currentEvent.startDate
                          .toISOString()
                          .split("T")[0] as unknown as Date)
                    : undefined,
            endDate:
                currentEvent && currentEvent.endDate
                    ? (currentEvent.endDate
                          .toISOString()
                          .split("T")[0] as unknown as Date)
                    : undefined,
            startTime:
                currentEvent && currentEvent.startDate
                    ? formatTime(currentEvent.startDate)
                    : undefined,
            endTime:
                currentEvent && currentEvent.endDate
                    ? formatTime(currentEvent.endDate)
                    : undefined,
            notes: currentEvent ? currentEvent.notes : "",
            rejectReason: "",
        },
        resolver: yupResolver(formSchema),
    });

    // If a linked emission is selected, clear the `accept` value.
    const similarEmission = form.watch("similarEmission");
    useEffect(() => {
        if (similarEmission !== null) {
            form.setValue("accept", null);
        }
    }, [similarEmission]);

    // Handle saving
    const saveChanges = async (data) => {
        // If we are just linking this to an existing event.
        if (data.similarEmission !== null) {
            await apiClient.emissionRecordsPartialUpdate({
                id: emissionRecord.id,
                patchedEmissionRecordUpdateRequest: {
                    emissionStatus: "ACCEPTED",
                    events: [data.similarEmission],
                },
            });
            form.reset();
            onSuccess();
            queryClient.invalidateQueries();
            return;
        }

        // Check if we need to create the event and set it as accepted.
        let eventId = currentEvent?.id;

        // First update emission and create event if necessary.
        // If status is `MATCH` or `NEAR_MATCH` then update event.
        let emissionStatus;
        if (
            emissionRecord.emissionStatus === EmissionStatusEnum.NearMatch ||
            emissionRecord.emissionStatus === EmissionStatusEnum.Matched
        ) {
            emissionStatus = data.accept ? "ACCEPTED" : "REJECTED";
        }

        let rejectReason = data.rejectReason;
        if (data.accept) {
            rejectReason = "";
        }

        const response = await apiClient.emissionRecordsPartialUpdate({
            id: emissionRecord.id,
            patchedEmissionRecordUpdateRequest: {
                emissionStatus,
                // FIXME: Only set infrastructure if the current user is reponsible,
                // I think it's best if this is handled on the form level (disabling fields that are not viewable)
                infrastructure:
                    data.responsible === currentUserId
                        ? data.infrastructure
                        : undefined,
                infrastructureDetail:
                    data.responsible === currentUserId
                        ? data.infrastructureDetail
                        : undefined,
                createEvent: data.accept,
                rejectReason,
            },
        });

        // Fill eventId if still empty
        // We assume there's always going to be an event coming from the platform here.
        if (!eventId && response.events.length > 0) {
            eventId = response.events[0];
        }

        // If we have event and we are accepting it, also update event.
        if (
            eventId &&
            (data.accept || emissionRecord.emissionStatus === "ACCEPTED")
        ) {
            // Compute start and end date timestamps (if provided)
            const startDate = data.startDate;
            if (data.startDate && data.startTime) {
                // Split the time string into hours and minutes
                const [hours, minutes] = data.startTime.split(":").map(Number);
                // Set hours and minutes to the date object
                startDate.setHours(hours, minutes, 0, 0);
            }

            // Compute start and end date timestamps (if provided)
            const endDate = data.endDate;
            if (data.endDate && data.endTime) {
                // Split the time string into hours and minutes
                const [hours, minutes] = data.endTime.split(":").map(Number);
                // Set hours and minutes to the date object
                endDate.setHours(hours, minutes, 0, 0);
            }

            await eventsApiClient.emissioneventsPartialUpdate({
                id: eventId,
                patchedEmissionEventUpdateRequest: {
                    eventStatus: data.markAsComplete
                        ? EventStatusEnum.Completed
                        : undefined,
                    responsible:
                        data.responsible && data.responsible !== null
                            ? data.responsible
                            : undefined,
                    notifyResponsible:
                        data.responsible && data.responsible !== null
                            ? data.notifyResponsible
                            : undefined,
                    rootCause: data.rootCause,
                    startDate: startDate,
                    endDate: endDate,
                    notes: data.notes,
                },
            });
        }
        form.reset();
        onSuccess();
        queryClient.invalidateQueries();
    };

    return {
        form,
        submit: form.handleSubmit(saveChanges),
    };
};
