export const camelToSnakeCase = (value?: string): string => {
    return (
        value?.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`) ?? ""
    );
};

export const snakeToCamelCase = (value?: string): string => {
    return (
        value?.replace(/(_\w)/g, (matches) => matches[1].toUpperCase()) ?? ""
    );
};
