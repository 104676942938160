import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { InfrastructureTreeData, convertToNestedTree } from "../utils/tree";
import { Infrastructure } from "../../../apiClient/generated";

const TreeNode = (props: InfrastructureTreeData) => {
    const [isExpanded, setIsExpanded] = useState(true);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div>
            <div
                className="relative flex p-2 items-center group hover:bg-slate-100 rounded-sm cursor-pointer"
                onClick={handleToggle}
            >
                <div className="absolute left-0 top-0.5 h-4 rounded-bl w-2 border-l border-b border-black"></div>
                {props.children && props.children.length > 0 && (
                    <div className="flex items-center justify-center rounded-sm border border-black h-3 w-3 text-xs">
                        {props.children.length}
                    </div>
                )}
                <div className="ml-1">{props.name || props.siteName}</div>
                <span className="flex-grow" />
                {props.children && props.children.length > 0 && (
                    <div className="opacity-0 group-hover:opacity-100">
                        {isExpanded ? (
                            <ChevronUpIcon className="h-4" />
                        ) : (
                            <ChevronDownIcon className="h-4" />
                        )}
                    </div>
                )}
            </div>
            {isExpanded && props.children && (
                <div style={{ marginLeft: "20px" }}>
                    {props.children
                        .sort((a, b) =>
                            a.name.localeCompare(b.name, undefined, {
                                numeric: true,
                            }),
                        )
                        .map((child, index) => (
                            <TreeNode key={index} {...child} />
                        ))}
                </div>
            )}
        </div>
    );
};

interface InfrastructureTreeProps {
    infrastructure: Infrastructure[];
}

export const InfrastructureTree = (props: InfrastructureTreeProps) => {
    const treeData = convertToNestedTree(props.infrastructure);
    return (
        <div className="p-4 text-sm">
            {treeData.map((node, index) => (
                <TreeNode key={index} {...node} />
            ))}
        </div>
    );
};
