import { SuperEmitterProgramDashboard } from "../../components/dashboards/SuperEmitterProgramDashboard";
import { useAppSelector } from "../../hooks";
import { PermissionGate } from "../PermissionGate";

export const SuperEmitterProgramDashboardPage = () => {
    const userFlags = useAppSelector((s) => s.auth.flags);

    if (!userFlags.includes("enable_epa_sep")) {
        return;
    }

    return (
        <PermissionGate permission="emissionView" requiredFlag="enable_epa_sep">
            <SuperEmitterProgramDashboard />
        </PermissionGate>
    );
};
