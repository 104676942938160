import { DataDownloadDataTable } from "../components/DownloadsDataTable";
import { DashboardContainer, DashboardHeader } from "../ui/DashboardComponents";
import { PermissionGate } from "./PermissionGate";

export const DataDownloadPage = () => {
    return (
        <PermissionGate permission="dataDownloadView">
            <DashboardContainer>
                <DashboardHeader title="Data Downloads" />
                <DataDownloadDataTable />
            </DashboardContainer>
        </PermissionGate>
    );
};
