import { useEffect } from "react";
import { InfrastructureImport } from "../../apiClient/generated";
import { useInfrastructureApiClient } from "../../hooks";
import { Card } from "../../ui/Card";
import { BuildingOffice2Icon } from "@heroicons/react/24/outline";

interface WaitingDataImportProps {
    importData: InfrastructureImport;
    refresh?: () => void;
}

export const WaitingDataImport = (props: WaitingDataImportProps) => {
    const { importData, refresh } = props;
    const apiClient = useInfrastructureApiClient();

    useEffect(() => {
        if (importData && importData.importStatus === "DATA_IMPORT") {
            const interval = setInterval(async () => {
                const response =
                    await apiClient.infrastructureAdminImportRetrieve({
                        id: importData.id,
                    });
                if (response.importStatus !== "DATA_IMPORT") {
                    refresh();
                }
            }, 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [importData, refresh, apiClient]);

    return (
        <Card>
            <p className="w-full flex justify-center">
                <BuildingOffice2Icon className="w-20 h-20" />
                <BuildingOffice2Icon className="absolute w-20 h-20 animate-ping opacity-10" />
            </p>

            <p className="w-full flex justify-center">
                Importing data into system...
            </p>

            <p className="w-full flex justify-center text-sm">
                Check back in a few minutes, or
                <button className="ml-1 text-blue-800" onClick={props.refresh}>
                    refresh status now
                </button>
                .
            </p>
        </Card>
    );
};
