/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CsvDataSridEnum } from './CsvDataSridEnum';
import {
    CsvDataSridEnumFromJSON,
    CsvDataSridEnumFromJSONTyped,
    CsvDataSridEnumToJSON,
} from './CsvDataSridEnum';

/**
 * Infrastructure Import serializer.
 * @export
 * @interface InfrastructureImportRequest
 */
export interface InfrastructureImportRequest {
    /**
     * 
     * @type {CsvDataSridEnum}
     * @memberof InfrastructureImportRequest
     */
    csvDataSrid?: CsvDataSridEnum;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureImportRequest
     */
    csvInputFilename: string;
    /**
     * 
     * @type {number}
     * @memberof InfrastructureImportRequest
     */
    exactMatchDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof InfrastructureImportRequest
     */
    nearMatchDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof InfrastructureImportRequest
     */
    similarityCheckerCentroidDistance?: number;
}

/**
 * Check if a given object implements the InfrastructureImportRequest interface.
 */
export function instanceOfInfrastructureImportRequest(value: object): boolean {
    if (!('csvInputFilename' in value)) return false;
    return true;
}

export function InfrastructureImportRequestFromJSON(json: any): InfrastructureImportRequest {
    return InfrastructureImportRequestFromJSONTyped(json, false);
}

export function InfrastructureImportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfrastructureImportRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'csvDataSrid': json['csv_data_srid'] == null ? undefined : CsvDataSridEnumFromJSON(json['csv_data_srid']),
        'csvInputFilename': json['csv_input_filename'],
        'exactMatchDistance': json['exact_match_distance'] == null ? undefined : json['exact_match_distance'],
        'nearMatchDistance': json['near_match_distance'] == null ? undefined : json['near_match_distance'],
        'similarityCheckerCentroidDistance': json['similarity_checker_centroid_distance'] == null ? undefined : json['similarity_checker_centroid_distance'],
    };
}

export function InfrastructureImportRequestToJSON(value?: InfrastructureImportRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'csv_data_srid': CsvDataSridEnumToJSON(value['csvDataSrid']),
        'csv_input_filename': value['csvInputFilename'],
        'exact_match_distance': value['exactMatchDistance'],
        'near_match_distance': value['nearMatchDistance'],
        'similarity_checker_centroid_distance': value['similarityCheckerCentroidDistance'],
    };
}

