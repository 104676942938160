/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SSORequiredCheckResponse
 */
export interface SSORequiredCheckResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SSORequiredCheckResponse
     */
    ssoRequired: boolean;
    /**
     * 
     * @type {string}
     * @memberof SSORequiredCheckResponse
     */
    authUrl: string;
}

/**
 * Check if a given object implements the SSORequiredCheckResponse interface.
 */
export function instanceOfSSORequiredCheckResponse(value: object): boolean {
    if (!('ssoRequired' in value)) return false;
    if (!('authUrl' in value)) return false;
    return true;
}

export function SSORequiredCheckResponseFromJSON(json: any): SSORequiredCheckResponse {
    return SSORequiredCheckResponseFromJSONTyped(json, false);
}

export function SSORequiredCheckResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SSORequiredCheckResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'ssoRequired': json['sso_required'],
        'authUrl': json['auth_url'],
    };
}

export function SSORequiredCheckResponseToJSON(value?: SSORequiredCheckResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sso_required': value['ssoRequired'],
        'auth_url': value['authUrl'],
    };
}

