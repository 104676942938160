/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NotificationChannel } from './NotificationChannel';
import {
    NotificationChannelFromJSON,
    NotificationChannelFromJSONTyped,
    NotificationChannelToJSON,
} from './NotificationChannel';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * Serializer for notification groups.
 * @export
 * @interface NotificationGroup
 */
export interface NotificationGroup {
    /**
     * 
     * @type {number}
     * @memberof NotificationGroup
     */
    readonly id: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationGroup
     */
    owner: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationGroup
     */
    name: string;
    /**
     * 
     * @type {Array<NotificationChannel>}
     * @memberof NotificationGroup
     */
    channels: Array<NotificationChannel>;
    /**
     * 
     * @type {Array<User>}
     * @memberof NotificationGroup
     */
    people: Array<User>;
}

/**
 * Check if a given object implements the NotificationGroup interface.
 */
export function instanceOfNotificationGroup(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('owner' in value)) return false;
    if (!('name' in value)) return false;
    if (!('channels' in value)) return false;
    if (!('people' in value)) return false;
    return true;
}

export function NotificationGroupFromJSON(json: any): NotificationGroup {
    return NotificationGroupFromJSONTyped(json, false);
}

export function NotificationGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationGroup {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'owner': json['owner'],
        'name': json['name'],
        'channels': ((json['channels'] as Array<any>).map(NotificationChannelFromJSON)),
        'people': ((json['people'] as Array<any>).map(UserFromJSON)),
    };
}

export function NotificationGroupToJSON(value?: Omit<NotificationGroup, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'owner': value['owner'],
        'name': value['name'],
        'channels': ((value['channels'] as Array<any>).map(NotificationChannelToJSON)),
        'people': ((value['people'] as Array<any>).map(UserToJSON)),
    };
}

