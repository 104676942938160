import { useQuery } from "@tanstack/react-query";
import { useDataPointsApi } from "../../../hooks";
import { SidebarSection, MapSidebarSkeleton } from "../ui/SidebarItems";
import { DataPointDisplay } from "../../Emissions/DataPointDisplay";
import { useMapData } from "../hooks/mapDataAndFilters";

const DataPointDetails = () => {
    const { selectedContext } = useMapData("mainMap");
    const apiClient = useDataPointsApi();

    // Emission information
    const dataPointQuery = useQuery({
        queryKey: ["dataPointDetails", selectedContext.dataPointId],
        queryFn: async () => {
            return await apiClient.dataPointsRetrieve({
                id: selectedContext.dataPointId,
            });
        },
        enabled: !!selectedContext.dataPointId,
    });

    return (
        <>
            <SidebarSection
                title="Properties"
                loading={dataPointQuery.isLoading}
            >
                <DataPointDisplay dataPoint={dataPointQuery.data} />
            </SidebarSection>
        </>
    );
};

export const DataPointDetailSidebar = () => (
    <MapSidebarSkeleton
        title="Emission (public)"
        tabs={[
            {
                title: "Details",
                content: <DataPointDetails />,
            },
        ]}
    />
);
