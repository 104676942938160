/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AdminInfrastructureImportItemLocation } from './AdminInfrastructureImportItemLocation';
import {
    AdminInfrastructureImportItemLocationFromJSON,
    AdminInfrastructureImportItemLocationFromJSONTyped,
    AdminInfrastructureImportItemLocationToJSON,
} from './AdminInfrastructureImportItemLocation';
import type { AdminInfrastructureImportItemPipelineShape } from './AdminInfrastructureImportItemPipelineShape';
import {
    AdminInfrastructureImportItemPipelineShapeFromJSON,
    AdminInfrastructureImportItemPipelineShapeFromJSONTyped,
    AdminInfrastructureImportItemPipelineShapeToJSON,
} from './AdminInfrastructureImportItemPipelineShape';
import type { AdminInfrastructureImportItemShape } from './AdminInfrastructureImportItemShape';
import {
    AdminInfrastructureImportItemShapeFromJSON,
    AdminInfrastructureImportItemShapeFromJSONTyped,
    AdminInfrastructureImportItemShapeToJSON,
} from './AdminInfrastructureImportItemShape';
import type { InfraTypeEnum } from './InfraTypeEnum';
import {
    InfraTypeEnumFromJSON,
    InfraTypeEnumFromJSONTyped,
    InfraTypeEnumToJSON,
} from './InfraTypeEnum';

/**
 * Serializer used by map that returns the least amount of
 * information necessary to render the map.
 * @export
 * @interface InfrastructureMapList
 */
export interface InfrastructureMapList {
    /**
     * 
     * @type {string}
     * @memberof InfrastructureMapList
     */
    readonly id: string;
    /**
     * 
     * @type {AdminInfrastructureImportItemLocation}
     * @memberof InfrastructureMapList
     */
    location?: AdminInfrastructureImportItemLocation;
    /**
     * 
     * @type {AdminInfrastructureImportItemShape}
     * @memberof InfrastructureMapList
     */
    shape?: AdminInfrastructureImportItemShape;
    /**
     * 
     * @type {AdminInfrastructureImportItemPipelineShape}
     * @memberof InfrastructureMapList
     */
    pipelineShape?: AdminInfrastructureImportItemPipelineShape;
    /**
     * 
     * @type {InfraTypeEnum}
     * @memberof InfrastructureMapList
     */
    infraType: InfraTypeEnum;
}

/**
 * Check if a given object implements the InfrastructureMapList interface.
 */
export function instanceOfInfrastructureMapList(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('infraType' in value)) return false;
    return true;
}

export function InfrastructureMapListFromJSON(json: any): InfrastructureMapList {
    return InfrastructureMapListFromJSONTyped(json, false);
}

export function InfrastructureMapListFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfrastructureMapList {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'location': json['location'] == null ? undefined : AdminInfrastructureImportItemLocationFromJSON(json['location']),
        'shape': json['shape'] == null ? undefined : AdminInfrastructureImportItemShapeFromJSON(json['shape']),
        'pipelineShape': json['pipeline_shape'] == null ? undefined : AdminInfrastructureImportItemPipelineShapeFromJSON(json['pipeline_shape']),
        'infraType': InfraTypeEnumFromJSON(json['infra_type']),
    };
}

export function InfrastructureMapListToJSON(value?: Omit<InfrastructureMapList, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'location': AdminInfrastructureImportItemLocationToJSON(value['location']),
        'shape': AdminInfrastructureImportItemShapeToJSON(value['shape']),
        'pipeline_shape': AdminInfrastructureImportItemPipelineShapeToJSON(value['pipelineShape']),
        'infra_type': InfraTypeEnumToJSON(value['infraType']),
    };
}

