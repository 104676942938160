import {
    CheckIcon,
    PaperAirplaneIcon,
    TrashIcon,
    XMarkIcon,
} from "@heroicons/react/24/solid";
import { useMemo, useState } from "react";
import {
    ChannelTypeEnum,
    NotificationChannel,
} from "../../apiClient/generated";
import { useNotificationsApi } from "../../hooks";
import { createColumnHelper } from "@tanstack/react-table";
import { Modal } from "../../ui/Modals";
import { PrimaryButton, SecondaryButton } from "../../ui/Buttons";
import { RadioSelector } from "../../ui/Radio";
import { useQueryClient } from "@tanstack/react-query";
import { DataTableV3, RowActionButtons } from "../DataTable/DataTableV3";

const channelsColumnHelper = createColumnHelper<NotificationChannel>();

interface ChannelSettingsProps {
    company?: number;
}

export const NotificationChannelSettings = (props: ChannelSettingsProps) => {
    const queryClient = useQueryClient();
    const apiClient = useNotificationsApi();
    const [showAddModal, setShowAddModal] = useState(false);
    const [notificationType, setNotificationType] = useState<ChannelTypeEnum>(
        ChannelTypeEnum.Email,
    );
    const [recipient, setRecipient] = useState("");

    const createChannel = async () => {
        await apiClient.notificationsChannelsCreate({
            notificationChannelRequest: {
                owner: props.company,
                channelType: notificationType,
                email: (notificationType === "EMAIL" && recipient) || "",
                webhookUrl:
                    (notificationType === "TEAMS_WEBHOOK" && recipient) || "",
            },
        });
        setRecipient("");
        setShowAddModal(false);
        queryClient.refetchQueries({ queryKey: ["channelQuery"] });
        queryClient.refetchQueries({ queryKey: ["notificationGroup"] });
    };

    const fetchFunction = async (props: any) => {
        return await apiClient.notificationsChannelsList(props);
    };

    const deleteRowFunction = async (id) => {
        return await apiClient.notificationsChannelsDestroy({ id });
    };

    const columns = useMemo(
        () => [
            channelsColumnHelper.accessor("channelType", {
                id: "channelType",
                header: () => "Type",
                cell: (info) => (
                    <span className="capitalize">
                        {info.getValue().toLowerCase()}
                    </span>
                ),
                enableSorting: true,
            }),
            channelsColumnHelper.accessor(
                (row) => row.email || row.webhookUrl,
                {
                    id: "email_webhook",
                    cell: (info) => (
                        <span className="line-clamp-1">{info.getValue()}</span>
                    ),
                    header: () => "Email / Webhook URL",
                    enableSorting: false,
                },
            ),
            channelsColumnHelper.accessor(
                (row) => {
                    return {
                        channelType: row.channelType,
                        active: row.active,
                    };
                },
                {
                    id: "active",
                    cell: (info) => {
                        const value = info.getValue();
                        const active =
                            value.channelType === ChannelTypeEnum.Email
                                ? value.active
                                : true;
                        return (
                            <div className="flex items-center gap-2">
                                {active ? (
                                    <CheckIcon className="text-green-600 w-4" />
                                ) : (
                                    <XMarkIcon className="text-red-600 w-4" />
                                )}
                            </div>
                        );
                    },
                    header: () => "Active",
                    enableSorting: false,
                },
            ),
            channelsColumnHelper.display({
                id: "actions",
                cell: (info) => {
                    const actions = [
                        {
                            fn: () =>
                                info.table.options.meta?.deleteRow(
                                    info.row.index,
                                    info.row.original.id,
                                ),
                            icon: <TrashIcon className="h-4 w-4" />,
                            tooltip: "Delete",
                        },
                    ];

                    if (
                        !info.row.original.active &&
                        info.row.original.channelType === ChannelTypeEnum.Email
                    ) {
                        actions.unshift({
                            fn: async () => {
                                await apiClient.notificationsChannelsResendEmailConfirmationCreate(
                                    {
                                        id: info.row.original.id,
                                    },
                                );
                                alert("Email verification re-sent.");
                            },
                            icon: <PaperAirplaneIcon className="h-4 w-4" />,
                            tooltip: "Resend confirmation email.",
                        });
                    }

                    return <RowActionButtons actions={actions} />;
                },
            }),
        ],
        [apiClient],
    );

    return (
        <div>
            <hr className="mt-2" />
            <DataTableV3<NotificationChannel>
                dataName="notificationChannel"
                columns={columns}
                extraFilters={{
                    owner: props.company,
                }}
                fetchFunction={fetchFunction}
                deleteRow={deleteRowFunction}
                createRow={() => setShowAddModal(true)}
                sortable={false}
                filterable={false}
            />

            {/* Add modal */}
            <Modal
                size="sm"
                visible={showAddModal}
                onClose={() => setShowAddModal(false)}
            >
                <p className="mt-4 font-semibold text-lg">
                    Add new notification channel
                </p>
                <div className="my-4 text-base">
                    <div className="mb-2">
                        <p className="mb-1">Type:</p>
                        <RadioSelector
                            value={notificationType}
                            onChange={(value) =>
                                setNotificationType(value as ChannelTypeEnum)
                            }
                            options={[
                                {
                                    key: ChannelTypeEnum.Email,
                                    label: "Email",
                                },
                                {
                                    key: ChannelTypeEnum.TeamsWebhook,
                                    label: "Teams Webhook",
                                },
                            ]}
                        />
                    </div>
                    <div>
                        <p className="mb-1">
                            {notificationType === ChannelTypeEnum.Email
                                ? "Email:"
                                : "Webhook URL:"}
                        </p>
                        <input
                            className="rounded w-full"
                            type={
                                notificationType === ChannelTypeEnum.Email
                                    ? "email"
                                    : "url"
                            }
                            value={recipient}
                            onChange={(e) => setRecipient(e.target.value)}
                        />
                    </div>
                </div>
                <div className="flex justify-end gap-2">
                    <SecondaryButton onClick={() => setShowAddModal(false)}>
                        Cancel
                    </SecondaryButton>
                    <PrimaryButton onClick={createChannel}>Save</PrimaryButton>
                </div>
            </Modal>
        </div>
    );
};
