/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `ok` - ok
 * * `already_in_progress` - already_in_progress
 * * `no_items` - no_items
 * @export
 */
export const ReportResposeStatusEnum = {
    Ok: 'ok',
    AlreadyInProgress: 'already_in_progress',
    NoItems: 'no_items'
} as const;
export type ReportResposeStatusEnum = typeof ReportResposeStatusEnum[keyof typeof ReportResposeStatusEnum];


export function instanceOfReportResposeStatusEnum(value: any): boolean {
    return Object.values(ReportResposeStatusEnum).includes(value);
}

export function ReportResposeStatusEnumFromJSON(json: any): ReportResposeStatusEnum {
    return ReportResposeStatusEnumFromJSONTyped(json, false);
}

export function ReportResposeStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportResposeStatusEnum {
    return json as ReportResposeStatusEnum;
}

export function ReportResposeStatusEnumToJSON(value?: ReportResposeStatusEnum | null): any {
    return value as any;
}

