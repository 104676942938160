import { ShieldCheckIcon } from "@heroicons/react/24/outline";
import { Card } from "../../ui/Card";

export const WaitingApproval = () => (
    <Card>
        <p className="w-full flex justify-center">
            <ShieldCheckIcon className="w-20 h-20" />
        </p>

        <p className="w-full flex justify-center">
            Waiting approval from Aerscape Data Team
        </p>

        <p className="w-full flex justify-center text-sm">
            We'll review the submitted data and run a few checks.
        </p>

        <p className="w-full flex justify-center text-sm">
            You'll receive an email when your data is approved.
        </p>
    </Card>
);
