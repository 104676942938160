import {
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useCopyToClipboard } from "@uidotdev/usehooks";
import { useDrawingAndFilteringOnMap } from "./hooks";
import { DrawingControl } from "./elements/DrawingControl";
import { DataControls } from "./elements/DataControls";
import { MapViewState } from "./CustomTypes";
import { LoadingIndicator } from "./ui/LoadingIndicator";
import { useSearchParams } from "react-router-dom";
import { deserializeMapStates } from "./utils/state";
import { isValidCoordinates } from "../../utils/geopatialUtils";
import { useMap } from "./hooks/mapState";
import { MapBase } from "./Map";
import { MapSidebar } from "./sidebar/MapSidebar";
import { MapScale } from "./elements/MapScale";
import { ZoomAndLegend } from "./elements/ZoomAndLegend";
import { useMapData, useMapFilters } from "./hooks/mapDataAndFilters";
import {
    useInfrastructureOnMap,
    usePipelinesOnMap,
} from "./hooks/infrastructure";
import { useAppSelector } from "../../hooks";
import type { PickingInfo } from "deck.gl";
import { MapPopup, PickedItems } from "./MapPopup";
import {
    useEmissionRecordsOnMap,
    usePublicDataPointsOnMap,
} from "./hooks/emissions";
import { CROSSHAIR_ICONS, EMISSION_COLORS } from "./constants";

export type MapContext = {
    drawAndFilterControls: ReturnType<typeof useDrawingAndFilteringOnMap>;
    mapState: {
        viewstate: MapViewState;
        areaOnScreen: any;
    };
};
export const MapContext = createContext<MapContext>(undefined);

export const MapView = () => {
    // Utilities
    const [, copyToClipboard] = useCopyToClipboard();
    const [urlParams, setSearchParams] = useSearchParams();
    const userflags = useAppSelector((state) => state.auth.flags);

    // Restore map state from URL if provided
    const deserializedState = useMemo(() => {
        const rawValue = urlParams.get("mapState");
        if (rawValue) {
            try {
                return deserializeMapStates(rawValue);
            } catch {
                return undefined;
            }
        }
        return undefined;
    }, [urlParams]);

    // Lat and lon coordinates from URL
    const startingCoords = useMemo(() => {
        const lat = parseFloat(urlParams.get("lat"));
        const lon = parseFloat(urlParams.get("lon"));
        if (
            Number.isNaN(lat) ||
            Number.isNaN(lon) ||
            !isValidCoordinates(lat, lon)
        ) {
            return undefined;
        }
        return [lon, lat];
    }, [urlParams]);

    // Emission ID from URL
    const initialEmissionId = useMemo(() => {
        return urlParams.get("emissionId");
    }, [urlParams]);

    // Clear the `mapState` and `lat/lon` parameters from the URL
    // to avoid giving the impression it's being
    // update as the user moves the map around.
    useEffect(() => {
        if (
            urlParams.get("emissionId") ||
            urlParams.get("mapState") ||
            urlParams.get("lat") ||
            urlParams.get("lon")
        ) {
            setSearchParams(new URLSearchParams());
        }
    }, [urlParams]);

    // Map state: basemap + viewstate data coming from
    // base map component and state.
    const { _viewState, basemap, debounced, flyTo } = useMap("mainMap", {
        basemap:
            (deserializedState && deserializedState.basemap) ||
            localStorage.getItem("basemap") ||
            null,
        _viewState: {
            longitude: deserializedState
                ? deserializedState.lon
                : startingCoords
                  ? startingCoords[0]
                  : -98.58,
            latitude: deserializedState
                ? deserializedState.lat
                : startingCoords
                  ? startingCoords[1]
                  : 39.82,
            zoom: deserializedState
                ? deserializedState.z
                : startingCoords
                  ? 15
                  : 4,
            bearing: 0,
            pitch: 0,
        },
    });

    // Handles storing the `basemap` on localstorage
    // and updating the value as it changes.
    useEffect(() => {
        if (basemap) {
            localStorage.setItem("basemap", basemap as string);
        } else {
            localStorage.removeItem("basemap");
        }
    }, [basemap]);

    // Drawing controls & filter using area state
    const drawing = useDrawingAndFilteringOnMap();

    // Map context states
    // This is only used in the main map to display the
    // sidebar and hide/show plumes.
    const { setSelectedContext } = useMapData("mainMap", {
        selectedContext: {
            ...(deserializedState?.mapData || {
                emissionRecordId: initialEmissionId,
            }),
        },
    });

    /**
     * Map click handling and context selection section.
     *
     * This is where the map inteligently handles when the user
     * clicks on the map.
     *
     * - If it's a single item, them open sidebar immediately.
     * - If it's nothing, deselect and close sidebar.
     * - If it's more than one item, show a sidebar with options.
     */

    // Store list of items picked when user clicks on screen.
    const [pickedItems, setPickedItems] = useState<PickedItems>();

    /**
     * pickItem
     *
     * Used to handle setting the selected context.
     * It decides if we need to hide a plume and what kind
     * of layer is selected and applied the appropriate
     * data filters.
     */
    const pickItem = useCallback((item: PickingInfo) => {
        let relatedPlume = undefined;
        const layerId = item.layer.id;
        const itemProperties = item.object.properties;
        if (layerId !== "infrastructure" && layerId !== "pipelines") {
            if (itemProperties.plumeImage) {
                relatedPlume = itemProperties.plumeImage;
            } else if (itemProperties.geometry || !itemProperties.plumeImage) {
                relatedPlume = null;
            }
        }

        if (
            layerId == "infrastructure-clusters" ||
            layerId.includes("-emission-clusters")
        ) {
            flyTo(
                item.coordinate[1],
                item.coordinate[0],
                itemProperties.zoom,
                true,
            );
            return;
        }

        setSelectedContext({
            infrastructureId:
                layerId === "infrastructure" || layerId === "pipelines"
                    ? itemProperties.id
                    : undefined,
            emissionRecordId:
                (layerId.includes("emission-points") ||
                    layerId.includes("emission-crosshairs")) &&
                !layerId.includes("datapoint")
                    ? itemProperties.id
                    : undefined,
            dataPointId: layerId.includes("datapoint")
                ? itemProperties.id
                : undefined,
            relatedPlume,
            pipeline:
                layerId === "pipelines-v2"
                    ? {
                          id: itemProperties.id,
                          coordinates: item.coordinate,
                      }
                    : undefined,
        });
        setPickedItems(undefined);
    }, []);

    /**
     * onClickMap
     *
     * Callback for the map's picking event on click, handles the different
     * cases and set's the `pickedItems` value accordingly.
     */
    const onClickMap = useCallback(
        (info: PickingInfo[], event: React.MouseEvent<HTMLDivElement>) => {
            const filteredInfo = info.filter(
                (i) => i.layer.id !== "drawing-layer",
            );
            // If nothing was clicked, clear selection
            if (filteredInfo.length == 0) {
                setPickedItems(undefined);
                setSelectedContext({});
            }
            // If only one item is picked, select context immediately.
            else if (filteredInfo.length == 1) {
                pickItem(filteredInfo[0]);
            }
            // Else, set items and display tooltip at user cursor position.
            else {
                // Keep old behavior if new_picking_menu is disabled.
                // FIXME: remove when approved.
                if (!userflags.includes("new_picking_behavior")) {
                    pickItem(filteredInfo[0]);
                    return;
                }

                // Compute clicked emissions
                const infrastructures = filteredInfo.filter(
                    (i) => i.layer.id === "infrastructure",
                );
                const pipelines = filteredInfo.filter(
                    (i) => i.layer.id === "pipelines-v2",
                );
                const emissions = filteredInfo.filter(
                    (i) =>
                        (i.layer.id.includes("emission-points") ||
                            i.layer.id.includes("emission-crosshairs")) &&
                        !i.layer.id.includes("datapoint"),
                );

                // If there's only one emission (ignore data points), show modal directly
                if (
                    infrastructures.length == 0 &&
                    pipelines.length == 0 &&
                    emissions.length == 1
                ) {
                    pickItem(emissions[0]);
                } else {
                    // Else set state and show modal.
                    setPickedItems({
                        pointClicked: {
                            x: event.clientX,
                            y: event.clientY,
                        },
                        infrastructures,
                        pipelines,
                        emissions,
                        dataPoints:
                            emissions.length == 0
                                ? filteredInfo.filter((i) =>
                                      i.layer.id.includes("datapoint"),
                                  )
                                : [],
                    });
                }
            }
        },
        [],
    );

    // If the map changes (zoom, pan, drag) then clear `pickedItems`.
    // This is a side effect to remove the dropdown if the viewset changes.
    useEffect(() => {
        setPickedItems(undefined);
    }, [_viewState]);

    /**
     * Map data filter values.
     *
     * Stores the values for the filters selected in the map:
     * - Infrastructure: type of infrastructure, show aerial images, etc.
     * - Emissions: Date ranges, provider and type filters.
     */
    const { filterState } = useMapFilters(
        "mainMap",
        deserializedState
            ? {
                  emissions: deserializedState.emissions,
                  infrastructure: deserializedState.infrastructure,
              }
            : undefined,
    );
    // Map data fetching
    // FIXME: too much repetition here, this should be refactored to be simpler,
    // maybe useAerscapeData handling all the data fetching and it's results being passed
    // to useAerscape layers to avoid spreading results everywhere here.
    // TODO: there's a small delay to hide the points because
    // this function is also being responsible for controlling
    // rendering of the layers and depends on the debounced view state
    // FIXME: Maybe return sites + equipments separately and handle
    // hiding/showing while layer rendering instead of inside data fetching?
    const infrastructureMapData = useInfrastructureOnMap(
        filterState.infrastructure.showInfrastructure &&
            filterState.infrastructure.infraTypeFilter.length > 0,
        filterState.infrastructure.infraTypeFilter,
        userflags.includes("enable_infrastructure_overviews"),
        drawing.filterByArea,
    );

    // New pipeline data loader
    const pipelineMapData = usePipelinesOnMap(
        filterState.infrastructure.showInfrastructure &&
            filterState.infrastructure.pipelineProduct.length > 0 &&
            filterState.infrastructure.pipelineType.length > 0,
        filterState.infrastructure.pipelineProduct,
        filterState.infrastructure.pipelineType,
        // TODO: move filtering to backend when using presets
        drawing.filterByArea,
    );

    // Retriving map emission data and computing layers.
    // Third Party
    const emissionsThirdParty = useEmissionRecordsOnMap(
        filterState.emissions.showEmissions,
        "thirdParty",
        userflags.includes("enable_emission_overviews"),
        useMemo(() => {
            return {
                detectionDateRangeAfter:
                    filterState.emissions.startDateFilter !== ""
                        ? new Date(filterState.emissions.startDateFilter)
                        : undefined,
                detectionDateRangeBefore:
                    filterState.emissions.endDateFilter !== ""
                        ? new Date(filterState.emissions.endDateFilter)
                        : undefined,
                providerWithSource:
                    filterState.emissions.emissionGroupFiltersV2.thirdParty,
                dataSource: ["THIRD_PARTY"],
            };
        }, [
            filterState.emissions.startDateFilter,
            filterState.emissions.endDateFilter,
            filterState.emissions.emissionGroupFiltersV2.thirdParty,
        ]),
        EMISSION_COLORS.thirdParty,
        CROSSHAIR_ICONS.thirdParty,
        drawing.filterByArea,
    );

    // Self reported (my company monitoring)
    const emissionsSelfReported = useEmissionRecordsOnMap(
        filterState.emissions.showEmissions,
        "selfReported",
        userflags.includes("enable_emission_overviews"),
        useMemo(() => {
            return {
                detectionDateRangeAfter:
                    filterState.emissions.startDateFilter !== ""
                        ? new Date(filterState.emissions.startDateFilter)
                        : undefined,
                detectionDateRangeBefore:
                    filterState.emissions.endDateFilter !== ""
                        ? new Date(filterState.emissions.endDateFilter)
                        : undefined,
                providerWithSource:
                    filterState.emissions.emissionGroupFiltersV2.selfReported,
                dataSource: ["SELF_REPORTED"],
            };
        }, [
            filterState.emissions.startDateFilter,
            filterState.emissions.endDateFilter,
            filterState.emissions.emissionGroupFiltersV2.selfReported,
        ]),
        EMISSION_COLORS.operatorProvided,
        CROSSHAIR_ICONS.operatorProvided,
        drawing.filterByArea,
    );

    // EPA SEP data
    const emissionsEpa = useEmissionRecordsOnMap(
        filterState.emissions.showEmissions,
        "epa",
        userflags.includes("enable_emission_overviews"),
        useMemo(() => {
            return {
                detectionDateRangeAfter:
                    filterState.emissions.startDateFilter !== ""
                        ? new Date(filterState.emissions.startDateFilter)
                        : undefined,
                detectionDateRangeBefore:
                    filterState.emissions.endDateFilter !== ""
                        ? new Date(filterState.emissions.endDateFilter)
                        : undefined,
                providerWithSource:
                    filterState.emissions.emissionGroupFiltersV2.epa,
                dataSource: ["EPA"],
            };
        }, [
            filterState.emissions.startDateFilter,
            filterState.emissions.endDateFilter,
            filterState.emissions.emissionGroupFiltersV2.epa,
        ]),
        EMISSION_COLORS.epa,
        CROSSHAIR_ICONS.epa,
        drawing.filterByArea,
    );

    // Public emissions
    const publicEmissions = usePublicDataPointsOnMap(
        filterState.emissions.emissionGroupFiltersV2.thirdPartyPublic ==
            "all" ||
            filterState.emissions.emissionGroupFiltersV2.thirdPartyPublic
                .length > 0,
        "public_datapoint",
        useMemo(() => {
            return {
                detectionDateRangeAfter:
                    filterState.emissions.startDateFilter !== ""
                        ? new Date(filterState.emissions.startDateFilter)
                        : undefined,
                detectionDateRangeBefore:
                    filterState.emissions.endDateFilter !== ""
                        ? new Date(filterState.emissions.endDateFilter)
                        : undefined,
                providerWithSource:
                    filterState.emissions.emissionGroupFiltersV2
                        .thirdPartyPublic,
                dataSource: ["THIRD_PARTY"],
            };
        }, [
            filterState.emissions.startDateFilter,
            filterState.emissions.endDateFilter,
            filterState.emissions.emissionGroupFiltersV2.thirdPartyPublic,
        ]),
        EMISSION_COLORS.thirdPartyPublic,
        CROSSHAIR_ICONS.thirdPartyPublic,
        drawing.filterByArea,
    );

    // Layer rendering
    const layers = [
        infrastructureMapData.aerialImageLayers,
        [
            ...publicEmissions.plumeLayers,
            ...emissionsThirdParty.plumeLayers,
            ...emissionsSelfReported.plumeLayers,
            ...emissionsEpa.plumeLayers,
        ],
        pipelineMapData.layers,
        infrastructureMapData.infrastructureLayers,
        [
            ...publicEmissions.emissionLayers,
            ...emissionsThirdParty.emissionLayers,
            ...emissionsSelfReported.emissionLayers,
            ...emissionsEpa.emissionLayers,
        ],
    ];

    const loading =
        infrastructureMapData.loading ||
        pipelineMapData.loading ||
        emissionsThirdParty.loading ||
        emissionsSelfReported.loading ||
        emissionsEpa.loading ||
        publicEmissions.loading;

    // Context
    const contextData: MapContext = {
        drawAndFilterControls: drawing,
        mapState: {
            viewstate: debounced.viewState,
            areaOnScreen: debounced.areaOnScreen,
        },
    };

    // Render component
    return (
        <MapContext.Provider value={contextData}>
            <div className="flex">
                <div className="relative h-screen w-full overflow-hidden">
                    <MapBase
                        mapId="mainMap"
                        layers={[...layers, drawing.drawingLayer]}
                        cursor={drawing.isDrawing ? "crosshair" : "grab"}
                        onRightClick={(info) => {
                            copyToClipboard(
                                info.coordinate.reverse().join(","),
                            );
                        }}
                        onLeftClick={({ info, event }) =>
                            onClickMap(info, event)
                        }
                    />
                    <DataControls />
                    <div className="absolute bottom-6 right-6 flex gap-2 items-end">
                        {loading && <LoadingIndicator />}
                        <MapScale
                            latitude={_viewState.latitude}
                            zoom={_viewState.zoom}
                        />
                        <ZoomAndLegend />
                    </div>
                    <div className="absolute bottom-6 left-6 flex flex-col gap-2">
                        <DrawingControl />
                    </div>
                </div>
                {pickedItems && (
                    <MapPopup
                        picked={pickedItems}
                        onClickItem={(item) => {
                            setPickedItems(undefined);
                            pickItem(item);
                        }}
                    />
                )}
                <MapSidebar />
            </div>
        </MapContext.Provider>
    );
};
