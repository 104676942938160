/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DataPointMapLocation } from './DataPointMapLocation';
import {
    DataPointMapLocationFromJSON,
    DataPointMapLocationFromJSONTyped,
    DataPointMapLocationToJSON,
} from './DataPointMapLocation';

/**
 * Serializer for the EpaNotification model.
 * @export
 * @interface EpaNotification
 */
export interface EpaNotification {
    /**
     * 
     * @type {string}
     * @memberof EpaNotification
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof EpaNotification
     */
    notificationReportId: string;
    /**
     * 
     * @type {Date}
     * @memberof EpaNotification
     */
    detectionDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof EpaNotification
     */
    notificationDate?: Date;
    /**
     * 
     * @type {DataPointMapLocation}
     * @memberof EpaNotification
     */
    location: DataPointMapLocation;
    /**
     * 
     * @type {number}
     * @memberof EpaNotification
     */
    detectedRate: number;
    /**
     * 
     * @type {string}
     * @memberof EpaNotification
     */
    notifierName: string;
    /**
     * 
     * @type {string}
     * @memberof EpaNotification
     */
    providerName: string;
    /**
     * 
     * @type {Date}
     * @memberof EpaNotification
     */
    readonly createdAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof EpaNotification
     */
    readonly updatedAt: Date | null;
}

/**
 * Check if a given object implements the EpaNotification interface.
 */
export function instanceOfEpaNotification(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('notificationReportId' in value)) return false;
    if (!('detectionDate' in value)) return false;
    if (!('location' in value)) return false;
    if (!('detectedRate' in value)) return false;
    if (!('notifierName' in value)) return false;
    if (!('providerName' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    return true;
}

export function EpaNotificationFromJSON(json: any): EpaNotification {
    return EpaNotificationFromJSONTyped(json, false);
}

export function EpaNotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EpaNotification {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'notificationReportId': json['notification_report_id'],
        'detectionDate': (new Date(json['detection_date'])),
        'notificationDate': json['notification_date'] == null ? undefined : (new Date(json['notification_date'])),
        'location': DataPointMapLocationFromJSON(json['location']),
        'detectedRate': json['detected_rate'],
        'notifierName': json['notifier_name'],
        'providerName': json['provider_name'],
        'createdAt': (json['created_at'] == null ? null : new Date(json['created_at'])),
        'updatedAt': (json['updated_at'] == null ? null : new Date(json['updated_at'])),
    };
}

export function EpaNotificationToJSON(value?: Omit<EpaNotification, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'notification_report_id': value['notificationReportId'],
        'detection_date': ((value['detectionDate']).toISOString()),
        'notification_date': value['notificationDate'] == null ? undefined : ((value['notificationDate'] as any).toISOString()),
        'location': DataPointMapLocationToJSON(value['location']),
        'detected_rate': value['detectedRate'],
        'notifier_name': value['notifierName'],
        'provider_name': value['providerName'],
    };
}

