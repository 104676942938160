/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DataPointMapLocation } from './DataPointMapLocation';
import {
    DataPointMapLocationFromJSON,
    DataPointMapLocationFromJSONTyped,
    DataPointMapLocationToJSON,
} from './DataPointMapLocation';

/**
 * Serializer for the EpaNotification model.
 * @export
 * @interface EpaNotificationRequest
 */
export interface EpaNotificationRequest {
    /**
     * 
     * @type {string}
     * @memberof EpaNotificationRequest
     */
    notificationReportId: string;
    /**
     * 
     * @type {Date}
     * @memberof EpaNotificationRequest
     */
    detectionDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof EpaNotificationRequest
     */
    notificationDate?: Date;
    /**
     * 
     * @type {DataPointMapLocation}
     * @memberof EpaNotificationRequest
     */
    location: DataPointMapLocation;
    /**
     * 
     * @type {number}
     * @memberof EpaNotificationRequest
     */
    detectedRate: number;
    /**
     * 
     * @type {string}
     * @memberof EpaNotificationRequest
     */
    notifierName: string;
    /**
     * 
     * @type {string}
     * @memberof EpaNotificationRequest
     */
    providerName: string;
}

/**
 * Check if a given object implements the EpaNotificationRequest interface.
 */
export function instanceOfEpaNotificationRequest(value: object): boolean {
    if (!('notificationReportId' in value)) return false;
    if (!('detectionDate' in value)) return false;
    if (!('location' in value)) return false;
    if (!('detectedRate' in value)) return false;
    if (!('notifierName' in value)) return false;
    if (!('providerName' in value)) return false;
    return true;
}

export function EpaNotificationRequestFromJSON(json: any): EpaNotificationRequest {
    return EpaNotificationRequestFromJSONTyped(json, false);
}

export function EpaNotificationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EpaNotificationRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'notificationReportId': json['notification_report_id'],
        'detectionDate': (new Date(json['detection_date'])),
        'notificationDate': json['notification_date'] == null ? undefined : (new Date(json['notification_date'])),
        'location': DataPointMapLocationFromJSON(json['location']),
        'detectedRate': json['detected_rate'],
        'notifierName': json['notifier_name'],
        'providerName': json['provider_name'],
    };
}

export function EpaNotificationRequestToJSON(value?: EpaNotificationRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'notification_report_id': value['notificationReportId'],
        'detection_date': ((value['detectionDate']).toISOString()),
        'notification_date': value['notificationDate'] == null ? undefined : ((value['notificationDate'] as any).toISOString()),
        'location': DataPointMapLocationToJSON(value['location']),
        'detected_rate': value['detectedRate'],
        'notifier_name': value['notifierName'],
        'provider_name': value['providerName'],
    };
}

