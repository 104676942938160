/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NonDetectGeometry } from './NonDetectGeometry';
import {
    NonDetectGeometryFromJSON,
    NonDetectGeometryFromJSONTyped,
    NonDetectGeometryToJSON,
} from './NonDetectGeometry';

/**
 * Serializer for the NonDetect model.
 * @export
 * @interface NonDetect
 */
export interface NonDetect {
    /**
     * 
     * @type {number}
     * @memberof NonDetect
     */
    readonly id: number;
    /**
     * 
     * @type {Date}
     * @memberof NonDetect
     */
    timestampFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof NonDetect
     */
    timestampTo: Date;
    /**
     * 
     * @type {NonDetectGeometry}
     * @memberof NonDetect
     */
    geometry: NonDetectGeometry;
    /**
     * 
     * @type {Date}
     * @memberof NonDetect
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof NonDetect
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof NonDetect
     */
    scene: string;
}

/**
 * Check if a given object implements the NonDetect interface.
 */
export function instanceOfNonDetect(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('timestampFrom' in value)) return false;
    if (!('timestampTo' in value)) return false;
    if (!('geometry' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    if (!('scene' in value)) return false;
    return true;
}

export function NonDetectFromJSON(json: any): NonDetect {
    return NonDetectFromJSONTyped(json, false);
}

export function NonDetectFromJSONTyped(json: any, ignoreDiscriminator: boolean): NonDetect {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'timestampFrom': (new Date(json['timestamp_from'])),
        'timestampTo': (new Date(json['timestamp_to'])),
        'geometry': NonDetectGeometryFromJSON(json['geometry']),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'scene': json['scene'],
    };
}

export function NonDetectToJSON(value?: Omit<NonDetect, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'timestamp_from': ((value['timestampFrom']).toISOString()),
        'timestamp_to': ((value['timestampTo']).toISOString()),
        'geometry': NonDetectGeometryToJSON(value['geometry']),
        'scene': value['scene'],
    };
}

