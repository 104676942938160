/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AerialImagesList } from './AerialImagesList';
import {
    AerialImagesListFromJSON,
    AerialImagesListFromJSONTyped,
    AerialImagesListToJSON,
} from './AerialImagesList';

/**
 * 
 * @export
 * @interface PaginatedAerialImagesListList
 */
export interface PaginatedAerialImagesListList {
    /**
     * 
     * @type {string}
     * @memberof PaginatedAerialImagesListList
     */
    next?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAerialImagesListList
     */
    previous?: string;
    /**
     * 
     * @type {Array<AerialImagesList>}
     * @memberof PaginatedAerialImagesListList
     */
    results: Array<AerialImagesList>;
}

/**
 * Check if a given object implements the PaginatedAerialImagesListList interface.
 */
export function instanceOfPaginatedAerialImagesListList(value: object): boolean {
    if (!('results' in value)) return false;
    return true;
}

export function PaginatedAerialImagesListListFromJSON(json: any): PaginatedAerialImagesListList {
    return PaginatedAerialImagesListListFromJSONTyped(json, false);
}

export function PaginatedAerialImagesListListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedAerialImagesListList {
    if (json == null) {
        return json;
    }
    return {
        
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(AerialImagesListFromJSON)),
    };
}

export function PaginatedAerialImagesListListToJSON(value?: PaginatedAerialImagesListList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(AerialImagesListToJSON)),
    };
}

