/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DataImport } from './DataImport';
import {
    DataImportFromJSON,
    DataImportFromJSONTyped,
    DataImportToJSON,
} from './DataImport';

/**
 * 
 * @export
 * @interface PaginatedDataImportList
 */
export interface PaginatedDataImportList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedDataImportList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedDataImportList
     */
    next?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedDataImportList
     */
    previous?: string;
    /**
     * 
     * @type {Array<DataImport>}
     * @memberof PaginatedDataImportList
     */
    results: Array<DataImport>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedDataImportList
     */
    numPages?: number;
}

/**
 * Check if a given object implements the PaginatedDataImportList interface.
 */
export function instanceOfPaginatedDataImportList(value: object): boolean {
    if (!('count' in value)) return false;
    if (!('results' in value)) return false;
    return true;
}

export function PaginatedDataImportListFromJSON(json: any): PaginatedDataImportList {
    return PaginatedDataImportListFromJSONTyped(json, false);
}

export function PaginatedDataImportListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedDataImportList {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(DataImportFromJSON)),
        'numPages': json['num_pages'] == null ? undefined : json['num_pages'],
    };
}

export function PaginatedDataImportListToJSON(value?: PaginatedDataImportList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(DataImportToJSON)),
        'num_pages': value['numPages'],
    };
}

