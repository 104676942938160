/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EpaSepStatsBarResponse
 */
export interface EpaSepStatsBarResponse {
    /**
     * 
     * @type {number}
     * @memberof EpaSepStatsBarResponse
     */
    companyCount: number;
    /**
     * 
     * @type {number}
     * @memberof EpaSepStatsBarResponse
     */
    totalCount: number;
    /**
     * 
     * @type {number}
     * @memberof EpaSepStatsBarResponse
     */
    avgRate: number;
    /**
     * 
     * @type {number}
     * @memberof EpaSepStatsBarResponse
     */
    highestRate: number;
    /**
     * 
     * @type {number}
     * @memberof EpaSepStatsBarResponse
     */
    avgResponseTimeDays: number;
    /**
     * 
     * @type {number}
     * @memberof EpaSepStatsBarResponse
     */
    avgCompletionTimeDays: number;
}

/**
 * Check if a given object implements the EpaSepStatsBarResponse interface.
 */
export function instanceOfEpaSepStatsBarResponse(value: object): boolean {
    if (!('companyCount' in value)) return false;
    if (!('totalCount' in value)) return false;
    if (!('avgRate' in value)) return false;
    if (!('highestRate' in value)) return false;
    if (!('avgResponseTimeDays' in value)) return false;
    if (!('avgCompletionTimeDays' in value)) return false;
    return true;
}

export function EpaSepStatsBarResponseFromJSON(json: any): EpaSepStatsBarResponse {
    return EpaSepStatsBarResponseFromJSONTyped(json, false);
}

export function EpaSepStatsBarResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EpaSepStatsBarResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'companyCount': json['company_count'],
        'totalCount': json['total_count'],
        'avgRate': json['avg_rate'],
        'highestRate': json['highest_rate'],
        'avgResponseTimeDays': json['avg_response_time_days'],
        'avgCompletionTimeDays': json['avg_completion_time_days'],
    };
}

export function EpaSepStatsBarResponseToJSON(value?: EpaSepStatsBarResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'company_count': value['companyCount'],
        'total_count': value['totalCount'],
        'avg_rate': value['avgRate'],
        'highest_rate': value['highestRate'],
        'avg_response_time_days': value['avgResponseTimeDays'],
        'avg_completion_time_days': value['avgCompletionTimeDays'],
    };
}

