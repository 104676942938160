import { XCircleIcon } from "@heroicons/react/20/solid";
import { ArrowPathIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

const passwordConditions = [
    {
        label: "Minimum of 12 characters.",
        check: (password: string) => {
            return password.trim().length > 12;
        },
    },
    {
        label: "At least 1 lowercase letter.",
        check: (password: string) => {
            return /[a-z]/.test(password);
        },
    },
    {
        label: "At least 1 uppercase letter.",
        check: (password: string) => {
            return /[A-Z]/.test(password);
        },
    },
    {
        label: "At least 1 number.",
        check: (password: string) => {
            return /[0-9]/.test(password);
        },
    },
    {
        label: "At least 1 special character.",
        check: (password: string) => {
            return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);
        },
    },
];

interface Props {
    loading: boolean;
    error?: string;
    setPassword: (newPassword: string) => void;
}

export const NewPasswordForm = (props: Props) => {
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    let validPassword = true;
    const conditionChecks = passwordConditions.map((passwordCondition) => {
        const passCheck = passwordCondition.check(password);
        if (!passCheck) {
            validPassword = false;
        }
        return {
            label: passwordCondition.label,
            pass: passCheck,
        };
    });

    return (
        <>
            <h2 className="mt-12 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Set new password
            </h2>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="space-y-6">
                    <div>
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            New password
                        </label>
                        <div className="mt-2">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="password"
                                disabled={props.loading}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        {conditionChecks.map(({ label, pass }) => (
                            <div
                                key={label}
                                className="flex items-center space-x-2 mb-1"
                            >
                                {pass ? (
                                    <CheckCircleIcon className="h-6 w-6 text-green-500" />
                                ) : (
                                    <XCircleIcon className="h-6 w-6 text-red-500" />
                                )}
                                {label}
                            </div>
                        ))}
                    </div>

                    <div>
                        <label
                            htmlFor="passwordConfirmation"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Confirm password
                        </label>
                        <div className="mt-2 flex">
                            <input
                                id="passwordConfirmation"
                                name="passwordConfirmation"
                                type="password"
                                autoComplete="passwordConfirmation"
                                disabled={props.loading}
                                value={passwordConfirmation}
                                onChange={(e) =>
                                    setPasswordConfirmation(e.target.value)
                                }
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        if (
                                            !props.loading &&
                                            validPassword &&
                                            password === passwordConfirmation
                                        ) {
                                            props.setPassword(password);
                                        }
                                    }
                                }}
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        {password === passwordConfirmation && (
                            <div className="flex items-center mt-2">
                                <CheckCircleIcon className="mr-2 h-6 w-6 text-green-500" />
                                Password confirmation matches
                            </div>
                        )}
                    </div>

                    <div>
                        <button
                            type="submit"
                            disabled={
                                props.loading ||
                                !(
                                    validPassword &&
                                    password === passwordConfirmation
                                )
                            }
                            onClick={() => props.setPassword(password)}
                            className="flex w-full justify-center rounded-md transition-all duration-800 bg-slate-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-700"
                        >
                            {props.loading ? (
                                <ArrowPathIcon className="animate-spin h-6" />
                            ) : (
                                "Reset password"
                            )}
                        </button>
                    </div>

                    {props.error && (
                        <div className="px-4 py-2 bg-red-500 rounded-lg text-white">
                            {props.error}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
