import { PermissionGate } from "./PermissionGate";
import { InfrastructureImportApiDataTable } from "../components/InfrastructureDataTable/InfrastructureImportDataTable";
import { useNavigate } from "react-router-dom";
import { ROUTES } from ".";
import { DashboardContainer, DashboardHeader } from "../ui/DashboardComponents";
import { PlusIcon } from "@heroicons/react/24/outline";

export const InfrastructureImportPage = () => {
    const navigate = useNavigate();
    return (
        <PermissionGate permission="infrastructureBuilderView">
            <DashboardContainer>
                <DashboardHeader
                    title="Manage Infrastructure"
                    description="Use this page to view and act on infrastructure import processes."
                    actions={[
                        {
                            fn: () =>
                                navigate(
                                    ROUTES.INFRASTRUCURE_BUILDER.replace(
                                        ":id",
                                        "new",
                                    ),
                                ),
                            label: (
                                <span className="flex items-center gap-2">
                                    <PlusIcon className="w-5 h-5" />
                                    New Infrastructure Import
                                </span>
                            ),
                        },
                    ]}
                />
                <InfrastructureImportApiDataTable />
            </DashboardContainer>
        </PermissionGate>
    );
};
