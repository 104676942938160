import {
    InfrastructureImport,
    InfrastructureImportItem,
} from "../../../apiClient/generated";
import "reactflow/dist/style.css";

import React, { useCallback, useEffect, useState } from "react";
import ReactFlow, {
    ReactFlowProvider,
    Panel,
    useNodesState,
    useEdgesState,
    useReactFlow,
    Background,
    Controls,
    MarkerType,
    addEdge,
    MiniMap,
    Connection,
    Edge,
} from "reactflow";
import {
    ArrowPathIcon,
    ArrowsPointingOutIcon,
    BoltIcon,
    QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { DetailView } from "../../../ui/DetailView";
import { CustomNode } from "./CustomNode";
import { CustomSwitch } from "../../../ui/CustomSwitch";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import * as d3 from "d3";
import { GRAPH_EDITOR_MAX_ITEMS, useInfrastructureImportGraph } from "./hooks";
import { XMarkIcon } from "@heroicons/react/24/solid";

const customNodeTypes = {
    custom: CustomNode,
};

const infraTypeMap = {
    PIPELINE: 0,
    SITE: 1,
    EQUIPMENT_GROUP: 2,
    EQUIPMENT: 3,
    EQUIPMENT_COMPONENT: 4,
};

const getLayoutedElementsV2 = (nodes: InfrastructureImportItem[]) => {
    const data = {};
    let remainingNodes = [...nodes];
    const trees = [];

    const getChildren = (parentId: number) => {
        return remainingNodes.filter((item) => item.parent === parentId);
    };

    // Build lookup dictionaries
    nodes.forEach((item) => {
        if (!item.parent) {
            const tree = [item, ...getChildren(item.id)];
            trees.push(tree);
            remainingNodes = remainingNodes.filter(
                (item) => !tree.map((i) => i.id).includes(item.id),
            );
        }
        data[item.id] = item;
    });

    // Go through remaining items
    while (remainingNodes.length > 0) {
        trees.forEach((tree) => {
            tree.forEach((item) => {
                const children = getChildren(item.id);
                children.forEach((i) => tree.push(i));
                remainingNodes = remainingNodes.filter(
                    (item) => !children.map((i) => i.id).includes(item.id),
                );
            });
        });
    }

    // Separate tree with a single item, we'll rank those manually.
    const itemsWithoutParents = [];
    const fullTrees = [];
    trees.forEach((tree) => {
        if (tree.length > 1) {
            fullTrees.push(tree);
        } else {
            itemsWithoutParents.push(tree);
        }
    });

    // Export ReactFlow nodes
    const reactFlowNodes = [];
    const reactFlowEdges = [];

    // Depth map
    const depthMap = {
        PIPELINE: 0,
        SITE: 200,
        EQUIPMENT_GROUP: 400,
        EQUIPMENT: 600,
        EQUIPMENT_COMPONENT: 800,
    };

    // A recursive function to process nodes
    const processNode = (node, depth = 0) => {
        // Create a node for React Flow
        reactFlowNodes.push({
            id: `${node.data.id}`,
            type: "custom",
            data: node.data,
            position: {
                y: depthMap[node.data.infraType] || depth * 200,
                x: node.x,
            },
            deletable: false,
        });

        // Process children
        if (node.children) {
            node.children.forEach((child) => {
                // Create an edge
                reactFlowEdges.push({
                    id: `e${node.data.id}-${child.data.id}`,
                    source: `${node.data.id}`,
                    target: `${child.data.id}`,
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                    },
                });

                // Recursively process the child
                processNode(child, depth + 1);
            });
        }
    };

    // Store offset to properly position all trees on map
    let xOffset = 0;

    // Create d3 tree using stratify for each tree
    fullTrees.forEach((tree) => {
        const d3Tree = d3.cluster().nodeSize([250, 200]);
        const parentData = d3
            .stratify<InfrastructureImportItem>()
            .id((d) => `${d.id}`)
            .parentId((d) => (d.parent ? `${d.parent}` : undefined))(tree);
        const processedTree = d3Tree(parentData);

        // Compute tree width
        let minX = Infinity;
        let maxX = -Infinity;
        processedTree.descendants().forEach((node) => {
            if (node.x < minX) minX = node.x;
            if (node.x > maxX) maxX = node.x;
        });

        // Offset tree so that all nodes start at 0
        processedTree.descendants().forEach((node) => {
            node.x = node.x - minX + xOffset;
        });

        // Update the offset for next tree
        xOffset += maxX - minX + 250;

        // Convert to ReactFlow data structure
        processNode(processedTree);
    });

    // Compute tree max X
    let maxX = -Infinity;
    reactFlowNodes.forEach((node) => {
        if (node.position.x > maxX) maxX = node.position.x;
    });
    const endOfGraph = maxX === -Infinity ? 0 : maxX + 350;

    // Add items without parents.
    const xIndexTracker = {};
    itemsWithoutParents.forEach((tree) => {
        const item = tree[0];
        const y = depthMap[item.infraType] || 0;
        const xIndex = xIndexTracker[item.infraType] || 0;
        const x = endOfGraph + xIndex * 250;
        reactFlowNodes.push({
            id: `${item.id}_${new Date().toISOString()}`,
            type: "custom",
            data: item,
            position: { y, x },
            deletable: false,
        });
        xIndexTracker[item.infraType] = xIndex + 1;
    });

    return {
        newNodes: reactFlowNodes,
        newEdges: reactFlowEdges,
    };
};

interface LayoutFlowProps {
    importData: InfrastructureImport;
}

const LayoutFlow = (props: LayoutFlowProps) => {
    const { fitView } = useReactFlow();

    // Data states
    const [showWithoutParent, setShowWithoutParent] = useState(true);
    const [siteFilter, setSiteFilter] = useState<string>();

    // Display states
    const [showHelp, setShowHelp] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showConnectionWarning, setShowConnectionWarning] = useState(false);

    // Custom hook to load data from backend
    const {
        graphItems,
        sitesAndPipelines,
        loadingData,
        infraCount,
        refreshData,
        onChangeRelationship,
    } = useInfrastructureImportGraph({
        relatedImport: props.importData,
        showWithoutParent,
        siteFilter,
    });

    // React flow and display states
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    // When edges change
    // TODO: Improve error handling here
    const onConnect = useCallback(
        async (params) => {
            setLoading(true);
            try {
                // Find item ids and make API call.
                const itemId = parseInt(params.target);
                const newParent = parseInt(params.source);
                await onChangeRelationship(itemId, newParent);
                setEdges((els) => addEdge(params, els));
                setNodes((nodes) =>
                    nodes.map((n) => {
                        if (n.data.id === itemId) {
                            return {
                                ...n,
                                data: {
                                    ...n.data,
                                    parent: newParent,
                                },
                            };
                        }
                        return n;
                    }),
                );
            } catch (e) {
                alert("Error while updating node, try again.");
                refreshData();
            }
            setLoading(false);
        },
        [setEdges, setNodes],
    );

    const onDelete = useCallback(
        async (edgesToDelete: Edge[]) => {
            setLoading(true);
            for (const edge of edgesToDelete) {
                try {
                    const itemId = parseInt(edge.target);
                    await onChangeRelationship(itemId, null);
                    setEdges((edges) =>
                        edges.filter((ed) => ed.id !== edge.id),
                    );
                    setNodes((nodes) =>
                        nodes.map((n) => {
                            if (n.data.id === itemId) {
                                return {
                                    ...n,
                                    data: {
                                        ...n.data,
                                        parent: undefined,
                                    },
                                };
                            }
                            return n;
                        }),
                    );
                } catch (e) {
                    alert("Error while updating node, try again.");
                    refreshData();
                }
            }
            setLoading(false);
        },
        [setEdges, setNodes],
    );

    const checkConnectionValid = useCallback(
        (connection: Connection): boolean => {
            const sourceNode = nodes.find((n) => n.id === connection.source);
            const targetNode = nodes.find((n) => n.id === connection.target);
            const isValid =
                infraTypeMap[sourceNode.data.infraType] <
                infraTypeMap[targetNode.data.infraType];
            setShowConnectionWarning(!isValid);
            return isValid;
        },
        [nodes],
    );

    // Hide message after a few seconds
    useEffect(() => {
        if (showConnectionWarning) {
            const timeout = setTimeout(
                () => setShowConnectionWarning(false),
                3000,
            );
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [showConnectionWarning]);

    // When data changes.
    useEffect(() => {
        setLoading(true);
        if (graphItems && graphItems.length > 0) {
            // Compute nodes from infrastructure data
            const { newNodes, newEdges } = getLayoutedElementsV2(graphItems);
            setNodes(newNodes);
            setEdges(newEdges);
            setTimeout(() => fitView(), 100);
        }
        setLoading(false);
    }, [graphItems]);

    // Recompute layout
    const onLayout = useCallback(() => {
        if (nodes && nodes.length > 0) {
            // Compute nodes from infrastructure data
            const { newNodes, newEdges } = getLayoutedElementsV2(
                nodes.map((n) => n.data),
            );
            setNodes(newNodes);
            setEdges(newEdges);
            setTimeout(() => fitView(), 100);
        }
    }, [nodes, edges]);

    // React Flow component
    const reactFlowComponent = (
        <>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnectStart={() => setShowConnectionWarning(false)}
                edgeUpdaterRadius={60}
                connectionRadius={60}
                onEdgesDelete={onDelete}
                onConnect={onConnect}
                nodeTypes={customNodeTypes}
                onEdgeDoubleClick={(e, edge) => onDelete([edge])}
                isValidConnection={checkConnectionValid}
                minZoom={0.3}
                snapToGrid
                fitView
            >
                <Panel
                    position="top-right"
                    className="flex items-center text-sm"
                >
                    {siteFilter && (
                        <div className="flex items-center mr-4 bg-slate-200 px-3 py-2 rounded-lg h-10">
                            <CustomSwitch
                                checked={showWithoutParent}
                                onChange={() =>
                                    setShowWithoutParent(!showWithoutParent)
                                }
                                size="sm"
                            />
                            <span className="ml-2">
                                Include items without any parents
                            </span>
                        </div>
                    )}
                    <div className="flex items-center">
                        <select
                            key={`select_key__${siteFilter}`}
                            className="rounded-lg w-36 text-sm bg-slate-200 hover:bg-slate-300 border-transparent h-10"
                            value={siteFilter}
                            onChange={(e) => setSiteFilter(e.target.value)}
                        >
                            <option value={""}>Filter by Site</option>
                            {sitesAndPipelines.map((site) => (
                                <option value={site.id}>
                                    {site.siteName} - {site.placeholderId} (
                                    {site.infraType
                                        .split("_")
                                        .join(" ")
                                        .toLowerCase()}
                                    )
                                </option>
                            ))}
                        </select>
                    </div>
                </Panel>

                <Panel position="top-left">
                    <button
                        onClick={onLayout}
                        className="mb-2 flex h-10 px-3 py-2 text-xs items-center rounded-lg bg-slate-200 hover:bg-slate-600 hover:text-white"
                    >
                        <BoltIcon className="w-4 h-4 mr-1" />
                        Auto-organize
                    </button>

                    {!fullScreen && (
                        <button
                            onClick={() => setFullScreen(true)}
                            className="flex h-10 px-3 py-2 text-xs mb-2 items-center rounded-lg bg-slate-200 hover:bg-slate-600 hover:text-white"
                        >
                            <ArrowsPointingOutIcon className="w-4 h-4 mr-1" />
                            Full Screen
                        </button>
                    )}

                    <button
                        onClick={refreshData}
                        className="mb-2 flex h-10 px-3 py-2 text-xs items-center rounded-lg bg-slate-200 hover:bg-slate-600 hover:text-white"
                    >
                        <ArrowPathIcon className="w-4 h-4 mr-1" />
                        Refresh view
                    </button>

                    <button
                        onClick={() => setShowHelp(true)}
                        className="mb-2 flex h-10 px-3 py-2 text-xs items-center rounded-lg bg-slate-200 hover:bg-slate-600 hover:text-white"
                    >
                        <QuestionMarkCircleIcon className="w-4 h-4 mr-1" />
                        Help
                    </button>
                </Panel>
                <Background />
                <Controls />
                {fullScreen && (
                    <MiniMap
                        nodeColor="#000000"
                        maskColor="rgb(56,56,56,0.6)"
                        pannable
                        zoomable
                    />
                )}
                {showConnectionWarning && (
                    <Panel position="bottom-center">
                        <div className="flex items-center p-2 text-base bg-yellow-300 rounded-lg shadow-xl">
                            <ExclamationTriangleIcon className="text-black h-7 w-7" />
                            You can't connect this component to this parent.
                        </div>
                    </Panel>
                )}
            </ReactFlow>
            {(loading || loadingData) && (
                <div className="absolute top-0 left-0 w-full h-full rounded-3xl flex items-center justify-center opacity-90 bg-white">
                    <ArrowPathIcon className="w-10 h-10 animate-spin ml-5" />
                </div>
            )}
            {showHelp && (
                <div
                    onClick={() => setShowHelp(false)}
                    className="absolute top-0 left-0 w-full h-full rounded-3xl flex items-center justify-center opacity-90 bg-white"
                >
                    <div className="text-base max-w-lg opacity-100">
                        <ul className="list-disc">
                            <li>
                                Move the infrastructure items by dragging the
                                boxes.
                            </li>
                            <li>
                                Create connections by clicking and dragging from
                                the gray sections at the top/bottom of the
                                infrastructure items.
                            </li>
                            <li>
                                Delete connections by double clicking the
                                connecting lines or selecting them and pressing{" "}
                                <i>Delete/Backspace</i>.
                            </li>
                            <li>
                                Select multiple connections holding{" "}
                                <i>Command/Ctrl</i> and clicking on the
                                connecting lines.
                            </li>
                        </ul>
                        <button
                            onClick={() => setShowHelp(false)}
                            className="flex mt-4 bg-slate-200 p-2 rounded-lg"
                        >
                            <XMarkIcon className="w-6 h-6 text-red-500" />
                            Close help
                        </button>
                    </div>
                </div>
            )}
            {infraCount > GRAPH_EDITOR_MAX_ITEMS && !siteFilter && (
                <div className="absolute top-0 rounded-3xl left-0 opacity-90 w-full h-full flex flex-col items-center justify-center bg-white">
                    <p>
                        This import has more than {GRAPH_EDITOR_MAX_ITEMS} items
                        and cannot be fully displayed here.
                    </p>
                    <p>
                        Please select a site using the filter below to continue.
                    </p>
                    <select
                        className="rounded-lg mt-2 w-36 text-sm bg-slate-200 hover:bg-slate-300 border-transparent h-10"
                        value={siteFilter}
                        onChange={(e) => setSiteFilter(e.target.value)}
                    >
                        <option value={""}>Filter by Site</option>
                        {sitesAndPipelines.map((site) => (
                            <option value={site.id}>
                                {site.siteName} - {site.placeholderId} (
                                {site.infraType
                                    .split("_")
                                    .join(" ")
                                    .toLowerCase()}
                                )
                            </option>
                        ))}
                    </select>
                </div>
            )}
        </>
    );

    return (
        <>
            {fullScreen ? (
                <DetailView
                    title="Graph editor"
                    visible={fullScreen}
                    onClose={() => setFullScreen(false)}
                >
                    <div className="h-[800px]">{reactFlowComponent}</div>
                </DetailView>
            ) : (
                <div className="h-[600px]">{reactFlowComponent}</div>
            )}
        </>
    );
};

interface RelationshipBuilderGraphViewProps {
    importData: InfrastructureImport;
    refresh?: () => void;
}

export const RelationshipBuilderGraphView = (
    props: RelationshipBuilderGraphViewProps,
) => {
    return (
        <ReactFlowProvider>
            <LayoutFlow importData={props.importData} />
        </ReactFlowProvider>
    );
};
