/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `NEAR_MATCH` - Near Match
 * * `MATCHED` - Matched
 * * `ACCEPTED` - Accepted
 * * `REJECTED` - Rejected
 * @export
 */
export const EmissionStatusEnum = {
    NearMatch: 'NEAR_MATCH',
    Matched: 'MATCHED',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;
export type EmissionStatusEnum = typeof EmissionStatusEnum[keyof typeof EmissionStatusEnum];


export function instanceOfEmissionStatusEnum(value: any): boolean {
    return Object.values(EmissionStatusEnum).includes(value);
}

export function EmissionStatusEnumFromJSON(json: any): EmissionStatusEnum {
    return EmissionStatusEnumFromJSONTyped(json, false);
}

export function EmissionStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmissionStatusEnum {
    return json as EmissionStatusEnum;
}

export function EmissionStatusEnumToJSON(value?: EmissionStatusEnum | null): any {
    return value as any;
}

