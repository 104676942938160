import { MapView } from "../components/MapV2/MapView";
import { PermissionGate } from "./PermissionGate";

export const MapPage = () => {
    return (
        <PermissionGate permission="mapView">
            <MapView />
        </PermissionGate>
    );
};
