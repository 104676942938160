/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Pipeline Segment serializer, used to display table and
 * detailed infrastructure information.
 * @export
 * @interface PipelineSegment
 */
export interface PipelineSegment {
    /**
     * 
     * @type {string}
     * @memberof PipelineSegment
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineSegment
     */
    pipeline: string;
    /**
     * Pipeline diameter in inches.
     * @type {number}
     * @memberof PipelineSegment
     */
    diameter?: number;
    /**
     * 
     * @type {string}
     * @memberof PipelineSegment
     */
    material?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PipelineSegment
     */
    aboveGround?: boolean;
    /**
     * 
     * @type {any}
     * @memberof PipelineSegment
     */
    extraData?: any;
}

/**
 * Check if a given object implements the PipelineSegment interface.
 */
export function instanceOfPipelineSegment(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('pipeline' in value)) return false;
    return true;
}

export function PipelineSegmentFromJSON(json: any): PipelineSegment {
    return PipelineSegmentFromJSONTyped(json, false);
}

export function PipelineSegmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PipelineSegment {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'pipeline': json['pipeline'],
        'diameter': json['diameter'] == null ? undefined : json['diameter'],
        'material': json['material'] == null ? undefined : json['material'],
        'aboveGround': json['above_ground'] == null ? undefined : json['above_ground'],
        'extraData': json['extra_data'] == null ? undefined : json['extra_data'],
    };
}

export function PipelineSegmentToJSON(value?: Omit<PipelineSegment, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pipeline': value['pipeline'],
        'diameter': value['diameter'],
        'material': value['material'],
        'above_ground': value['aboveGround'],
        'extra_data': value['extraData'],
    };
}

