/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Provider serializer with count.
 * @export
 * @interface Provider
 */
export interface Provider {
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    provider: string;
    /**
     * 
     * @type {number}
     * @memberof Provider
     */
    count: number;
}

/**
 * Check if a given object implements the Provider interface.
 */
export function instanceOfProvider(value: object): boolean {
    if (!('provider' in value)) return false;
    if (!('count' in value)) return false;
    return true;
}

export function ProviderFromJSON(json: any): Provider {
    return ProviderFromJSONTyped(json, false);
}

export function ProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Provider {
    if (json == null) {
        return json;
    }
    return {
        
        'provider': json['provider'],
        'count': json['count'],
    };
}

export function ProviderToJSON(value?: Provider | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'provider': value['provider'],
        'count': value['count'],
    };
}

