import { ComponentType } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp, IconDefinition } from "@fortawesome/fontawesome-svg-core";

interface Props {
    icon: ComponentType<any> | IconDefinition;
    className?: string;
}

/**
 * Render a unified icon component that can handle both font awesome and heroicons.
 */
export const UnifiedIcon = ({ icon, className }: Props) => {
    // typeguard for font awesome IconDefinition
    if ((icon as IconDefinition).prefix) {
        return (
            <FontAwesomeIcon icon={icon as IconProp} className={className} />
        );
    }
    const IconComponent = icon as ComponentType<any>;
    return <IconComponent className={className} />;
};
