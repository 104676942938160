import { useEffect } from "react";
import { Navigate } from "react-router";
import { useAppDispatch, useAppSelector, useAuthApiClient } from "../../hooks";
import { ROUTES } from "../../routes";
import { logInWithToken } from "../../store/auth";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

export const SSOComplete = () => {
    const isLoggedIn = useAppSelector((state) => state.auth.loggedIn);
    const dispatch = useAppDispatch();
    const apiClient = useAuthApiClient();

    const [searchParams] = useSearchParams();
    const { provider, code } = useParams();

    const query = useQuery({
        queryKey: ["ssoRetrieveToken"],
        queryFn: async () => {
            const response = await apiClient.authSsoRetrieveTokenRetrieve({
                provider,
                code,
            });
            return response.accessToken;
        },
    });

    useEffect(() => {
        if (query.data) {
            dispatch(logInWithToken(query.data));
        }
    }, [query.data, dispatch]);

    if (!(provider && code)) {
        return <Navigate to={ROUTES.LOGIN} />;
    }

    const nextParam = searchParams.get("next");

    // Redirect to dashboard if logged in
    if (isLoggedIn) {
        let nextPage = ROUTES.ROOT;
        if (nextParam) {
            nextPage = nextParam;
        }
        return <Navigate to={nextPage} />;
    }

    // FIXME: This should display a message if loginWithToken fails
    return <noscript />;
};
