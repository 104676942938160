/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ChannelTypeEnum } from './ChannelTypeEnum';
import {
    ChannelTypeEnumFromJSON,
    ChannelTypeEnumFromJSONTyped,
    ChannelTypeEnumToJSON,
} from './ChannelTypeEnum';

/**
 * Serializer for notification channels.
 * @export
 * @interface PatchedNotificationChannelRequest
 */
export interface PatchedNotificationChannelRequest {
    /**
     * 
     * @type {number}
     * @memberof PatchedNotificationChannelRequest
     */
    owner?: number;
    /**
     * 
     * @type {ChannelTypeEnum}
     * @memberof PatchedNotificationChannelRequest
     */
    channelType?: ChannelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchedNotificationChannelRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedNotificationChannelRequest
     */
    webhookUrl?: string;
}

/**
 * Check if a given object implements the PatchedNotificationChannelRequest interface.
 */
export function instanceOfPatchedNotificationChannelRequest(value: object): boolean {
    return true;
}

export function PatchedNotificationChannelRequestFromJSON(json: any): PatchedNotificationChannelRequest {
    return PatchedNotificationChannelRequestFromJSONTyped(json, false);
}

export function PatchedNotificationChannelRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedNotificationChannelRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'owner': json['owner'] == null ? undefined : json['owner'],
        'channelType': json['channel_type'] == null ? undefined : ChannelTypeEnumFromJSON(json['channel_type']),
        'email': json['email'] == null ? undefined : json['email'],
        'webhookUrl': json['webhook_url'] == null ? undefined : json['webhook_url'],
    };
}

export function PatchedNotificationChannelRequestToJSON(value?: PatchedNotificationChannelRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'owner': value['owner'],
        'channel_type': ChannelTypeEnumToJSON(value['channelType']),
        'email': value['email'],
        'webhook_url': value['webhookUrl'],
    };
}

