import { ThirdPartyEmissionDashboard } from "../../components/dashboards/EmissionDashboard";
import { PermissionGate } from "../PermissionGate";

export const ThirdPartyEmissionDashboardsPage = () => {
    return (
        <PermissionGate
            permission="emissionView"
            requiredFlag="enable_unified_dashboards"
        >
            <ThirdPartyEmissionDashboard />
        </PermissionGate>
    );
};
