/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AdminInfrastructureImportItemLocation } from './AdminInfrastructureImportItemLocation';
import {
    AdminInfrastructureImportItemLocationFromJSON,
    AdminInfrastructureImportItemLocationFromJSONTyped,
    AdminInfrastructureImportItemLocationToJSON,
} from './AdminInfrastructureImportItemLocation';

/**
 * Serializer used by map views that retrieve AerialImage information.
 * @export
 * @interface AerialImagesList
 */
export interface AerialImagesList {
    /**
     * 
     * @type {string}
     * @memberof AerialImagesList
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof AerialImagesList
     */
    infrastructure?: string;
    /**
     * 
     * @type {Date}
     * @memberof AerialImagesList
     */
    takenAt: Date;
    /**
     * 
     * @type {string}
     * @memberof AerialImagesList
     */
    image: string;
    /**
     * 
     * @type {AdminInfrastructureImportItemLocation}
     * @memberof AerialImagesList
     */
    center?: AdminInfrastructureImportItemLocation;
    /**
     * 
     * @type {Array<number>}
     * @memberof AerialImagesList
     */
    readonly bounds: Array<number>;
}

/**
 * Check if a given object implements the AerialImagesList interface.
 */
export function instanceOfAerialImagesList(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('takenAt' in value)) return false;
    if (!('image' in value)) return false;
    if (!('bounds' in value)) return false;
    return true;
}

export function AerialImagesListFromJSON(json: any): AerialImagesList {
    return AerialImagesListFromJSONTyped(json, false);
}

export function AerialImagesListFromJSONTyped(json: any, ignoreDiscriminator: boolean): AerialImagesList {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'infrastructure': json['infrastructure'] == null ? undefined : json['infrastructure'],
        'takenAt': (new Date(json['taken_at'])),
        'image': json['image'],
        'center': json['center'] == null ? undefined : AdminInfrastructureImportItemLocationFromJSON(json['center']),
        'bounds': json['bounds'],
    };
}

export function AerialImagesListToJSON(value?: Omit<AerialImagesList, 'id'|'bounds'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'infrastructure': value['infrastructure'],
        'taken_at': ((value['takenAt']).toISOString().substring(0,10)),
        'image': value['image'],
        'center': AdminInfrastructureImportItemLocationToJSON(value['center']),
    };
}

