/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataPackagePresignedUploadUrl
 */
export interface DataPackagePresignedUploadUrl {
    /**
     * 
     * @type {string}
     * @memberof DataPackagePresignedUploadUrl
     */
    uploadUrl: string;
}

/**
 * Check if a given object implements the DataPackagePresignedUploadUrl interface.
 */
export function instanceOfDataPackagePresignedUploadUrl(value: object): boolean {
    if (!('uploadUrl' in value)) return false;
    return true;
}

export function DataPackagePresignedUploadUrlFromJSON(json: any): DataPackagePresignedUploadUrl {
    return DataPackagePresignedUploadUrlFromJSONTyped(json, false);
}

export function DataPackagePresignedUploadUrlFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataPackagePresignedUploadUrl {
    if (json == null) {
        return json;
    }
    return {
        
        'uploadUrl': json['upload_url'],
    };
}

export function DataPackagePresignedUploadUrlToJSON(value?: DataPackagePresignedUploadUrl | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'upload_url': value['uploadUrl'],
    };
}

