/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EpaFormFileOperationRequest
 */
export interface EpaFormFileOperationRequest {
    /**
     * 
     * @type {string}
     * @memberof EpaFormFileOperationRequest
     */
    fileId: string;
}

/**
 * Check if a given object implements the EpaFormFileOperationRequest interface.
 */
export function instanceOfEpaFormFileOperationRequest(value: object): boolean {
    if (!('fileId' in value)) return false;
    return true;
}

export function EpaFormFileOperationRequestFromJSON(json: any): EpaFormFileOperationRequest {
    return EpaFormFileOperationRequestFromJSONTyped(json, false);
}

export function EpaFormFileOperationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EpaFormFileOperationRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'fileId': json['file_id'],
    };
}

export function EpaFormFileOperationRequestToJSON(value?: EpaFormFileOperationRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'file_id': value['fileId'],
    };
}

