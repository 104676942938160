import { useCallback, useEffect, useState } from "react";
import {
    InfraTypeEnum,
    InfrastructureImport,
    InfrastructureImportItem,
} from "../../../apiClient/generated";
import { useInfrastructureApiClient } from "../../../hooks";

// Max number of items displayed in the editor's main view.
export const GRAPH_EDITOR_MAX_ITEMS = 50;

interface InfrastructureImportGraphProps {
    relatedImport: InfrastructureImport;
    showWithoutParent: boolean;
    siteFilter: string;
}

export const useInfrastructureImportGraph = (
    props: InfrastructureImportGraphProps,
) => {
    // API Client
    const apiClient = useInfrastructureApiClient();

    // State management
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState<InfrastructureImportItem[]>([]);
    const [infraCount, setInfraCount] = useState<number>();
    const [sitesAndPipelines, setSitesAndPipelines] = useState<
        InfrastructureImportItem[]
    >([]);

    // Fetch data function
    const fetchData = async () => {
        setLoading(true);

        // Fetch all import items from database
        let fetchItems = [];
        const response = await apiClient.infrastructureImportItemsList({
            relatedImport: props.relatedImport.id,
            treeFromParentId: props.siteFilter && parseInt(props.siteFilter),
            pageSize: 500,
        });
        fetchItems = fetchItems.concat(response.results);

        // Iterate over number of pages to retrieve all results
        for (let page = 2; page <= response.numPages; page++) {
            const response = await apiClient.infrastructureImportItemsList({
                relatedImport: props.relatedImport.id,
                treeFromParentId:
                    props.siteFilter && parseInt(props.siteFilter),
                pageSize: 500,
                page,
            });
            fetchItems = fetchItems.concat(response.results);
        }

        if (props.showWithoutParent && props.siteFilter) {
            const response = await apiClient.infrastructureImportItemsList({
                relatedImport: props.relatedImport.id,
                missingParentWithTree: true,
                pageSize: 500,
            });
            fetchItems = fetchItems.concat(response.results);

            // Iterate over number of pages to retrieve all results
            for (let page = 2; page <= response.numPages; page++) {
                const response = await apiClient.infrastructureImportItemsList({
                    relatedImport: props.relatedImport.id,
                    missingParentWithTree: true,
                    pageSize: 500,
                    page,
                });
                fetchItems = fetchItems.concat(response.results);
            }
        }

        // Save to state
        setItems(fetchItems);
        setLoading(false);
    };

    // Load data
    useEffect(() => {
        const loadInitialData = async () => {
            setLoading(true);
            // Fetch list of sites
            const sites = await apiClient.infrastructureImportItemsList({
                relatedImport: props.relatedImport.id,
                infraType: InfraTypeEnum.Site,
                pageSize: 500,
            });
            // Note: we're not paginating sites, so this is a 500 site limit
            // for the graphical editor.
            // TODO: improve this.
            setSitesAndPipelines(sites.results);

            // Fetch small page to get count item count
            const countResponse = await apiClient.infrastructureImportItemsList(
                {
                    relatedImport: props.relatedImport.id,
                    pageSize: 1,
                },
            );
            setInfraCount(countResponse.count);
            setLoading(false);

            // If importing more than 50 items, don't load any data now.
            if (countResponse.count <= GRAPH_EDITOR_MAX_ITEMS) {
                fetchData();
            }
        };
        loadInitialData();
    }, [props.relatedImport]);

    // Update data when filters change
    useEffect(() => {
        if (
            infraCount &&
            (props.siteFilter ||
                (!props.siteFilter && infraCount <= GRAPH_EDITOR_MAX_ITEMS))
        ) {
            fetchData();
        }
    }, [props.siteFilter, props.showWithoutParent, infraCount]);

    // Update data when changing relationships
    const onChangeRelationship = useCallback(
        async (itemId, newParent) => {
            await apiClient.infrastructureImportItemsPartialUpdate({
                id: itemId,
                patchedInfrastructureImportItemRequest: {
                    parent: newParent,
                },
            });
        },
        [apiClient],
    );

    // Return useful variables
    return {
        graphItems: items,
        sitesAndPipelines,
        loadingData: loading,
        infraCount,
        refreshData: fetchData,
        onChangeRelationship,
    };
};
