import { GeoJsonLayer } from "@deck.gl/layers";
import { InfrastructureMapList } from "../../../apiClient/generated";
import { createFeatureCollection } from "../../../utils/geopatialUtils";
import { layerColorMap } from "../constants";

type Props = {
    data: InfrastructureMapList[];
    enabledLayers: string[];
    onHover?: (hoverInfo: any) => void;
    disableHighlight?: boolean;
};

export const InfrastructureLayer = (props: Props) => {
    return [
        new GeoJsonLayer({
            id: "infrastructure",
            data: createFeatureCollection(props.data),
            pointType: "circle",
            filled: true,
            getPointRadius: 6,
            pointRadiusUnits: "pixels",
            lineWidthUnits: "pixels",
            stroked: true,
            pickable: !props.disableHighlight,
            visible: props.enabledLayers.includes("infrastructure"),
            autoHighlight: !props.disableHighlight,
            onHover: props.onHover,
            highlightColor: (item) => {
                return (
                    layerColorMap[item.object.properties!.infraType]
                        ?.highlightColor || [0, 0, 0, 30]
                );
            },
            getFillColor: (item) => {
                if (item.properties!.shape) {
                    return [0, 0, 0, 0];
                }
                return (
                    layerColorMap[item.properties!.infraType]?.fillColor || [
                        0, 255, 255, 255,
                    ]
                );
            },
            getLineWidth: (item) => {
                return (
                    layerColorMap[item.properties!.infraType]?.lineWidth || 1
                );
            },
            getLineColor: (item) => {
                if (item.properties!.shape) {
                    return (
                        layerColorMap[item.properties!.infraType]
                            ?.fillColor || [0, 0, 0, 255]
                    );
                }
                return (
                    layerColorMap[item.properties!.infraType]?.lineColor || [
                        0, 255, 255, 255,
                    ]
                );
            },
        }),
    ];
};
