/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Permissions } from './Permissions';
import {
    PermissionsFromJSON,
    PermissionsFromJSONTyped,
    PermissionsToJSON,
} from './Permissions';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * Serializer for retrieving user permissions and flags.
 * @export
 * @interface CompanyMembership
 */
export interface CompanyMembership {
    /**
     * 
     * @type {User}
     * @memberof CompanyMembership
     */
    readonly user: User;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyMembership
     */
    readonly isStaff: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyMembership
     */
    readonly isSuperuser: boolean;
    /**
     * 
     * @type {number}
     * @memberof CompanyMembership
     */
    company: number;
    /**
     * 
     * @type {Permissions}
     * @memberof CompanyMembership
     */
    permissions: Permissions;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyMembership
     */
    readonly flags: Array<string>;
}

/**
 * Check if a given object implements the CompanyMembership interface.
 */
export function instanceOfCompanyMembership(value: object): boolean {
    if (!('user' in value)) return false;
    if (!('isStaff' in value)) return false;
    if (!('isSuperuser' in value)) return false;
    if (!('company' in value)) return false;
    if (!('permissions' in value)) return false;
    if (!('flags' in value)) return false;
    return true;
}

export function CompanyMembershipFromJSON(json: any): CompanyMembership {
    return CompanyMembershipFromJSONTyped(json, false);
}

export function CompanyMembershipFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyMembership {
    if (json == null) {
        return json;
    }
    return {
        
        'user': UserFromJSON(json['user']),
        'isStaff': json['is_staff'],
        'isSuperuser': json['is_superuser'],
        'company': json['company'],
        'permissions': PermissionsFromJSON(json['permissions']),
        'flags': json['flags'],
    };
}

export function CompanyMembershipToJSON(value?: Omit<CompanyMembership, 'user'|'is_staff'|'is_superuser'|'flags'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'company': value['company'],
        'permissions': PermissionsToJSON(value['permissions']),
    };
}

