import * as yup from "yup";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { PrimaryButton } from "../../../../ui/Buttons";
import { SearchInput } from "../../../../ui/Inputs";
import { ControlButton, MapDropdownMenu } from "../../ui/MapControls";
import { isValidCoordinates } from "../../../../utils/geopatialUtils";
import { useMap } from "../../hooks/mapState";

// Define a schema for the coordinates
const coordinatesSchema = yup.object({
    coordinates: yup
        .string()
        .required("Coordinates are required")
        .matches(
            /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/,
            "Coordinates must be in the format latitude,longitude.",
        )
        .test(
            "isValidCoordinates",
            "Latitude must be between -90 and 90, and Longitude must be between -180 and 180.",
            (value) => {
                const [lat, lon] = value
                    .split(",")
                    .map((num) => parseFloat(num.trim()));
                return isValidCoordinates(lat, lon);
            },
        ),
});

export const GoToLocationControl = () => {
    const { flyTo } = useMap("mainMap");
    const [coordinates, setCoordinates] = useState("");
    const [error, setError] = useState("");

    const navigateToCoords = async () => {
        try {
            // Validate coordinates
            await coordinatesSchema.validate({ coordinates });
            const split = coordinates.split(",");
            const lat = parseFloat(split[0]);
            const lon = parseFloat(split[1]);
            if (Number.isNaN(lat) || Number.isNaN(lon)) {
                throw new Error("Invalid numbers for coordinates.");
            }
            flyTo(lat, lon, 18);
            setError(""); // Clear error if everything is fine
        } catch (err) {
            setError(err.message); // Set error message from yup or parsing error
        }
    };

    return (
        <MapDropdownMenu
            button={
                <ControlButton className="w-8 rounded-l">
                    <MapPinIcon className="w-4" />
                </ControlButton>
            }
        >
            <div className="w-64">
                <p className="text-slate-700 text-sm font-semibold mb-2">
                    Go to coordinates (EPSG 4326):
                </p>
                <div className="flex flex-row space-x-2">
                    <SearchInput
                        onKeyUp={(e) => e.key === "Enter" && navigateToCoords()}
                        value={coordinates}
                        onChange={(value) => setCoordinates(value)}
                        placeholder="31.750, -103.193"
                        className="w-full"
                    />
                    <PrimaryButton onClick={navigateToCoords}>Go</PrimaryButton>
                </div>
                {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
            </div>
        </MapDropdownMenu>
    );
};
