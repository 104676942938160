import { useSearchParams } from "react-router-dom";
import { DashboardCard } from "../../../ui/Card";
import { useMemo } from "react";
import { EpaFormModal } from "./EpaForm";
import { EpaSepDashboardTables } from "./Table";
import { useEpaSepApiClient } from "../../../hooks";
import { useQuery } from "@tanstack/react-query";
import { SEPTimelinePlot } from "./plot";
import {
    DashboardStatsGroup,
    DashboardMetric,
} from "../../../ui/DashboardComponents";

const EpaSepStatsPlot = () => {
    const apiClient = useEpaSepApiClient();
    const epaSepStatsQuery = useQuery({
        queryKey: ["EpaSepStats"],
        queryFn: async () => {
            return await apiClient.epaSepNotificationsSepEventStatsList();
        },
    });

    if (!epaSepStatsQuery.data) {
        return null;
    }

    return (
        <div>
            <div className="flex items-center justify-between gap-4 my-2 mb-4">
                <p>Super Emitter Program Timeline</p>
            </div>
            <SEPTimelinePlot data={epaSepStatsQuery.data} />
        </div>
    );
};

const EpaSepStatsBar = () => {
    const apiClient = useEpaSepApiClient();
    const epaSepStatsBarQuery = useQuery({
        queryKey: ["EpaSepStatsBar"],
        queryFn: async () => {
            return await apiClient.epaSepNotificationsEpaSepStatsRetrieve();
        },
    });

    return (
        <div className="p-6 flex justify-between max-w-full overflow-x-scroll">
            <DashboardStatsGroup title="Notification Count">
                <DashboardMetric
                    value={epaSepStatsBarQuery.data?.companyCount}
                    label="My company"
                />
                <DashboardMetric
                    value={epaSepStatsBarQuery.data?.companyCount}
                    label="All EPA Events"
                />
            </DashboardStatsGroup>
            <DashboardStatsGroup title="Emission Rates">
                <DashboardMetric
                    value={`${(
                        epaSepStatsBarQuery.data?.highestRate / 1000
                    ).toFixed(0)} kg/h`}
                    label={"Highest event"}
                />
                <DashboardMetric
                    value={`${(
                        epaSepStatsBarQuery.data?.avgRate / 1000
                    ).toFixed(0)} kg/h`}
                    label={"Average"}
                />
            </DashboardStatsGroup>
            <DashboardStatsGroup title="Response time (days)">
                <DashboardMetric
                    value={epaSepStatsBarQuery.data?.avgResponseTimeDays || "-"}
                    label={"To first response"}
                />
                <DashboardMetric
                    value={
                        epaSepStatsBarQuery.data?.avgCompletionTimeDays || "-"
                    }
                    label={"To EPA submission"}
                />
            </DashboardStatsGroup>
        </div>
    );
};

export const SuperEmitterProgramDashboard = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const selectedEvent = useMemo(() => {
        return searchParams.get("selectedEvent");
    }, [searchParams]);

    const setSelectedEvent = (eventId?: string) => {
        setSearchParams((params) => {
            if (eventId) {
                params.set("selectedEvent", eventId);
            } else {
                params.delete("selectedEvent");
            }
            return params;
        });
    };

    return (
        <div className="mt-10">
            <DashboardCard
                flair={{
                    label: "EPA Super Emitter Program",
                    className: "bg-ae-emission-epa-sep",
                }}
            >
                <EpaSepStatsBar />
            </DashboardCard>
            <DashboardCard className="px-6 py-4 text-sm">
                <EpaSepStatsPlot />
            </DashboardCard>
            <DashboardCard>
                <EpaSepDashboardTables
                    setEvent={(id) => setSelectedEvent(id)}
                />
            </DashboardCard>
            <EpaFormModal
                selectedEvent={selectedEvent}
                onClose={() => setSelectedEvent()}
            />
        </div>
    );
};
