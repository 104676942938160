import {
    AnyAction,
    Reducer,
    combineReducers,
    configureStore,
} from "@reduxjs/toolkit";
import authReducer from "./auth";

const combinedReducer = combineReducers({
    auth: authReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
    // Clear state when user logs out.
    if (action.type === "auth/logOut/fulfilled") {
        state = {} as RootState;
    }
    return combinedReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
});
export type AppDispatch = typeof store.dispatch;
