/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `PLANNED` - Planned
 * * `ACTIVE` - Active
 * * `NOT_ACTIVE` - Not Active
 * @export
 */
export const InfraStatusEnum = {
    Planned: 'PLANNED',
    Active: 'ACTIVE',
    NotActive: 'NOT_ACTIVE'
} as const;
export type InfraStatusEnum = typeof InfraStatusEnum[keyof typeof InfraStatusEnum];


export function instanceOfInfraStatusEnum(value: any): boolean {
    return Object.values(InfraStatusEnum).includes(value);
}

export function InfraStatusEnumFromJSON(json: any): InfraStatusEnum {
    return InfraStatusEnumFromJSONTyped(json, false);
}

export function InfraStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfraStatusEnum {
    return json as InfraStatusEnum;
}

export function InfraStatusEnumToJSON(value?: InfraStatusEnum | null): any {
    return value as any;
}

