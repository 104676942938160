/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `NO_CONFLICT` - No Conflict
 * * `CONFLICT` - Conflict
 * * `NEAR_MATCH` - Near Match
 * * `ALLOW_AND_CREATE` - Allow And Create
 * * `BLOCK_AND_IGNORE` - Block And Ignore
 * @export
 */
export const CrossOperatorConflictStatusEnum = {
    NoConflict: 'NO_CONFLICT',
    Conflict: 'CONFLICT',
    NearMatch: 'NEAR_MATCH',
    AllowAndCreate: 'ALLOW_AND_CREATE',
    BlockAndIgnore: 'BLOCK_AND_IGNORE'
} as const;
export type CrossOperatorConflictStatusEnum = typeof CrossOperatorConflictStatusEnum[keyof typeof CrossOperatorConflictStatusEnum];


export function instanceOfCrossOperatorConflictStatusEnum(value: any): boolean {
    return Object.values(CrossOperatorConflictStatusEnum).includes(value);
}

export function CrossOperatorConflictStatusEnumFromJSON(json: any): CrossOperatorConflictStatusEnum {
    return CrossOperatorConflictStatusEnumFromJSONTyped(json, false);
}

export function CrossOperatorConflictStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): CrossOperatorConflictStatusEnum {
    return json as CrossOperatorConflictStatusEnum;
}

export function CrossOperatorConflictStatusEnumToJSON(value?: CrossOperatorConflictStatusEnum | null): any {
    return value as any;
}

