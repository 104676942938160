import { ReactNode } from "react";
import { Link } from "react-router-dom";

interface Props {
    to: string;
    children: ReactNode;
}

export const StyledLink = (props: Props) => {
    return (
        <Link
            to={props.to}
            className="text-blue-800 hover:cursor-pointer hover:underline flex items-center gap-1"
        >
            {props.children}
        </Link>
    );
};

export default StyledLink;
