import {
    ArrowPathIcon,
    CheckCircleIcon,
    ExclamationCircleIcon,
    ShieldCheckIcon,
    XCircleIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../routes";
import { useEffect, useState } from "react";
import {
    InfraTypeEnum,
    AdminInfrastructureImport,
} from "../../../apiClient/generated";
import { useInfrastructureApiClient } from "../../../hooks";

interface AdminApprovalTabProps {
    infrastructureImport: AdminInfrastructureImport;
}

export const AdminApprovalTab = (props: AdminApprovalTabProps) => {
    const navigate = useNavigate();
    const apiClient = useInfrastructureApiClient();
    const [loading, setLoading] = useState(false);
    const [checklistState, setChecklistState] = useState<{
        checkData: boolean;
        checkMap: boolean;
        checkGraph: boolean;
    }>({
        checkData: false,
        checkGraph: false,
        checkMap: false,
    });

    const [loadingValidation, setLoadingValidation] = useState(true);
    const [validationMessages, setValidationMessages] = useState<
        {
            pass: boolean;
            warning: boolean;
            message: string;
        }[]
    >([]);

    // Every time modal is reloaded, reload map data.
    useEffect(() => {
        const validateRequirements = async () => {
            setLoadingValidation(true);
            const valMessages = [];

            // Check if all items have IDs and no locations are missing
            const idResponse = await apiClient.infrastructureImportItemsList({
                relatedImport: props.infrastructureImport.id,
                emptyPlaceholderId: true,
            });

            if (idResponse.count > 0) {
                valMessages.push({
                    pass: false,
                    warning: false,
                    message: `No ID is set for ${idResponse.count} infrastructure points`,
                });
            } else {
                valMessages.push({
                    pass: true,
                    warning: false,
                    message: "All infrastructure points have IDs.",
                });
            }

            // Items missing location
            const noLocationResponse =
                await apiClient.infrastructureImportItemsList({
                    relatedImport: props.infrastructureImport.id,
                    noLocation: true,
                });

            if (noLocationResponse.count > 0) {
                // Check if any sites are missing locations
                const noLocationSiteResponse =
                    await apiClient.infrastructureImportItemsList({
                        relatedImport: props.infrastructureImport.id,
                        noLocation: true,
                        infraType: InfraTypeEnum.Site as string,
                    });

                if (noLocationSiteResponse.count > 0) {
                    valMessages.push({
                        pass: false,
                        warning: false,
                        message: `No location information in ${noLocationSiteResponse.count} site(s).`,
                    });
                } else {
                    valMessages.push({
                        pass: true,
                        warning: true,
                        message: `No location information in ${noLocationResponse.count} rows`,
                    });
                }
            } else {
                valMessages.push({
                    pass: true,
                    warning: false,
                    message: "All points have information location.",
                });
            }

            // Missing parents
            const missingParentResponse =
                await apiClient.infrastructureImportItemsList({
                    relatedImport: props.infrastructureImport.id,
                    missingParent: true,
                });

            if (missingParentResponse.count > 0) {
                valMessages.push({
                    pass: false,
                    warning: false,
                    message: `Missing parent relationships in ${missingParentResponse.count} rows`,
                });
            } else {
                valMessages.push({
                    pass: true,
                    warning: false,
                    message: "Relationship set for all infrastructures.",
                });
            }

            // Items missing infra type
            const missingInfraTypeResponse =
                await apiClient.infrastructureImportItemsList({
                    relatedImport: props.infrastructureImport.id,
                    missingInfraType: true,
                });
            if (missingInfraTypeResponse.count > 0) {
                valMessages.push({
                    pass: false,
                    message: `No infrastructure type defined in ${missingInfraTypeResponse.count} rows`,
                });
            } else {
                valMessages.push({
                    pass: true,
                    warning: false,
                    message: "All points have infrastructure type information.",
                });
            }

            // Items missing infra type
            const missingLocationMatchResponse =
                await apiClient.infrastructureImportItemsList({
                    relatedImport: props.infrastructureImport.id,
                    locationNeedsReview: true,
                });
            if (missingLocationMatchResponse.count > 0) {
                valMessages.push({
                    pass: false,
                    message: `${missingLocationMatchResponse.count} infrastructure match(es) not reviewed.`,
                });
            } else {
                valMessages.push({
                    pass: true,
                    warning: false,
                    message: "All matched infrastructure was reviewed.",
                });
            }

            // Conflict status == BLOCKED
            const conflictStatusResponse =
                await apiClient.infrastructureAdminImportItemsList({
                    relatedImport: props.infrastructureImport.id,
                    crossOperatorConflict: true,
                });
            if (conflictStatusResponse.count > 0) {
                valMessages.push({
                    pass: false,
                    message: `${conflictStatusResponse.count} infrastructure conflict(s) pending resolution.`,
                });
            } else {
                valMessages.push({
                    pass: true,
                    warning: false,
                    message: "No infrastructure conflicts pending resolution.",
                });
            }

            setValidationMessages(valMessages);
            setLoadingValidation(false);
        };
        validateRequirements();
    }, [props.infrastructureImport, apiClient]);

    if (!props.infrastructureImport) {
        return null;
    }

    const allowApprove =
        Object.values(checklistState).every((e) => e) &&
        !loading &&
        props.infrastructureImport.importStatus === "WAITING_REVIEW" &&
        Object.values(validationMessages).every((e) => e.pass);

    const approveImport = async () => {
        if (!allowApprove) {
            return;
        }
        setLoading(true);
        await apiClient.infrastructureAdminImportApproveAndImportDataCreate({
            id: props.infrastructureImport.id,
        });
        navigate(ROUTES.ADMIN_INFRASTRUCURE_IMPORT);
        setLoading(false);
    };

    return (
        <div className="bg-white h-[85vh] w-full">
            <div className="container mx-auto p-6">
                <h1 className="text-2xl">Checklist & approval</h1>

                <hr className="my-4" />

                <p>
                    After this step in complete and data is approved, the
                    infrastructure points will be deployed to the map. Approving
                    the import means the data will be pushed into the main map.
                </p>

                <p>
                    Ensure any in the table/map/graph is acurate and belongs to
                    the operator that is importing it.
                </p>

                <hr className="my-4" />

                {loadingValidation ? (
                    <div className="flex justify-center">
                        <ArrowPathIcon className="animate-spin w-10 h-10" />
                    </div>
                ) : (
                    <div>
                        <p className="font-bold">Data checks:</p>
                        {validationMessages.map((s, index) => (
                            <p
                                key={`message_${index}`}
                                className="flex items-center"
                            >
                                {s.pass ? (
                                    s.warning ? (
                                        <ExclamationCircleIcon className="h-5 w-5 mr-2 text-yellow-500" />
                                    ) : (
                                        <CheckCircleIcon className="h-5 w-5 mr-2 text-green-500" />
                                    )
                                ) : (
                                    <XCircleIcon className="h-5 w-5 mr-2 text-red-500" />
                                )}
                                {s.message}
                            </p>
                        ))}
                    </div>
                )}

                <hr className="my-4" />

                <p className="mt-1">Please complete the checklist below:</p>

                <ul>
                    <li className="flex items-center space-x-2 my-1">
                        <input
                            id="checkData"
                            type="checkbox"
                            className="rounded"
                            checked={checklistState.checkData}
                            onChange={() =>
                                setChecklistState({
                                    ...checklistState,
                                    checkData: !checklistState.checkData,
                                })
                            }
                        />
                        <label htmlFor="checkData">
                            I have checked the data in the table and verified
                            the items contain acurate information.
                        </label>
                    </li>
                    <li className="flex items-center space-x-2 my-1">
                        <input
                            id="checkGraph"
                            type="checkbox"
                            className="rounded"
                            checked={checklistState.checkGraph}
                            onChange={() =>
                                setChecklistState({
                                    ...checklistState,
                                    checkGraph: !checklistState.checkGraph,
                                })
                            }
                        />
                        <label htmlFor="checkGraph">
                            I have that the relationships between infrastructure
                            types were correctly defined and there are no
                            equipments outside sites in the import.
                        </label>
                    </li>
                    <li className="flex items-center space-x-2 my-1">
                        <input
                            id="checkMap"
                            type="checkbox"
                            className="rounded"
                            checked={checklistState.checkMap}
                            onChange={() =>
                                setChecklistState({
                                    ...checklistState,
                                    checkMap: !checklistState.checkMap,
                                })
                            }
                        />
                        <label htmlFor="checkMap">
                            I verified that the points on the map are correct
                            and roughly line up with operator infrastructure.
                        </label>
                    </li>
                </ul>

                <div className="mt-4">
                    <button
                        onClick={approveImport}
                        disabled={!allowApprove}
                        className={`flex px-4 py-2 rounded-lg ${
                            allowApprove
                                ? "bg-slate-300 hover:bg-slate-800 hover:text-white"
                                : "bg-slate-100 text-slate-600 cursor-not-allowed"
                        }`}
                    >
                        Approve & import infrastructure data
                        <ShieldCheckIcon className="ml-3 w-6 h-6" />
                    </button>
                </div>
            </div>
        </div>
    );
};
