/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Infrastructure } from './Infrastructure';
import {
    InfrastructureFromJSON,
    InfrastructureFromJSONTyped,
    InfrastructureToJSON,
} from './Infrastructure';

/**
 * Infrastructure Match serializer
 * @export
 * @interface InfrastructureMatch
 */
export interface InfrastructureMatch {
    /**
     * 
     * @type {number}
     * @memberof InfrastructureMatch
     */
    readonly id: number;
    /**
     * 
     * @type {Infrastructure}
     * @memberof InfrastructureMatch
     */
    infrastructure: Infrastructure;
    /**
     * Retrieves distance from queryset if it exists.
     * @type {number}
     * @memberof InfrastructureMatch
     */
    readonly distance: number;
    /**
     * Retrieves infrastructure_area from queryset if it exists.
     * @type {number}
     * @memberof InfrastructureMatch
     */
    readonly infrastructureArea: number;
    /**
     * Retrieves overlap_with_match from queryset if it exists.
     * @type {number}
     * @memberof InfrastructureMatch
     */
    readonly overlapWithMatch: number;
}

/**
 * Check if a given object implements the InfrastructureMatch interface.
 */
export function instanceOfInfrastructureMatch(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('infrastructure' in value)) return false;
    if (!('distance' in value)) return false;
    if (!('infrastructureArea' in value)) return false;
    if (!('overlapWithMatch' in value)) return false;
    return true;
}

export function InfrastructureMatchFromJSON(json: any): InfrastructureMatch {
    return InfrastructureMatchFromJSONTyped(json, false);
}

export function InfrastructureMatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfrastructureMatch {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'infrastructure': InfrastructureFromJSON(json['infrastructure']),
        'distance': json['distance'],
        'infrastructureArea': json['infrastructure_area'],
        'overlapWithMatch': json['overlap_with_match'],
    };
}

export function InfrastructureMatchToJSON(value?: Omit<InfrastructureMatch, 'id'|'distance'|'infrastructure_area'|'overlap_with_match'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'infrastructure': InfrastructureToJSON(value['infrastructure']),
    };
}

