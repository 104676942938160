import { ArrowPathIcon } from "@heroicons/react/20/solid";
import {
    CheckCircleIcon,
    ChevronDoubleRightIcon,
} from "@heroicons/react/24/solid";
import { Card } from "../../ui/Card";
import { ImportStatusEnum } from "../../apiClient/generated";

interface StepProps {
    number: number;
    checked: boolean;
    label: string;
    loading: boolean;
}

const Step = (props: StepProps) => (
    <span className="flex items-center justify-center">
        {props.loading ? (
            <div className="h-7 w-7 rounded-full flex items-center justify-center text-sm mr-2 border-2 p-1 border-slate-700">
                <ArrowPathIcon className="text-slate-600 animate-spin" />
            </div>
        ) : props.checked ? (
            <CheckCircleIcon className="h-8 w-8 mr-2" />
        ) : (
            <div className="h-7 w-7 rounded-full flex items-center justify-center text-sm mr-2 border-2 p-1 border-slate-700">
                {props.number}
            </div>
        )}
        {props.label}
    </span>
);

const steps = [
    {
        steps: [],
        label: "Initial Data",
    },
    {
        steps: ["AV_CHECK", "DATA_IMPORT", "VERIFY_AND_COMPLETE"],
        label: "Verify & Complete data",
    },
    {
        steps: ["WAITING_REVIEW"],
        label: "Waiting Review",
    },
    {
        steps: ["COMPLETE"],
        label: "Complete",
    },
];

interface StepIndicatorProps {
    step: ImportStatusEnum;
    loading: boolean;
}

export const StepIndicator = (props: StepIndicatorProps) => {
    const stepNumber =
        steps.findIndex((e) => e.steps.includes(props.step)) || 0;

    return (
        <Card compact>
            <ol className="flex items-center w-full text-sm font-medium text-center text-slate-800">
                {steps.map((stepDefinition, index) => (
                    <div key={index} className="flex items-center">
                        <Step
                            checked={stepNumber > index}
                            loading={stepNumber == index && props.loading}
                            label={stepDefinition.label}
                            number={index + 1}
                        />
                        {index < steps.length - 1 && (
                            <ChevronDoubleRightIcon className="h-6 w-6 mx-4" />
                        )}
                    </div>
                ))}
            </ol>
        </Card>
    );
};
