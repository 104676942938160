import { useQuery } from "@tanstack/react-query";
import { useAccountsApiClient, useAppSelector } from "../hooks";
import { ReactNode, useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { NotificationChannelSettings } from "../components/NotificationSettings/ChannelSettings";
import { NotificationGroupSettings } from "../components/NotificationSettings/GroupSettings";
import { EmissionSettings } from "../components/NotificationSettings/EmissionSettings";
import { DataExportSettings } from "../components/NotificationSettings/DataExportSettings";

interface CardProps {
    children: ReactNode;
}

const Card = (props: CardProps) => (
    <div className="rounded bg-white drop-shadow p-4 w-full">
        {props.children}
    </div>
);

interface CollapsibleCardProps {
    title: string;
    description?: string;
    children: ReactNode;
    defaultOpen?: boolean;
}

const CollapsibleCard = (props: CollapsibleCardProps) => (
    <Card>
        <Disclosure defaultOpen={props.defaultOpen}>
            {({ open }) => (
                <>
                    <Disclosure.Button className="py-2 w-full flex items-center">
                        <div className="flex-grow">
                            <div>
                                <h2 className="text-left">{props.title}</h2>
                                {props.description && (
                                    <p className="text-sm text-zinc-600 w-5/6 text-left">
                                        {props.description}
                                    </p>
                                )}
                            </div>
                        </div>
                        <ChevronDownIcon
                            className={`h-5 w-5 transform ${
                                open ? "rotate-180" : "rotate-0"
                            }`}
                        />
                    </Disclosure.Button>
                    <Disclosure.Panel>{props.children}</Disclosure.Panel>
                </>
            )}
        </Disclosure>
    </Card>
);

export const NotificationSettingsPage = () => {
    const accountsApiClient = useAccountsApiClient();
    const isSuperuser = useAppSelector((state) => state.auth.isSuperuser);
    const [company, setCompany] = useState<number>(undefined);

    const companyList = useQuery(
        ["AdminCompanyList"],
        () => accountsApiClient.accountsCompaniesList(),
        {
            enabled: isSuperuser,
        },
    );

    useEffect(() => {
        if (companyList.data && companyList.data.length > 1) {
            setCompany(companyList.data[0].id);
        }
    }, [companyList.data]);

    return (
        <div className="w-full h-screen pt-10 flex flex-col items-center">
            <div className="w-2/3">
                <div className="p-8">
                    <h1 className="text-stone-900 text-xl font-semibold leading-7">
                        Notification Settings
                    </h1>
                    <p className="text-zinc-600 text-snug text-sm leading-snug">
                        Change company notification settings, create email
                        groups and manage webhooks.
                    </p>
                </div>
                <div className="flex flex-col gap-4">
                    {isSuperuser && (
                        <Card>
                            <h2 className="text-lg mb-2 font-bold">
                                Superuser mode!
                            </h2>
                            <p className="text-zinc-600 text-snug text-sm leading-snug mb-2">
                                Please select a company to edit notification
                                settings.
                            </p>
                            <select
                                className="rounded"
                                value={company}
                                onChange={(e) =>
                                    setCompany(parseInt(e.target.value))
                                }
                            >
                                {companyList.isFetched &&
                                    companyList.data.map((c) => (
                                        <option key={c.id} value={c.id}>
                                            {c.name}
                                        </option>
                                    ))}
                            </select>
                        </Card>
                    )}
                    <CollapsibleCard
                        title="Mailing lists & Webhooks"
                        description="Configure mailing lists and Microsoft Teams webhooks."
                    >
                        <NotificationChannelSettings company={company} />
                    </CollapsibleCard>
                    <CollapsibleCard
                        title="Notification groups"
                        description="Create and manage groups of people to be notified  when events happen or emissions are detected."
                    >
                        <NotificationGroupSettings company={company} />
                    </CollapsibleCard>
                    <CollapsibleCard
                        title="Emission alerts"
                        description="Configure when and who the platform should alert when emissions come in the system, configure weekly emission  digests and enable/disable notifications for 3rd party providers."
                    >
                        <EmissionSettings company={company} />
                    </CollapsibleCard>
                    <CollapsibleCard
                        title="Data export alerts"
                        description="Configure who is alerted when users request CSV exports of emission data."
                    >
                        <DataExportSettings company={company} />
                    </CollapsibleCard>
                </div>
            </div>
        </div>
    );
};
