/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ThirdPartyEnabledEnum } from './ThirdPartyEnabledEnum';
import {
    ThirdPartyEnabledEnumFromJSON,
    ThirdPartyEnabledEnumFromJSONTyped,
    ThirdPartyEnabledEnumToJSON,
} from './ThirdPartyEnabledEnum';

/**
 * Serializer to represent `EmissionNotification` information.
 * @export
 * @interface EmissionNotificationSettingsRequest
 */
export interface EmissionNotificationSettingsRequest {
    /**
     * 
     * @type {ThirdPartyEnabledEnum}
     * @memberof EmissionNotificationSettingsRequest
     */
    epaSepEnabled?: ThirdPartyEnabledEnum;
    /**
     * 
     * @type {number}
     * @memberof EmissionNotificationSettingsRequest
     */
    epaSepGroup?: number;
    /**
     * 
     * @type {ThirdPartyEnabledEnum}
     * @memberof EmissionNotificationSettingsRequest
     */
    selfReportedEnabled?: ThirdPartyEnabledEnum;
    /**
     * 
     * @type {number}
     * @memberof EmissionNotificationSettingsRequest
     */
    selfReportedGroup?: number;
    /**
     * 
     * @type {ThirdPartyEnabledEnum}
     * @memberof EmissionNotificationSettingsRequest
     */
    thirdPartyEnabled?: ThirdPartyEnabledEnum;
    /**
     * 
     * @type {number}
     * @memberof EmissionNotificationSettingsRequest
     */
    thirdPartyGroup?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmissionNotificationSettingsRequest
     */
    thirdPartyExcludedProviders?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof EmissionNotificationSettingsRequest
     */
    notifyGroupOnReport?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EmissionNotificationSettingsRequest
     */
    reportNotificationGroup?: number;
}

/**
 * Check if a given object implements the EmissionNotificationSettingsRequest interface.
 */
export function instanceOfEmissionNotificationSettingsRequest(value: object): boolean {
    return true;
}

export function EmissionNotificationSettingsRequestFromJSON(json: any): EmissionNotificationSettingsRequest {
    return EmissionNotificationSettingsRequestFromJSONTyped(json, false);
}

export function EmissionNotificationSettingsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmissionNotificationSettingsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'epaSepEnabled': json['epa_sep_enabled'] == null ? undefined : ThirdPartyEnabledEnumFromJSON(json['epa_sep_enabled']),
        'epaSepGroup': json['epa_sep_group'] == null ? undefined : json['epa_sep_group'],
        'selfReportedEnabled': json['self_reported_enabled'] == null ? undefined : ThirdPartyEnabledEnumFromJSON(json['self_reported_enabled']),
        'selfReportedGroup': json['self_reported_group'] == null ? undefined : json['self_reported_group'],
        'thirdPartyEnabled': json['third_party_enabled'] == null ? undefined : ThirdPartyEnabledEnumFromJSON(json['third_party_enabled']),
        'thirdPartyGroup': json['third_party_group'] == null ? undefined : json['third_party_group'],
        'thirdPartyExcludedProviders': json['third_party_excluded_providers'] == null ? undefined : json['third_party_excluded_providers'],
        'notifyGroupOnReport': json['notify_group_on_report'] == null ? undefined : json['notify_group_on_report'],
        'reportNotificationGroup': json['report_notification_group'] == null ? undefined : json['report_notification_group'],
    };
}

export function EmissionNotificationSettingsRequestToJSON(value?: EmissionNotificationSettingsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'epa_sep_enabled': ThirdPartyEnabledEnumToJSON(value['epaSepEnabled']),
        'epa_sep_group': value['epaSepGroup'],
        'self_reported_enabled': ThirdPartyEnabledEnumToJSON(value['selfReportedEnabled']),
        'self_reported_group': value['selfReportedGroup'],
        'third_party_enabled': ThirdPartyEnabledEnumToJSON(value['thirdPartyEnabled']),
        'third_party_group': value['thirdPartyGroup'],
        'third_party_excluded_providers': value['thirdPartyExcludedProviders'],
        'notify_group_on_report': value['notifyGroupOnReport'],
        'report_notification_group': value['reportNotificationGroup'],
    };
}

