import { useInfrastructureApiClient } from "../../hooks";
import { createColumnHelper } from "@tanstack/react-table";
import {
    ImportStatusEnum,
    InfrastructureImport,
} from "../../apiClient/generated";
import { ROUTES } from "../../routes";
import { DateTime } from "luxon";
import { useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { SearchInput } from "../../ui/Inputs";
import { useNavigate } from "react-router";
import { DataTableV3, RowActionButtons } from "../DataTable/DataTableV3";
import { useAtom } from "jotai";
import { tableStateFamily } from "../DataTable/state";

const columnHelper = createColumnHelper<InfrastructureImport>();

export const InfrastructureImportApiDataTable = () => {
    const navigate = useNavigate();
    const apiClient = useInfrastructureApiClient();
    const [filterSearch, setFilterSearch] = useState("");
    const debouncedSearch = useDebounce(filterSearch, 600);

    // Set initial table state
    useAtom(
        tableStateFamily({
            tableId: "infrastructureImport",
            initialState: {
                sorting: {
                    id: "uploaded_at",
                    desc: true,
                },
            },
        }),
    );

    const fetchFunction = async (props: any) => {
        return await apiClient.infrastructureImportList(props);
    };

    const deleteFunction = async (itemId: string) => {
        return await apiClient.infrastructureImportDestroy({
            id: itemId,
        });
    };

    const columns = [
        columnHelper.accessor("csvInputFilename", {
            id: "csvInputFilename",
            header: () => "CSV Import File",
            cell: (info) => info.renderValue(),
            enableSorting: true,
        }),
        columnHelper.accessor("importStatus", {
            id: "importStatus",
            header: "Status",
            cell: (info) => (
                <i className="capitalize">
                    {info.getValue().split("_").join(" ").toLowerCase()}
                </i>
            ),
            enableSorting: true,
        }),
        columnHelper.accessor("itemCount", {
            id: "itemCount",
            header: () => "Number of items",
            cell: (info) =>
                info.renderValue() !== 0 ? info.renderValue() : "-",
            enableSorting: false,
        }),
        columnHelper.accessor("createdByName", {
            id: "createdByName",
            header: () => "Created By",
            cell: (info) => info.renderValue(),
        }),
        columnHelper.accessor("createdAt", {
            id: "createdAt",
            header: () => "Created At",
            cell: (info) =>
                DateTime.fromJSDate(info.renderValue())
                    .setZone("utc")
                    .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS),
        }),
        columnHelper.accessor("updatedAt", {
            id: "updatedAt",
            header: () => "Last Updated At",
            cell: (info) =>
                DateTime.fromJSDate(info.renderValue())
                    .setZone("utc")
                    .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS),
        }),
        columnHelper.display({
            id: "actions",
            header: () => "",
            cell: (props) => {
                if (
                    props.row.original.importStatus ===
                        ImportStatusEnum.Complete ||
                    props.row.original.importStatus ===
                        ImportStatusEnum.WaitingImport ||
                    props.row.original.importStatus ===
                        ImportStatusEnum.ImportingData
                ) {
                    return;
                }

                return (
                    <RowActionButtons
                        actions={[
                            {
                                fn: () =>
                                    navigate(
                                        `${ROUTES.INFRASTRUCURE_BUILDER.replace(
                                            ":id",
                                            props.row.original.id,
                                        )}`,
                                    ),
                                icon: <PencilSquareIcon className="w-4 h-4" />,
                                tooltip: "Continue editing",
                            },
                            {
                                fn: () => {
                                    if (
                                        confirm(
                                            "Are you sure you want to delete this import?",
                                        )
                                    ) {
                                        if (
                                            props.row.original.importStatus ===
                                                ImportStatusEnum.Complete ||
                                            props.row.original.importStatus ===
                                                ImportStatusEnum.WaitingImport ||
                                            props.row.original.importStatus ===
                                                ImportStatusEnum.ImportingData
                                        ) {
                                            return "-";
                                        }
                                        props.table.options.meta?.deleteRow(
                                            props.row.index,
                                            props.row.original.id,
                                        );
                                    }
                                },
                                icon: <TrashIcon className="w-4 h-4" />,
                                tooltip: "Delete import",
                            },
                        ]}
                    />
                );
            },
        }),
    ];

    return (
        <div className="flex-1 flex flex-col">
            {/* Search bar */}
            <div className="py-3 px-4 flex items-center text-sm justify-between border-b">
                <SearchInput
                    placeholder="Search by CSV filename..."
                    value={filterSearch}
                    onChange={(newValue) => setFilterSearch(newValue)}
                    className="w-96"
                />
            </div>

            {/* Data table */}
            <DataTableV3<InfrastructureImport>
                dataName="infrastructureImport"
                columns={columns}
                extraFilters={{
                    search: debouncedSearch,
                }}
                fetchFunction={fetchFunction}
                deleteRow={deleteFunction}
                sortable={true}
                filterable={false}
            />
        </div>
    );
};
