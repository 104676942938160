import { useEffect, useState } from "react";
import { FileImport } from "./FileImport";
import { StepIndicator } from "./StepIndicator";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { useInfrastructureApiClient } from "../../hooks";
import { InfrastructureImport } from "../../apiClient/generated";
import { WaitingAntivirusCheck } from "./WaitingAntivirusCheck";
import { WaitingDataImport } from "./WaitingDataImport";
import { VerifyAndPreview } from "./VerifyAndPreview";
import { WaitingApproval } from "./WaitingApproval";
import { WaitingLocationMatcher } from "./WaitingLocationMatcher";
import { WaitingLineDeduplicator } from "./WaitingLineDeduplicator";

interface InfrastructureBuilderProps {
    infrastructureImportId: string;
}

export const InfrastructureBuilder = (props: InfrastructureBuilderProps) => {
    const apiClient = useInfrastructureApiClient();
    const [loading, setLoading] = useState(true);
    const [importData, setImportData] = useState<InfrastructureImport>();

    // Load data here.
    const fetchData = async () => {
        setLoading(true);
        const response = await apiClient.infrastructureImportRetrieve({
            id: props.infrastructureImportId,
        });
        setImportData(response);
        setLoading(false);
    };

    useEffect(() => {
        if (props.infrastructureImportId === "new") {
            setLoading(false);
        } else {
            fetchData();
        }
    }, [props]);

    if (loading) {
        return (
            <div className="flex justify-center my-20">
                <ArrowPathIcon className="h-20 w-20 animate-spin" />
            </div>
        );
    }

    return (
        <div className="w-full">
            <StepIndicator step={importData?.importStatus} loading={false} />
            {!importData && <FileImport />}

            {importData && importData.importStatus === "AV_CHECK" && (
                <WaitingAntivirusCheck
                    importData={importData}
                    refresh={fetchData}
                />
            )}

            {importData && importData.importStatus === "DATA_IMPORT" && (
                <WaitingDataImport
                    importData={importData}
                    refresh={fetchData}
                />
            )}

            {importData &&
                importData.importStatus === "VERIFY_AND_COMPLETE" &&
                importData.matchingStatus !== "RUNNING" &&
                importData.deduplicationStatus !== "RUNNING" && (
                    <VerifyAndPreview
                        importData={importData}
                        refresh={fetchData}
                    />
                )}

            {importData &&
                importData.importStatus === "VERIFY_AND_COMPLETE" &&
                importData.matchingStatus === "RUNNING" && (
                    <WaitingLocationMatcher
                        importData={importData}
                        refresh={fetchData}
                    />
                )}

            {importData &&
                importData.importStatus === "VERIFY_AND_COMPLETE" &&
                importData.deduplicationStatus === "RUNNING" && (
                    <WaitingLineDeduplicator
                        importData={importData}
                        refresh={fetchData}
                    />
                )}

            {importData && importData.importStatus === "WAITING_REVIEW" && (
                <WaitingApproval />
            )}
        </div>
    );
};
