import { SidebarSection, MapSidebarSkeleton } from "../ui/SidebarItems";
import { useAppSelector, useInfrastructureApiClient } from "../../../hooks";
import { useQuery } from "@tanstack/react-query";
import { InfrastructureTree } from "../ui/InfrastructureTree";
import {
    InfrastructureSidebarDisplay,
    PipelineSegmentSidebarDisplay,
    PipelineSidebarDisplay,
} from "./common";
import { useMapData } from "../hooks/mapDataAndFilters";
import { ROUTES } from "../../../routes";

const InfrastructureTab = () => {
    const apiClient = useInfrastructureApiClient();
    const { selectedContext } = useMapData("mainMap");
    const userFlags = useAppSelector((s) => s.auth.flags);

    // Fetch main item information
    const itemQuery = useQuery({
        queryKey: ["mapInfrastructureItem", selectedContext.infrastructureId],
        queryFn: async () => {
            return await apiClient.infrastructureListRetrieve({
                id: selectedContext.infrastructureId,
            });
        },
        enabled: !!selectedContext.infrastructureId,
    });

    // Fetch main item information
    const treeQuery = useQuery({
        queryKey: ["infrastructure", selectedContext.infrastructureId],
        queryFn: async () => {
            return await apiClient.infrastructureListList({
                parent: [selectedContext.infrastructureId],
                pageSize: 100,
            });
        },
        enabled: !!selectedContext.infrastructureId,
    });

    const action = userFlags.includes("enable_site_page")
        ? {
              label: "Site",
              fn: () =>
                  window.open(
                      ROUTES.INFRASTRUCURE_DETAIL.replace(
                          ":id",
                          itemQuery.data?.id,
                      ),
                  ),
          }
        : undefined;

    return (
        <div>
            <SidebarSection
                title="General Information"
                loading={itemQuery.isLoading}
                action={action}
            >
                <InfrastructureSidebarDisplay infrastructure={itemQuery.data} />
            </SidebarSection>
            <SidebarSection
                title="Related Infrastructure"
                loading={treeQuery.isLoading}
            >
                <InfrastructureTree
                    infrastructure={treeQuery.data?.results || []}
                />
            </SidebarSection>
        </div>
    );
};

export const InfrastructureDetailSidebar = () => (
    <MapSidebarSkeleton
        title="Infrastructure"
        tabs={[
            {
                title: "Details",
                content: <InfrastructureTab />,
            },
        ]}
    />
);

const PipelineTab = () => {
    const apiClient = useInfrastructureApiClient();
    const { selectedContext } = useMapData("mainMap");

    // Fetch main item information
    const itemQuery = useQuery({
        queryKey: ["pipeline", selectedContext.pipeline.id],
        queryFn: async () => {
            return await apiClient.infrastructurePipelineRetrieve({
                id: selectedContext.pipeline.id,
            });
        },
        enabled: !!selectedContext.pipeline,
    });

    // Pipeline Segment
    const segmentQuery = useQuery({
        queryKey: ["pipelineSegment", selectedContext.pipeline],
        queryFn: async () => {
            return await apiClient.infrastructurePipelineSegmentsList({
                pipeline: selectedContext.pipeline.id,
                nearPoint: JSON.stringify({
                    type: "Point",
                    coordinates: selectedContext.pipeline.coordinates,
                }),
            });
        },
        enabled: !!selectedContext.pipeline,
    });

    return (
        <div>
            <SidebarSection
                title="General Information"
                loading={itemQuery.isLoading}
            >
                <PipelineSidebarDisplay pipeline={itemQuery.data} />
            </SidebarSection>
            <SidebarSection
                title="Pipeline Segment"
                loading={segmentQuery.isLoading}
            >
                {segmentQuery.data?.results.map((segment) => (
                    <PipelineSegmentSidebarDisplay segment={segment} />
                ))}
            </SidebarSection>
        </div>
    );
};

export const PipelineDetailSidebar = () => (
    <MapSidebarSkeleton
        title="Pipeline"
        tabs={[
            {
                title: "Details",
                content: <PipelineTab />,
            },
        ]}
    />
);
