import { ArrowPathIcon } from "@heroicons/react/24/solid";

interface Props {
    challengeResponse: string;
    onChangeChallengeResponse: (string) => void;
    device: string;
    loading: boolean;
    error?: string;
    performLogin: () => void;
}

export const SecondFactorForm = (props: Props) => (
    <>
        <h2 className="mt-12 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Second factor authentication
        </h2>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="space-y-6">
                <div>
                    <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                    >
                        {props.device === "EMAIL" &&
                            "Insert the code received via email:"}
                        {props.device === "AUTHENTICATOR_APP" &&
                            "Insert the code from your authentication app:"}
                    </label>
                    <div className="mt-2">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            disabled={props.loading}
                            value={props.challengeResponse}
                            onChange={(e) =>
                                props.onChangeChallengeResponse(e.target.value)
                            }
                            onKeyDown={(e) => {
                                if (e.key === "Enter" && !props.loading) {
                                    props.performLogin();
                                }
                            }}
                            required
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>

                {props.error && (
                    <div className="px-4 py-2 bg-red-500 rounded-xl text-white">
                        {props.error}
                    </div>
                )}

                <div>
                    <button
                        type="submit"
                        disabled={props.loading}
                        onClick={props.performLogin}
                        className="flex w-full justify-center rounded-md transition-all duration-800 bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-700"
                    >
                        {props.loading ? (
                            <ArrowPathIcon className="animate-spin h-6" />
                        ) : (
                            "Verify"
                        )}
                    </button>
                </div>
            </div>
        </div>
    </>
);
