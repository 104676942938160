import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import { Ref } from "react";
import React, { useState } from "react";

interface Props {
    inputRef?: Ref<HTMLInputElement>;
    accept: string;
    multiple?: boolean;
    maxFileSize?: number;
    onChange: (files: FileList) => void;
}

export const UploadDropdown = (props: Props) => {
    const [dragOver, setDragOver] = useState(false);

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragOver(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragOver(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragOver(true);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragOver(false);
        props.onChange(e.dataTransfer.files);
    };

    return (
        <div
            className="flex items-center justify-center w-full"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
        >
            <label
                className={`
                    flex flex-col items-center justify-center w-full 
                    border border-dashed rounded cursor-pointer
                    ${
                        dragOver
                            ? "bg-ae-blue-100 border-ae-blue-500 cursor-grabbing"
                            : "border-ae-gray-300 bg-white hover:bg-ae-blue-100 hover:border-ae-blue-500"
                    }
                `}
            >
                <div className="flex flex-col items-center justify-center px-4 py-8">
                    <CloudArrowUpIcon className="w-7 h-7 mb-2 text-ae-blue-900" />
                    <p className="mb-2 text-sm text-gray-500">
                        <span className="font-bold text-ae-blue-900 mr-1">
                            Click to upload
                        </span>
                        or drag and drop
                    </p>
                    <p className="text-center break-words text-xs text-gray-500">
                        {props.accept
                            .replaceAll(".", "")
                            .replaceAll(",", ", ")
                            .toUpperCase()}
                        {props.maxFileSize &&
                            ` (max ${props.maxFileSize}GB/file)`}
                    </p>
                </div>
                <input
                    ref={props.inputRef}
                    type="file"
                    className="hidden"
                    multiple={props.multiple}
                    onChange={(e) => props.onChange(e.target.files)}
                    accept={props.accept}
                />
            </label>
        </div>
    );
};

export default UploadDropdown;
