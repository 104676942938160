/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PlumeImage } from './PlumeImage';
import {
    PlumeImageFromJSON,
    PlumeImageFromJSONTyped,
    PlumeImageToJSON,
} from './PlumeImage';

/**
 * 
 * @export
 * @interface PaginatedPlumeImageList
 */
export interface PaginatedPlumeImageList {
    /**
     * 
     * @type {string}
     * @memberof PaginatedPlumeImageList
     */
    next?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPlumeImageList
     */
    previous?: string;
    /**
     * 
     * @type {Array<PlumeImage>}
     * @memberof PaginatedPlumeImageList
     */
    results: Array<PlumeImage>;
}

/**
 * Check if a given object implements the PaginatedPlumeImageList interface.
 */
export function instanceOfPaginatedPlumeImageList(value: object): boolean {
    if (!('results' in value)) return false;
    return true;
}

export function PaginatedPlumeImageListFromJSON(json: any): PaginatedPlumeImageList {
    return PaginatedPlumeImageListFromJSONTyped(json, false);
}

export function PaginatedPlumeImageListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedPlumeImageList {
    if (json == null) {
        return json;
    }
    return {
        
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(PlumeImageFromJSON)),
    };
}

export function PaginatedPlumeImageListToJSON(value?: PaginatedPlumeImageList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(PlumeImageToJSON)),
    };
}

