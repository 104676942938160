/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InfrastructureMapList } from './InfrastructureMapList';
import {
    InfrastructureMapListFromJSON,
    InfrastructureMapListFromJSONTyped,
    InfrastructureMapListToJSON,
} from './InfrastructureMapList';

/**
 * 
 * @export
 * @interface PaginatedInfrastructureMapListList
 */
export interface PaginatedInfrastructureMapListList {
    /**
     * 
     * @type {string}
     * @memberof PaginatedInfrastructureMapListList
     */
    next?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedInfrastructureMapListList
     */
    previous?: string;
    /**
     * 
     * @type {Array<InfrastructureMapList>}
     * @memberof PaginatedInfrastructureMapListList
     */
    results: Array<InfrastructureMapList>;
}

/**
 * Check if a given object implements the PaginatedInfrastructureMapListList interface.
 */
export function instanceOfPaginatedInfrastructureMapListList(value: object): boolean {
    if (!('results' in value)) return false;
    return true;
}

export function PaginatedInfrastructureMapListListFromJSON(json: any): PaginatedInfrastructureMapListList {
    return PaginatedInfrastructureMapListListFromJSONTyped(json, false);
}

export function PaginatedInfrastructureMapListListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedInfrastructureMapListList {
    if (json == null) {
        return json;
    }
    return {
        
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(InfrastructureMapListFromJSON)),
    };
}

export function PaginatedInfrastructureMapListListToJSON(value?: PaginatedInfrastructureMapListList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(InfrastructureMapListToJSON)),
    };
}

