import {
    ArrowUpRightIcon,
    Square3Stack3DIcon,
    MapPinIcon,
    XMarkIcon,
    PencilSquareIcon,
} from "@heroicons/react/24/solid";
import { useState, useMemo } from "react";
import {
    AdminInfrastructureImportItemLocation,
    AdminInfrastructureImportItemShape,
    AdminInfrastructureImportItemPipelineShape,
    InfraTypeEnum,
} from "../../../apiClient/generated";
import UploadDropdown from "../../../components/UploadDropdown";
import { CoordinatesField } from "../../GenericFields";
import * as turf from "@turf/turf";
import { EditableCell } from "./EditableCells";
import { readGeoFileAsGeoJson } from "../../../utils/geopatialUtils";
import { LngLat } from "maplibre-gl";
import { usBoundaries } from "../../../constants/boundaries";
import {
    CheckCircleIcon,
    ExclamationCircleIcon,
    XCircleIcon,
} from "@heroicons/react/20/solid";
import { DrawableMiniMap } from "../../../components/MapV2/Minimaps";

interface EditableLocationCellProps {
    initialLocation?: AdminInfrastructureImportItemLocation;
    initialShape?: AdminInfrastructureImportItemShape;
    initialPipelineShape?: AdminInfrastructureImportItemPipelineShape;
    infraType?: string;
    onChange: (
        newLocation?: AdminInfrastructureImportItemLocation,
        newShape?: AdminInfrastructureImportItemShape,
        newPipelineShape?: AdminInfrastructureImportItemPipelineShape,
    ) => Promise<void>;
}

export const EditableLocationCell = (props: EditableLocationCellProps) => {
    // Location
    const [location, setLocation] = useState(
        props.initialLocation?.coordinates.join(","),
    );

    // Compute point (if a valid point can be generated)
    const locationMapPoint = useMemo(() => {
        try {
            const coordinates = location.split(",").map((v) => parseFloat(v));
            // Check if this is a valid point
            new LngLat(coordinates[0], coordinates[1]);
            return {
                type: "Point",
                coordinates,
            } as AdminInfrastructureImportItemLocation;
        } catch {
            return;
        }
    }, [location]);

    // Shape
    const [shape, setShape] = useState(
        props.initialShape || props.initialPipelineShape,
    );
    const [shapeError, setShapeError] = useState(false);

    // Data validation
    const pointValidationMessages = useMemo(() => {
        const messages = [];

        if (locationMapPoint) {
            const usPolygon = turf.polygon(usBoundaries.geometry.coordinates);
            if (
                !turf.booleanPointInPolygon(
                    turf.point(locationMapPoint.coordinates),
                    usPolygon,
                )
            ) {
                messages.push({
                    pass: false,
                    warning: false,
                    message: "Point outside continental US.",
                });
            }
        } else {
            messages.push({
                pass: true,
                warning: true,
                message: "Not a valid point.",
            });
        }

        return messages;
    }, [locationMapPoint]);

    const shapeValidationMessages = useMemo(() => {
        const messages = [];

        if (shape) {
            const usPolygon = turf.polygon(usBoundaries.geometry.coordinates);
            const centroid = turf.centroid(shape);
            if (
                !turf.booleanPointInPolygon(
                    turf.point(centroid.geometry.coordinates),
                    usPolygon,
                )
            ) {
                messages.push({
                    pass: false,
                    warning: false,
                    message: "Shape outside continental US.",
                });
            }
        }

        return messages;
    }, [shape]);

    // Load shape from upload
    const onDropShape = (files: FileList) => {
        readGeoFileAsGeoJson({
            file: files[0],
            callback: (data, error) => {
                if (error) {
                    setShapeError(true);
                    setShape(null);
                    return;
                }
                if (
                    data.geometry &&
                    ["Polygon", "LineString"].includes(data.geometry.type)
                ) {
                    setShape(data.geometry);
                    // Also set point if none is set
                    if (!location || location.trim() === "") {
                        const center = turf.centerOfMass(data);
                        setLocation(center.geometry.coordinates.join(","));
                    }
                    setShapeError(false);
                } else if (
                    data.type === "FeatureCollection" &&
                    data.features.length > 0
                ) {
                    setShape(data.features[0].geometry);
                    // Also set point if none is set
                    if (!location || location.trim() === "") {
                        const center = turf.centerOfMass(data);
                        setLocation(center.geometry.coordinates.join(","));
                    }
                    setShapeError(false);
                } else {
                    setShapeError(true);
                    setShape(null);
                }
            },
        });
    };

    // Display value
    const displayValue = useMemo(() => {
        let coordinates = props.initialLocation?.coordinates;
        let area = undefined;
        let length = undefined;
        let centroid = undefined;
        let icon = <MapPinIcon className="h-4 w-4" />;

        // Calculate length/area depending on shape type and set icon.
        if (props.initialPipelineShape) {
            const linestring = turf.lineString(props.initialPipelineShape);
            length = turf.length(linestring, { units: "miles" });
            centroid = turf.centroid(props.initialPipelineShape);
            icon = <ArrowUpRightIcon className="h-4 w-4" />;
        } else if (props.initialShape) {
            area = turf.area(props.initialShape);
            centroid = turf.centroid(props.initialShape);
            icon = <Square3Stack3DIcon className="h-4 w-4" />;
        }

        // Automatically calculate centroid coordinates if they are missing.
        if (!coordinates && centroid) {
            coordinates = centroid.geometry.coordinates;
        }

        // Render data.
        return (
            <div className="flex items-center">
                {icon}
                <span className="mx-1">
                    <CoordinatesField coordinates={coordinates} />
                </span>
                <span>
                    {length && `(${length} miles)`}
                    {area && (
                        <>
                            ({(area * 3.861e-7).toFixed(2)} mi<sup>2</sup>)
                        </>
                    )}
                </span>
            </div>
        );
    }, [props.initialLocation, props.initialPipelineShape, props.initialShape]);

    return (
        <EditableCell
            title="Edit location/shape"
            editable={
                <div className="flex h-96">
                    <div className="w-1/3 pr-4">
                        {props.infraType === InfraTypeEnum.Pipeline ? (
                            <>
                                <p className="font-bold">Pipeline shape:</p>
                                <p>
                                    <UploadDropdown
                                        accept=".geojson,.json,.kml"
                                        onChange={onDropShape}
                                    />
                                </p>
                                <p>
                                    <small>
                                        The pipeline shape needs to be a
                                        KML/GeoJSON file with a LineString
                                        geometry/feature.
                                    </small>
                                </p>
                            </>
                        ) : (
                            <>
                                <p className="font-bold flex items-center">
                                    Point:
                                    <input
                                        className="font-normal w-full mx-2 p-2 border-2 border-slate-300 rounded-lg"
                                        placeholder="-134.12, 23.32"
                                        value={location}
                                        onChange={(e) =>
                                            setLocation(e.target.value)
                                        }
                                    />
                                </p>
                                {pointValidationMessages.length > 0 && (
                                    <div className="ml-14 my-2">
                                        {pointValidationMessages.map((item) => (
                                            <p className="flex items-center">
                                                {item.pass ? (
                                                    item.warning ? (
                                                        <ExclamationCircleIcon className="w-4 h-4 mr-2 text-yellow-600" />
                                                    ) : (
                                                        <CheckCircleIcon className="w-4 h-4 mr-2 text-green-600" />
                                                    )
                                                ) : (
                                                    <XCircleIcon className="w-4 h-4 mr-2 text-red-600" />
                                                )}
                                                {item.message}
                                            </p>
                                        ))}
                                    </div>
                                )}
                                <small>
                                    The point field accepts EPSG 4326
                                    coordinates in a comma-separated format. You
                                    can optionally add a marker by using the map
                                    pin tool. If the point is not set, the shape
                                    centroid will be used as the main point.
                                </small>

                                <p className="mt-4 flex items-center font-bold">
                                    Shape (optional):
                                </p>
                                {shape ? (
                                    <>
                                        <p className="text-sm">
                                            Area:{" "}
                                            {(
                                                turf.area(shape) * 3.861e-7
                                            ).toFixed(2)}{" "}
                                            mi<sup>2</sup>
                                        </p>
                                        {shapeValidationMessages.length > 0 && (
                                            <div className="my-2">
                                                {shapeValidationMessages.map(
                                                    (item) => (
                                                        <p className="flex items-center">
                                                            {item.pass ? (
                                                                item.warning ? (
                                                                    <ExclamationCircleIcon className="w-4 h-4 mr-2 text-yellow-600" />
                                                                ) : (
                                                                    <CheckCircleIcon className="w-4 h-4 mr-2 text-green-600" />
                                                                )
                                                            ) : (
                                                                <XCircleIcon className="w-4 h-4 mr-2 text-red-600" />
                                                            )}
                                                            {item.message}
                                                        </p>
                                                    ),
                                                )}
                                            </div>
                                        )}

                                        <button
                                            className="flex py-2 px-4 mt-2 bg-slate-200 rounded-lg items-center hover:bg-slate-600 hover:text-white"
                                            onClick={() => setShape(undefined)}
                                        >
                                            Remove shape
                                            <XMarkIcon className="ml-2 w-5 h-5" />
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <div>
                                            <span className="flex items-center text-sm mb-2">
                                                Draw using map tools{" "}
                                                <PencilSquareIcon className="h-3 w-3 mr-1" />{" "}
                                                or drop a geospatial file below.
                                            </span>
                                            <UploadDropdown
                                                accept=".geojson,.json,.kml"
                                                onChange={onDropShape}
                                            />
                                        </div>
                                        <p>
                                            <small>
                                                The infrastructure shape needs
                                                to be a KML/GeoJSON file with a
                                                Polygon geometry/feature.
                                            </small>
                                        </p>
                                        {shapeError && (
                                            <p className="p-2 mt-2 rounded-lg bg-red-500 text-white font-bold">
                                                Invalid GeoJSON file.
                                            </p>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div className="relative h-full w-2/3 overflow-hidden">
                        <DrawableMiniMap
                            point={locationMapPoint}
                            onChangePoint={(newValue) =>
                                setLocation(
                                    newValue.coordinates
                                        .map((p) => p.toFixed(8))
                                        .join(","),
                                )
                            }
                            shape={shape}
                            onChangeShape={(newValue) => {
                                setShape(newValue);
                                if (!location) {
                                    const centroid = turf.centroid(newValue);
                                    if (centroid) {
                                        setLocation(
                                            centroid.geometry.coordinates
                                                .map((c) => c.toFixed(8))
                                                .join(","),
                                        );
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            }
            useDetailView={true}
            valueDisplay={displayValue}
            onSave={() => {
                const point = locationMapPoint
                    ? turf.truncate(locationMapPoint, { coordinates: 2 })
                    : null;
                let responseShape = null;
                let responsePipelineShape = null;

                if (shape) {
                    if (shape.type === "Polygon") {
                        responseShape = turf.truncate(shape, {
                            coordinates: 2,
                        });
                        responsePipelineShape = undefined;
                    } else if (shape.type === "LineString") {
                        responseShape = undefined;
                        responsePipelineShape = turf.truncate(shape, {
                            coordinates: 2,
                        });
                    }
                }

                return props.onChange(
                    point,
                    responseShape,
                    responsePipelineShape,
                );
            }}
            onCancel={() => {
                setLocation(props.initialLocation?.coordinates.join(","));
                setShape(props.initialShape || props.initialPipelineShape);
            }}
            disableSave={
                pointValidationMessages.length > 0 ||
                shapeValidationMessages.length > 0
            }
        />
    );
};
