/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `OIL` - Oil
 * * `CONDENSATE` - Condensate
 * * `NGL` - Natural Gas Liquid
 * * `NATURAL_GAS` - Natural Gas
 * * `WATER` - Water
 * * `OTHER` - Other
 * @export
 */
export const PipelineProductEnum = {
    Oil: 'OIL',
    Condensate: 'CONDENSATE',
    Ngl: 'NGL',
    NaturalGas: 'NATURAL_GAS',
    Water: 'WATER',
    Other: 'OTHER'
} as const;
export type PipelineProductEnum = typeof PipelineProductEnum[keyof typeof PipelineProductEnum];


export function instanceOfPipelineProductEnum(value: any): boolean {
    return Object.values(PipelineProductEnum).includes(value);
}

export function PipelineProductEnumFromJSON(json: any): PipelineProductEnum {
    return PipelineProductEnumFromJSONTyped(json, false);
}

export function PipelineProductEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PipelineProductEnum {
    return json as PipelineProductEnum;
}

export function PipelineProductEnumToJSON(value?: PipelineProductEnum | null): any {
    return value as any;
}

