/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FilterGroup } from './FilterGroup';
import {
    FilterGroupFromJSON,
    FilterGroupFromJSONTyped,
    FilterGroupToJSON,
} from './FilterGroup';

/**
 * 
 * @export
 * @interface PaginatedFilterGroupList
 */
export interface PaginatedFilterGroupList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedFilterGroupList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedFilterGroupList
     */
    next?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedFilterGroupList
     */
    previous?: string;
    /**
     * 
     * @type {Array<FilterGroup>}
     * @memberof PaginatedFilterGroupList
     */
    results: Array<FilterGroup>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedFilterGroupList
     */
    numPages?: number;
}

/**
 * Check if a given object implements the PaginatedFilterGroupList interface.
 */
export function instanceOfPaginatedFilterGroupList(value: object): boolean {
    if (!('count' in value)) return false;
    if (!('results' in value)) return false;
    return true;
}

export function PaginatedFilterGroupListFromJSON(json: any): PaginatedFilterGroupList {
    return PaginatedFilterGroupListFromJSONTyped(json, false);
}

export function PaginatedFilterGroupListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedFilterGroupList {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(FilterGroupFromJSON)),
        'numPages': json['num_pages'] == null ? undefined : json['num_pages'],
    };
}

export function PaginatedFilterGroupListToJSON(value?: PaginatedFilterGroupList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(FilterGroupToJSON)),
        'num_pages': value['numPages'],
    };
}

