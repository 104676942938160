/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DataPointMapLocation } from './DataPointMapLocation';
import {
    DataPointMapLocationFromJSON,
    DataPointMapLocationFromJSONTyped,
    DataPointMapLocationToJSON,
} from './DataPointMapLocation';

/**
 * Custom serializer that returns map data.
 * @export
 * @interface EmissionRecordMap
 */
export interface EmissionRecordMap {
    /**
     * 
     * @type {string}
     * @memberof EmissionRecordMap
     */
    readonly id: string;
    /**
     * 
     * @type {number}
     * @memberof EmissionRecordMap
     */
    detectedRate: number;
    /**
     * 
     * @type {number}
     * @memberof EmissionRecordMap
     */
    concentration: number;
    /**
     * 
     * @type {DataPointMapLocation}
     * @memberof EmissionRecordMap
     */
    location: DataPointMapLocation;
    /**
     * 
     * @type {string}
     * @memberof EmissionRecordMap
     */
    plumeImage: string;
}

/**
 * Check if a given object implements the EmissionRecordMap interface.
 */
export function instanceOfEmissionRecordMap(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('detectedRate' in value)) return false;
    if (!('concentration' in value)) return false;
    if (!('location' in value)) return false;
    if (!('plumeImage' in value)) return false;
    return true;
}

export function EmissionRecordMapFromJSON(json: any): EmissionRecordMap {
    return EmissionRecordMapFromJSONTyped(json, false);
}

export function EmissionRecordMapFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmissionRecordMap {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'detectedRate': json['detected_rate'],
        'concentration': json['concentration'],
        'location': DataPointMapLocationFromJSON(json['location']),
        'plumeImage': json['plume_image'],
    };
}

export function EmissionRecordMapToJSON(value?: Omit<EmissionRecordMap, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'detected_rate': value['detectedRate'],
        'concentration': value['concentration'],
        'location': DataPointMapLocationToJSON(value['location']),
        'plume_image': value['plumeImage'],
    };
}

