/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Custom serializer that adds permission fields.
 * @export
 * @interface Permissions
 */
export interface Permissions {
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    mapView?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    mapEdit?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    emissionEventView?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    emissionEventEdit?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    emissionView?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    emissionEdit?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    emissionExport?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    infrastructureView?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    infrastructureEdit?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    infrastructureBuilderView?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    infrastructureBuilderEdit?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    dataUploadsView?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    dataUploadsEdit?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    dataDownloadView?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    dataDownloadEdit?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    notificationsView?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    notificationsEdit?: boolean;
}

/**
 * Check if a given object implements the Permissions interface.
 */
export function instanceOfPermissions(value: object): boolean {
    return true;
}

export function PermissionsFromJSON(json: any): Permissions {
    return PermissionsFromJSONTyped(json, false);
}

export function PermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Permissions {
    if (json == null) {
        return json;
    }
    return {
        
        'mapView': json['map_view'] == null ? undefined : json['map_view'],
        'mapEdit': json['map_edit'] == null ? undefined : json['map_edit'],
        'emissionEventView': json['emission_event_view'] == null ? undefined : json['emission_event_view'],
        'emissionEventEdit': json['emission_event_edit'] == null ? undefined : json['emission_event_edit'],
        'emissionView': json['emission_view'] == null ? undefined : json['emission_view'],
        'emissionEdit': json['emission_edit'] == null ? undefined : json['emission_edit'],
        'emissionExport': json['emission_export'] == null ? undefined : json['emission_export'],
        'infrastructureView': json['infrastructure_view'] == null ? undefined : json['infrastructure_view'],
        'infrastructureEdit': json['infrastructure_edit'] == null ? undefined : json['infrastructure_edit'],
        'infrastructureBuilderView': json['infrastructure_builder_view'] == null ? undefined : json['infrastructure_builder_view'],
        'infrastructureBuilderEdit': json['infrastructure_builder_edit'] == null ? undefined : json['infrastructure_builder_edit'],
        'dataUploadsView': json['data_uploads_view'] == null ? undefined : json['data_uploads_view'],
        'dataUploadsEdit': json['data_uploads_edit'] == null ? undefined : json['data_uploads_edit'],
        'dataDownloadView': json['data_download_view'] == null ? undefined : json['data_download_view'],
        'dataDownloadEdit': json['data_download_edit'] == null ? undefined : json['data_download_edit'],
        'notificationsView': json['notifications_view'] == null ? undefined : json['notifications_view'],
        'notificationsEdit': json['notifications_edit'] == null ? undefined : json['notifications_edit'],
    };
}

export function PermissionsToJSON(value?: Permissions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'map_view': value['mapView'],
        'map_edit': value['mapEdit'],
        'emission_event_view': value['emissionEventView'],
        'emission_event_edit': value['emissionEventEdit'],
        'emission_view': value['emissionView'],
        'emission_edit': value['emissionEdit'],
        'emission_export': value['emissionExport'],
        'infrastructure_view': value['infrastructureView'],
        'infrastructure_edit': value['infrastructureEdit'],
        'infrastructure_builder_view': value['infrastructureBuilderView'],
        'infrastructure_builder_edit': value['infrastructureBuilderEdit'],
        'data_uploads_view': value['dataUploadsView'],
        'data_uploads_edit': value['dataUploadsEdit'],
        'data_download_view': value['dataDownloadView'],
        'data_download_edit': value['dataDownloadEdit'],
        'notifications_view': value['notificationsView'],
        'notifications_edit': value['notificationsEdit'],
    };
}

