import { Tab } from "@headlessui/react";
import { ArrowPathIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/sharp-light-svg-icons";
import { useMapData } from "../hooks/mapDataAndFilters";

interface SidebarSectionTitleProps {
    title: string;
    action?: {
        label: string;
        fn: () => void;
    };
}

export const SidebarSectionTitle = (props: SidebarSectionTitleProps) => (
    <div className="flex items-center w-full h-12 py-2 px-4 bg-neutral-50 border border-zinc-200 text-sm">
        <p className="font-semibold flex-grow">{props.title}</p>
        {props.action && (
            <button
                className="flex gap-2 items-center justify-center text-ae-blue-500 text-sm"
                onClick={props.action.fn}
            >
                {props.action.label}
                <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare}
                    className="w-3"
                />
            </button>
        )}
    </div>
);

export const LoadingSection = () => (
    <div className="h-40 flex flex-col justify-center text-neutral-600 animate-pulse">
        <div className="w-full flex justify-center items-center mb-2">
            <ArrowPathIcon className="h-10 animate-spin" />
        </div>
        <div className="w-full flex justify-center items-center">
            Loading data...
        </div>
    </div>
);

interface SidebarSectionProps {
    title: string;
    loading: boolean;
    children: ReactNode;
    action?: {
        label: string;
        fn: () => void;
    };
}

export const SidebarSection = (props: SidebarSectionProps) => (
    <div>
        <SidebarSectionTitle title={props.title} action={props.action} />
        <div className="px-4 py-2">
            {props.loading ? <LoadingSection /> : props.children}
        </div>
    </div>
);

interface SidebarSkeletonProps {
    title: string;
    tabs: {
        title: string;
        content: ReactNode;
    }[];
    onClose: () => void;
}

export const SidebarSkeleton = (props: SidebarSkeletonProps) => {
    return (
        <div>
            <div className="flex px-4 items-center mt-6 mb-1 font-semibold">
                <p className="flex-grow">{props.title}</p>
                <button
                    className="p-2 rounded hover:bg-ae-slate-300"
                    onClick={() => props.onClose && props.onClose()}
                >
                    <XMarkIcon className="h-4" />
                </button>
            </div>

            <Tab.Group>
                <Tab.List className="text-sm flex gap-4 px-4 h-10">
                    {props.tabs.map((tab, index) => (
                        <Tab as={Fragment} key={index}>
                            {({ selected }) => (
                                <button
                                    className={`border-b-2 border-white ${
                                        selected &&
                                        "border-b-ae-blue-500 text-ae-blue-500"
                                    }`}
                                >
                                    {tab.title}
                                </button>
                            )}
                        </Tab>
                    ))}
                </Tab.List>
                <Tab.Panels>
                    {props.tabs.map((tab, index) => (
                        <Tab.Panel key={index}>{tab.content}</Tab.Panel>
                    ))}
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
};

interface MapSidebarSkeletonProps {
    title: string;
    tabs: {
        title: string;
        content: ReactNode;
    }[];
}

export const MapSidebarSkeleton = (props: MapSidebarSkeletonProps) => {
    const { setSelectedContext } = useMapData("mainMap");
    return (
        <SidebarSkeleton {...props} onClose={() => setSelectedContext({})} />
    );
};
