/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `EMAIL` - Email
 * * `TEAMS_WEBHOOK` - Teams Webhook
 * @export
 */
export const ChannelTypeEnum = {
    Email: 'EMAIL',
    TeamsWebhook: 'TEAMS_WEBHOOK'
} as const;
export type ChannelTypeEnum = typeof ChannelTypeEnum[keyof typeof ChannelTypeEnum];


export function instanceOfChannelTypeEnum(value: any): boolean {
    return Object.values(ChannelTypeEnum).includes(value);
}

export function ChannelTypeEnumFromJSON(json: any): ChannelTypeEnum {
    return ChannelTypeEnumFromJSONTyped(json, false);
}

export function ChannelTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChannelTypeEnum {
    return json as ChannelTypeEnum;
}

export function ChannelTypeEnumToJSON(value?: ChannelTypeEnum | null): any {
    return value as any;
}

