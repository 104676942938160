import { DashboardCard } from "../../ui/Card";
import {
    DashboardMetric,
    DashboardStatsGroup,
} from "../../ui/DashboardComponents";
import {
    useEmissionEventsApiClient,
    useEmissionRecordsApiClient,
} from "../../hooks";
import { useQuery } from "@tanstack/react-query";
import { EmissioneventsListDataSourceEnum } from "../../apiClient/generated";
import { EmissionAndEventManagement } from "./common";
import { DataExport } from "./DataExport";

const StatsPanel = () => {
    const emissionApiClient = useEmissionRecordsApiClient();
    const eventsApiClient = useEmissionEventsApiClient();

    const emissionsQuery = useQuery({
        queryKey: ["selfReportedEmissionRecordStats"],
        queryFn: async () => {
            return await emissionApiClient.emissionRecordsEmissionRecordStatsRetrieve(
                {
                    dataSource: [EmissioneventsListDataSourceEnum.SelfReported],
                },
            );
        },
    });

    const eventsQuery = useQuery({
        queryKey: ["selfReportedEventStats"],
        queryFn: async () => {
            return await eventsApiClient.emissioneventsEventStatsRetrieve({
                dataSource: [EmissioneventsListDataSourceEnum.SelfReported],
            });
        },
    });

    return (
        <div className="p-6 flex justify-between">
            <DashboardStatsGroup title="My Company Monitoring Events">
                <DashboardMetric
                    value={emissionsQuery.data?.lastNDays}
                    label="Added last 90 days"
                />
                <DashboardMetric
                    value={emissionsQuery.data?.total}
                    label="Total Events"
                />
            </DashboardStatsGroup>
            <DashboardStatsGroup title="Emission Rates">
                <DashboardMetric
                    value={`${(emissionsQuery.data?.highestRate / 1000).toFixed(
                        0,
                    )} kg/h`}
                    label={"Highest event"}
                />
                <DashboardMetric
                    value={`${(emissionsQuery.data?.avgRate / 1000).toFixed(
                        0,
                    )} kg/h`}
                    label={"Average"}
                />
            </DashboardStatsGroup>
            <DashboardStatsGroup title="Event Status">
                <DashboardMetric
                    value={emissionsQuery.data?._new}
                    label={"New"}
                />
                <DashboardMetric
                    value={eventsQuery.data?.inProgress}
                    label={"In progress"}
                />
                <DashboardMetric
                    value={eventsQuery.data?.complete}
                    label={"Completed"}
                />
            </DashboardStatsGroup>
        </div>
    );
};

export const SelfReportedEmissionDashboard = () => {
    return (
        <div>
            <DataExport dataSource="SELF_REPORTED" />
            <DashboardCard
                flair={{
                    label: "My Company Monitoring",
                    className: "bg-ae-emission-self-reported",
                }}
            >
                <StatsPanel />
            </DashboardCard>
            <EmissionAndEventManagement
                dataSource={["SELF_REPORTED"]}
                eventModalTitle="My Company Monitoring"
            />
        </div>
    );
};
