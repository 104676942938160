import { Square2StackIcon } from "@heroicons/react/24/outline";
import { SUPPORT_EMAIL } from "../../constants/globals";
import { Card } from "../../ui/Card";
import { useCopyToClipboard } from "@uidotdev/usehooks";
import StyledLink from "../../components/Link";
import { ROUTES } from "..";

export const SupportPage = () => {
    const [, copyToClipBoard] = useCopyToClipboard();

    return (
        <div className="prose px-6 max-w-6xl mx-auto">
            <h1 className="flex pt-10 font-bold font-sans antialiased text-inherit text-xl">
                Help & Support
            </h1>

            <Card>
                <p className="flex items-center text-base">
                    If you need help, please e-mail us at{" "}
                    <a
                        className="text-base text-blue-700 ml-1 mr-2"
                        href={`mailto:${SUPPORT_EMAIL}`}
                    >
                        {SUPPORT_EMAIL}
                    </a>
                    <button
                        className="flex items-center text-sm justify-center"
                        onClick={() => copyToClipBoard(SUPPORT_EMAIL)}
                    >
                        <Square2StackIcon className="w-4 h-4 mr-1" />
                        Copy
                    </button>
                </p>

                <hr className="my-4" />

                <p className="text-base">
                    Useful links:
                    <StyledLink to={ROUTES.TERMS_OF_SERVICE}>
                        Terms of Service
                    </StyledLink>
                    <StyledLink to={ROUTES.PRIVACY_POLICY}>
                        Privacy Policy
                    </StyledLink>
                </p>
                <p className="text-base">
                    Guides:
                    <a
                        href="/AERSHED-User-Guide.pdf"
                        className="
                            text-blue-800 hover:cursor-pointer hover:underline flex items-center
                        "
                    >
                        AERSHED User Guide
                    </a>
                </p>
            </Card>
        </div>
    );
};
