import { ReactNode } from "react";

interface ControlContainerProps {
    children: ReactNode;
    className?: string;
}

export const ControlContainer = (props: ControlContainerProps) => (
    <div
        className={`rounded bg-neutral-50 bg-opacity-70 border border-zinc-100 flex ${props.className}`}
    >
        {props.children}
    </div>
);
