/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AdminInfrastructureImportItem } from './AdminInfrastructureImportItem';
import {
    AdminInfrastructureImportItemFromJSON,
    AdminInfrastructureImportItemFromJSONTyped,
    AdminInfrastructureImportItemToJSON,
} from './AdminInfrastructureImportItem';

/**
 * 
 * @export
 * @interface PaginatedAdminInfrastructureImportItemList
 */
export interface PaginatedAdminInfrastructureImportItemList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedAdminInfrastructureImportItemList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAdminInfrastructureImportItemList
     */
    next?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAdminInfrastructureImportItemList
     */
    previous?: string;
    /**
     * 
     * @type {Array<AdminInfrastructureImportItem>}
     * @memberof PaginatedAdminInfrastructureImportItemList
     */
    results: Array<AdminInfrastructureImportItem>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedAdminInfrastructureImportItemList
     */
    numPages?: number;
}

/**
 * Check if a given object implements the PaginatedAdminInfrastructureImportItemList interface.
 */
export function instanceOfPaginatedAdminInfrastructureImportItemList(value: object): boolean {
    if (!('count' in value)) return false;
    if (!('results' in value)) return false;
    return true;
}

export function PaginatedAdminInfrastructureImportItemListFromJSON(json: any): PaginatedAdminInfrastructureImportItemList {
    return PaginatedAdminInfrastructureImportItemListFromJSONTyped(json, false);
}

export function PaginatedAdminInfrastructureImportItemListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedAdminInfrastructureImportItemList {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(AdminInfrastructureImportItemFromJSON)),
        'numPages': json['num_pages'] == null ? undefined : json['num_pages'],
    };
}

export function PaginatedAdminInfrastructureImportItemListToJSON(value?: PaginatedAdminInfrastructureImportItemList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(AdminInfrastructureImportItemToJSON)),
        'num_pages': value['numPages'],
    };
}

