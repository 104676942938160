import { ReactNode } from "react";
import { PrimaryButton } from "./Buttons";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate } from "@fortawesome/pro-light-svg-icons";

interface DashboardMetricProps {
    value?: string | number;
    label: string;
}

export const DashboardMetric = (props: DashboardMetricProps) => (
    <div className="w-32">
        <div className="text-xl mb-1">{props.value}</div>
        <div className="text-sm text-zinc-600 font-semibold">{props.label}</div>
    </div>
);

interface DashboardStatsGroupProps {
    title: string;
    children: ReactNode;
}

export const DashboardStatsGroup = (props: DashboardStatsGroupProps) => (
    <div className="whitespace-nowrap">
        <p className="text-sm font-semibold leading-snug flex items-center mb-2">
            {props.title}
            <ChevronRightIcon className="w-3 ml-2 " />
        </p>
        <div className="flex gap-10">{props.children}</div>
    </div>
);

interface DashboardContainerProps {
    children: ReactNode;
}

export const DashboardContainer = (props: DashboardContainerProps) => (
    <div className="flex w-full px-6 py-8">
        <div className="flex flex-col w-full bg-white rounded-md border-slate-200 border border-solid">
            {props.children}
        </div>
    </div>
);

interface DashboardHeaderProps {
    title?: string;
    description?: string | ReactNode;
    actions?: {
        label: string | ReactNode;
        fn: () => void;
    }[];
}

export const DashboardHeader = (props: DashboardHeaderProps) => (
    <div className="p-4 border-b flex justify-between items-center">
        <div>
            <h1 className="text-ae-blue-900 font-semibold text-xl mb-1">
                {props.title ? (
                    props.title
                ) : (
                    <FontAwesomeIcon
                        icon={faRotate}
                        className="h-4 animate-spin"
                    />
                )}
            </h1>
            {props.description && (
                <p className="text-slate-500 font-medium text-sm">
                    {props.description}
                </p>
            )}
        </div>
        {props.actions && (
            <div className="flex gap-2">
                {props.actions.map((action, index) => (
                    <PrimaryButton key={index} onClick={action.fn}>
                        {action.label}
                    </PrimaryButton>
                ))}
            </div>
        )}
    </div>
);
