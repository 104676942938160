import { useMemo, useState } from "react";

export const CookieBanner = () => {
    const [changed, setChanged] = useState(false);
    const acceptedCookies = useMemo(
        () => localStorage.getItem("cookiesAccepted") === "yes",
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [changed],
    );

    if (acceptedCookies) {
        return null;
    }

    return (
        <div className="w-full p-2 flex items-center justify-center bg-slate-200">
            This website requires cookies to work.
            <button
                onClick={() => {
                    localStorage.setItem("cookiesAccepted", "yes");
                    setChanged(true);
                }}
                className="ml-2 p-2 bg-slate-300 rounded-lg font-bold hover:bg-slate-700 hover:text-white "
            >
                Accept
            </button>
        </div>
    );
};
