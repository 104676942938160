import { useAtom } from "jotai";
import { useCallback } from "react";
import { MapState, mapStateFamily } from "../state";
import { LinearInterpolator, TRANSITION_EVENTS } from "@deck.gl/core";

export const useMap = (
    // FIXME: to avoid having to pass mapId everytime, accept either this input or a context (?)
    mapId: string,
    initialState?: Partial<MapState>,
) => {
    // Atom to hold map state here.
    const [mapState, setMapState] = useAtom(
        mapStateFamily({ mapId: mapId, initialState }),
    );

    // helper functions (navigate)
    const zoomIn = useCallback(() => {
        setMapState((ms) => ({
            ...ms,
            _viewState: {
                ...ms._viewState,
                zoom: ms._viewState.zoom + 1,
            },
        }));
    }, [setMapState]);

    const zoomOut = useCallback(() => {
        setMapState((ms) => ({
            ...ms,
            _viewState: {
                ...ms._viewState,
                zoom: Math.max(ms._viewState.zoom - 1, 1.5),
            },
        }));
    }, [setMapState]);

    const flyTo = useCallback(
        (
            latitude: number,
            longitude: number,
            zoom?: number,
            useInterpolator?: boolean,
        ) => {
            setMapState((ms) => ({
                ...ms,
                _viewState: {
                    ...ms._viewState,
                    latitude,
                    longitude,
                    zoom: zoom || ms._viewState.zoom,
                    transitionInterpolator: useInterpolator
                        ? new LinearInterpolator()
                        : undefined,
                    transitionDuration: 300,
                    transitionInterruption: TRANSITION_EVENTS.BREAK,
                    onTransitionEnd: () => {
                        setMapState((i) => {
                            i._viewState.transitionInterpolator = undefined;
                            return i;
                        });
                    },
                },
            }));
        },
        [setMapState],
    );

    return {
        ...mapState,
        setMapState,
        zoomIn,
        zoomOut,
        flyTo,
    };
};
