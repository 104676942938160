import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { ROUTES } from "../../routes";
import StyledLink from "../Link";

export const PasswordResetComplete = () => (
    <>
        <div className="w-full flex justify-center mt-12">
            <CheckCircleIcon className="w-16 h-16 text-green-500" />
        </div>
        <div className="w-full flex justify-center mt-6">
            Password reset complete.
        </div>
        <div className="w-full flex justify-center">
            <StyledLink to={ROUTES.LOGIN}>
                Click here to go back to the login page.
            </StyledLink>
        </div>
    </>
);
