/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * EPA SEP stats serializer.
 * @export
 * @interface SepEventStats
 */
export interface SepEventStats {
    /**
     * 
     * @type {Date}
     * @memberof SepEventStats
     */
    dueDate: Date;
    /**
     * 
     * @type {number}
     * @memberof SepEventStats
     */
    completed: number;
    /**
     * 
     * @type {number}
     * @memberof SepEventStats
     */
    inProgress: number;
    /**
     * 
     * @type {number}
     * @memberof SepEventStats
     */
    notStartedCount: number;
    /**
     * 
     * @type {number}
     * @memberof SepEventStats
     */
    myCompleted: number;
    /**
     * 
     * @type {number}
     * @memberof SepEventStats
     */
    myInProgress: number;
    /**
     * 
     * @type {number}
     * @memberof SepEventStats
     */
    myNotStartedCount: number;
}

/**
 * Check if a given object implements the SepEventStats interface.
 */
export function instanceOfSepEventStats(value: object): boolean {
    if (!('dueDate' in value)) return false;
    if (!('completed' in value)) return false;
    if (!('inProgress' in value)) return false;
    if (!('notStartedCount' in value)) return false;
    if (!('myCompleted' in value)) return false;
    if (!('myInProgress' in value)) return false;
    if (!('myNotStartedCount' in value)) return false;
    return true;
}

export function SepEventStatsFromJSON(json: any): SepEventStats {
    return SepEventStatsFromJSONTyped(json, false);
}

export function SepEventStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SepEventStats {
    if (json == null) {
        return json;
    }
    return {
        
        'dueDate': (new Date(json['due_date'])),
        'completed': json['completed'],
        'inProgress': json['in_progress'],
        'notStartedCount': json['not_started_count'],
        'myCompleted': json['my_completed'],
        'myInProgress': json['my_in_progress'],
        'myNotStartedCount': json['my_not_started_count'],
    };
}

export function SepEventStatsToJSON(value?: SepEventStats | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'due_date': ((value['dueDate']).toISOString().substring(0,10)),
        'completed': value['completed'],
        'in_progress': value['inProgress'],
        'not_started_count': value['notStartedCount'],
        'my_completed': value['myCompleted'],
        'my_in_progress': value['myInProgress'],
        'my_not_started_count': value['myNotStartedCount'],
    };
}

