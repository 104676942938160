import { object, boolean, array, string, date } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { EpaForm } from "../../../apiClient/generated";
import { useEpaSepApiClient } from "../../../hooks";
import { useEffect } from "react";

export const formSchema = object({
    markAsSubmitted: boolean().optional(),
    responsibleForEmission: boolean().nullable().optional(),
    apiWellId: string().optional(),
    facilityName: string().optional(),
    address: string().optional(),
    assertDemonstrableError: boolean().nullable().optional(),
    statementDemonstrableError: string().optional(),
    affectedFacility: boolean().nullable().optional(),
    identifiedSource: boolean().nullable().optional(),
    investigationSteps: array().of(boolean().nullable()).optional(),
    alternateTestMethods: string().optional(),
    investigationDate: date().optional(),
    investigationTime: string().optional(),
    investigationTimezone: string().optional(),
    certifiesAllInvestigationsDone: boolean().nullable().optional(),
    sourceOfEmission: string().optional(),
    subjectToRegulation: array().of(boolean().nullable()).optional(),
    citationOfRegulation: string().optional(),
    eventStillOngoing: boolean().optional(),
    eventEndDate: date().optional(),
    isEstimate: boolean().optional(),
    eventEndTime: string().optional(),
    targetedEndDate: date().optional(),
    planForEvent: string().optional(),
});

export const useEpaForm = (epaForm: EpaForm, onSuccess: () => void) => {
    const queryClient = useQueryClient();
    const apiClient = useEpaSepApiClient();

    // Form setup
    const form = useForm({
        mode: "onChange",
        defaultValues: {
            markAsSubmitted: false,
            responsibleForEmission: epaForm.responsibleForEmission,
            apiWellId: epaForm.apiWellId,
            facilityName: epaForm.facilityName,
            address: epaForm.address,
            assertDemonstrableError: epaForm.assertDemonstrableError,
            statementDemonstrableError: epaForm.statementDemonstrableError,
            affectedFacility: epaForm.affectedFacility,
            identifiedSource: epaForm.identifiedSource,
            investigationSteps: epaForm.investigationSteps,
            alternateTestMethods: epaForm.alternateTestMethods,
            investigationDate: epaForm.investigationDate,
            investigationTime: epaForm.investigationTime,
            investigationTimezone: epaForm.investigationTimezone,
            certifiesAllInvestigationsDone:
                epaForm.certifiesAllInvestigationsDone,
            sourceOfEmission: epaForm.sourceOfEmission,
            subjectToRegulation: epaForm.subjectToRegulation,
            citationOfRegulation: epaForm.citationOfRegulation,
            eventStillOngoing: epaForm.eventStillOngoing,
            eventEndDate: epaForm.eventEndDate,
            isEstimate: epaForm.isEstimate,
            eventEndTime: epaForm.eventEndTime,
            targetedEndDate: epaForm.targetedEndDate,
            planForEvent: epaForm.planForEvent,
        },
        resolver: yupResolver(formSchema),
    });

    useEffect(() => {
        form.reset(epaForm);
    }, [epaForm]);

    const saveChanges = async (data) => {
        // Get the dirty/touched fields from the form
        const dirtyFields = form.formState.dirtyFields;
        const { markAsSubmitted, ...restData } = data;

        // Create patch data excluding markAsSubmitted
        const patchData = Object.keys(dirtyFields).reduce(
            (acc, key) => {
                if (key !== "markAsSubmitted" && dirtyFields[key]) {
                    acc[key] = restData[key];
                }
                return acc;
            },
            {} as Partial<typeof restData>,
        );

        // Only make the patch request if there are changes other than markAsSubmitted
        if (Object.keys(patchData).length > 0) {
            await apiClient.epaSepFormPartialUpdateRaw({
                id: epaForm.id,
                patchedEpaFormRequest: patchData,
            });
        }

        // If markAsSubmitted is true, make an additional request
        if (markAsSubmitted) {
            await apiClient.epaSepNotificationsPartialUpdate({
                id: epaForm.operatorNotification.id,
                patchedEpaOperatorNotificationRequest: {
                    status: "SUBMITTED_TO_EPA",
                },
            });
        }

        form.reset();
        onSuccess();
        queryClient.invalidateQueries();
    };

    return {
        form,
        submit: form.handleSubmit(saveChanges),
    };
};
