import { LockClosedIcon } from "@heroicons/react/24/solid";
import { useAppSelector } from "../hooks";
import { ROUTES } from ".";
import { Link } from "react-router-dom";

interface SettingsCardProps {
    label: string;
    description: string;
    disabled: boolean;
    locked: boolean;
    to: string;
}

const SettingsCard = (props: SettingsCardProps) => {
    return (
        <Link
            to={props.disabled || props.locked ? "" : props.to}
            className={`
                w-full h-20 px-6 py-4 
                rounded shadow border border-gray-300 
                justify-start items-end inline-flex 
                ${
                    props.disabled || props.locked
                        ? "bg-neutral-200 cursor-default"
                        : "cursor-pointer hover:shadow-lg"
                }
            `}
        >
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                <div className="text-stone-900 text-lg font-semibold leading-7 flex items-center gap-2">
                    {props.label}
                    {props.locked && <LockClosedIcon className="w-4" />}
                </div>
                <div className="self-stretch text-zinc-600 text-sm leading-snug">
                    {props.description}
                </div>
            </div>
        </Link>
    );
};

export const SettingsHomePage = () => {
    const userPermissions = useAppSelector((state) => state.auth.permissions);

    return (
        <div className="w-full h-screen pt-10 flex flex-col items-center">
            <div className="min-w-1/3">
                <div className="p-8">
                    <h1 className="text-stone-900 text-xl font-semibold leading-7">
                        Settings
                    </h1>
                    <p className="text-zinc-600 text-snug text-sm leading-snug">
                        Configure your account and your companies settings
                        through this screen.
                    </p>
                </div>
                <div className="flex flex-col gap-4">
                    <SettingsCard
                        to=""
                        label="Account"
                        description="Change user settings, password and email."
                        disabled={true}
                        locked={false}
                    />
                    <SettingsCard
                        to={ROUTES.SETTINGS_NOTIFICATIONS}
                        label="Notification Settings"
                        description="Change company notification settings, create email groups and manage webhooks."
                        disabled={false}
                        locked={userPermissions.notificationsView !== true}
                    />
                </div>
            </div>
            <div className="absolute bottom-0 right-0 text-sm text-gray-400">
                Build number: {__BUILD_DATE__}
            </div>
        </div>
    );
};
