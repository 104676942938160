import {
    CheckIcon,
    PencilSquareIcon,
    XMarkIcon,
} from "@heroicons/react/20/solid";
import React, { ReactElement, ReactNode, useRef, useState } from "react";
import { InfraTypeEnum } from "../../../apiClient/generated";
import { useOnClickAway } from "../../../hooks";
import { ArrowPathIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import { DetailView, DetailViewActions } from "../../DetailView";
import { CustomSwitch } from "../../CustomSwitch";
import { DateTime } from "luxon";

interface EditableCellProps {
    onCancel?: () => void;
    onSave: () => Promise<void>;
    valueDisplay: ReactElement | string;
    editable: ReactElement;
    useDetailView?: boolean;
    title?: string;
    disableSave?: boolean;
}

export const EditableCell = (props: EditableCellProps) => {
    const ref = useRef();
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);

    useOnClickAway(ref, () => {
        if (!props.useDetailView) {
            setEditing(false);
            props.onCancel();
        }
    });

    if (loading) {
        return (
            <span className="flex items-center space-x-1">
                Saving
                <ArrowPathIcon className="ml-2 h-4 w-4 animate-spin" />
            </span>
        );
    }

    return (
        <div className="flex items-center -my-1" ref={ref}>
            {editing ? (
                props.useDetailView ? (
                    <DetailView
                        title={props.title || "Edit"}
                        visible={editing}
                        onClose={() => {
                            setEditing(false);
                            props.onCancel();
                        }}
                    >
                        {props.editable}
                        <DetailViewActions
                            actions={[
                                {
                                    action: () => {
                                        setEditing(false);
                                        props.onCancel();
                                    },
                                    label: "Cancel",
                                },
                                {
                                    action: () => {
                                        setEditing(false);
                                        props.onSave();
                                    },
                                    label: "Save",
                                    disabled: props.disableSave,
                                },
                            ]}
                        />
                    </DetailView>
                ) : (
                    <div
                        onKeyDownCapture={async (e) => {
                            if (e.key === "Enter") {
                                setEditing(false);
                                setLoading(true);
                                await props.onSave();
                                setLoading(false);
                            } else if (e.key === "Escape") {
                                setEditing(false);
                                if (props.onCancel) {
                                    props.onCancel();
                                }
                            }
                        }}
                    >
                        {props.editable}
                        <button
                            className="hover:bg-slate-600 hover:text-white p-1 ml-1 rounded-md"
                            disabled={props.disableSave === true}
                            onClick={async () => {
                                setEditing(false);
                                setLoading(true);
                                await props.onSave();
                                setLoading(false);
                            }}
                        >
                            <CheckIcon className="h-4 w-4" />
                        </button>
                        <button
                            className="hover:bg-slate-600 hover:text-white p-1 ml-1 rounded-md"
                            onClick={() => {
                                setEditing(false);
                                if (props.onCancel) {
                                    props.onCancel();
                                }
                            }}
                        >
                            <XMarkIcon className="h-4 w-4" />
                        </button>
                    </div>
                )
            ) : (
                <>
                    <button
                        className="flex items-center gap-x-2 p-2 hover:bg-slate-500 hover:text-white rounded-md"
                        onClick={() => setEditing(true)}
                    >
                        {props.valueDisplay}
                        <PencilSquareIcon className="h-4 w-4" />
                    </button>
                </>
            )}
        </div>
    );
};

interface DetailViewEditableCellProps {
    onCancel?: () => void;
    onSave: () => Promise<void>;
    children: (editing: boolean) => ReactNode;
    title?: string;
    disableSave?: boolean;
}

/**
 * DetailViewEditableCell
 *
 * This passes render props ("editable": boolean) to the children, that way the
 * parent component decides when and what to render.
 * This also allows us to only fetch item data once the parmeter is edited,
 * avoiding repetitive calls to the backend and issues with table consistency.
 */
export const DetailViewEditableCell = (props: DetailViewEditableCellProps) => {
    const ref = useRef();
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);

    if (loading) {
        return (
            <span className="flex space-x-1">
                Saving
                <ArrowPathIcon className="ml-2 h-4 w-4 animate-spin" />
            </span>
        );
    }

    return (
        <div className="flex items-center -my-1" ref={ref}>
            {editing ? (
                <DetailView
                    title={props.title || "Edit"}
                    visible={editing}
                    onClose={() => {
                        setEditing(false);
                        props.onCancel();
                    }}
                >
                    {props.children(editing)}
                    <DetailViewActions
                        actions={[
                            {
                                action: () => {
                                    setEditing(false);
                                    props.onCancel();
                                },
                                label: "Cancel",
                            },
                            {
                                action: async () => {
                                    setEditing(false);
                                    setLoading(true);
                                    await props.onSave();
                                    setLoading(false);
                                },
                                label: "Save",
                                disabled: props.disableSave,
                            },
                        ]}
                    />
                </DetailView>
            ) : (
                <>
                    <button
                        className="flex items-center gap-x-2 p-2 hover:bg-slate-500 hover:text-white rounded-md"
                        onClick={() => setEditing(true)}
                    >
                        {props.children(editing)}
                        <PencilSquareIcon className="h-4 w-4" />
                    </button>
                </>
            )}
        </div>
    );
};

interface EditableTextCellProps {
    initialValue: string;
    onChange: (newValue: string) => Promise<void>;
}

export const EditableTextCell = (props: EditableTextCellProps) => {
    const [value, setValue] = useState(props.initialValue);

    return (
        <EditableCell
            editable={
                <input
                    className="p-1 rounded-lg"
                    autoFocus
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                />
            }
            valueDisplay={props.initialValue}
            onCancel={() => setValue(props.initialValue)}
            onSave={() => props.onChange(value)}
        />
    );
};

interface EditableNumberCellProps {
    initialValue: number;
    onChange: (newValue: number) => Promise<void>;
}

export const EditableNumberCell = (props: EditableNumberCellProps) => {
    const [value, setValue] = useState(props.initialValue);

    return (
        <EditableCell
            editable={
                <input
                    className="p-1 rounded-lg"
                    autoFocus
                    type="number"
                    value={value}
                    onChange={(e) => setValue(+e.target.value)}
                />
            }
            valueDisplay={props.initialValue?.toString()}
            onCancel={() => setValue(props.initialValue)}
            onSave={() => props.onChange(value)}
        />
    );
};

interface EditableBooleanCellProps {
    initialValue: boolean;
    onChange: (newValue: boolean) => Promise<void>;
}

export const EditableBooleanCell = (props: EditableBooleanCellProps) => {
    const [value, setValue] = useState(props.initialValue);

    return (
        <EditableCell
            editable={
                <div className="flex gap-2">
                    <CustomSwitch
                        checked={value}
                        onChange={() => setValue(!value)}
                        size="sm"
                    />
                    <p>{value ? "Yes" : "No"}</p>
                </div>
            }
            valueDisplay={props.initialValue ? "Yes" : "No"}
            onCancel={() => setValue(props.initialValue)}
            onSave={() => props.onChange(value)}
        />
    );
};

interface EditableDateCellProps {
    initialValue?: Date;
    onChange: (newValue: Date) => Promise<void>;
}

export const EditableDateCell = (props: EditableDateCellProps) => {
    const [value, setValue] = useState(
        props.initialValue?.toISOString().split("T")[0],
    );

    return (
        <EditableCell
            editable={
                <div className="flex gap-2">
                    <input
                        type="date"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    />
                </div>
            }
            valueDisplay={
                props.initialValue &&
                DateTime.fromJSDate(props.initialValue)
                    .setZone("utc")
                    .toLocaleString(DateTime.DATE_SHORT)
            }
            onCancel={() =>
                setValue(props.initialValue?.toISOString().split("T")[0])
            }
            onSave={() => props.onChange(new Date(value))}
        />
    );
};

interface EditableInfraTypeCellProps {
    initialValue: string;
    onChange: (newValue: string) => Promise<void>;
}

export const EditableInfraTypeCell = (props: EditableInfraTypeCellProps) => {
    const [value, setValue] = useState(props.initialValue);
    return (
        <EditableCell
            editable={
                <select
                    className="p-1 rounded-lg"
                    autoFocus
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                >
                    {Object.keys(InfraTypeEnum).map((item) => (
                        <option value={InfraTypeEnum[item]} key={item}>
                            {InfraTypeEnum[item]
                                .replace("_", " ")
                                .toLowerCase()
                                .replace(/\b\w/g, (l) => l.toUpperCase())}
                        </option>
                    ))}
                </select>
            }
            valueDisplay={
                <span className="capitalize">
                    {props.initialValue.replace("_", " ").toLowerCase()}
                </span>
            }
            onCancel={() => setValue(props.initialValue)}
            onSave={() => props.onChange(value)}
        />
    );
};

interface EditableEnumCellProps<T> {
    initialValue?: T | "";
    onChange: (newValue?: T) => Promise<void>;
    possibleValues: Record<string, T>;
    nullable?: boolean;
}

export const EditableEnumCell = <T extends string>(
    props: EditableEnumCellProps<T>,
) => {
    const [value, setValue] = useState(props.initialValue || "");
    return (
        <EditableCell
            editable={
                <select
                    className="p-1 rounded-lg w-32"
                    autoFocus
                    value={value}
                    onChange={(e) => setValue(e.target.value as T)}
                >
                    {props.nullable && (
                        <option value={undefined} key="null_value">
                            {" "}
                        </option>
                    )}
                    {Object.entries(props.possibleValues).map(([k, v]) => (
                        <option value={v} key={k}>
                            {v
                                .replace("_", " ")
                                .toLowerCase()
                                .replace(/\b\w/g, (l) => l.toUpperCase())}
                        </option>
                    ))}
                </select>
            }
            valueDisplay={
                <span className="capitalize">
                    {props.initialValue?.replaceAll("_", " ").toLowerCase()}
                </span>
            }
            onCancel={() => setValue(props.initialValue)}
            onSave={() => props.onChange(value as T)}
        />
    );
};

interface EditableAttributesCellProps {
    initialValue: {
        [key: string]: string;
    };
    onChange: (newValue: { [key: string]: string }) => Promise<void>;
}

export const EditableAttributesCell = (props: EditableAttributesCellProps) => {
    const [attributes, setAttributes] = useState(
        Object.keys(props.initialValue).map((key, index) => {
            return {
                id: index,
                key: key,
                value: props.initialValue[key],
            };
        }),
    );
    const keys = Object.keys(props.initialValue);

    return (
        <EditableCell
            title="Edit extra attributes"
            editable={
                <div>
                    <table className="w-full">
                        <thead>
                            <tr>
                                <th>Key</th>
                                <th>Value</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {attributes.map((item) => (
                                <tr key={item.id}>
                                    <td className="mr-4 w-1/4">
                                        <input
                                            className="p-1 -ml-1 m-1 rounded-lg border-slate-200 bg-slate-50 border-2 w-full"
                                            value={item.key}
                                            onChange={(e) => {
                                                const newState = [
                                                    ...attributes,
                                                ];
                                                <input
                                                    className="p-1 -ml-1 m-1 rounded-lg border-slate-400"
                                                    value={item.key}
                                                    onChange={(e) => {
                                                        const newState = [
                                                            ...attributes,
                                                        ];
                                                        newState[item.id].key =
                                                            e.target.value;
                                                        setAttributes(newState);
                                                    }}
                                                />;
                                                newState[item.id].key =
                                                    e.target.value;
                                                setAttributes(newState);
                                            }}
                                        />
                                    </td>
                                    <td className="w-2/4">
                                        <input
                                            className="p-1 rounded-lg border-slate-200 bg-slate-50 border-2 w-full"
                                            value={item.value}
                                            onChange={(e) => {
                                                const newState = [
                                                    ...attributes,
                                                ];
                                                newState[item.id].value =
                                                    e.target.value;
                                                setAttributes(newState);
                                            }}
                                        />
                                    </td>
                                    <td className="w-1/4">
                                        <button
                                            className="p-1 mx-3 rounded-lg hover:bg-slate-600 hover:text-white"
                                            onClick={() => {
                                                const newState = [
                                                    ...attributes,
                                                ];
                                                newState.splice(item.id, 1);
                                                setAttributes(newState);
                                            }}
                                        >
                                            <TrashIcon className="h-5 w-5" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            <tr key="newItem">
                                <td>
                                    <button
                                        className="flex items-center -ml-1 px-4 mt-1 py-2 rounded-lg bg-slate-100 hover:bg-slate-600 hover:text-white"
                                        onClick={() => {
                                            const newState = [...attributes];
                                            newState.push({
                                                id: attributes.length,
                                                key: "",
                                                value: "",
                                            });
                                            setAttributes(newState);
                                        }}
                                    >
                                        Add line
                                        <PlusIcon className="ml-2 h-5 w-5" />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
            useDetailView={true}
            valueDisplay={
                <span>
                    {keys.length} items:
                    <span className="italic ml-1">
                        {keys.join(", ").substring(0, 10)}
                    </span>
                    ...
                </span>
            }
            onSave={() => {
                const newValue = {};
                attributes.forEach((item) => {
                    newValue[item.key] = item.value;
                });
                return props.onChange(newValue);
            }}
            onCancel={() => {
                setAttributes(
                    Object.keys(props.initialValue).map((key, index) => {
                        return {
                            id: index,
                            key: key,
                            value: props.initialValue[key],
                        };
                    }),
                );
            }}
        />
    );
};
