import { createColumnHelper } from "@tanstack/react-table";
import { DownloadableDataPackage } from "../apiClient/generated";
import { useDatadownloadsApiClient } from "../hooks";
import {
    ArrowDownTrayIcon,
    DocumentTextIcon,
    MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { useAtom } from "jotai";
import { DataTableV3, RowActionButtons } from "./DataTable/DataTableV3";
import { tableStateFamily } from "./DataTable/state";
import { useSearchParams } from "react-router-dom";
import { Modal } from "../ui/Modals";

const columnHelper = createColumnHelper<DownloadableDataPackage>();

export const DataDownloadDataTable = () => {
    const apiClient = useDatadownloadsApiClient();
    const [urlParams, setSearchParams] = useSearchParams();
    const [notes, setNotes] = useState<string>();

    // Clear the `search` parameter from the URL
    useEffect(() => {
        if (urlParams.get("search")) {
            setSearchParams(new URLSearchParams());
        }
    }, [urlParams]);

    const [filterSearch, setFilterSearch] = useState(urlParams.get("search"));
    const debouncedSearch = useDebounce(filterSearch, 400);

    // Set initial table state
    useAtom(
        tableStateFamily({
            tableId: "datapackagedownloads",
            initialState: {
                sorting: {
                    id: "uploaded_at",
                    desc: true,
                },
            },
        }),
    );

    const fetchFunction = async (props: any) => {
        return await apiClient.datadownloadsDataPackageList(props);
    };

    // Download archive action
    const downloadArchive = async (dataPackageId: string) => {
        const response =
            await apiClient.datadownloadsDataPackageDownloadUrlRetrieve({
                id: dataPackageId,
            });
        window.open(response.downloadUrl);
    };

    // Column definition
    const columns = [
        columnHelper.accessor("uploadedAt", {
            id: "uploadedAt",
            header: () => "Uploaded at",
            cell: (info) =>
                DateTime.fromJSDate(info.renderValue())
                    .setZone("utc")
                    .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS),
            enableSorting: true,
            size: 200,
        }),
        columnHelper.accessor("description", {
            id: "description",
            cell: (info) => info.getValue(),
            header: () => <span>Description</span>,
            enableSorting: true,
        }),
        columnHelper.accessor("dataDownloadStatus", {
            id: "dataDownloadStatus",
            cell: (info) => (
                <span className="flex items-center gap-2">
                    {info.getValue() === "READY" ? (
                        <>
                            <div className="h-2 w-2 rounded-full bg-green-600" />
                            <span>Ready</span>
                        </>
                    ) : (
                        <>
                            <div className="h-2 w-2 rounded-full bg-yellow-400 animate-pulse" />
                            <span className="animate-pulse">Processing</span>
                        </>
                    )}
                </span>
            ),
            header: () => "Status",
            enableSorting: false,
            size: 300,
        }),
        columnHelper.accessor("notes", {
            id: "notes",
            cell: (info) => (
                <span className="line-clamp-1">{info.getValue()}</span>
            ),
            header: () => "Notes",
            enableSorting: false,
            size: 300,
        }),
        columnHelper.display({
            id: "actions",
            header: () => "",
            cell: (info) => {
                const actions = [];
                if (info.row.original.notes && info.row.original.notes !== "") {
                    actions.push({
                        fn: () => setNotes(info.row.original.notes),
                        icon: <DocumentTextIcon className="h-5 w-5" />,
                        tooltip: "View Notes",
                    });
                }
                if (info.row.original.dataDownloadStatus === "READY") {
                    actions.push({
                        fn: () => downloadArchive(info.row.original.id),
                        icon: <ArrowDownTrayIcon className="h-5 w-5" />,
                        tooltip: "Download",
                    });
                }
                return <RowActionButtons actions={actions} />;
            },
        }),
    ];

    return (
        <div className="flex-1 flex flex-col">
            {/* Search bar */}
            <div className="py-3 px-4 flex items-center text-sm justify-between border-b">
                <div className="flex items-center">
                    <div className="relative">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <MagnifyingGlassIcon className="w-5 h-5 text-ae-blue-900" />
                        </div>
                        <input
                            type="search"
                            id="default-search"
                            className="block w-96 p-2 ps-10 text-sm rounded-md placeholder:text-ae-blue-900 border border-ae-gray-200"
                            placeholder="Search by description or notes"
                            value={filterSearch}
                            onChange={(e) => setFilterSearch(e.target.value)}
                        />
                    </div>
                </div>
            </div>

            {/* Data table */}
            <DataTableV3<DownloadableDataPackage>
                dataName="datapackagedownloads"
                columns={columns}
                extraFilters={{
                    search: debouncedSearch,
                }}
                fetchFunction={fetchFunction}
                sortable={true}
                filterable={false}
            />

            {/* Notes modal */}
            <Modal
                size="sm"
                visible={!!notes}
                onClose={() => setNotes(undefined)}
            >
                <h1 className="text-ae-blue-900 text-lg font-semibold mb-2 mt-3">
                    Notes
                </h1>
                <textarea
                    className="mt-4 w-full min-h-56 rounded border-ae-gray-300"
                    value={notes}
                    disabled
                    readOnly
                />
            </Modal>
        </div>
    );
};
