import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import {
    AdminEmissionsRecordsStatsListProviderWithSourceParameterInner,
    DataProvider,
} from "../../apiClient/generated";
import { useDataProvidersApi } from "../../hooks";
import { CustomCheckbox } from "../../ui/CustomCheckbox";
import { SearchInput } from "../../ui/Inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faChevronRight,
    faCircleInfo,
    faXmark,
} from "@fortawesome/pro-light-svg-icons";
import {
    useFloating,
    offset,
    shift,
    flip,
    autoUpdate,
} from "@floating-ui/react";
import { Popover } from "@headlessui/react";

interface MultipleChoiceNestedFilterProps {
    filterValue?: AdminEmissionsRecordsStatsListProviderWithSourceParameterInner[];
    setFilterValue: (
        newFilterValue?: AdminEmissionsRecordsStatsListProviderWithSourceParameterInner[],
    ) => void;
}
export const DataProviderNestedFilter = ({
    filterValue,
    setFilterValue,
}: MultipleChoiceNestedFilterProps) => {
    const [search, setSearch] = useState("");
    const [selectedGroup, setSelectedGroup] = useState<string>();

    const apiClient = useDataProvidersApi();
    const dataProvidersQuery = useQuery({
        queryKey: ["dataProvidersFullTree"],
        queryFn: async () => {
            return await apiClient.dataProvidersList();
        },
        refetchOnWindowFocus: false,
        staleTime: 0,
    });

    const setFilter = (optionId: string, parentId?: string) => {
        const currentValues = filterValue || [];
        const newValues = [...currentValues];

        if (parentId) {
            // Handle secondary source selection
            const parentIndex = newValues.findIndex((v) => v.id === parentId);
            if (parentIndex !== -1) {
                const parent = newValues[parentIndex];
                const sources = parent.se || [];
                const sourceIndex = sources.indexOf(optionId);

                if (sourceIndex !== -1) {
                    // Remove source
                    const newSources = sources.filter((s) => s !== optionId);
                    if (newSources.length === 0) {
                        newValues.splice(parentIndex, 1);
                    } else {
                        newValues[parentIndex] = { ...parent, se: newSources };
                    }
                } else {
                    // Add source
                    newValues[parentIndex] = {
                        ...parent,
                        se: [...sources, optionId],
                    };
                }
            } else {
                // Add new parent with source
                newValues.push({
                    id: parentId,
                    se: [optionId],
                });
            }
        } else {
            // Handle provider selection with all children
            const existingIndex = newValues.findIndex((v) => v.id === optionId);
            const provider = dataProvidersQuery.data?.results.find(
                (p) => p.id === optionId,
            );

            if (existingIndex !== -1) {
                // If provider exists, remove it and all its children
                newValues.splice(existingIndex, 1);
            } else {
                // Add provider with all its children
                newValues.push({
                    id: optionId,
                    se: provider?.secondaryDataSources || [],
                });
            }
        }

        setFilterValue(newValues.length > 0 ? newValues : undefined);
    };

    const renderSecondarySource = (source: string, parentId: string) => {
        const active = filterValue
            ?.find((i) => i.id === parentId)
            ?.se?.includes(source);

        return (
            <div key={source} className="ml-4">
                <div className="relative cursor-default select-none flex gap-2 items-center px-2 py-1 rounded">
                    <CustomCheckbox
                        checked={active ? "yes" : "no"}
                        onClick={() => setFilter(source, parentId)}
                    />
                    <span className="block truncate">{source}</span>
                </div>
            </div>
        );
    };

    const renderDataProvider = (provider: {
        id: string;
        name: string;
        secondaryDataSources?: string[];
    }) => {
        const currentFilter = filterValue?.find((i) => i.id === provider.id);

        // Calculate selection state
        const selectionState = currentFilter
            ? currentFilter.se?.length === 0
                ? "yes" // Provider selected without sources
                : currentFilter.se?.length ===
                    provider.secondaryDataSources?.length
                  ? "yes" // All sources selected
                  : "partial" // Some sources selected
            : "no"; // Nothing selected

        return (
            <div
                key={provider.id}
                className={`
                    w-full flex justify-between items-center 
                    ${
                        provider.id === selectedGroup &&
                        "text-ae-blue-550 bg-ae-blue-40"
                    }
                `}
                onClick={() => setSelectedGroup(provider.id)}
            >
                <div className="cursor-pointer flex gap-2 items-center px-2 py-1.5 rounded">
                    <CustomCheckbox
                        checked={selectionState}
                        onClick={() => setFilter(provider.id)}
                    />
                    <span className="block truncate line-clamp-1 w-32">
                        {provider.name}
                    </span>
                </div>
                {provider.secondaryDataSources &&
                    provider.secondaryDataSources.length > 0 && (
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            className="w-4 mr-2"
                        />
                    )}
            </div>
        );
    };

    const filteredOptions = dataProvidersQuery.data?.results.filter(
        (provider) => {
            // Search provider names
            const matchesSearch =
                search.trim() === "" ||
                provider.name.toLowerCase().includes(search.toLowerCase());

            // If a provider has children also search them,
            // and if the substring is present the parent appears.
            if (provider.secondaryDataSources) {
                const hasMatchingChildren = provider.secondaryDataSources.some(
                    (source) =>
                        source.toLowerCase().includes(search.toLowerCase()),
                );
                return matchesSearch || hasMatchingChildren;
            }

            return matchesSearch;
        },
    );

    const activeProvider = dataProvidersQuery.data?.results.find(
        (i) => i.id === selectedGroup,
    );

    return (
        <div className="text-sm font-normal p-1 w-full">
            <div className="bg-white pb-2">
                <SearchInput
                    containerClassName="w-full"
                    value={search}
                    onChange={setSearch}
                    placeholder="Search"
                />
            </div>
            <div className="overflow-y-auto max-h-96 flex gap-10 text-sm">
                <div className="w-2/5">
                    {filteredOptions?.map((provider) =>
                        renderDataProvider(provider),
                    )}
                </div>
                <div className="w-2/5">
                    {activeProvider?.secondaryDataSources
                        .filter(
                            (i) =>
                                search.trim() === "" ||
                                i.toLowerCase().includes(search.toLowerCase()),
                        )
                        .map((source) => {
                            return renderSecondarySource(
                                source,
                                activeProvider.id,
                            );
                        })}
                    {!activeProvider?.secondaryDataSources?.length && (
                        <span className="mt-10 italic">
                            This provider has no secondary data source
                            information.
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                className="ml-2 w-4"
                            />
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export const DataProviderDropdown = ({
    filterValue,
    setFilterValue,
}: MultipleChoiceNestedFilterProps) => {
    const apiClient = useDataProvidersApi();
    const dataProvidersQuery = useQuery({
        queryKey: ["dataProvidersFullTree"],
        queryFn: async () => {
            return await apiClient.dataProvidersList();
        },
        refetchOnWindowFocus: false,
        staleTime: 0,
    });

    const { refs, floatingStyles } = useFloating({
        strategy: "fixed",
        placement: "bottom-start",
        middleware: [
            offset({
                mainAxis: 4,
            }),
            shift(),
            flip(),
        ],
        whileElementsMounted: autoUpdate,
    });

    const renderPill = (
        value: AdminEmissionsRecordsStatsListProviderWithSourceParameterInner,
    ) => {
        const dataProvider = dataProvidersQuery.data?.results.find(
            (i) => i.id === value.id,
        );
        let secondaryText = "";
        if (value.se?.length === dataProvider?.secondaryDataSources.length) {
            secondaryText = " (all)";
        } else if (
            value.se?.length < dataProvider?.secondaryDataSources.length
        ) {
            secondaryText = ` (${value.se.length})`;
        }

        return (
            <button
                type="button"
                className="flex text-ae-blue-650 border border-ae-blue-80 bg-ae-blue-50 items-center rounded h-5 px-1 capitalize gap-1  whitespace-nowrap"
                onClick={() => {
                    setFilterValue(
                        filterValue.filter((i) => i.id !== value.id),
                    );
                }}
            >
                {dataProvider?.name}
                {secondaryText}
                <FontAwesomeIcon icon={faXmark} className="w-3 h-3" />
            </button>
        );
    };

    return (
        <Popover>
            <div
                ref={refs.setReference}
                className="text-sm rounded flex justify-between border border-ae-gray-250 bg-white items-center px-2 focus-within:ring-2 ring-ae-blue-550 min-h-8"
            >
                <div className="flex flex-wrap gap-1 py-1 w-full">
                    {(!filterValue || filterValue.length === 0) && (
                        <span className="text-neutral-600">All providers</span>
                    )}
                    {filterValue?.map((value) => renderPill(value))}
                </div>
                <Popover.Button className="px-2" type="button">
                    <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3" />
                </Popover.Button>
            </div>
            <Popover.Panel
                ref={refs.setFloating}
                style={floatingStyles}
                className="bg-white rounded p-0.5 drop-shadow-lg z-50 max-h-96 w-[400px] overflow-scroll"
            >
                <DataProviderNestedFilter
                    filterValue={filterValue}
                    setFilterValue={setFilterValue}
                />
            </Popover.Panel>
        </Popover>
    );
};

interface SecondaryFilterProps {
    parentId: string;
    source: string;
    dataProviders: DataProvider[];
    filterValue:
        | AdminEmissionsRecordsStatsListProviderWithSourceParameterInner[]
        | "all";
    setFilterValue: (
        newFilterValue?:
            | AdminEmissionsRecordsStatsListProviderWithSourceParameterInner[]
            | "all",
    ) => void;
}

const SecondaryFilter = ({
    parentId,
    source,
    dataProviders,
    filterValue,
    setFilterValue,
}: SecondaryFilterProps) => {
    let active = false;
    if (filterValue === "all") {
        active = true;
    } else if (filterValue) {
        const providerState = filterValue.find((i) => i.id === parentId);
        active =
            (providerState && !providerState.se) ||
            (providerState && providerState.se.includes(source));
    }

    const setSecondaryFilter = (providerId: string, dataSource: string) => {
        const providerData = dataProviders.find((p) => p.id === providerId);
        if (filterValue == "all") {
            setFilterValue(
                dataProviders.map((dp) => {
                    if (providerId === dp.id) {
                        return {
                            id: dp.id,
                            se: providerData.secondaryDataSources.filter(
                                (d) => d !== dataSource,
                            ),
                        };
                    }
                    return { id: dp.id };
                }),
            );
        } else {
            const providerState = filterValue.find((i) => i.id === providerId);
            if (providerState) {
                if (providerState.se) {
                    if (providerState.se.includes(dataSource)) {
                        if (providerState.se.length - 1 === 0) {
                            setFilterValue([
                                ...filterValue.filter(
                                    (i) => i.id !== providerId,
                                ),
                            ]);
                        } else {
                            setFilterValue([
                                ...filterValue.filter(
                                    (i) => i.id !== providerId,
                                ),
                                {
                                    id: providerId,
                                    se: providerState.se.filter(
                                        (s) => s !== dataSource,
                                    ),
                                },
                            ]);
                        }
                    } else {
                        if (
                            providerState.se.length + 1 ===
                            providerData.secondaryDataSources.length
                        ) {
                            setFilterValue([
                                ...filterValue.filter(
                                    (i) => i.id !== providerId,
                                ),
                                { id: providerId },
                            ]);
                        } else {
                            setFilterValue([
                                ...filterValue.filter(
                                    (i) => i.id !== providerId,
                                ),
                                {
                                    id: providerId,
                                    se: [...providerState.se, dataSource],
                                },
                            ]);
                        }
                    }
                } else {
                    setFilterValue([
                        ...filterValue.filter((i) => i.id !== providerId),
                        {
                            id: providerId,
                            se: providerData.secondaryDataSources.filter(
                                (i) => i !== dataSource,
                            ),
                        },
                    ]);
                }
            } else {
                setFilterValue([
                    ...filterValue,
                    {
                        id: providerId,
                        se: [dataSource],
                    },
                ]);
            }
        }
    };

    return (
        <div key={source}>
            <div className="relative cursor-default select-none flex gap-2 items-center px-2 py-1 rounded">
                <CustomCheckbox
                    checked={active ? "yes" : "no"}
                    onClick={() => setSecondaryFilter(parentId, source)}
                />
                <span className="block truncate">{source}</span>
            </div>
        </div>
    );
};

interface ProviderFilterProps {
    provider: DataProvider;
    selectedGroup: string | undefined;
    setSelectedGroup: (groupId: string) => void;
    dataProviders: DataProvider[];
    filterValue:
        | AdminEmissionsRecordsStatsListProviderWithSourceParameterInner[]
        | "all";
    setFilterValue: (
        newFilterValue?:
            | AdminEmissionsRecordsStatsListProviderWithSourceParameterInner[]
            | "all",
    ) => void;
}

const ProviderFilter = ({
    provider,
    filterValue,
    setFilterValue,
    dataProviders,
    selectedGroup,
    setSelectedGroup,
}: ProviderFilterProps) => {
    // Calculate selection state
    let selectionState: "yes" | "no" | "partial" = "no";
    if (filterValue === "all") {
        selectionState = "yes";
    } else {
        const currentFilter = filterValue?.find((i) => i.id === provider.id);
        if (currentFilter) {
            if (currentFilter.se) {
                if (
                    currentFilter.se?.length ===
                    provider.secondaryDataSources?.length
                ) {
                    selectionState = "yes";
                } else {
                    selectionState = "partial";
                }
            } else {
                selectionState = "yes";
            }
        }
    }

    const setFilter = (providerId: string) => {
        if (filterValue === "all") {
            setFilterValue(
                dataProviders
                    .filter((dp) => dp.id !== providerId)
                    .map((dp) => ({
                        id: dp.id,
                    })),
            );
        } else {
            const providerFilterState = filterValue.find(
                (i) => i.id == providerId,
            );
            if (providerFilterState) {
                setFilterValue(filterValue.filter((f) => f.id !== providerId));
            } else {
                setFilterValue([
                    ...filterValue,
                    {
                        id: providerId,
                    },
                ]);
            }
        }
    };

    return (
        <div
            key={provider.id}
            className={`
                    w-full flex justify-between items-center 
                    ${
                        provider.id === selectedGroup &&
                        "text-ae-blue-550 bg-ae-blue-40/40"
                    }
                `}
            onClick={() => setSelectedGroup(provider.id)}
        >
            <div className="cursor-pointer flex gap-2 items-center px-2 py-1.5 rounded">
                <CustomCheckbox
                    checked={selectionState}
                    onClick={() => setFilter(provider.id)}
                />
                <span className="block truncate line-clamp-1 w-32">
                    {provider.name}
                </span>
            </div>
            {provider.secondaryDataSources &&
                provider.secondaryDataSources.length > 0 && (
                    <FontAwesomeIcon
                        icon={faChevronRight}
                        className="w-4 mr-2"
                    />
                )}
        </div>
    );
};

interface MapProviderFilterProps {
    filterValue?:
        | AdminEmissionsRecordsStatsListProviderWithSourceParameterInner[]
        | "all";
    setFilterValue: (
        newFilterValue?:
            | AdminEmissionsRecordsStatsListProviderWithSourceParameterInner[]
            | "all",
    ) => void;
    dataProviders: DataProvider[];
}
/**
 * Map Provider filter.
 *
 * This is a copy of the component above with changes to make it
 * work on the map, but has some improvements.
 *
 * // FIXME: This code looks really bad.
 */
export const MapProviderFilter = ({
    filterValue,
    setFilterValue,
    dataProviders,
}: MapProviderFilterProps) => {
    const [search, setSearch] = useState("");
    const [selectedGroup, setSelectedGroup] = useState<string>();

    const filteredOptions = dataProviders.filter((provider) => {
        // Search provider names
        const matchesSearch =
            search.trim() === "" ||
            provider.name.toLowerCase().includes(search.toLowerCase());

        // If a provider has children also search them,
        // and if the substring is present the parent appears.
        if (provider.secondaryDataSources) {
            const hasMatchingChildren = provider.secondaryDataSources.some(
                (source) => source.toLowerCase().includes(search.toLowerCase()),
            );
            return matchesSearch || hasMatchingChildren;
        }

        return matchesSearch;
    });

    const activeProvider = dataProviders.find((i) => i.id === selectedGroup);

    return (
        <div className="text-sm font-normal p-1 w-full">
            <div className="bg-white pb-2">
                <SearchInput
                    containerClassName="w-full"
                    value={search}
                    onChange={setSearch}
                    placeholder="Search"
                />
            </div>
            <div className="overflow-y-auto max-h-96 flex text-sm">
                <div className="w-full">
                    {filteredOptions?.map((provider) => (
                        <ProviderFilter
                            key={provider.id}
                            dataProviders={dataProviders}
                            filterValue={filterValue}
                            provider={provider}
                            selectedGroup={selectedGroup}
                            setSelectedGroup={setSelectedGroup}
                            setFilterValue={setFilterValue}
                        />
                    ))}
                </div>
                {activeProvider?.secondaryDataSources?.length > 0 && (
                    <div className="w-full overflow-hidden">
                        {activeProvider?.secondaryDataSources
                            .filter(
                                (i) =>
                                    search.trim() === "" ||
                                    i
                                        .toLowerCase()
                                        .includes(search.toLowerCase()),
                            )
                            .map((source) => (
                                <SecondaryFilter
                                    key={`${activeProvider.id}_${source}`}
                                    filterValue={filterValue}
                                    source={source}
                                    dataProviders={dataProviders}
                                    parentId={activeProvider.id}
                                    setFilterValue={setFilterValue}
                                />
                            ))}
                    </div>
                )}
            </div>
        </div>
    );
};
