import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes";
import { useAppSelector } from "../../hooks";
import aershedLogo from "./../../assets/img/aershed-logo-horizontal.png";
import aershedLogoSmall from "./../../assets/img/aershed-symbol.png";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import {
    faArrowRightFromBracket,
    faBuilding,
    faBuildingColumns,
    faBullseyeArrow,
    faChevronsLeft,
    faChevronsRight,
    faCog,
    faDownload,
    faMap,
    faPuzzle,
    faQuestionCircle,
    faTriangleExclamation,
    faUpload,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFloating, offset, shift, autoUpdate } from "@floating-ui/react";

interface SidebarMenuProps {
    icon: IconDefinition;
    to: string;
    text: string;
    expanded: boolean;
}

const SidebarButton = (props: SidebarMenuProps) => {
    return (
        <NavLink
            to={props.to}
            className={({ isActive }) => {
                return `
                    px-4 py-3 w-full rounded-lg flex items-center justify-center
                    transition-all gap-4 ${
                        isActive
                            ? "bg-ae-blue-900 text-stone-50 font-bold"
                            : "text-ae-blue-900 hover:bg-neutral-200"
                    }
                `;
            }}
        >
            <FontAwesomeIcon icon={props.icon} className="h-5 w-5" />
            <h2
                className={`flex-grow font-medium ${
                    props.expanded ? "" : "hidden"
                }`}
            >
                {props.text}
            </h2>
        </NavLink>
    );
};

export const SideMenu = () => {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(true);

    // Expand/contract floating
    const { refs, floatingStyles } = useFloating({
        strategy: "fixed",
        placement: "right",
        middleware: [
            offset({
                mainAxis: -12,
            }),
            shift(),
        ],
        whileElementsMounted: autoUpdate,
    });

    // User specific information
    const isStaff = useAppSelector((state) => state.auth.isStaff);
    const firstName = useAppSelector((state) => state.auth.firstName);
    const lastName = useAppSelector((state) => state.auth.lastName);

    // Permissions
    const isSuperuser = useAppSelector((state) => state.auth.isSuperuser);
    const userPermissions = useAppSelector((state) => state.auth.permissions);
    const flags = useAppSelector((state) => state.auth.flags);

    return (
        <div
            ref={refs.setReference}
            className={`
                ${expanded ? "w-64" : "w-20"} h-screen
                bg-ae-neutral-50 border-r border-slate-200
                flex flex-col p-3 transition-all group relative
                overflow-y-scroll overflow-x-hidden
            `}
        >
            {/* Expand/collapse controls */}
            <button
                ref={refs.setFloating}
                title={expanded ? "Minimize menu" : "Expand menu"}
                style={floatingStyles}
                className="relative hidden group-hover:block z-50 rounded-r bg-ae-neutral-50 w-6"
                onClick={() => setExpanded(!expanded)}
            >
                <div className="absolute right-0 w-1/2 h-full border border-slate-200 border-l-0 rounded-r" />
                <div className="flex items-center justify-center rounded h-12 hover:bg-ae-neutral-200 w-full">
                    {expanded ? (
                        <FontAwesomeIcon
                            icon={faChevronsLeft}
                            className="w-3"
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faChevronsRight}
                            className="w-3"
                        />
                    )}
                </div>
            </button>

            {/* Header */}
            <div className="mt-4 mb-14 flex items-center justify-evenly">
                <a
                    onClick={() => {
                        navigate(ROUTES.ROOT);
                    }}
                >
                    <img
                        className="max-h-10"
                        src={expanded ? aershedLogo : aershedLogoSmall}
                    />
                </a>
            </div>

            {/* Main navigation pages */}
            <div className="flex-grow flex flex-col gap-1 mb-1">
                {/* Dashboards */}
                {flags.includes("enable_unified_dashboards") && (
                    <SidebarButton
                        to={ROUTES.DASHBOARD}
                        icon={faBullseyeArrow}
                        text="Emissions"
                        expanded={expanded}
                    />
                )}
                {/* Map */}
                {userPermissions.mapView && (
                    <SidebarButton
                        to={ROUTES.MAP}
                        icon={faMap}
                        text="Maps"
                        expanded={expanded}
                    />
                )}
                {userPermissions.infrastructureView && (
                    <SidebarButton
                        to={ROUTES.INFRASTRUCURE}
                        icon={faBuilding}
                        text="Infrastructure"
                        expanded={expanded}
                    />
                )}
                {userPermissions.dataUploadsView && (
                    <SidebarButton
                        to={ROUTES.DATAUPLOADS}
                        icon={faUpload}
                        text="Data upload"
                        expanded={expanded}
                    />
                )}
                {userPermissions.dataDownloadView && (
                    <SidebarButton
                        to={ROUTES.DATADOWNLOAD}
                        icon={faDownload}
                        text="Data download"
                        expanded={expanded}
                    />
                )}
            </div>

            {/* Items attached to bottom of page */}
            <div>
                <div className="flex flex-col mb-8 gap-1">
                    {isStaff && (
                        <SidebarButton
                            to={ROUTES.ADMIN_DASHBOARD}
                            icon={faPuzzle}
                            text="Administration"
                            expanded={expanded}
                        />
                    )}
                    <SidebarButton
                        to={ROUTES.SUPPORT}
                        icon={faQuestionCircle}
                        text="Help & Support"
                        expanded={expanded}
                    />
                    <SidebarButton
                        to={ROUTES.SETTINGS_HOME}
                        icon={faCog}
                        text="Settings"
                        expanded={expanded}
                    />
                    <SidebarButton
                        to={ROUTES.LICENSING}
                        icon={faBuildingColumns}
                        text="Licensing"
                        expanded={expanded}
                    />
                </div>

                {/* User header */}
                <div className="px-4 mb-4 flex justify-evenly items-center text-ae-blue-900">
                    {expanded && (
                        <div className="grow text-base font-bold">
                            {`${firstName} ${lastName}`}
                        </div>
                    )}
                    <NavLink
                        title="Logout"
                        to={ROUTES.LOGOUT}
                        className="flex items-center justify-center p-2 rounded-lg hover:bg-neutral-200 "
                    >
                        <FontAwesomeIcon
                            icon={faArrowRightFromBracket}
                            className="h-5"
                        />
                    </NavLink>
                </div>

                {/* Superuser account warning */}
                {isStaff && isSuperuser && (
                    <p className="flex bg-red-500 justify-center p-2 -mb-3 -mx-3 text-white text-sm font-bold">
                        {expanded ? (
                            "You are using a superuser account!"
                        ) : (
                            <FontAwesomeIcon
                                icon={faTriangleExclamation}
                                className="h-5"
                            />
                        )}
                    </p>
                )}
            </div>
        </div>
    );
};
