/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EventStatsResponse
 */
export interface EventStatsResponse {
    /**
     * 
     * @type {number}
     * @memberof EventStatsResponse
     */
    inProgress: number;
    /**
     * 
     * @type {number}
     * @memberof EventStatsResponse
     */
    complete: number;
}

/**
 * Check if a given object implements the EventStatsResponse interface.
 */
export function instanceOfEventStatsResponse(value: object): boolean {
    if (!('inProgress' in value)) return false;
    if (!('complete' in value)) return false;
    return true;
}

export function EventStatsResponseFromJSON(json: any): EventStatsResponse {
    return EventStatsResponseFromJSONTyped(json, false);
}

export function EventStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventStatsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'inProgress': json['in_progress'],
        'complete': json['complete'],
    };
}

export function EventStatsResponseToJSON(value?: EventStatsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'in_progress': value['inProgress'],
        'complete': value['complete'],
    };
}

