import { ArrowPathIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { ReactNode } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

interface DetailViewActionsProps {
    actions: {
        label: string;
        action: () => void;
        disabled?: boolean;
        loading?: boolean;
    }[];
}

export const DetailViewActions = (props: DetailViewActionsProps) => (
    <div className="flex mt-4 items-center justify-end space-x-2">
        {props.actions &&
            props.actions.map((actionItem) => (
                <button
                    key={actionItem.label}
                    type="button"
                    disabled={actionItem.disabled || actionItem.loading}
                    className={`
                        inline-flex justify-center items-center rounded-md 
                        border border-transparent px-4 py-2 
                        focus:outline-none focus-visible:ring-2 
                        focus-visible:ring-blue-500 focus-visible:ring-offset-2
                        ${
                            actionItem.disabled || actionItem.loading
                                ? "bg-slate-100"
                                : "bg-slate-300 hover:bg-slate-600 hover:text-white"
                        }
                    `}
                    onClick={actionItem.action}
                >
                    {actionItem.label}
                    {actionItem.loading && (
                        <ArrowPathIcon className="ml-2 h-4 w-6 animate-spin" />
                    )}
                </button>
            ))}
    </div>
);
interface DetailViewProps {
    visible: boolean;
    title: string;
    onClose?: () => void;
    children: ReactNode;
}

export const DetailView = (props: DetailViewProps) => {
    const isOpen = props.visible;

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={props.onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-7xl transform overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="flex justify-between w-full text-lg font-medium leading-6 text-gray-900"
                                >
                                    {props.title}
                                    <button
                                        type="button"
                                        className="justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium hover:bg-slate-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                        onClick={props.onClose}
                                    >
                                        <XMarkIcon className="h-6 w-6" />
                                    </button>
                                </Dialog.Title>

                                <div className="mt-2">{props.children}</div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};
