import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useState, useCallback, useMemo, useEffect, ReactNode } from "react";
import { EpaForm, FileTypeEnum } from "../../../apiClient/generated";
import { ALLOWED_FILE_EXTENSIONS } from "../../../constants/globals";
import { useEpaSepApiClient } from "../../../hooks";
import UploadDropdown from "../../UploadDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowsRotate,
    faDownload,
    faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { LoadingIndicator } from "../../MapV2/ui/LoadingIndicator";

interface UploadState {
    fileId?: string;
    file: File;
    loaded?: number;
    total?: number;
    status: "waiting" | "uploading" | "success" | "error";
    error?: string;
    abortController?: AbortController;
}

const useFileUploader = (
    form: EpaForm,
    uploadType: FileTypeEnum,
    uploadCompleteCallback?: () => void,
) => {
    // API client
    const apiClient = useEpaSepApiClient();

    // State tracking for uploads
    const [uploadState, setUploadState] = useState<{
        [key: string]: UploadState;
    }>({});

    // Add files ignoring any duplicates
    const addFiles = useCallback(
        (newFiles: File[]) => {
            const newUploadState = { ...uploadState };
            newFiles.forEach((f) => {
                if (newUploadState[f.name] === undefined) {
                    newUploadState[f.name] = {
                        file: f,
                        status: "waiting",
                        abortController: new AbortController(),
                    };
                }
            });
            setUploadState(newUploadState);
        },
        [uploadState, setUploadState],
    );

    // Remove a file
    const removeFile = useCallback(
        (filename: string) => {
            const newUploadState = { ...uploadState };
            const fileId = newUploadState[filename].fileId;
            newUploadState[filename].abortController.abort();
            delete newUploadState[filename];
            setUploadState(newUploadState);
            // Handle deleting on server
            if (fileId) {
                apiClient.epaSepFormFilesDestroy({
                    id: form.id,
                    fileId,
                });
            }
        },
        [uploadState, setUploadState],
    );

    // Update state of an item (internal)
    const updateState = useCallback(
        (filename: string, data: Partial<UploadState>) => {
            setUploadState((prevState) => {
                const newUploadState = { ...prevState };
                newUploadState[filename] = {
                    ...newUploadState[filename],
                    ...data,
                };
                return newUploadState;
            });
        },
        [setUploadState],
    );

    // Download file (internal)
    const downloadFile = useCallback(
        async (state: UploadState) => {
            updateState(state.file.name, {
                status: "uploading",
            });
            try {
                // Retrieve presigned URL
                const response = await apiClient.epaSepFormFilesCreate({
                    id: form.id,
                    epaFormFileCreateRequest: {
                        file: state.file.name,
                        fileType: uploadType,
                    },
                });

                // Update internal state
                updateState(state.file.name, {
                    fileId: response.id,
                });

                // Upload file and update upload status
                await axios.put(response.uploadUrl, state.file, {
                    headers: {
                        "Content-Type": state.file.type,
                    },
                    onUploadProgress: (e) =>
                        updateState(state.file.name, {
                            status: "uploading",
                            total: e.total,
                            loaded: e.loaded,
                        }),
                    signal: state.abortController.signal,
                });

                // Mark upload as complete
                await apiClient.epaSepFormFilesUpdate({
                    id: form.id,
                    epaFormFileOperationRequest: {
                        fileId: response.id,
                    },
                });

                // Update state
                updateState(state.file.name, {
                    loaded: undefined,
                    total: undefined,
                    status: "success",
                });
                if (uploadCompleteCallback) {
                    uploadCompleteCallback();
                }
            } catch (e) {
                // Ignore abort errors
                if (e.code === "ERR_CANCELED") {
                    return;
                }
                // Else, try parsing error
                let error = "Could not complete request.";
                try {
                    const errorResponse = await e.response.json();
                    error = errorResponse.file[0];
                } catch {
                    console.log("Failed to parse error message body.");
                }
                updateState(state.file.name, {
                    loaded: undefined,
                    total: undefined,
                    status: "error",
                    error: error,
                });
            }
        },
        [uploadState, setUploadState, updateState, form, apiClient],
    );

    // Start upload process as soon as there are new items
    useEffect(() => {
        // Loop through all items
        for (const filename in uploadState) {
            const state = uploadState[filename];

            // Check if they are waiting upload
            if (state.status === "waiting") {
                downloadFile(state);
            }
        }
    }, [uploadState, downloadFile]);

    return {
        addFiles,
        removeFile,
        uploadState,
    };
};

const FileItemDisplay = (props: {
    filename: string;
    extraInfo?: string | ReactNode;
    error?: string;
    downloadUrl?: string;
    scanComplete: boolean;
    virusDetected: boolean;
    onDelete: () => void;
}) => (
    <div
        className={`
        w-full px-4 py-2 border rounded 
        font-sm flex items-center justify-between
        ${props.error || props.virusDetected ? "border-red-500" : ""}
    `}
    >
        <div className="max-w-52 overflow-hidden">
            <span className="line-clamp-2" title={props.filename}>
                {props.filename}
            </span>
            {!props.error && props.extraInfo && (
                <span className="text-neutral-400">{props.extraInfo}</span>
            )}
            {(props.error || props.virusDetected) && (
                <span className="text-red-500">
                    {props.error || "File flagged as infected by AV."}
                </span>
            )}
        </div>
        <div className="flex gap-2">
            <button type="button" onClick={props.onDelete}>
                <FontAwesomeIcon icon={faTrash} className="w-4" />
            </button>
            {props.scanComplete &&
                !props.virusDetected &&
                props.downloadUrl && (
                    <button
                        type="button"
                        onClick={() => {
                            window.open(props.downloadUrl, "_blank");
                        }}
                    >
                        <FontAwesomeIcon icon={faDownload} className="w-4" />
                    </button>
                )}
        </div>
    </div>
);

interface EpaFormUploadField {
    form: EpaForm;
    uploadType: FileTypeEnum;
}

export const EpaFormUploadField = (props: EpaFormUploadField) => {
    const queryClient = useQueryClient();
    const apiClient = useEpaSepApiClient();

    // Retrieve files
    const filesQuery = useQuery({
        queryKey: ["epaFormFiles", props.form.id],
        queryFn: async () => {
            return await apiClient.epaSepFormFilesList({
                id: props.form.id,
            });
        },
        refetchOnWindowFocus: false,
        refetchInterval: (d) => {
            if (d && d.some((f) => !f.scanComplete)) {
                // While there are files not scanned, refresh every 30s.
                return 30000;
            }
            return false;
        },
    });
    const uploadedFilenames = useMemo(() => {
        return filesQuery.data?.map((i) => i.file);
    }, [filesQuery.data]);

    const dataUploader = useFileUploader(props.form, props.uploadType, () =>
        queryClient.invalidateQueries({
            queryKey: ["epaFormFiles", props.form.id],
        }),
    );

    // When files are added to dropzone
    const onDropFiles = (files: FileList) => {
        if (!files) return;
        const newFiles = [...files];
        const filesToUpload = newFiles.filter(
            (f) => !uploadedFilenames.includes(f.name),
        );
        dataUploader.addFiles(filesToUpload);
    };

    const deleteFileMutation = useMutation({
        mutationKey: ["epaFormFiles", props.form.id],
        mutationFn: (fileId: string) => {
            return apiClient.epaSepFormFilesDestroy({
                id: props.form.id,
                fileId,
            });
        },
        onSuccess: () => {
            // Invalidate and refetch the files query
            queryClient.invalidateQueries({
                queryKey: ["epaFormFiles", props.form.id],
            });
        },
    });

    return (
        <div className="flex flex-col gap-2">
            <UploadDropdown
                accept={ALLOWED_FILE_EXTENSIONS.join(",")}
                onChange={onDropFiles}
                multiple
            />
            {Object.values(dataUploader.uploadState)
                .filter((u) => u.status !== "success")
                .map((upload) => (
                    <FileItemDisplay
                        filename={upload.file?.name}
                        extraInfo={
                            upload.status === "uploading" &&
                            upload.loaded &&
                            upload.total ? (
                                `${((upload.loaded / upload.total) * 100).toFixed(1)} %`
                            ) : upload.status === "uploading" &&
                              !upload.loaded &&
                              !upload.total ? (
                                <FontAwesomeIcon
                                    icon={faArrowsRotate}
                                    className="w-4 animate-spin"
                                />
                            ) : undefined
                        }
                        error={upload.error}
                        scanComplete={false}
                        virusDetected={false}
                        onDelete={() =>
                            dataUploader.removeFile(upload.file?.name)
                        }
                    />
                ))}
            {filesQuery.isLoading && <LoadingIndicator />}
            {filesQuery.data
                ?.filter((f) => f.fileType === props.uploadType)
                .map((file) => (
                    <FileItemDisplay
                        filename={file.filename}
                        extraInfo={
                            !file.scanComplete
                                ? "Waiting AV check..."
                                : undefined
                        }
                        downloadUrl={file.file}
                        scanComplete={file.scanComplete}
                        virusDetected={file.virusDetected}
                        onDelete={() => deleteFileMutation.mutate(file.id)}
                    />
                ))}
        </div>
    );
};
