import { PuzzlePieceIcon } from "@heroicons/react/24/outline";
import { Card } from "../../ui/Card";

export const AdminOverviewPage = () => {
    return (
        <div className="container mx-auto px-6">
            <Card>
                <span className="flex items-center">
                    Work in progress
                    <PuzzlePieceIcon className="h-8 w-8 ml-3" />
                </span>

                <span className="mt-2 text-sm">
                    Use the links in the navbar to navigate.
                </span>
            </Card>
        </div>
    );
};
