import { useQuery } from "@tanstack/react-query";
import { useDataProvidersApi, useNotificationsApi } from "../../hooks";
import { ThirdPartyEnabledEnum } from "../../apiClient/generated";
import { RadioGroup } from "@headlessui/react";
import { ReactNode } from "react";
import { LoadingIndicator } from "../MapV2/ui/LoadingIndicator";

const EmissionOptions = [
    {
        key: ThirdPartyEnabledEnum.All,
        label: "All",
        description: "All new notifications will be sent individually.",
    },
    {
        key: ThirdPartyEnabledEnum.BatchDigest,
        label: "Digest",
        description:
            "New notifications will be batched and a digest will be sent.",
    },
    {
        key: ThirdPartyEnabledEnum.None,
        label: "None",
        description: "We won't send any notifications for this.",
    },
];

interface OptionSelectorProps {
    options: {
        key: string;
        label: string;
        description?: string;
    }[];
    value: string;
    onChange: (newValue: string) => void;
}

const OptionSelector = (props: OptionSelectorProps) => (
    <RadioGroup
        className="flex gap-1 bg-ae-slate-200 w-fit p-1 rounded-lg"
        value={props.value}
        onChange={props.onChange}
    >
        {props.options.map((option) => (
            <RadioGroup.Option
                value={option.key}
                className={`
                    cursor-pointer py-2 px-4 rounded-lg 
                    ${props.value === option.key ? "bg-white" : ""}
                `}
                title={option.description}
            >
                <span className={props.value === option.key && "font-bold"}>
                    {option.label}
                </span>
            </RadioGroup.Option>
        ))}
    </RadioGroup>
);

const SettingsSection = (props: { title: string; children: ReactNode }) => (
    <>
        <hr className="my-4" />
        <h3 className="font-md">{props.title}</h3>
        <div>{props.children}</div>
    </>
);

interface EmissionSettingsProps {
    company?: number;
}

export const EmissionSettings = (props: EmissionSettingsProps) => {
    const apiClient = useNotificationsApi();
    const dataProvidersApiClient = useDataProvidersApi();

    // Fetch additional data
    const enabledDataProvidersQuery = useQuery({
        queryKey: ["enabledDataProviders", props.company],
        queryFn: async () => {
            return await dataProvidersApiClient.dataProvidersList({
                isEnabled: true,
                company: props.company as unknown as string,
            });
        },
        staleTime: Infinity,
    });
    const dataProvidersQuery = useQuery({
        queryKey: ["dataProviders"],
        queryFn: async () => {
            return await dataProvidersApiClient.dataProvidersList();
        },
        staleTime: Infinity,
    });

    const notificationGroupsQuery = useQuery({
        queryKey: ["notificationGroup", props.company],
        queryFn: async () => {
            const response = await apiClient.notificationsGroupsList({
                owner: props.company,
                pageSize: 30,
            });
            return response.results;
        },
    });

    // Notification settings
    const notificationSettingsQuery = useQuery({
        queryKey: ["emissionNotificationSettings", props.company],
        queryFn: async () => {
            const response = await apiClient.notificationsEmissionsList({
                owner: props.company,
                pageSize: 1,
            });
            return response.results[0];
        },
    });

    // Save changes fn
    const saveChanges = async (field: string, value: string) => {
        await apiClient.notificationsEmissionsPartialUpdate({
            id: notificationSettingsQuery.data?.id,
            patchedEmissionNotificationSettingsRequest: {
                [field]: value,
            },
        });
        notificationSettingsQuery.refetch();
    };

    const toggleProvider = async (providerId: string) => {
        let newValues = [
            ...notificationSettingsQuery.data.thirdPartyExcludedProviders,
        ];

        if (newValues.includes(providerId)) {
            newValues = newValues.filter((i) => i !== providerId);
        } else {
            newValues.push(providerId);
        }

        await apiClient.notificationsEmissionsPartialUpdate({
            id: notificationSettingsQuery.data?.id,
            patchedEmissionNotificationSettingsRequest: {
                thirdPartyExcludedProviders: newValues,
            },
        });
        notificationSettingsQuery.refetch();
    };

    if (
        notificationSettingsQuery.isLoading ||
        notificationGroupsQuery.isLoading ||
        dataProvidersQuery.isLoading ||
        enabledDataProvidersQuery.isLoading
    ) {
        return <LoadingIndicator />;
    }

    const enabledProviderIds = enabledDataProvidersQuery.data.results.map(
        (p) => p.id,
    );
    const disabledDataProviders = dataProvidersQuery.data.results.filter(
        (p) => !enabledProviderIds.includes(p.id),
    );

    return (
        <div className="mt-2 text-sm">
            <div className="mt-4">
                <SettingsSection title="EPA Super Emitter Program ">
                    <div className="flex gap-2">
                        <div className="mt-1 text-ae-slate-600">
                            Select if how you'd like to be notified.
                        </div>

                        <OptionSelector
                            options={EmissionOptions}
                            value={notificationSettingsQuery.data.epaSepEnabled}
                            onChange={(value) =>
                                saveChanges("epaSepEnabled", value)
                            }
                        />

                        <div className="my-1 text-ae-slate-600">
                            Who should be notified about new data?
                        </div>
                        <select
                            className="min-w-64 rounded p-2"
                            value={notificationSettingsQuery.data.epaSepGroup}
                            onChange={(e) => {
                                saveChanges("epaSepGroup", e.target.value);
                            }}
                        >
                            <option
                                disabled
                                selected={
                                    notificationSettingsQuery.data
                                        .epaSepGroup === undefined
                                }
                            ></option>
                            {notificationGroupsQuery.data.map((group) => (
                                <option key={group.id} value={group.id}>
                                    {group.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </SettingsSection>

                <SettingsSection title="My Company Monitoring">
                    <div className="flex gap-2">
                        <div className="mt-1 text-ae-slate-600">
                            Select if how you'd like to be notified.
                        </div>

                        <OptionSelector
                            options={EmissionOptions}
                            value={
                                notificationSettingsQuery.data
                                    .selfReportedEnabled
                            }
                            onChange={(value) =>
                                saveChanges("selfReportedEnabled", value)
                            }
                        />

                        <div className="my-1 text-ae-slate-600">
                            Who should be notified about new data?
                        </div>
                        <select
                            className="min-w-64 rounded p-2"
                            value={
                                notificationSettingsQuery.data.selfReportedGroup
                            }
                            onChange={(e) => {
                                saveChanges(
                                    "selfReportedGroup",
                                    e.target.value,
                                );
                            }}
                        >
                            <option
                                disabled
                                selected={
                                    notificationSettingsQuery.data
                                        .selfReportedGroup === undefined
                                }
                            ></option>
                            {notificationGroupsQuery.data.map((group) => (
                                <option key={group.id} value={group.id}>
                                    {group.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </SettingsSection>

                <SettingsSection title="3rd Party">
                    <div className="flex gap-2">
                        <div className="mt-1 text-ae-slate-600">
                            Select if how you'd like to be notified.
                        </div>

                        <OptionSelector
                            options={EmissionOptions}
                            value={
                                notificationSettingsQuery.data.thirdPartyEnabled
                            }
                            onChange={(value) =>
                                saveChanges("thirdPartyEnabled", value)
                            }
                        />

                        <div className="my-1 text-ae-slate-600">
                            Who should be notified about new data?
                        </div>

                        <select
                            className="min-w-64 rounded p-2"
                            value={
                                notificationSettingsQuery.data.thirdPartyGroup
                            }
                            onChange={(e) => {
                                saveChanges("thirdPartyGroup", e.target.value);
                            }}
                        >
                            <option
                                disabled
                                selected={
                                    notificationSettingsQuery.data
                                        .thirdPartyGroup === undefined
                                }
                            ></option>

                            {notificationGroupsQuery.data.map((group) => (
                                <option key={group.id} value={group.id}>
                                    {group.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="my-1 text-ae-slate-600">
                        Which providers should trigger alerts?
                    </div>

                    <div className="grid grid-cols-2 gap-2">
                        {enabledDataProvidersQuery.data.results.map(
                            (provider) => (
                                <div className="flex gap-2 items-center whitespace-nowrap line-clamp-1">
                                    <input
                                        type="checkbox"
                                        className="rounded text-ae-blue-500"
                                        checked={
                                            !notificationSettingsQuery.data.thirdPartyExcludedProviders.includes(
                                                provider.id,
                                            )
                                        }
                                        onChange={() =>
                                            toggleProvider(provider.id)
                                        }
                                    />
                                    <label>{provider.name}</label>
                                </div>
                            ),
                        )}
                        {disabledDataProviders.map((provider) => (
                            <div className="flex gap-2 items-center whitespace-nowrap line-clamp-1">
                                <input
                                    type="checkbox"
                                    className="rounded text-ae-blue-500 bg-gray-100"
                                    checked={false}
                                    disabled={true}
                                />
                                <label className="text-gray-400">
                                    {provider.name}
                                </label>
                            </div>
                        ))}
                    </div>
                </SettingsSection>
            </div>
        </div>
    );
};
