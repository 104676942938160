/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PaginatedNonDetectList,
} from '../models/index';
import {
    PaginatedNonDetectListFromJSON,
    PaginatedNonDetectListToJSON,
} from '../models/index';

export interface NondetectsNondectListRequest {
    infrastructure?: string;
    ordering?: string;
    page?: number;
    pageSize?: number;
    timestampFrom?: Date;
    timestampTo?: Date;
}

/**
 * 
 */
export class NondetectsApi extends runtime.BaseAPI {

    /**
     * API endpoint that allows NonDetects to be viewed.
     */
    async nondetectsNondectListRaw(requestParameters: NondetectsNondectListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedNonDetectList>> {
        const queryParameters: any = {};

        if (requestParameters['infrastructure'] != null) {
            queryParameters['infrastructure'] = requestParameters['infrastructure'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['timestampFrom'] != null) {
            queryParameters['timestamp_from'] = (requestParameters['timestampFrom'] as any).toISOString();
        }

        if (requestParameters['timestampTo'] != null) {
            queryParameters['timestamp_to'] = (requestParameters['timestampTo'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/nondetects/nondect/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedNonDetectListFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows NonDetects to be viewed.
     */
    async nondetectsNondectList(requestParameters: NondetectsNondectListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedNonDetectList> {
        const response = await this.nondetectsNondectListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
