import { InfrastructureDetailView } from "../components/Infrastructure/Detail";
import { PermissionGate } from "./PermissionGate";
import { useParams } from "react-router";

export const InfrastructureDetailPage = () => {
    const { id } = useParams();

    if (!id) {
        return null;
    }

    return (
        <PermissionGate permission="infrastructureView">
            <InfrastructureDetailView infrastructureId={id} />
        </PermissionGate>
    );
};
