/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Serializer for data providers type.
 * @export
 * @interface DataProvider
 */
export interface DataProvider {
    /**
     * 
     * @type {string}
     * @memberof DataProvider
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof DataProvider
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataProvider
     */
    readonly secondaryDataSources: Array<string>;
}

/**
 * Check if a given object implements the DataProvider interface.
 */
export function instanceOfDataProvider(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('secondaryDataSources' in value)) return false;
    return true;
}

export function DataProviderFromJSON(json: any): DataProvider {
    return DataProviderFromJSONTyped(json, false);
}

export function DataProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataProvider {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'secondaryDataSources': json['secondary_data_sources'],
    };
}

export function DataProviderToJSON(value?: Omit<DataProvider, 'id'|'secondary_data_sources'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
    };
}

