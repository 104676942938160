/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Company,
  CompanyMembership,
  Token,
  User,
} from '../models/index';
import {
    CompanyFromJSON,
    CompanyToJSON,
    CompanyMembershipFromJSON,
    CompanyMembershipToJSON,
    TokenFromJSON,
    TokenToJSON,
    UserFromJSON,
    UserToJSON,
} from '../models/index';

export interface AccountsCompaniesListRequest {
    ordering?: string;
}

export interface AccountsUsersListRequest {
    companymembershipCompany?: number;
    ordering?: string;
}

/**
 * 
 */
export class AccountsApi extends runtime.BaseAPI {

    /**
     * A viewset to retrieve company names.
     */
    async accountsCompaniesListRaw(requestParameters: AccountsCompaniesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Company>>> {
        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/accounts/companies/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyFromJSON));
    }

    /**
     * A viewset to retrieve company names.
     */
    async accountsCompaniesList(requestParameters: AccountsCompaniesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Company>> {
        const response = await this.accountsCompaniesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns membership of current user.
     */
    async accountsMembershipMyRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyMembership>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/accounts/membership/my/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyMembershipFromJSON(jsonValue));
    }

    /**
     * Returns membership of current user.
     */
    async accountsMembershipMyRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyMembership> {
        const response = await this.accountsMembershipMyRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Generate or regenerate user\'s token.
     */
    async accountsTokenCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Token>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/accounts/token/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenFromJSON(jsonValue));
    }

    /**
     * Generate or regenerate user\'s token.
     */
    async accountsTokenCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Token> {
        const response = await this.accountsTokenCreateRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieve user\'s token.
     */
    async accountsTokenRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Token>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/accounts/token/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenFromJSON(jsonValue));
    }

    /**
     * Retrieve user\'s token.
     */
    async accountsTokenRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Token> {
        const response = await this.accountsTokenRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve rasters information
     */
    async accountsUsersListRaw(requestParameters: AccountsUsersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<User>>> {
        const queryParameters: any = {};

        if (requestParameters['companymembershipCompany'] != null) {
            queryParameters['companymembership__company'] = requestParameters['companymembershipCompany'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/accounts/users/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     * A viewset to retrieve rasters information
     */
    async accountsUsersList(requestParameters: AccountsUsersListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<User>> {
        const response = await this.accountsUsersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
