/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AdminInfrastructureImportItemLocation } from './AdminInfrastructureImportItemLocation';
import {
    AdminInfrastructureImportItemLocationFromJSON,
    AdminInfrastructureImportItemLocationFromJSONTyped,
    AdminInfrastructureImportItemLocationToJSON,
} from './AdminInfrastructureImportItemLocation';

/**
 * Custom serializer that returns plume data for the map.
 * @export
 * @interface PlumeImageRequest
 */
export interface PlumeImageRequest {
    /**
     * 
     * @type {Blob}
     * @memberof PlumeImageRequest
     */
    image: Blob;
    /**
     * 
     * @type {AdminInfrastructureImportItemLocation}
     * @memberof PlumeImageRequest
     */
    center?: AdminInfrastructureImportItemLocation;
    /**
     * 
     * @type {boolean}
     * @memberof PlumeImageRequest
     */
    processed?: boolean;
}

/**
 * Check if a given object implements the PlumeImageRequest interface.
 */
export function instanceOfPlumeImageRequest(value: object): boolean {
    if (!('image' in value)) return false;
    return true;
}

export function PlumeImageRequestFromJSON(json: any): PlumeImageRequest {
    return PlumeImageRequestFromJSONTyped(json, false);
}

export function PlumeImageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlumeImageRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'image': json['image'],
        'center': json['center'] == null ? undefined : AdminInfrastructureImportItemLocationFromJSON(json['center']),
        'processed': json['processed'] == null ? undefined : json['processed'],
    };
}

export function PlumeImageRequestToJSON(value?: PlumeImageRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'image': value['image'],
        'center': AdminInfrastructureImportItemLocationToJSON(value['center']),
        'processed': value['processed'],
    };
}

