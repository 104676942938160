/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NonDetectGeometry
 */
export interface NonDetectGeometry {
    /**
     * 
     * @type {string}
     * @memberof NonDetectGeometry
     */
    type?: NonDetectGeometryTypeEnum;
    /**
     * 
     * @type {Array<Array<Array<Array<number>>>>}
     * @memberof NonDetectGeometry
     */
    coordinates?: Array<Array<Array<Array<number>>>>;
}


/**
 * @export
 */
export const NonDetectGeometryTypeEnum = {
    MultiPolygon: 'MultiPolygon'
} as const;
export type NonDetectGeometryTypeEnum = typeof NonDetectGeometryTypeEnum[keyof typeof NonDetectGeometryTypeEnum];


/**
 * Check if a given object implements the NonDetectGeometry interface.
 */
export function instanceOfNonDetectGeometry(value: object): boolean {
    return true;
}

export function NonDetectGeometryFromJSON(json: any): NonDetectGeometry {
    return NonDetectGeometryFromJSONTyped(json, false);
}

export function NonDetectGeometryFromJSONTyped(json: any, ignoreDiscriminator: boolean): NonDetectGeometry {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : json['type'],
        'coordinates': json['coordinates'] == null ? undefined : json['coordinates'],
    };
}

export function NonDetectGeometryToJSON(value?: NonDetectGeometry | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'coordinates': value['coordinates'],
    };
}

